import React from "react";
import { Show, TextField, SimpleShowLayout, RichTextField, BooleanField } from "react-admin";

export const AccordionShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="title" label="Title" />
      <RichTextField source="heading_content" label="Heading content" />
      <RichTextField source="content" label="Content" />
      <RichTextField source="footer_contet" label="Footer content" />
      <BooleanField label="Table with discount beneftits" source="has_dynamic_table" />
      <BooleanField
        label="Table with regrut benefits"
        source="has_dynamic_table_discount_for_invites"
      />
      <TextField source="sort_order" label="Order" />
    </SimpleShowLayout>
  </Show>
);
