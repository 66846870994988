import resources from "../../../resources/resources";

// Map includes to fields
const fields = {
  USERS_EXPORT: {
    users: {
      first_name: {
        field: "first_name",
        alias: "First name"
      },
      rid: {
        field: "rid",
        alias: "RID"
      },
      last_name: {
        field: "last_name",
        alias: "Last name"
      },
      patronimic_name: {
        field: "patronimic_name",
        alias: "Patronimic name"
      },
      company_name: {
        field: "company_name",
        alias: "Company name"
      },
      created_at: {
        field: "created_at",
        alias: "Created at"
      },
      updated_at: {
        field: "updated_at",
        alias: "Updated at"
      },
      last_update_by: {
        field: "last_update_by",
        alias: "Last update by"
      },
      phone: {
        field: "phone",
        alias: "Phone"
      },
      email: {
        field: "email",
        alias: "Email"
      },
      birth_date: {
        field: "birth_date",
        alias: "Birth date"
      },
      external_id: {
        field: "external_id",
        alias: "External"
      },
      source_application: {
        field: "source_application",
        alias: "Source"
      }
    },
    userTypes: {
      user_type_id: {
        field: "name",
        alias: "User type"
      }
    },
    cities: {
      "defaultAddress.city_id": {
        field: "name",
        alias: "City"
      }
    },
    addresses: {
      userAddressLines: {
        field: "address_line1",
        alias: "User address lines"
      },
      "defaultAddress.zip_code": {
        field: "zip_code",
        alias: "Zip code"
      }
    },
    userLevels: {
      user_level_id: {
        field: "name",
        alias: "User level"
      }
    },
    priceLevels: {
      price_level_id: {
        field: "name",
        alias: "Price level"
      }
    },
    stateOrProvinces: {
      stateOrProvince: {
        field: "name",
        alias: "State or province"
      }
    },
    roles: {
      userRole: {
        field: "name",
        alias: "User role"
      }
    }
  }
};

const getFields = (selection, exportFields) => {
  let fields = {};
  let aliases = [];
  // According to mdn Object.entries does not work on IE
  // So Object.keys was used everywhere

  // Fill the results object with the proper arrays
  Object.keys(exportFields).forEach(userKey => {
    fields[userKey] = [];
  });
  // Use selection object as entry point ({ key: boolean, ... })
  // when deciding what to add to fields
  Object.keys(selection).forEach(selectionKey => {
    Object.keys(exportFields).forEach(userExportKey => {
      if (
        // Example if 'fields.USERS.users' object has key
        // 'first_name' and it is selected (selection[selectionKey] === true)
        // then add it to the right results array
        // eslint-disable-next-line prettier/prettier
        Object.keys(exportFields[userExportKey]).indexOf(selectionKey) !== -1 &&
        selection[selectionKey]
      ) {
        // Example result.users.push(fields.USERS_EXPORT.users.external_id.field)
        fields[userExportKey].push(exportFields[userExportKey][selectionKey].field);
        aliases.push(
          `alias[${userExportKey}.${exportFields[userExportKey][selectionKey].field}]=${exportFields[userExportKey][selectionKey].alias}`
        );
      }
    });
  });

  return { fields, aliases };
};

const getFieldsHandler = (resource, selection) => {
  switch (resource) {
    case resources.USERS:
      return getFields(selection, fields.USERS_EXPORT);
    default:
      return {};
  }
};

export default getFieldsHandler;
