// Validation functions
import {
  validationRegex,
  validateRepetetiveCharacters,
  validateDashLetters,
  validateDashLetters2,
  validateRepetetiveDashes
} from "../../utils/validators/regex";

// Validation for product name
export const productValidationName = (value, productList) => {
  const validateName = validationRegex(/^[a-zA-Z0-9 ]+$/);

  if (!value) {
    return "Product's name is required";
  }
  const productNameExists = productList?.find(
    productName => productName?.name?.toUpperCase() === value?.toUpperCase()
  );

  if (value && validateRepetetiveCharacters(value)) {
    return "Please remove repetetive characters or white-spaces";
  }

  if (value && (value?.startsWith("-") || value?.endsWith("-"))) {
    return "Please make sure your input is not starting or ending in -";
  }

  if (value && (value?.startsWith(" ") || value?.endsWith(" "))) {
    return "Please make sure your input is not starting or ending in a white-space";
  }

  if (value && productNameExists) {
    return "Product's name already exists";
  }

  return undefined;
};

// Validation for product slug
export const productValidationSlug = (value, productList) => {
  const productSlugExists = productList?.find(
    productSlug => productSlug?.url_slug?.toUpperCase() === value?.toUpperCase()
  );

  if (value && validateRepetetiveCharacters(value) && !productSlugExists) {
    return "Please remove repetetive characters or white-spaces";
  }

  if (value && (value?.startsWith("-") || value?.endsWith("-"))) {
    return "Please make sure your input is not starting or ending in -";
  }

  if (value && (value?.startsWith(" ") || value?.endsWith(" "))) {
    return "Please make sure your input is not starting or ending in a white-space";
  }

  if (value && !validateDashLetters(value)) {
    return "Please make sure that your input only contains characters and a -";
  }

  if (value && !validateRepetetiveDashes(value)) {
    return "Please make sure you only have one - per group";
  }

  if (!value) {
    return "Product's Slug is required";
  }

  if (value && productSlugExists) {
    return "Product's slug already exists";
  }

  return undefined;
};

// Validation for product external id
export const productValidationID = (value, productList) => {
  const productExternalIDExists = productList?.find(
    productExternalID => productExternalID?.external_id?.toUpperCase() === value?.toUpperCase()
  );

  if (value && (value?.startsWith("-") || value?.endsWith("-"))) {
    return "Please make sure your input is not starting or ending in -";
  }

  if (value && (value?.startsWith(" ") || value?.endsWith(" "))) {
    return "Please make sure your input is not starting or ending in a white-space";
  }

  if (value && !validateDashLetters2(value)) {
    return "Please make sure that your input only contains allowed characters";
  }

  if (!value) {
    return "Product's external ID is required";
  }

  if (value && productExternalIDExists) {
    return "Product's external id already exists";
  }

  return undefined;
};

// Validation for product external_number
export const productValidationExternalNumber = (value, productList) => {
  const productExternalNumberExists = productList?.find(
    productExternalNumber =>
      productExternalNumber?.external_number?.toUpperCase() === value?.toUpperCase()
  );

  if (value && !validateDashLetters2(value)) {
    return "Please remove any special symbols except -, +, . and ,";
  }

  if (value && (value?.startsWith(" ") || value?.endsWith(" "))) {
    return "Please make sure your input is not starting or ending in a white-space";
  }

  if (value && (value?.startsWith("-") || value?.endsWith("-"))) {
    return "Please make sure your input is not starting or ending in -";
  }

  if (!value) {
    return "Product's external number is required";
  }

  if (value && productExternalNumberExists) {
    return "Product's external number already exists";
  }

  return undefined;
};

// Validation for SIRV link
export const productValidationSirv = value => {
  const sirvUrlRegex = validationRegex(/^https?:\/\/.+\.sirv.com\/+.+\.spin$/);
  if (value?.length > 0) {
    if (!sirvUrlRegex(value)) {
      return "Url must be in the following format 'https://[SUBDOMAIN].sirv.com/[PATH].spin'";
    }
  }
};
