import React, { useState, useEffect } from "react";
import { useDataProvider, TextInput, required } from "react-admin";

export const PageNameValidationTextInput = formValues => {
  let saveExistingPageName =
    formValues && formValues.record && formValues.record.name ? formValues.record.name : "";

  // Declaring state
  const dataProvider = useDataProvider();
  const [pagesList, setPagesList] = useState([]);
  const [currentKeyName, setCurrentKeyName] = useState(saveExistingPageName);

  // Drag needed information
  useEffect(() => {
    dataProvider
      .getList("pages", {
        filter: {},
        sort: { field: "created_at", order: "DESC" },
        pagination: { page: "all" }
      })
      .then(({ data }) => {
        setPagesList(data);
      })
      .catch(error => {
        console.error("There was a problem with data in your dataProvider, getList for pages");
      });
    return () => {
      setPagesList([]);
    };
  }, [currentKeyName]);

  // Validation for page name input field
  const validateName = (value, formValues) => {
    if (!value) {
      return "ra.validation.required";
    }

    if (value && !value.trim()) {
      return "Page name is not valid";
    }
    const pageNameExists = pagesList.find(
      page => page?.name?.toLowerCase() === value?.toLowerCase()
    );

    if (pageNameExists && pageNameExists.name.toUpperCase() !== currentKeyName.toUpperCase()) {
      return "Page name already exists. Please change page name.";
    }

    return undefined;
  };

  // Return TextInput field to be showcased on the form
  return (
    <TextInput
      variant="standard"
      style={{ minWidth: "256px" }}
      label="resources.pages.fields.name"
      source="name"
      validate={[validateName, required()]}
    />
  );
};

export const PageSlugValidationTextInput = formValues => {
  let saveExistingPageName =
    formValues && formValues.record && formValues.record.url_slug ? formValues.record.url_slug : "";

  // Declaring state
  const dataProvider = useDataProvider();
  const [pagesList, setPagesList] = useState([]);
  const [currentKeyName, setCurrentKeyName] = useState(saveExistingPageName);

  // Drag needed information
  useEffect(() => {
    dataProvider
      .getList("pages", {
        filter: {},
        sort: { field: "created_at", order: "DESC" },
        pagination: { page: "all" }
      })
      .then(({ data }) => {
        setPagesList(data);
      })
      .catch(error => {
        console.error("There was a problem with data in your dataProvider, getList for pages");
      });
    return () => {
      setPagesList([]);
    };
  }, [currentKeyName]);

  // Validation for page name input field
  const validateSlug = (value, formValues) => {
    // const countryAppId = localStorage.getItem("appId");
    // const countryIsoCode = JSON.parse(localStorage.getItem("countries"))
    //   .find(country => country.application_id === countryAppId)
    //   .iso_code_3.toLowerCase();

    if (formValues?.pageType?.name === "homepage") {
      return null;
    }
    if (!value) {
      return "ra.validation.required";
    }

    // if (value && !value.trim()) {
    //   return "Page url slug is not valid";
    // }

    const pageSlugExists = pagesList.find(page => page.url_slug === value && value.toLowerCase());

    if (pageSlugExists && pageSlugExists.url_slug.toUpperCase() !== currentKeyName.toUpperCase()) {
      return "Page url slug already exists. Please change page url slug.";
    }

    return undefined;
  };

  // Return TextInput field to be showcased on the form
  return (
    <TextInput
      variant="standard"
      style={{ minWidth: "256px" }}
      label="resources.pages.fields.url_slug"
      source="url_slug"
      validate={[validateSlug, required()]}
    />
  );
};

export const ValidateBannerLink = value => {
  let bannerLinkString = value;

  if (
    value &&
    !bannerLinkString?.startsWith("/categories/") &&
    !bannerLinkString?.startsWith("https://")
  ) {
    return "Please follow the string schema: /categories/NAME or enter a SSL url";
  }

  if (bannerLinkString?.length === 12 && bannerLinkString?.startsWith("/categories/")) {
    return "Please attach the name of your category";
  }

  return undefined;
};
