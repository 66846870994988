import { handleSingleRequest } from "../restClient";

export const transformBestSellingProductCreateUpdate = (type, resource, params) => {
  const newParams = {
    ...params,
    data: { ...params.data, ids: params.data.bestsellers.map(item => item.product_id) }
  };

  delete newParams.data.bestsellers;

  return handleSingleRequest(type, "bestSellingProductUpdates", newParams).then(
    ({ data = [], included = [], ...rest }) => {
      return { data, ...rest };
    }
  );
};
