import React from "react";
import { Show, TextField, SimpleShowLayout } from "react-admin";

export const DiscountForNumberOfRegisteredShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="number_of_registered" label="number_of_registered" />
      <TextField source="display_price_level" label="Display price level" />
      <TextField source="price_level" label="Price level" />
      <TextField source="sort_order" label="Order" />
    </SimpleShowLayout>
  </Show>
);
