import React from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceField
} from "react-admin";

const ShopShowTitle = ({ record }) => `Shop ${record.name}`;

export const ShopShow = props => (
  <Show title={<ShopShowTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField label="resources.shops.fields.name" source="name" />
      <TextField label="resources.shops.fields.domain" source="domain" />
      <TextField label="resources.shops.fields.client_name" source="client_name" />
      <ReferenceField
        label="resources.shops.fields.homepage_name"
        source="homepage_id"
        reference="pages"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField label="resources.shops.fields.start_date" source="start_date" />
      <DateField label="resources.shops.fields.end_date" source="end_date" />
      <ReferenceArrayField
        // addLabel={false}
        reference="productCategories"
        source="categoryIds"
        label="resources.shops.fields.categories"
        allowEmpty
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField label="resources.shops.fields.created_at" source="created_at" />
      <DateField label="resources.shops.fields.updated_at" source="updated_at" />
    </SimpleShowLayout>
  </Show>
);
