import React from "react";
import { Layout, Sidebar } from "react-admin";
import AppBar from "./AppBar";
import CSVDownloadInfo from "../CSVDownloadInfo";

const CustomSidebar = props => (
  <Sidebar size={200} id="sidebar" {...props}>
    <div>
      {props.children}
      <CSVDownloadInfo />
    </div>
  </Sidebar>
);

const CustomLayout = props => <Layout appBar={AppBar} sidebar={CustomSidebar} {...props} />;

export default CustomLayout;
