export const googleCategories = [
  {
    id: "Hardware > Plumbing > Water Dispensing & Filtration",
    name: "Hardware > Plumbing > Water Dispensing & Filtration"
  },
  {
    id: "Health & Beauty > Health Care > Light Therapy Lamps",
    name: "Health & Beauty > Health Care > Light Therapy Lamps"
  },
  {
    id: "Health & Beauty > Personal Care > Cosmetics ",
    name: "Health & Beauty > Personal Care > Cosmetics "
  },
  {
    id: "Home & Garden > Kitchen & Dining > Tableware",
    name: "Home & Garden > Kitchen & Dining > Tableware"
  },
  { id: "Home & Garden > Kitchen & Dining", name: "Home & Garden > Kitchen & Dining" },
  {
    id: "Health & Beauty > Personal Care > Vision Care > Eyewear Accessories",
    name: "Health & Beauty > Personal Care > Vision Care > Eyewear Accessories"
  },
  {
    id: "Home & Garden > Household Appliance Accessories > Vacuum Accessories",
    name: "Home & Garden > Household Appliance Accessories > Vacuum Accessories"
  },
  {
    id: "Home & Garden > Household Appliances > Climate Control Appliances > Air Purifiers",
    name: "Home & Garden > Household Appliances > Climate Control Appliances > Air Purifiers"
  }
];
