import React from "react";
import { List, TextField, DateField, BooleanField, ReferenceField } from "react-admin";

import { ListFilters } from "./Filters";

import CustomizableDatagrid from "../../components/CustomizableDatagrid";

const styles = {
  activeNotification: {
    backgroundColor: "green",
    width: "50%",
    maxWidth: "50px",
    height: "50px"
  },
  inactiveNotification: {
    backgroundColor: "red",
    width: "50%",
    maxWidth: "50px",
    height: "50px"
  },
  draftNotification: {
    backgroundColor: "orange",
    width: "50%",
    maxWidth: "50px",
    height: "50px"
  }
};

const NotificationStatusField = ({ record }) => {
  return (
    <div
      style={
        record.active === true && record.published === true
          ? styles.activeNotification
          : record.active === true && record.published === false
          ? styles.inactiveNotification
          : record.active === false && record.published === false
          ? styles.draftNotification
          : styles.inactiveNotification
      }
    />
  );
};

export const NotificationsList = props => (
  <List exporter={false} bulkActionButtons={false} {...props} filters={<ListFilters />}>
    <CustomizableDatagrid
      rowClick="edit"
      defaultColumns={[
        "category_id",
        "title",
        "description",
        "schedule_at",
        "override_consent",
        "published",
        "active",
        "notification_sent"
      ]}
    >
      <NotificationStatusField />
      <ReferenceField
        label="resources.notifications.fields.category_id"
        link={false}
        source="category_id"
        reference="notificationCategories"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField label="resources.notifications.fields.title" source="title" />
      <TextField label="resources.notifications.fields.body" source="body" />
      <BooleanField
        label="resources.notifications.fields.override_consent"
        source="override_consent"
      />
      <BooleanField label="resources.notifications.fields.published" source="published" />
      <BooleanField label="resources.notifications.fields.active" source="active" />
      <DateField label="resources.notifications.fields.created_at" source="created_at" />
      <DateField label="resources.notifications.fields.updated_at" source="updated_at" />
      <BooleanField
        label="resources.notifications.fields.notification_sent"
        source="notification_sent"
      />
    </CustomizableDatagrid>
  </List>
);
