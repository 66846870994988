import React from "react";
import { List, TextField, Datagrid } from "react-admin";

export const TableList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField label="Key" source="key" />
      <TextField label="Value" source="value" />
      <TextField label="Order" source="sort_order" />
    </Datagrid>
  </List>
);
