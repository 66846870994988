import React from "react";
import { Filter, ReferenceInput, AutocompleteInput, SelectInput, translate } from "react-admin";

const PriceFilters = ({ translate, ...rest }) => (
  <Filter variant="standard" {...rest}>
    {/* <ReferenceInput
      source="product_id"
      reference="products"
      sort={{ field: "name", order: "ASC" }}
      allowEmpty={true}
      filterToQuery={name => ({ name_ilike: name })}
      alwaysOn
      resettable
    >
      <AutocompleteInput
        optionText="name"
        isFilter={true}
        limitChoicesToValue
      />
    </ReferenceInput> */}
    <ReferenceInput
      label="External number"
      source="product_id"
      reference="products"
      sort={{ field: "name", order: "ASC" }}
      allowEmpty={true}
      filterToQuery={name => {
        if (name) {
          return { external_number_ilike: name };
        }
      }}
      alwaysOn
      resettable
    >
      <AutocompleteInput optionText="external_number" isFilter={true} limitChoicesToValue />
    </ReferenceInput>
    <ReferenceInput
      source="price_level_id"
      reference="priceLevels"
      sort={{ field: "name", order: "ASC" }}
      allowEmpty={true}
      resettable
      perPage={200}
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export default translate(PriceFilters);
