import React, { Component } from "react";
import { translate } from "react-admin";
import { Link } from "react-router-dom";
import compose from "recompose/compose";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination
} from "@material-ui/core";
import moment from "moment";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";
import { defaultLabelDisplayedRows } from "./index";

const style = theme => ({
  root: {
    marginTop: "2%"
  },
  cl100: {
    fontWeight: "bold",
    color: theme.palette.primary.main
  },
  itemTextRight: {
    fontSize: "0.6em"
  },
  chip: {
    fontSize: "0.6em",
    color: theme.palette.primary.main
  },
  headerLabel: {
    fontWeight: "bold",
    borderBottom: "4px solid #3498db",
    width: "fit-content",
    margin: "12px 24px"
  },
  item: {
    padding: "8px 24px"
  },
  customerName: {
    textTransform: "uppercase",
    color: "#000",
    textDecoration: "none !important"
  },
  contactInfo: {
    fontSize: "9px",
    color: "#A5A5A5"
  },
  tableHeader: {
    fontSize: "9px",
    fontWeight: "600"
  },
  tableCellHead: {
    padding: "0 24px"
  },
  tableCellBody: {
    padding: 0
  },
  tableCellBodyContent: {
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "8px 24px",
    minHeight: 86
  }
});

class LatestCustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "calories",
      selected: [],
      data: props.latestCustomers,
      page: 0,
      rowsPerPage: 10
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.latestCustomers !== prevProps.latestCustomers) {
      this.setState({ data: this.props.latestCustomers });
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { latestCustomersTotal, classes } = this.props;
    const { data, rowsPerPage, page } = this.state;

    return (
      <Card className={classes.root}>
        <TranslatableParagraph
          customStyling={{
            fontWeight: "bold",
            width: "fit-content",
            marginLeft: "1.25rem",
            fontSize: "11px",
            borderBottom: "3px solid #3489db"
          }}
          translationLabel="resources.dashboard.tabs.customers.latestCustomers"
        />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellHead}>
                <TranslatableParagraph translationLabel="resources.dashboard.tabs.customers.details" />
              </TableCell>
              <TableCell className={classes.tableCellHead}>
                <TranslatableParagraph translationLabel="resources.dashboard.tabs.customers.platform" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .reverse()
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map(customer => {
                const customerName = customer.customer_name;

                const phone = customer.phone;
                const email = customer.email;
                const created_at = customer.date;

                const isCL100 =
                  customer.role_name === "Affiliate" || customer.role_name === "Member";

                const applicationSource = customer.source_application;

                return (
                  <TableRow key={customer.id}>
                    <TableCell className={classes.tableCellBody}>
                      <Link
                        to={`/users/${customer.id}/show`}
                        className={classes.tableCellBodyContent}
                      >
                        <p style={{ margin: 0 }}>
                          <span className={classes.customerName}>{customerName + " / "}</span>
                          {isCL100 && <span className={classes.cl100}>{"CL100"}</span>}
                        </p>
                        <p style={{ margin: "5px 0 0" }} className={classes.contactInfo}>
                          {phone}
                        </p>
                        <p style={{ margin: 0 }} className={classes.contactInfo}>
                          {email}
                        </p>
                        <div
                          style={{
                            margin: 0,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                          }}
                          className={classes.contactInfo}
                        >
                          <TranslatableParagraph
                            customStyling={{ margin: 0 }}
                            translationLabel={`resources.dashboard.tabs.customers.joinedOn`}
                          />{" "}
                          : {moment(created_at).format("MMM DD YYYY")}
                        </div>
                      </Link>
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      <Link
                        to={`/users/${customer.id}/show`}
                        className={classes.tableCellBodyContent}
                      >
                        <span className={classes.itemTextRight}>{applicationSource}</span>
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={latestCustomersTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={
            <TranslatableParagraph translationLabel="ra.navigation.page_rows_per_page" />
          }
          labelDisplayedRows={defaultLabelDisplayedRows}
          backIconButtonProps={{
            "aria-label": <TranslatableParagraph translationLabel="ra.navigation.next" />
          }}
          nextIconButtonProps={{
            "aria-label": <TranslatableParagraph translationLabel="ra.navigation.previous" />
          }}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Card>
    );
  }
}

const enhance = compose(withStyles(style), translate);

export default enhance(LatestCustomers);
