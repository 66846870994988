import React, { Component } from "react";
import PrintAdvancedItem from "./PrintAdvancedItem";

class PrintAdvancedList extends Component {
  render() {
    const { orders, selectedIds } = this.props;
    const selectedOrders = Object.values(orders).filter(order => selectedIds.includes(order.id));
    return (
      <table style={{ width: "100%", padding: "0.5rem" }}>
        <tbody>
          {selectedOrders.map(order => {
            return <PrintAdvancedItem key={order.id} order={order} />;
          })}
        </tbody>
      </table>
    );
  }
}

export default PrintAdvancedList;
