import { handleSingleRequest } from "../restClient";
import { GET_LIST, DELETE } from "../types";
import moment from "moment";
import { DELETE_MANY } from "react-admin";

import {
  zboziTemplate,
  facebookCsvTemplate,
  googleMerchentCsvTemplate
} from "../../../constants/feedTemplates";

const addAttributeType = value => {
  switch (value) {
    case "in_stock":
      return "stock_func";
    case "member_price":
      return "price";
    case "rrp_price":
      return "price";
    case "image_url":
      return "image";
    default:
      return "text";
  }
};

const addFileType = value => {
  switch (value) {
    case "zbozi":
      return "application/xml";
    case "facebookCsv":
      return "text/csv";
    case "googleMCsv":
      return "text/csv";
    default:
      return value;
  }
};

export const transformProductFeedsCreateUpdate = (type, resource, params) => {
  const startTime =
    moment(params.data.cron.start_date).format("YYYY-MM-DDTHH:mm:ss.sssZ").slice(0, 19) + "Z";
  const endTime =
    moment(params.data.cron.end_date).format("YYYY-MM-DDTHH:mm:ss.sssZ").slice(0, 19) + "Z";
  const createUpdateDate =
    params.data.created_at?.length < 11 && params.data.updated_at?.length < 11
      ? {
          created_at: params.data.created_at + "T00:00:00Z",
          updated_at: params.data.updated_at + "T00:00:00Z"
        }
      : null;
  const data = {
    ...params.data,
    ...createUpdateDate,
    file_type: addFileType(params.data.file_type),
    template:
      type === "CREATE" && params.data.file_type === "zbozi"
        ? zboziTemplate
        : type === "CREATE" && params.data.file_type === "facebookCsv"
        ? facebookCsvTemplate
        : type === "CREATE" && params.data.file_type === "googleMCsv"
        ? googleMerchentCsvTemplate
        : {
            ...params.data.template,
            attr_map: params.data.template?.attr_map?.map(item => {
              return {
                ...item,
                attribute_type: addAttributeType(item.field_name)
              };
            })
          },
    schedule_cron: [
      {
        start_at: startTime,
        end_at: endTime,
        every: 7,
        period: "days",
        period_opt: null
        // at: at
      }
    ]
  };
  return handleSingleRequest(type, resource, {
    ...params,
    data
  }).then(({ data = [], included = [], ...rest }) => {
    return { data, ...rest };
  });
};

export const transformProductFeeds = (type, resource, params) => {
  if (type === DELETE && params.id) {
    handleSingleRequest(DELETE, resource, {
      id: params.id,
      data: {}
    });
  } else if (type === DELETE_MANY && params.ids?.length) {
    params.ids.forEach(item => {
      handleSingleRequest(DELETE, resource, {
        id: item,
        data: {}
      });
    });
  }
  return handleSingleRequest(GET_LIST, resource, {
    filter: {},
    pagination: { page: 1, perPage: 10 },
    sort: { ...params.sort }
  }).then(async ({ data = {}, included = [], relationships, ...rest }) => {
    const newData = data.map(item => {
      return {
        ...item,
        created_at: item.created_at.slice(0, 10),
        updated_at: item.updated_at.slice(0, 10)
      };
    });
    return {
      data: newData,
      ...rest
    };
  });
};

export const transformProductFeed = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params
  }).then(async ({ data = {}, included = [], relationships, ...rest }) => {
    const newDataErrors = [];
    data?.data_errors &&
      data?.data_errors.map(item => {
        return (
          !newDataErrors.find(storedItem => storedItem.product_id === item.product_id) &&
          newDataErrors.push(item)
        );
      });
    const newData = {
      ...data,
      created_at: data.created_at.slice(0, 10),
      updated_at: data.updated_at.slice(0, 10),
      cron: {
        start_date: data.schedule_cron[0].start_at,
        end_date: data.schedule_cron[0].end_at
      },
      data_errors: newDataErrors
    };
    return {
      data: newData,
      ...rest
    };
  });
};
