import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  SaveButton,
  DateTimeInput,
  ListButton,
  Toolbar,
  required
} from "react-admin";

import DeleteUserPopup from "../../components/DeleteUserPopup";
// Icons
// import SaveIcon from "@material-ui/icons/Save";
// import DeleteIcon from "@material-ui/icons/Delete";
import GroupIcon from "@material-ui/icons/Group";

// Accordion 3rd party components
import Tooltip from "@material-ui/core/Tooltip";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

// Validation
const validatePhoneEmail = values => {
  const errors = {};
  var reEmail =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.)+[^<>()\.,;:\s@\"]{2,})$/gi;
  var rePhone =
    /^(?=(\+93|\+355|\+213|\+1-684|\+376|\+244|\+1-264|\+672|\+1-268|\+54|\+374|\+297|\+61|\+43|\+994|\+1-242|\+973|\+880|\+1-246|\+375|\+501|\+229|\+1-441|\+975|\+591|\+387|\+267|\+55|\+246|\+1-284|\+673|\+359|\+226|\+257|\+855|\+237|\+1|\+238|\+1-345|\+236|\+235|\+56|\+86|\+61|\+61|\+57|\+269|\+682|\+506|\+385|\+53|\+599|\+357|\+420|\+243|\+45|\+253|\+1-767|\+1-809 |\+1-829 |\+1-849|\+670|\+593|\+20|\+503|\+240|\+291|\+372|\+251|\+500|\+298|\+679|\+358|\+33|\+689|\+241|\+220|\+995|\+49|\+233|\+350|\+30|\+299|\+1-473|\+1-671|\+502|\+44-1481|\+224|\+245|\+592|\+509|\+504|\+852|\+36|\+354|\+91|\+62|\+98|\+964|\+353|\+44-1624|\+972|\+39|\+225|\+1-876|\+81|\+44-1534|\+962|\+7|\+254|\+686|\+383|\+965|\+996|\+856|\+371|\+961|\+266|\+231|\+218|\+423|\+370|\+352|\+853|\+389|\+261|\+265|\+60|\+960|\+223|\+356|\+692|\+222|\+230|\+262|\+52|\+691|\+373|\+377|\+976|\+382|\+1-664|\+212|\+258|\+95|\+264|\+674|\+977|\+31|\+599|\+687|\+64|\+505|\+227|\+234|\+683|\+850|\+1-670|\+47|\+968|\+92|\+680|\+970|\+507|\+675|\+595|\+51|\+63|\+64|\+48|\+351|\+1-787 |\+1-939|\+974|\+242|\+262|\+40|\+7|\+250|\+590|\+290|\+1-869|\+1-758|\+590|\+508|\+1-784|\+685|\+378|\+239|\+966|\+221|\+381|\+248|\+232|\+65|\+1-721|\+421|\+386|\+677|\+252|\+27|\+82|\+211|\+34|\+94|\+249|\+597|\+47|\+268|\+46|\+41|\+963|\+886|\+992|\+255|\+66|\+228|\+690|\+676|\+1-868|\+216|\+90|\+993|\+1-649|\+688|\+1-340|\+256|\+380|\+971|\+44|\+1|\+598|\+998|\+678|\+379|\+58|\+84|\+681|\+212|\+967|\+260|\+263)\d{6,15}$)/;
  if (!reEmail.test(values.email)) {
    errors.email = ["Email is not in valid form"];
  }
  if (!rePhone.test(values.phone)) {
    errors.phone = ["Please enter valid phone number with correct country prefix"];
  }
  // if (!values.deleted_by) {
  //   errors.deleted_by = ["Full Name is required"];
  // }
  // if (!values.delete_requested_at) {
  //   errors.delete_requested_at = ["Valid date is required"];
  // }
  return errors;
};

const Title = ({ record }) => `Edit ${record.readable_name} phone & email `;

// Styling
const styles = {
  content: {
    flexDirection: "column"
  },
  guide: {
    backgroundColor: "rgba(255,0,0,0.6)",
    padding: "5px 10px",
    color: "#f9f9f9",
    fontSize: "13px",
    marginRight: "-8px",
    marginLeft: "-8px",
    marginBottom: "10px",
    lineHeight: "30px"
  },
  warning: {
    backgroundColor: "#f9f9f9"
  },
  text: {
    textAlign: "left",
    color: "#f8f8f8"
  },
  svg: {
    fontSize: "18px",
    verticalAlign: "middle",
    marginRight: "2px"
  },
  save: {
    color: "#3498db",
    backgroundColor: "#f8f8f8",
    padding: "5px",
    borderRadius: "4px",
    margin: "0 5px"
  },
  delete: {
    color: "red",
    backgroundColor: "#f8f8f8",
    padding: "5px",
    borderRadius: "4px",
    margin: "0 5px"
  }
};

const ListBtn = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%"
      }}
    >
      <ListButton
        style={{ marginLeft: "10px", justifyContent: "flex-end" }}
        to={`/users`}
        label="resources.users.custom.back_to_users"
        icon={<GroupIcon />}
      />
    </div>
  );
};

const UserEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton redirect={`/users/${props.record.id}`} />
    <DeleteUserPopup />
    <ListBtn />
  </Toolbar>
);

const DeleteUserForm = props => {
  const { basePath, ...rest } = props;

  return (
    <Accordion {...rest} variant="elevation" style={styles.warning}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <TranslatableParagraph translationLabel="resources.users.fields.delete_form.delete_user_form" />{" "}
      </AccordionSummary>
      <AccordionDetails style={styles.content}>
        <div style={styles.guide}>
          <TranslatableParagraph translationLabel="resources.users.fields.delete_form.delete_user_text" />
        </div>
        <Tooltip
          title={
            <TranslatableParagraph translationLabel="resources.users.fields.delete_form.logging" />
          }
          placement="top"
        >
          <Typography component="div">
            <TextInput
              source="deleted_by"
              label="resources.users.fields.delete_form.deleted_by"
              variant="standard"
              style={{ minWidth: "100%" }}
            />
            <DateTimeInput
              source="delete_requested_at"
              label="resources.users.fields.delete_form.delete_date"
              variant="standard"
              style={{ minWidth: "100%" }}
            />
          </Typography>
        </Tooltip>
      </AccordionDetails>
    </Accordion>
  );
};

// const labelino = <SaveIcon style={styles.svg} />;

export const editPhoneEmail = props => {
  return (
    <Edit title={<Title />} {...props}>
      <SimpleForm variant="standard" validate={validatePhoneEmail} toolbar={<UserEditToolbar />}>
        <TextInput label="resources.users.fields.edit_phone" source="phone" validate={required()} />
        <TextInput label="resources.users.fields.edit_email" source="email" validate={required()} />
        <TextInput
          defaultValue="API"
          initialValue="API"
          disabled
          source="last_update_by"
          validate={required()}
        />
        <DeleteUserForm />
      </SimpleForm>
    </Edit>
  );
};
