import React, { useEffect, useState } from "react";
import { Create, Edit, SimpleForm, ReferenceInput, AutocompleteInput, GET_LIST } from "react-admin";
import { parse } from "query-string";
import { handleSingleRequest } from "../services/api/restClient";

export const ProductTagCreate = props => {
  const [assignedTags, setAssignedTags] = useState([]);
  const [tags, setTags] = useState([]);

  const { source: searchSource } = parse(props.location.search);
  const { product_id, tag_id } = JSON.parse(searchSource);

  const fetchProductTags = async () => {
    try {
      const response = await handleSingleRequest(GET_LIST, "productTags", {
        pagination: { page: "all" },
        sort: { field: "id", order: "DESC" },
        filter: { product_id }
      });
      setAssignedTags(response.data.map(tag => ({ id: tag.tag_id })));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTags = async () => {
    try {
      const response = await handleSingleRequest(GET_LIST, "tags", {
        pagination: { page: "all" },
        sort: { field: "id", order: "DESC" },
        filter: { product_id }
      });
      setTags(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (product_id) {
      fetchProductTags();
      fetchTags();
    }
  }, []);

  const unassignedTags = tags.filter(
    tag => !assignedTags.some(assignedTag => assignedTag.id === tag.id)
  );

  const redirect = product_id
    ? `/products/${product_id}/show`
    : tag_id
    ? `/tags/${tag_id}/show`
    : false;

  return (
    <Create title="resources.products.tabs.assigned_tags.title" {...props}>
      <SimpleForm variant="standard" redirect={redirect}>
        <ReferenceInput
          source="product_id"
          label="resources.products.tabs.assigned_tags.name"
          reference="products"
          sort={{ field: "name", order: "ASC" }}
          allowEmpty
          filterToQuery={name => {
            if (name) {
              return { external_number_ilike: name };
            }
          }}
          alwaysOn
          resettable
        >
          <AutocompleteInput optionText="external_number" isFilter limitChoicesToValue />
        </ReferenceInput>
        <AutocompleteInput
          source="tag_id"
          choices={unassignedTags}
          optionText="name"
          resettable
          suggestionLimit={10}
        />
      </SimpleForm>
    </Create>
  );
};

export const TagEdit = props => (
  <Edit title="resources.products.tabs.assigned_tags.edit.title" undoable={false} {...props}>
    <SimpleForm variant="standard">
      <ReferenceInput
        source="product_id"
        reference="products"
        label="resources.products.tabs.assigned_tags.name"
        sort={{ field: "name", order: "ASC" }}
        allowEmpty
        filterToQuery={name => {
          if (name) {
            return { external_number_ilike: name };
          }
        }}
        alwaysOn
        resettable
      >
        <AutocompleteInput optionText="external_number" isFilter limitChoicesToValue />
      </ReferenceInput>
      <ReferenceInput
        source="tag_id"
        reference="tags"
        label="resources.products.tabs.assigned_tags.tag_name"
        sort={{ field: "name", order: "ASC" }}
        allowEmpty
        filterToQuery={name => {
          if (name) {
            return { name_ilike: name };
          }
        }}
        alwaysOn
        resettable
      >
        <AutocompleteInput optionText="name" isFilter limitChoicesToValue />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
