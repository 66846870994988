export const devsprints = [
  {
    name: "31",
    date: "03/11/2022",
    version: "v2.8.0 + v2.9.0",
    id: "031",
    tasks: [
      {
        marp: `ZEM-987`,
        description: `Pages - Content - User is unable to delete content from page.`,
        type: `BUG`
      },
      {
        marp: `ZEM-992`,
        description: `Inability to view record if language has been disabled`,
        type: `BUG`
      },
      {
        marp: `ZEM-1037`,
        description: `Categories - Translations - All inactive (language) translations are deleted after user save new one`,
        type: `BUG`
      },
      {
        marp: `ZEM-1036`,
        description: `Categories - Translations - Add link dissapears after user refresh browser`,
        type: `BUG`
      },
      {
        marp: `ZEM-987`,
        description: `Pages - Content - User is unable to delete content from page.`,
        type: `BUG`
      },
      {
        marp: `ZEM-792`,
        description: `Product technical data translations`,
        type: `STORY`
      },
      {
        marp: `ZEM-896`,
        description: `Product technical data translations`,
        type: `TASK`
      },
      {
        marp: `ZEM-819`,
        description: `Add personal number - National ID to  users table in Admin page`,
        type: `TASK`
      },
      {
        marp: `ZEM-648`,
        description: `3D model of product in product presentation`,
        type: `TASK`
      },
      {
        marp: `ZEM-902`,
        description: `Footer contact information translation`,
        type: `TASK`
      },
      {
        marp: `ZEM-857`,
        description: `Blog articles - Multilanguage improvement`,
        type: `TASK`
      },
      {
        marp: `ZEM-1043`,
        description: `Footer url path input`,
        type: `TASK`
      }
    ]
  },
  {
    name: "30",
    date: "13/09/2022",
    version: "v2.7.0",
    id: "030",
    tasks: [
      {
        marp: `ZEM-737`,
        description: `Editor popup labels on Admin pages is not reading the strings correctly`,
        type: `BUG`
      },
      {
        marp: `ZEM-736`,
        description: `Text alignment on Pages ( Admin ) not passing correctly on WEB`,
        type: `BUG`
      },
      {
        marp: `ZEM-408`,
        description: `Add CU number in Specific Users Price Rules`,
        type: `BUG`
      },
      {
        marp: `ZEM-694`,
        description: `Banners on older pages tend to disappear, sometimes it happens on the newer ones. `,
        type: `BUG`
      },
      {
        marp: `ZEM-758`,
        description: `US - Admin - Trying to edit Homepage in admin causes "something went wrong" error`,
        type: `BUG`
      }
    ]
  },
  {
    name: "29",
    date: "11/08/2022",
    version: "v2.6.0",
    id: "029",
    tasks: [
      {
        marp: `ZEM-566`,
        description: `Updating user with already existing phone or email breaks the page`,
        type: `BUG`
      },
      {
        marp: `ZEM-232`,
        description: `Admin dashboard blank screen when user recreate blog category slug`,
        type: `BUG`
      },
      {
        marp: `ZEM-573`,
        description: `Admin - Pages - Duplicated "Content" tab is present`,
        type: `BUG`
      },
      {
        marp: `ZEM-407`,
        description: `Admin Product description is not presented on WEB or Mobile app`,
        type: `BUG`
      },
      {
        marp: `ZEM-393`,
        description: `ADMIN -Setting all images fields to accept upper case letters and lower case letters`,
        type: `BUG`
      },
      {
        marp: `ZEM-382`,
        description: `ADMIN - Url slug should have validation`,
        type: `BUG`
      },
      {
        marp: `ZEM-502`,
        description: `EPTI Admin - Page content translation - Multiple pages can be published at the same time and language should be a mandatory field`,
        type: `BUG`
      },
      {
        marp: `ZEM-509`,
        description: `EPTI Admin - Pages - page_name label is displayed as "resources.pageContents.fields.page_name"`,
        type: `BUG`
      },
      {
        marp: `ZEM-510`,
        description: `EPTI Admin - "Language" is not mandatory in Edit mode for already created pages without set language`,
        type: `BUG`
      },
      {
        marp: `ZEM-414`,
        description: `EPTI Admin - Dashboard - Issue with "Latest orders" label when there are no orders`,
        type: `BUG`
      },
      {
        marp: `ZEM-504`,
        description: `Multi language settings in Admin`,
        type: `TASK`
      },
      {
        marp: `ZEM-517`,
        description: `Tech Data - Creating || Editing redirects us back to the first tab on Show View`,
        type: `TASK`
      },
      {
        marp: `ZEM-489`,
        description: `Admin Product Description Improvements`,
        type: `TASK`
      },
      {
        marp: `ZEM-545`,
        description: `Rename the field for product title`,
        type: `TASK`
      },
      {
        marp: `ZEM-360`,
        description: `Page content translation Admin`,
        type: `TASK`
      },
      {
        marp: `ZEM-519`,
        description: `Admin Product Description Improvements - Mobile`,
        type: `TASK`
      },
      {
        marp: `ZEM-93`,
        description: `Add new one-time payment method - Admin pricing rules`,
        type: `TASK`
      },
      {
        marp: `ZEM-501`,
        description: `Blog article translation - Admin`,
        type: `TASK`
      },
      {
        marp: `ZEM-133`,
        description: `Make category name multi language - Admin`,
        type: `TASK`
      },
      {
        marp: `ZEM-87`,
        description: `Page content translation`,
        type: `STORY`
      },
      {
        marp: `ZEM-359`,
        description: `Page content translation`,
        type: `STORY`
      },
      {
        marp: `ZEM-103`,
        description: `Blog content translation`,
        type: `STORY`
      }
    ]
  },
  {
    name: "28",
    date: "08/07/2022",
    version: "v2.5.0",
    id: "028",
    tasks: [
      {
        marp: `ZEM-65`,
        description: `Unable to create a new page in Admin`,
        type: `BUG`
      },
      {
        marp: `ZEM-299`,
        description: `Blog post upload issue`,
        type: `BUG`
      },
      {
        marp: `ZEM-44`,
        description: `Page clone button not working`,
        type: `BUG`
      },
      {
        marp: `ZEM-251`,
        description: `Editing already existing blog post`,
        type: `BUG`
      },
      {
        marp: `ZEM-34`,
        description: `Price rules, remove clickable options on 404 elements`,
        type: `BUG`
      },
      {
        marp: `ZEM-220`,
        description: `Issue with user manual upload`,
        type: `BUG`
      },
      {
        marp: `ZEM-31`,
        description: `Company name not visible`,
        type: `BUG`
      },
      {
        marp: `ZEM-209`,
        description: `Pages not properly filtering when searching for page parent`,
        type: `BUG`
      },
      {
        marp: `ZEM-371`,
        description: `Problem with text input when creating a new page`,
        type: `TASK`
      }
    ]
  },
  {
    name: "27",
    date: "03/06/2022",
    version: "v2.3.0",
    id: "020",
    tasks: [
      {
        marp: `MARP-4266`,
        description: `Can not edit or create new category due name validation`,
        type: `BUG`
      },
      {
        marp: `MARP-3811`,
        description: `Product are missing in bonus products list`,
        type: `BUG`
      },
      {
        marp: `MARP-4162`,
        description: `Pages | Ability to create pages with same url slug`,
        type: `BUG`
      },
      {
        marp: `MARP-857`,
        description: `Search option for Pages`,
        type: `IMPROVEMENT`
      },
      {
        marp: `MARP-2302`,
        description: `Source button in WYSIWYG editor`,
        type: `TASK`
      },
      {
        marp: `MARP-4209`,
        description: `Separate configurations for Mobile and Web`,
        type: `TASK`
      },
      {
        marp: `MARP-4344`,
        description: `CMS Admin Access`,
        type: `TASK`
      }
    ]
  },
  {
    name: "26",
    date: "28/04/2022",
    version: "v2.2.0",
    id: "019",
    tasks: [
      {
        marp: `MARP-3810`,
        description: `EPTI Admin - Price Rules - "More than" operators can not be removed from the price rule`,
        type: `BUG`
      },
      {
        marp: `MARP-4089`,
        description: `PriceRules | Reordering weights breaks on save functionality`,
        type: `BUG`
      },
      {
        marp: `MARP-4145`,
        description: `Pages | Block ability for a page to be parent to itself`,
        type: `BUG`
      },
      {
        marp: `MARP-1768`,
        description: `Promotions - EPTI Admin - After updating the price level in admin, all products are shown up in the promotions on HR and RU (even though only couple of them are set for the promotions)`,
        type: `BUG`
      },
      {
        marp: `MARP-3862`,
        description: `Admin - Products - Error is displayed when user tries to delete an image from the details page`,
        type: `BUG`
      },
      {
        marp: `MARP-3834`,
        description: `Admin - Dashboard - Orders tab - Message for "No data available for selected Period" should be displayed instead "resources.dashboard.tabs.mutual.noDataForPeriod"`,
        type: `BUG`
      },
      {
        marp: `MARP-3941`,
        description: `Defining error message - when user create category with existing name`,
        type: `BUG`
      },
      {
        marp: `MARP-4149`,
        description: `Option in price rules "Is bonus?" is not working on WEB - APP`,
        type: `BUG`
      },
      {
        marp: `MARP-4179`,
        description: `ADMIN - Can not create or edit promotions price rule`,
        type: `BUG`
      },
      {
        marp: `MARP-1197`,
        description: `Sorting Banners`,
        type: `STORY`
      },
      {
        marp: `MARP-3790`,
        description: `Create unique category and subcategories`,
        type: `STORY`
      },
      {
        marp: `MARP-3029`,
        description: `Missing paypal method on payment methods table`,
        type: `STORY`
      },
      {
        marp: `MARP-3576`,
        description: `Inquiry making for orders`,
        type: `STORY`
      },
      {
        marp: `MARP-3951`,
        description: `Placeholder ИИН - Admin panel`,
        type: `TASK`
      },
      {
        marp: `MARP-3964`,
        description: `GROSS and NET Weight USA lbs issue`,
        type: `TASK`
      },
      {
        marp: `MARP-3753`,
        description: `Admin V3 regression testing`,
        type: `TASK`
      }
    ]
  },
  {
    name: "25",
    date: "29/03/2022",
    version: "v2.1.0",
    id: "018",
    tasks: [
      {
        marp: `MARP-4014`,
        description: `Uploading a PDF file on Pages breaks the save functionality`,
        type: `BUG`
      },
      {
        marp: `MARP-4066`,
        description: `Blog Articles - Updating date for blog articles crashes the page`,
        type: `BUG`
      },
      {
        marp: `MARP-4067`,
        description: `Product Categories - Inability to make subcategories `,
        type: `BUG`
      },
      {
        marp: `MARP-1768`,
        description: `Promotions - EPTI Admin - After updating the price level in admin, all products are shown up in the promotions on HR and RU (even though only couple of them are set for the promotions)`,
        type: `BUG`
      },
      {
        marp: `MARP-3862`,
        description: `Admin - Products - Error is displayed when user tries to delete an image from the details page`,
        type: `BUG`
      },
      {
        marp: `MARP-4019`,
        description: `EPTI Admin - Translations - Translations are missing for bestselling products`,
        type: `BUG`
      },
      {
        marp: `MARP-3501`,
        description: `EPTI Admin - Delete user - Name is overlapping with the line`,
        type: `BUG`
      },
      {
        marp: `MARP-3572`,
        description: `EPTI Admin - Price rules - "Rule type" and "Coupon code" labels are not displayed correctly in edit mode`,
        type: `BUG`
      },
      {
        marp: `MARP-3632`,
        description: `EPTI Admin - "URL slug" option is duplicated on Blog Categories page`,
        type: `BUG`
      },
      {
        marp: `MARP-3504`,
        description: `EPTI Admin - Dashboard - "Rows per page" is not translated`,
        type: `BUG`
      },
      {
        marp: `MARP-3522`,
        description: `EPTI Admin - AT, CH, DE - Translation issues`,
        type: `BUG`
      },
      {
        marp: `MARP-3570`,
        description: `EPTI Admin - Languages - Languages should be sorted`,
        type: `BUG`
      },
      {
        marp: `MARP-3321`,
        description: `Price rule Affiliates Basic rule `,
        type: `BUG`
      },
      {
        marp: `MARP-3866`,
        description: `EPTI Admin - price rules -1`,
        type: `BUG`
      },
      {
        marp: `MARP-3633`,
        description: `EPTI Admin - RO Translation issues`,
        type: `BUG`
      },
      {
        marp: `MARP-3969`,
        description: `EPTI Admin - Translations - RS - Additional issues`,
        type: `BUG`
      },
      {
        marp: `MARP-3625`,
        description: `EPTI Admin - FR Translation issues`,
        type: `BUG`
      },
      {
        marp: `MARP-3971`,
        description: `EPTI Admin - Products - "Translate" tab is not translated correctly`,
        type: `BUG`
      },
      {
        marp: `MARP-3571`,
        description: `EPTI Admin - HR translation - issues`,
        type: `BUG`
      },
      {
        marp: `MARP-3569`,
        description: `EPTI Admin - "Notification category" text is cut off on Russian language`,
        type: `BUG`
      },
      {
        marp: `MARP-3605`,
        description: `EPTI Admin - CZ Translation issues`,
        type: `BUG`
      },
      {
        marp: `MARP-3497`,
        description: `EPTI Admin - Dashboard - Name of the month is in French on all countries`,
        type: `BUG`
      },
      {
        marp: `MARP-3505`,
        description: `EPTI Admin - RU - Orders - Filter options "Created after" and "Created before" are not translated`,
        type: `BUG`
      },
      {
        marp: `MARP-3496`,
        description: `EPTI Admin - Payment rules - "Maxinstallments" title should be changed`,
        type: `BUG`
      },
      {
        marp: `MARP-3506`,
        description: `EPTI Admin - RU - Tags - Translation is not correct`,
        type: `BUG`
      },
      {
        marp: `MARP-3500`,
        description: `EPTI Admin - Users - "Tax" is not translated`,
        type: `BUG`
      },
      {
        marp: `MARP-3507`,
        description: `EPTI Admin - RU - Categories - "Max upload. 2MB *" text is missing `,
        type: `BUG`
      },
      {
        marp: `MARP-3508`,
        description: `EPTI Admin - RU - Payment Rules - "Other rules" text is not translated correctly`,
        type: `BUG`
      },
      {
        marp: `MARP-3630`,
        description: `EPTI Admin - PL Translation issues`,
        type: `BUG`
      },
      {
        marp: `MARP-3503`,
        description: `EPTI Admin - RS translation issues`,
        type: `BUG`
      },
      {
        marp: `MARP-3232`,
        description: `Best sellers in Admin`,
        type: `STORY`
      },
      {
        marp: `MARP-1501`,
        description: `Product content translation`,
        type: `STORY`
      },
      {
        marp: `MARP-3499`,
        description: `Testing of MARP-91 - Maintain available languages per country`,
        type: `TASK`
      },
      {
        marp: `MARP-3798`,
        description: `Adding Payment currency in Orders (Admin)`,
        type: `TASK`
      },
      {
        marp: `MARP-3890`,
        description: `Testing of configuration settings on admin and web/mobile`,
        type: `TASK`
      },
      {
        marp: `MARP-3906`,
        description: `Admin - Add missing countries for QA members on admin page on production environment`,
        type: `TASK`
      }
    ]
  },
  {
    name: "23",
    date: "11/02/2022",
    version: "v1.20.0",
    id: "017",
    tasks: [
      {
        marp: `MARP-3495`,
        description: `Price rules - Weights are multiplying on price rule tabs`,
        type: `BUG`
      },
      {
        marp: `MARP-3339`,
        description: `PDF for ready for baby item Z-RFB-001 is disappearing after upload`,
        type: `BUG`
      },
      {
        marp: `MARP-1570`,
        description: `Admin page Prices tab - Deposit Person, Deposit Company`,
        type: `IMPROVEMENT`
      },
      {
        marp: `MARP-247`,
        description: `Orders Payment Details tab`,
        type: `IMPROVEMENT`
      },
      {
        marp: `MARP-2290`,
        description: `Text Editor Improvement needed`,
        type: `IMPROVEMENT`
      }
    ]
  },
  {
    name: "22",
    date: "28/12/2021",
    version: "v1.19.0",
    id: "016",
    tasks: [
      {
        marp: `MARP-3304`,
        description: `EPTI Admin - Rule name validations should be removed (Special characters should be allowed)`,
        type: `BUG`
      },
      {
        marp: `MARP-2077`,
        description: `EPTI Admin - Resend emails is not working for orders with status "Pripremljeno za isporuku"`,
        type: `BUG`
      },
      {
        marp: `MARP-1460`,
        description: `EPTI Admin - User should not be able to add the same category as its subcategory`,
        type: `BUG`
      },
      {
        marp: `MARP-3212`,
        description: `Unwanted line is shown on price rule`,
        type: `BUG`
      },
      {
        marp: `MARP-3145`,
        description: `"Promo code" price rule changes to "Searcher" after using "Reorder weights" option`,
        type: `BUG`
      },
      {
        marp: `MARP-3217`,
        description: `Weight in Json is zero in table we are able to see duplicated weight`,
        type: `BUG`
      },
      {
        marp: `MARP-2011`,
        description: `EPTI Admin - Implement filter by rule type in Price Rules`,
        type: `IMPROVEMENT`
      },
      {
        marp: `MARP-3207`,
        description: `Forbid the same name of price rules`,
        type: `TASK`
      },
      {
        marp: `MARP-2308`,
        description: `External ID not required - New Webshop Product Descriptions`,
        type: `TASK`
      }
    ]
  },
  {
    name: "21",
    date: "25/11/2021",
    version: "v1.18.0",
    id: "015",
    tasks: [
      {
        marp: `MARP-261`,
        description: `EPTI Admin - Product Quantity operator - Product quantity value cancel Searcher promotion price on Search page `,
        type: `BUG`
      },
      {
        marp: `MARP-2986`,
        description: `RS - EPTI Admin - "Discount code" value is missing in the Admin`,
        type: `BUG`
      },
      {
        marp: `MARP-3039`,
        description: `EPTI Admin - Custom message (rule.message is required even though ti shouldn't be)`,
        type: `BUG`
      },
      {
        marp: `MARP-1654`,
        description: `EPTI Admin - Tags - Search is not working `,
        type: `BUG`
      },
      {
        marp: `MARP-2053`,
        description: `Discount flag and correct prices are not displayed if "Less than" or "Equals = 1" operators are used in price rule`,
        type: `BUG`
      },
      {
        marp: `MARP-3027`,
        description: `EPTI Admin - Implement a filter in Price Rules by price rule activation label`,
        type: `STORY`
      },
      {
        marp: `MARP-3005`,
        description: `EPTI Admin - Add "Promo Code - Searcher" and "Promo Code - Cart Amount" options in rule type `,
        type: `TASK`
      }
    ]
  },
  {
    name: "20",
    date: "13/10/2021",
    version: "v1.17.0",
    id: "014",
    tasks: [
      {
        marp: `MARP-1738`,
        description: `EPTI Admin - Price rule - Products from subcategories are not discounted, even though they are taken into quantity calculation when more than 3 products is turned on`,
        type: `BUG`
      },
      {
        marp: `MARP-1655`,
        description: `EPTI Admin - Dashboard - "Customers" tab leads to "Something went wrong" page`,
        type: `BUG`
      },
      {
        marp: `MARP-2892`,
        description: `FR - EPTI Admin - "Discount code" column is missing in the Admin for France`,
        type: `BUG`
      },
      {
        marp: `MARP-2898`,
        description: `EPTI Admin - FR - Bad request is present after clicking on "Customer" and "Seller" search fields on "Orders"`,
        type: `BUG`
      },
      {
        marp: `MARP-2816`,
        description: `EPTI Admin - "Inculde subcategories" toggle button is not displayed correctly`,
        type: `BUG`
      },
      {
        marp: `MARP-2658`,
        description: `EPTI Adimn -Push notification - "Clone" notification multiples "affiliates" options in "users roles selected" dropdown`,
        type: `BUG`
      },
      {
        marp: `MARP-2439`,
        description: `Maintain translations per country for Notification categories`,
        type: `STORY`
      },
      {
        marp: `MARP-2441`,
        description: `Maintain translations per country for Version`,
        type: `STORY`
      },
      {
        marp: `MARP-2441`,
        description: `Maintain translations per country for Push notifications`,
        type: `STORY`
      }
    ]
  },
  {
    name: "18",
    date: "21/09/2021",
    version: "v1.13.0",
    id: "013",
    tasks: [
      {
        marp: `MARP-1435`,
        description: `Price rules - Linked rules - A lot of price rules are missing in the list`,
        type: `BUG`
      },
      {
        marp: `MARP-2615`,
        description: `Missing Seo metatags on New countries on Pages, Products, Blogs (CMS Admin)`,
        type: `BUG`
      },
      {
        marp: `MARP-1514`,
        description: `We should create sticky header on Admin page for better user experience`,
        type: `BUG`
      },
      {
        marp: `MARP-2521`,
        description: `Admin - Blog content issue`,
        type: `BUG`
      },
      {
        marp: `MARP-2431`,
        description: `Maintain translations per country for Dashboard`,
        type: `STORY`
      },
      {
        marp: `MARP-2432`,
        description: `Maintain translations per country for Orders`,
        type: `STORY`
      },
      {
        marp: `MARP-2435`,
        description: `Maintain translations per country for Tag groups`,
        type: `STORY`
      },
      {
        marp: `MARP-2436`,
        description: `Maintain translations per country for Tags`,
        type: `STORY`
      },
      {
        marp: `MARP-2437`,
        description: `Maintain translations per country for Price rules`,
        type: `STORY`
      },
      {
        marp: `MARP-2438`,
        description: `Maintain translations per country for Payment rules`,
        type: `STORY`
      },
      {
        marp: `MARP-2627`,
        description: `Adding access to countries in admin`,
        type: `TASK`
      }
    ]
  },
  {
    name: "17",
    date: "23/08/2021",
    version: "v1.12.0",
    id: "012",
    tasks: [
      {
        marp: `MARP-2080`,
        description: `RS - Server communication error after clicking on push notifications`,
        type: `BUG`
      },
      {
        marp: `MARP-2130`,
        description: `We are able to upload any image size in Admin - Product - images`,
        type: `BUG`
      },
      {
        marp: `MARP-2324`,
        description: `Add DIČ field on Admin page`,
        type: `BUG`
      },
      {
        marp: `MARP-1912`,
        description: `EPTI Admin - Users - "Back to users" button leads to homepage`,
        type: `BUG`
      },
      {
        marp: `MARP-1512`,
        description: `Invite - Referral number is not correctly written in Admin panel and base and in Base referral_number`,
        type: `BUG`
      },
      {
        marp: `MARP-1281`,
        description: `EPTI Admin - Export users - Text is not encoded`,
        type: `IMPROVEMENT`
      },
      {
        marp: `MARP-2230`,
        description: `EPTI Admin brainstorming`,
        type: `STORY`
      }
    ]
  },
  {
    name: "16",
    date: "27/07/2021",
    version: "v1.11.0",
    id: "011",
    tasks: [
      {
        marp: `MARP-1927`,
        description: `Admin page - Edit customer email phone doesn't work`,
        type: `BUG`
      },
      {
        marp: `MARP-2085`,
        description: `Epti Admin - "Become member" toggle button is missing in "Create" mode (while creating price rules)`,
        type: `BUG`
      },
      {
        marp: `MARP-2084`,
        description: `EPTI Admin "include subcategories" toggle button is doubled`,
        type: `BUG`
      },
      {
        marp: `MARP-1863`,
        description: `Search users by CU number in CMS Admin`,
        type: `TASK`
      },
      {
        marp: `MARP-2075`,
        description: `Promo code should be unique`,
        type: `TASK`
      },
      {
        marp: `MARP-962`,
        description: `Lack of User Manual section within product cards`,
        type: `TASK`
      }
    ]
  },
  {
    name: "14",
    date: "26/05/2021",
    version: "v1.9.0",
    id: "010",
    tasks: [
      {
        marp: `MARP-1737`,
        description: `Combine products button is missing [Create Form]`,
        type: `BUG`
      },
      {
        marp: `MARP-514`,
        description: `User Details - Last updated by`,
        type: `BUG`
      },
      {
        marp: `MARP-571`,
        description: `Save buttont on edit tech data is not functional`,
        type: `BUG`
      },
      {
        marp: `MARP-1161`,
        description: `"Dokumenti" opens blank page after user adds new document via Epti Admin`,
        type: `BUG`
      },
      {
        marp: `MARP-1461`,
        description: `Price rule - Bad request if user combines "Combine product" with "Product amount operator" without selected quantity`,
        type: `TASK`
      },
      {
        marp: `MARP-1540`,
        description: `100:XX:PDLYY promotion`,
        type: `STORY`
      }
    ]
  },
  {
    name: "13",
    date: "27/04/2021",
    version: "v1.8.0",
    id: "009",
    tasks: [
      {
        marp: `MARP-1334`,
        description: `Price rules - End date can not be changed to the past`,
        type: `BUG`
      },
      {
        marp: `MARP-721`,
        description: `CMS Admin - "Weight" option is not working on the pages so user can not reorder the pages`,
        type: `BUG`
      },
      {
        marp: `MARP-262`,
        description: `Pricing rules - Custom message for product benefits`,
        type: `STORY`
      },
      {
        marp: `MARP-453`,
        description: `Epti Admin - User can not turn on combine products without selected quantity`,
        type: `BUG`
      },
      {
        marp: `MARP-650`,
        description: `Price rules - product amount`,
        type: `STORY`
      },
      {
        marp: `MARP-1446`,
        description: `Epti Admin - Price rules - Search by name is not working`,
        type: `BUG`
      }
    ]
  },
  {
    name: "12",
    date: "25/03/2021",
    version: "v1.7.0",
    id: "008",
    tasks: [
      {
        marp: `MARP-244`,
        description: `CMS Admin - Orders - Resend email is not working`,
        type: `BUG`
      },
      {
        marp: `MARP-600`,
        description: `Wrong text when deleting something on admin`,
        type: `BUG`
      },
      {
        marp: `MARP-701`,
        description: `EPTI Admin (Live International) - User can not create nor edit price rule`,
        type: `BUG`
      },
      {
        marp: `MARP-1332`,
        description: `EPTI Admin - Price rule can not be created`,
        type: `BUG`
      },
      {
        marp: `MARP-1182`,
        description: `Price-rule - extra discount on related products`,
        type: `TASK`
      },
      {
        marp: `MARP-618`,
        description: `Issues with Blog images`,
        type: `BUG`
      },
      {
        marp: `MARP-1228`,
        description: `Admin page error 400 bad request`,
        type: `BUG`
      }
    ]
  },
  {
    name: "11",
    date: "03/03/2021",
    version: "v1.6.0",
    id: "007",
    tasks: [
      {
        marp: `MARP-396`,
        description: `Search functionality is not working on price rule selection`,
        type: `BUG`
      },
      {
        marp: `MARP-834`,
        description: `Promotions - Products from selected categories or subcategories are not visible`,
        type: `BUG`
      },
      {
        marp: `MARP-601`,
        description: `Combine products set in Epti is not applied on the web and mobile app when Categories and Subcategories are selected`,
        type: `BUG`
      },
      {
        marp: `MARP-225`,
        description: `Search/Filter functionality is not working for price rules`,
        type: `BUG`
      }
    ]
  },
  {
    name: "9",
    date: "10/02/2021",
    version: "v1.5.0",
    id: "006",
    tasks: [
      {
        marp: `MARP-810`,
        description: `SEO meta tags field added`,
        type: `BUG`
      },
      {
        marp: `MARP-595`,
        description: `One-time only mobile push notifications administration and sending`,
        type: `STORY`
      }
    ]
  },
  {
    name: "4",
    date: "15/01/2021",
    version: "v1.4.0",
    id: "005",
    tasks: [
      {
        marp: `MARP-242`,
        description: `Ability to create blog articles`,
        type: `BUG`
      },
      {
        marp: `MARP-240`,
        description: `Ability to dynamically enable/disable blog articles`,
        type: `BUG`
      },
      {
        marp: `MARP-260`,
        description: `Delete confirmation for Blog Categories`,
        type: `IMPROVEMENT`
      }
    ]
  },
  {
    name: "8",
    date: "14/12/2020",
    version: "v1.3.0",
    id: "004",
    tasks: [
      {
        marp: `MARP-512`,
        description: `CMS User termination`,
        type: `TASK`
      },
      {
        marp: `MARP-523`,
        description: `Price rules with selected "become a member" option can not be edited`,
        type: `BUG`
      },
      {
        marp: `MARP-534`,
        description: `Add new price levels in Admin price rules`,
        type: `TASK`
      }
    ]
  },
  {
    name: "7",
    date: "01/12/2020",
    version: "v1.2.0",
    id: "003",
    tasks: [
      {
        marp: `MARP-316`,
        description: `Combine product toggle button is not visible when user tries to edit the price rule`,
        type: `BUG`
      },
      {
        marp: `MARP-251`,
        description: `Products arrow lead us to error page`,
        type: `BUG`
      },
      {
        marp: `MARP-319`,
        description: `Admin panel is visible when user enters invalid URL`,
        type: `BUG`
      },
      {
        marp: `MARP-218`,
        description: `Analysis - Behaviour of price rule weights`,
        type: `BUG`
      },
      {
        marp: `MARP-237`,
        description: `Price rules can not be deleted`,
        type: `BUG`
      },
      {
        marp: `MARP-359`,
        description: `New countries prefix`,
        type: `TASK`
      },
      {
        marp: `MARP-228`,
        description: `Become a member price rule conficlting with other rules`,
        type: `BUG`
      },
      {
        marp: `MARP-388`,
        description: `Admin Prices rules logic`,
        type: `IMPROVEMENT`
      },
      {
        marp: `MARP-312`,
        description: `Price rules, user can not enter text in category field while editing already existing price rules`,
        type: `BUG`
      }
    ]
  },
  {
    name: "6",
    date: "04/11/2020",
    version: "v1.1.0",
    id: "002",
    tasks: [
      {
        marp: `MARP-227`,
        description: `Fixed min and max installments switched places`,
        type: `BUG`
      },
      {
        marp: `MARP-259`,
        description: `Internal error 500 fixed after adding validation for Blog Categories`,
        type: `BUG`
      },
      {
        marp: `MARP-303`,
        description: `Admin version added on Admin Dashboard`,
        type: `BUG`
      },
      {
        marp: `MARP-73`,
        description: `Update for user email and phone regex enabled`,
        type: `BUG`
      },
      {
        marp: `MARP-238`,
        description: `Company name and Tax ID visible for all companies`,
        type: `BUG`
      },
      {
        marp: `MARP-246`,
        description: `Order can not be deleted`,
        type: `BUG`
      },
      {
        marp: `MARP-245`,
        description: `Click on link of blog category triggers error 400`,
        type: `BUG`
      },
      {
        marp: `MARP-351`,
        description: `Additional column on Prices tab - Products section`,
        type: `Improvement`
      },
      {
        marp: `MARP-241`,
        description: `Order number of blog category can be negative number`,
        type: `BUG`
      }
    ]
  },
  {
    name: "1",
    date: "04/11/2020",
    version: "v1.0.0",
    id: "001",
    tasks: [
      {
        marp: `MARP-147`,
        description: `Payment rules - Max number of installments based on the cart amount ( all countries)`,
        type: `BUG`
      },
      {
        marp: `MARP-226`,
        description: `Tech Data page limit from 25-100`,
        type: `BUG`
      },
      {
        marp: `MARP-144`,
        description: `Price rule - product quantity value`,
        type: `BUG`
      }
    ]
  }
];
