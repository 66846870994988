import React from "react";
import { List, TextField, Datagrid } from "react-admin";

export const DiscountForNumberOfRegisteredList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField label="First row" source="number_of_registered" />
      <TextField label="Second row" source="display_price_level" />
      <TextField label="Price Level" source="price_level" />
      <TextField label="Order" source="sort_order" />
    </Datagrid>
  </List>
);
