import resources from "../../../resources/resources";
import queryString from "query-string";

// TODO: Add orders filters here also
// By doing this, we are making sure that
// no other filter can be passed intentionally through url
const filters = {
  USERS_EXPORT: ["readable_name_ilike", "email_ilike", "phone_ilike", "external_id_ilike"]
};

const getFilters = (exportFilters, result, filter) => {
  Object.keys(filter).forEach(item => {
    if (exportFilters.indexOf(item) !== -1) {
      result.filter[item] = filter[item];
    }
  });

  return result;
};

const getFiltersAndSortHandler = (resource, search) => {
  let result = {};
  const parsedQuery = queryString.parse(search);
  const filter = parsedQuery.filter ? JSON.parse(parsedQuery.filter) : {};

  result.sort = parsedQuery.sort
    ? {
        sort: parsedQuery.sort,
        order: parsedQuery.order || "ASC"
      }
    : {};
  result.filter = {};
  switch (resource) {
    case resources.USERS: {
      return getFilters(filters.USERS_EXPORT, result, filter);
    }
    default:
      return result;
  }
};

export default getFiltersAndSortHandler;
