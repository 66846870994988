import React from "react";
import {
  List,
  TextField,
  // ShowButton,
  ChipField,
  ReferenceArrayField,
  SingleFieldList,
  DateField
} from "react-admin";
import { ListFilters } from "./Filters";
import CustomizableDatagrid from "../../components/CustomizableDatagrid";

export const TagsList = props => (
  <List exporter={false} bulkActionButtons={false} {...props} filters={<ListFilters />}>
    <CustomizableDatagrid
      defaultColumns={["name", "productIds", "tagGroupIds", "categoryIds"]}
      rowClick="show"
    >
      <TextField label="resources.tags.fields.name" source="name" />
      <ReferenceArrayField
        reference="productCategories"
        label="resources.tags.fields.categoryIds"
        source="categoryIds"
        allowEmpty
        addLabel={false}
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        reference="tagGroups"
        label="resources.tags.fields.tagGroupIds"
        source="tagGroupIds"
        allowEmpty
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        reference="products"
        label="resources.tags.fields.productsIds"
        source="productIds"
        allowEmpty
      >
        <SingleFieldList>
          <ChipField source="external_number" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField label="resources.tags.fields.created_at" source="created_at" />
      <DateField label="resources.tags.fields.updated_at" source="updated_at" />
    </CustomizableDatagrid>
  </List>
);
