import { CSV_URL_RECEIVED, RESET_CSV_URLS, REMOVE_CSV_URL, CHANGE_LOCALE } from "../types";

const initialState = {
  csvUrls: [],
  resource: "",
  locale: "en"
};

export const exportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CSV_URL_RECEIVED:
      return {
        ...state,
        csvUrls: [...state.csvUrls, JSON.parse(payload.csvUrl)],
        resource: payload.resource
      };
    case REMOVE_CSV_URL:
      return {
        ...state,
        csvUrls: state.csvUrls.filter(url => url.csv_url !== payload.csvUrl)
      };
    case RESET_CSV_URLS:
      return initialState;
    case CHANGE_LOCALE:
      return {
        ...state,
        resource: payload
      };
    default:
      return state;
  }
};
