import React from "react";
import {
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  ImageInput,
  ImageField,
  BooleanInput,
  ReferenceInput,
  NumberInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  required,
  translate
} from "react-admin";
import { handleSingleRequest } from "../../services/api/restClient";
import { GET_LIST } from "../../services/api/types";
import { facebookCategories } from "./FacebookCategories";
import { googleCategories } from "./GoogleCategories";

let productCategoriesList = [];

const categoryNameValidator = (value, formValues) => {
  if (!value) {
    return "ra.validation.required";
  }

  const categoryNameExists = productCategoriesList.find(
    category => category.name.toUpperCase() === value.toUpperCase()
  );

  if (categoryNameExists) {
    return "Category name already exists. Please change category name.";
  } else if (formValues.parent_category_id) {
    const parentCategory = productCategoriesList.find(
      category => category.id === formValues.parent_category_id
    );

    if (parentCategory && value.toUpperCase() === parentCategory.name.toUpperCase()) {
      return "Category name must not be the same as parent category name.";
    }
  }

  return undefined;
};

const keyNameValidator = (value, formValues) => {
  if (!value) {
    return "ra.validation.required";
  }

  const keyNameExists = productCategoriesList.find(
    category => category.key_name.toUpperCase() === value.toUpperCase()
  );

  if (keyNameExists) {
    return "Key name already exists. Please change key name.";
  }

  return undefined;
};

const CreateTitle = ({ record, translate }) =>
  translate("resources.productCategories.create.title", {
    productName: record.name
  });

const TranslatedTitle = translate(CreateTitle);

export class ProductCategoryCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagesList: []
    };
  }
  componentDidMount() {
    handleSingleRequest(GET_LIST, "productCategories", {
      pagination: {
        page: "all"
      },
      sort: { field: "name", order: "ASC" },
      filter: {
        is_enabled: true
      }
    })
      .then(({ data }) => {
        productCategoriesList = data;
      })
      .catch(error => console.log(error));

    handleSingleRequest(GET_LIST, "pages", {
      pagination: {
        page: "all"
      },
      sort: { field: "name", order: "ASC" },
      filter: {}
    })
      .then(({ data }) => {
        this.setState({ pagesList: data });
      })
      .catch(error => console.log(error));
  }

  componentWillUnmount() {
    productCategoriesList = [];
    this.state.pagesList = [];
  }

  render() {
    return (
      <Create title={<TranslatedTitle />} {...this.props}>
        <TabbedForm redirect={false} variant="standard">
          <FormTab label="basic">
            <ImageInput
              source="imageRaw"
              label="resources.productCategories.fields.imageRaw"
              labelSingle="resources.productCategories.fields.imageRawLabel"
              maxSize={2000000}
              accept=".jpg,.jpeg,.png"
              validate={required()}
            >
              <ImageField source="src" />
            </ImageInput>
            <BooleanInput source="overwrite" />
            <TextInput
              label="resources.productCategories.fields.categoryName"
              validate={categoryNameValidator}
              source="name"
            />
            <TextInput multiline validate={required()} source="description" fullWidth />
            <TextInput
              label="resources.productCategories.fields.keyName"
              validate={keyNameValidator}
              source="key_name"
            />
            <ReferenceInput
              label="resources.productCategories.fields.parent_category_id"
              source="parent_category_id"
              reference="productCategories"
              filterToQuery={name => {
                if (name) {
                  return { name_ilike: name };
                }
              }}
              perPage={20}
              allowEmpty
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <NumberInput source="order_number" min={0} />
            <BooleanInput source="show_category" />
            <AutocompleteInput
              source="page_id"
              label="resources.pages.fields.parent_id"
              choices={this.state.pagesList}
              emptyValue={null}
              emptyText={"Select to reset page parent"}
              allowEmpty
            />
            <ReferenceArrayInput
              label="resources.productCategories.fields.tagGroups"
              source="tagGroupIds"
              reference="tagGroups"
              sort={{ field: "name", order: "ASC" }}
              filterToQuery={name => {
                if (name) {
                  return { name_ilike: name };
                }
              }}
              perPage={20}
              defaultValue={[]}
              allowEmpty
            >
              <AutocompleteArrayInput optionText="name" />
            </ReferenceArrayInput>
            <AutocompleteInput
              source="google_category"
              label="Chose google category"
              choices={googleCategories}
              emptyValue={null}
              allowEmpty
            />
            <AutocompleteInput
              source="facebook_category"
              label="Chose facebook category"
              choices={facebookCategories}
              emptyValue={null}
              allowEmpty
            />
          </FormTab>
          <FormTab label="translations">
            <p style={{ fontSize: "0.895rem", lineHeight: "1.5rem", color: "#333" }}>
              Translations can be added later after category creation
            </p>
          </FormTab>
        </TabbedForm>
      </Create>
    );
  }
}
