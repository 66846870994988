import { Component } from "react";

export default class IsCategory extends Component {
  render() {
    let { children } = this.props;

    if (!children) {
      return null;
    }

    if (this.props.record.name === "category") return children;
    else return null;
  }
}
