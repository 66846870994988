import React, { Component } from "react";
import Slider from "react-slick";
import TranslatableParagraph from "../TranslationComponents/TranslatableParagraph";

// import choices from "../../constants/bannerSytles";

import Banner from "./Banner";

class SimpleSlider extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.bannersCarousel &&
      this.props.bannersCarousel.length !== prevProps.bannersCarousel.length
    ) {
      this.props.setSlider(this.slider);
    }
  }

  componentDidMount() {
    this.props.setSlider(this.slider);
  }

  render() {
    const { bannersCarousel, removeField, fields, savedBanner, saveBanner } = this.props;

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <div className="slider-container">
        <div
          style={{
            color: "rgba(0, 0, 0, 0.54)",
            fontSize: "0.8rem",
            marginTop: 0
          }}
        >
          {bannersCarousel && bannersCarousel[0] ? (
            <TranslatableParagraph translationLabel="resources.pageContents.fields.carousel_preview" />
          ) : (
            <TranslatableParagraph translationLabel="resources.pageContents.fields.carousel_preview_add" />
          )}
        </div>
        <Slider ref={slider => (this.slider = slider)} {...settings}>
          {bannersCarousel &&
            bannersCarousel.map((record, index) => (
              <Banner
                key={index}
                record={record}
                index={index}
                removeField={removeField}
                fields={fields}
                saveBanner={saveBanner}
                savedBanner={savedBanner}
              />
            ))}
        </Slider>
      </div>
    );
  }
}

export default SimpleSlider;
