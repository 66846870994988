import React, { Component } from "react";
import {
  TextInput,
  NumberInput,
  BooleanInput,
  AutocompleteInput,
  ReferenceInput,
  SimpleForm,
  Create,
  required,
  SelectInput
} from "react-admin";

import { PageNameValidationTextInput, PageSlugValidationTextInput } from "./validators";
import { handleSingleRequest } from "../../services/api/restClient";
import { GET_LIST } from "../../services/api/types";

class PageCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagesList: [],
      defaultLanguage: {}
    };
  }
  componentDidMount() {
    handleSingleRequest(GET_LIST, "pages", {
      pagination: {
        page: "all"
      },
      sort: { field: "name", order: "ASC" },
      filter: {}
    })
      .then(({ data }) => {
        this.setState({ pagesList: data });
      })

      .catch(error => {
        this.pagesList = [];
        console.log(error);
      });

    handleSingleRequest(GET_LIST, "languages", {
      pagination: {
        page: "all"
      },
      sort: { field: "id", order: "DESC" },
      filter: {
        is_enabled: true
      }
    }).then(({ data }) => {
      if (data.length > 0) {
        let defaultLanguage = data.find(language => language.is_default);
        if (!defaultLanguage) {
          // if country does not have default lang
          defaultLanguage = data[0];
        }
        this.setState({
          defaultLanguage
        });
      }
    });
  }

  componentWillUnmount() {
    this.setState({ defaultLanguage: {} });
  }

  render() {
    return (
      <Create {...this.props}>
        <SimpleForm variant="standard">
          <div style={{ display: "none" }}>
            <TextInput
              label="language"
              disabled
              source="language_id"
              defaultValue={this.state.defaultLanguage.id}
            />
          </div>
          <PageNameValidationTextInput />
          <TextInput label="resources.pages.fields.template_url" source="template_url" />
          <PageSlugValidationTextInput />
          <NumberInput label="resources.pages.fields.weight" source="weight" />
          <BooleanInput label="resources.pages.fields.enabled" source="enabled" />
          <AutocompleteInput
            source="parent_id"
            label="resources.pages.fields.parent_id"
            choices={this.state.pagesList}
            emptyValue={null}
            emptyText={"Select to reset page parent"}
            allowEmpty
          />
          <ReferenceInput
            label="resources.pages.fields.page_type"
            source="page_type_id"
            reference="pageTypes"
            perPage={20}
            validate={required()}
            isRequired
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    );
  }
}

export default PageCreate;
