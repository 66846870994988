import React, { Component } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import get from "lodash/get";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles } from "@material-ui/core/styles";

import FormInput from "./OrderedFormIterator/FormInput";

const styles = theme => ({
  root: {
    display: "none",
    padding: 0,
    marginBottom: 0,
    "& > li:last-child": {
      borderBottom: "none"
    }
  },
  line: {
    display: "flex",
    listStyleType: "none",
    borderBottom: `solid 1px ${theme.palette.divider}`,
    [theme.breakpoints.down("xs")]: { display: "block" },
    "&.fade-enter": {
      opacity: 0.01,
      transform: "translateX(100vw)"
    },
    "&.fade-enter-active": {
      opacity: 1,
      transform: "translateX(0)",
      transition: "all 500ms ease-in"
    },
    "&.fade-exit": {
      opacity: 1,
      transform: "translateX(0)"
    },
    "&.fade-exit-active": {
      opacity: 0.01,
      transform: "translateX(100vw)",
      transition: "all 500ms ease-in"
    }
  },
  index: {
    width: "3em",
    paddingTop: "1em",
    [theme.breakpoints.down("sm")]: { display: "none" }
  },
  form: { flex: 2 },
  action: {
    paddingTop: "0.5em"
  },
  leftIcon: {
    marginRight: theme.spacing()
  }
});

export class CustomFormIterator extends Component {
  componentDidMount() {
    const { setFields, fields } = this.props;
    setFields(fields);
  }

  componentDidUpdate(prevProps) {
    if (this.props.fields.value !== prevProps.fields.value) {
      this.props.setFields(this.props.fields);
    }
  }

  render() {
    const {
      classes = {},
      children,
      fields,
      meta: { error, submitFailed },
      record,
      resource,
      source,
      ids
    } = this.props;

    const records = get(record, source);
    return fields && ids.length ? (
      <ul className={classes.root}>
        {submitFailed && error && <FormHelperText error>{error}</FormHelperText>}
        {fields.map((member, index) => (
          <li key={index} className={classes.line}>
            <Typography variant="body1" className={classes.index}>
              {index + 1}
            </Typography>
            <section className={classes.form + " sectionForm"}>
              <FormInput
                input={children({
                  fields,
                  index
                })}
                record={(records && records[index]) || {}}
                resource={resource}
              />
            </section>
          </li>
        ))}
      </ul>
    ) : null;
  }
}

CustomFormIterator.propTypes = {
  defaultValue: PropTypes.any,
  basePath: PropTypes.string,
  children: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  meta: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string
};

export default compose(withStyles(styles))(CustomFormIterator);
