import React, { useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  GET_LIST,
  FormDataConsumer,
  required
} from "react-admin";
import { handleSingleRequest } from "../../services/api/restClient";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { validateNotificationCategoryName } from "./validators";
import { styles } from "../styles";

const ConfirmationToolbar = props => (
  <Toolbar {...props} style={styles.toolbar}>
    <SaveButton />
    <DeleteConfirmation undoable={false} />
  </Toolbar>
);

export const NotificationCategoryName = ({ formData, categoryList }) => {
  const [savedName, setSavedName] = useState(formData?.name);

  const validateCategoryName = value =>
    validateNotificationCategoryName(value, categoryList, savedName);

  return (
    <TextInput
      variant="standard"
      source="name"
      label="Name"
      style={{ minWidth: "256px" }}
      validate={[required(), validateCategoryName]}
    />
  );
};

export const NotificationCategoriesEdit = props => {
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleSingleRequest(GET_LIST, "notificationCategories", {
          pagination: { page: "all" },
          sort: { field: "id", order: "DESC" },
          filter: {}
        });
        setCategoryList(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Edit {...props} undoable={false}>
      <SimpleForm variant="standard" toolbar={<ConfirmationToolbar />}>
        <FormDataConsumer>
          {({ formData }) => {
            return <NotificationCategoryName formData={formData} categoryList={categoryList} />;
          }}
        </FormDataConsumer>
        <TextInput source="description" />
      </SimpleForm>
    </Edit>
  );
};
