import React from "react";
import {
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  DateField,
  NumberField,
  BooleanField,
  ReferenceField,
  ReferenceArrayField,
  ArrayField,
  Datagrid,
  EditButton
} from "react-admin";
import CustomImageField from "../../components/CustomImageField";

const CategoryTitle = ({ record }) => `Category ${record.name}`;

export const ProductCategoryShow = props => (
  <Show title={<CategoryTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="resources.productCategories.tabs.basic">
        <ReferenceField
          source="image_id"
          reference="images"
          sortable={false}
          label="resources.productCategories.fields.imageRaw"
        >
          <CustomImageField />
        </ReferenceField>
        <TextField source="id" />
        <TextField source="rid" />
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="key_name" />
        <ReferenceField
          source="parent_category_id"
          reference="productCategories"
          link={false}
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="order_number" />
        <BooleanField source="show_category" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
        <ReferenceField
          label="resources.productCategories.fields.pageName"
          source="page_id"
          reference="pages"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField label="Google category" source="google_category" />
        <TextField label="Facebook category" source="facebook_category" />
      </Tab>
      <Tab label="resources.productCategories.tabs.tagGroups">
        <ReferenceArrayField reference="tagGroups" source="tagGroupIds" allowEmpty>
          <Datagrid>
            <TextField source="name" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
            <EditButton />
          </Datagrid>
        </ReferenceArrayField>
      </Tab>
      <Tab label="translations">
        <ArrayField source="translations">
          <Datagrid>
            <TextField source="language.name" />
            <TextField source="name" />
            <TextField source="description" />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
