import React, { useState } from "react";

export const GuidanceBox = props => {
  const [infoBox, setInfoBox] = useState(false);

  const ImageZoomWeb = () => {
    const [backgroundImage, setBackgroundImage] = useState(`url(${props.imageWeb})`);
    const [backgroundPosition, setBackgroundPosition] = useState("0% 0%");

    const handleMouseMove = e => {
      const { left, top, width, height } = e.target.getBoundingClientRect();
      const x = ((e.pageX - left) / width) * 100;
      const y = ((e.pageY - top) / height) * 100;
      setBackgroundPosition(`${x}% ${y}%`);
    };

    return (
      <>
        <figure onMouseMove={handleMouseMove} style={{ backgroundImage, backgroundPosition }}>
          <img src={props?.imageWeb} alt="" />
        </figure>
      </>
    );
  };

  const ImageZoomMobile = () => {
    const [backgroundImage, setBackgroundImage] = useState(`url(${props.imageMobile})`);
    const [backgroundPosition, setBackgroundPosition] = useState("0% 0%");

    const handleMouseMove = e => {
      const { left, top, width, height } = e.target.getBoundingClientRect();
      const x = ((e.pageX - left) / width) * 100;
      const y = ((e.pageY - top) / height) * 100;
      setBackgroundPosition(`${x}% ${y}%`);
    };

    return (
      <figure
        onMouseMove={handleMouseMove}
        style={{ backgroundImage, backgroundPosition, marginLeft: "2em" }}
      >
        <img src={props?.imageMobile} alt="" />
      </figure>
    );
  };

  return (
    <>
      <button
        style={{
          border: "none",
          padding: "10px",
          borderRadius: "15px",
          boxShadow: "5px 5px 10px rgba(19, 22, 22, .15)",
          backgroundColor: "#99cbed",
          marginBottom: "1rem",
          cursor: "pointer",
          color: "#131616",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
        onClick={e => {
          setInfoBox(!infoBox);
          e.preventDefault();
        }}
      >
        {props.buttonLabel ? (
          props.buttonLabel + " ⇛ "
        ) : (
          <p style={{ marginRight: "0.5rem" }}>List of currently opened concerns ⇛</p>
        )}
        <b>{infoBox ? "Hide" : "Show"} Info Box</b>
      </button>
      <div
        className="infoBox"
        style={{
          backgroundColor: "#99cbed",
          padding: "10px",
          display: `${infoBox ? "flex" : "none"}`,
          flexDirection: "column",
          color: "#131616",
          maxWidth: "1024px",
          borderRadius: "15px",
          fontSize: 14,
          boxShadow: "5px 5px 10px rgba(19, 22, 22, .15)",
          marginBottom: "2em"
        }}
      >
        <p className="guidancebox" style={{ lineHeight: "150%", display: `${props.showInfoBox}` }}>
          {props.infoBoxText}
        </p>
        {props.imageWeb || props.imageMobile ? (
          <>
            <p>Hover over the image(s) to zoom.</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {props.imageWeb ? <ImageZoomWeb /> : null}
              {props.imageMobile ? <ImageZoomMobile /> : null}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default GuidanceBox;
