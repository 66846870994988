import React from "react";
import {
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  Toolbar,
  SaveButton,
  minLength
} from "react-admin";
import { styles } from "../styles";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import { ToolbarOptionsTechData } from "../../constants";
import { validateTechDataValue, minValue, validateWholeNumber } from "../../utils/validators/index";

const TechDataTitle = ({ record }) => `Edit ${record.name}`;

const getSearchStringParams = stringInut => {
  try {
    return JSON.parse(
      stringInut.substring(stringInut.indexOf("{"), stringInut.lastIndexOf("}") + 1)
    );
  } catch (e) {
    return null;
  }
};

export const TechDataEdit = props => {
  const ConfirmationToolbar = props => (
    <Toolbar {...props} style={styles.toolbar}>
      <SaveButton />
      <DeleteConfirmation undoable={false} />
    </Toolbar>
  );

  const decodedSearchString = decodeURIComponent(window.location.hash);

  const searchStringParams = getSearchStringParams(decodedSearchString);

  const productId = searchStringParams?.productId;
  // // const productId = searchStringParams?.
  const redirect = () => (productId ? `/products/${productId}/3` : window.history.go(-1));

  return (
    <Edit undoable={false} {...props}>
      <SimpleForm
        variant="standard"
        submitOnEnter={true}
        redirect={redirect}
        toolbar={<ConfirmationToolbar />}
        title={<TechDataTitle />}
      >
        {(searchStringParams?.isNewTranslation || searchStringParams?.isEditTranslation) && (
          <ReferenceInput
            filter={{ is_enabled: true }}
            label="Language"
            source="language_id"
            reference="languages"
            validate={required()}
            disabled
            // perPage={30}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
        <TextInput
          label="resources.products.tabs.tech_data.label"
          source="label"
          validate={[required(), validateTechDataValue]}
        />
        <NumberInput
          label="resources.products.tabs.tech_data.tech_data_order"
          source="tech_data_order"
          validate={[required(), minValue(1), validateWholeNumber]}
        />
        {!searchStringParams?.isEditTranslation && (
          <ReferenceInput
            source="product_id"
            reference="products"
            disabled
            label="resources.products.tabs.tech_data.tech_data_product"
            sort={{ field: "name", order: "ASC" }}
            filterToQuery={name => {
              if (name) {
                return { name_ilike: name };
              }
            }}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        )}
        <TextInput
          label="resources.products.tabs.tech_data.tech_data_value"
          source="tech_data_value"
          toolbar={[ToolbarOptionsTechData]}
          validate={[required(), validateTechDataValue]}
          multiline
          type="text"
        />
      </SimpleForm>
    </Edit>
  );
};
