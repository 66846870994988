import React from "react";
import {
  Filter,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  translate,
  DateInput
} from "react-admin";
import OrderStatusReferenceInput from "./OrderStatusReferenceInput";

const OrderFilters = ({ orderUnknownId, translate, ...props }) => {
  return (
    <Filter variant="standard" {...props}>
      <TextInput source="rid" />
      <TextInput
        label="resources.orders.fields.order_number"
        source="order_number_ilike"
        alwaysOn
      />
      <TextInput label="resources.orders.fields.external_number" source="external_number_ilike" />
      <TextInput label="resources.orders.fields.referral_number" source="referral_number_ilike" />
      <DateInput label="resources.orders.fields.created_after" source="created_at_gt" />
      <DateInput label="resources.orders.fields.created_before" source="created_at_lt" />
      <ReferenceInput
        label="resources.orders.fields.customer_id"
        source="customer_id"
        reference="users"
        sort={{ field: "name", order: "ASC" }}
        allowEmpty={true}
        filterToQuery={email => {
          if (email) {
            return { email_ilike: email };
          }
        }}
        alwaysOn
      >
        <AutocompleteInput optionText="email" isFilter={true} limitChoicesToValue />
      </ReferenceInput>
      <ReferenceInput
        label="resources.orders.fields.seller_id"
        source="seller_id"
        reference="users"
        sort={{ field: "name", order: "ASC" }}
        allowEmpty={true}
        filterToQuery={email => {
          if (email) {
            return { email_ilike: email };
          }
        }}
        alwaysOn
      >
        <AutocompleteInput optionText="email" isFilter={true} limitChoicesToValue />
      </ReferenceInput>
      <TextInput label="resources.orders.fields.address_line1" source="address_line1_ilike" />
      <TextInput label="resources.orders.fields.zip_code" source="zip_code_ilike" />
      <ReferenceInput
        source="city_id"
        reference="cities"
        sort={{ field: "name", order: "ASC" }}
        allowEmpty={false}
        filterToQuery={city => {
          if (city) {
            return { name_ilike: city };
          }
        }}
        perPage={200}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        source="last_update_by"
        choices={[
          { id: "ERP", name: "ERP" },
          { id: "API", name: "API" },
          { id: "PEN", name: "PEN" }
        ]}
        allowEmpty={false}
        resettable
      />
      <SelectInput
        source="invoice_status"
        choices={[
          {
            id: "N",
            name: translate("resources.orders.filters.emailStatusChoices.N")
          },
          {
            id: "Y",
            name: translate("resources.orders.filters.emailStatusChoices.Y")
          },
          {
            id: "C",
            name: translate("resources.orders.filters.emailStatusChoices.C")
          },
          {
            id: "F",
            name: translate("resources.orders.filters.emailStatusChoices.F")
          }
        ]}
        allowEmpty={false}
        resettable
      />
      <OrderStatusReferenceInput
        source="order_status_id"
        reference="orderStatuses"
        sort={{ field: "name", order: "ASC" }}
        allowEmpty={false}
        filterToQuery={orderStatusId => {
          if (orderStatusId) {
            return { order_status_id: orderStatusId };
          }
        }}
        filter={{ id_nin: orderUnknownId }}
      />
      <SelectInput
        source="payment_method"
        label="resources.orders.fields.paymentMethod.name"
        choices={[
          {
            id: "cash",
            name: translate("resources.orders.filters.paymentMethodChoices.cash")
          },
          {
            id: "creditCard",
            name: translate("resources.orders.filters.paymentMethodChoices.creditCard")
          }
        ]}
        allowEmpty={false}
        resettable
      />
    </Filter>
  );
};

export default translate(OrderFilters);
