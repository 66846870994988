import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import TranslatableParagraph from "../../../components/TranslationComponents/TranslatableParagraph";
import "./Graphs.css";
export default class LineGraph extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <LineChart
        width={400}
        height={260}
        data={data}
        // margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid stroke="#CFCFCF" strokeDasharray="6 2" />

        <XAxis dataKey="name" margin={{ right: 12 }} />
        <YAxis />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="current"
          name={
            <TranslatableParagraph translationLabel="resources.dashboard.tabs.analytics.currentPoint" />
          }
          stroke="#3498db"
          strokeWidth="3"
          strokeDasharray="10 5"
          dot={{
            r: 3,
            stroke: "#3498DB",
            strokeDasharray: "0",
            fill: "#3498DB"
          }}
          activeDot={{
            r: 6,
            stroke: "#3498DB",
            strokeDasharray: "0",
            fill: "#3498DB"
          }}
        />

        <Line
          type="monotone"
          dataKey="previous"
          name={
            <TranslatableParagraph translationLabel="resources.dashboard.tabs.analytics.referencePoint" />
          }
          stroke="#FF4100"
          strokeWidth="3"
          strokeDasharray="10 5"
          dot={{
            r: 3,
            stroke: "#FF4100",
            strokeDasharray: "0",
            fill: "#FF4100"
          }}
          activeDot={{
            r: 6,
            stroke: "#FF4100",
            strokeDasharray: "0",
            fill: "#FF4100"
          }}
        />
        <Legend align="right" iconType="circle" />
      </LineChart>
    );
  }
}
