// 3rd Party Components
import React from "react";
// Local components
import TranslatableParagraph from "../TranslationComponents/TranslatableParagraph";
// Data
import { devsprints } from "./tasks";
// Inline Styles Start
import { styles } from "./styles";
// Locally declared variables;
const JIRA_LINK = "https://zepter.atlassian.net/browse/";

const AdminVersion = () => {
  return (
    <div style={styles.dashboardVersion}>
      <h1 style={styles.version}>Changes</h1>
      {devsprints.map((sprint, index) => {
        return (
          <div style={styles.versionContainer} key={sprint.id}>
            {/* Legend - date | fixed | dev sprint | task legend */}
            <div style={styles.changesLegendLayout}>
              <div style={{ display: "flex" }}>
                <p style={{ ...styles.date, ...styles.changesLegend }}>{sprint.date}</p>
                <TranslatableParagraph
                  customStyling={{ ...styles.date, ...styles.changesLegend }}
                  component={"p"}
                  translationLabel="resources.version.fields.fixed"
                />
                <p style={{ ...styles.date, ...styles.changesLegend }}>
                  <TranslatableParagraph
                    textOnly
                    translationLabel="resources.version.fields.dev_sprint"
                  />{" "}
                  <span style={{ marginLeft: "5px" }}>{sprint.name}</span>
                </p>
              </div>
              {index === 0 ? (
                <div style={styles.mapLegend}>
                  <TranslatableParagraph
                    customStyling={{ ...styles.bug, ...styles.changesLegend }}
                    component={"span"}
                    translationLabel="resources.version.fields.bug"
                  />
                  <TranslatableParagraph
                    customStyling={{ ...styles.improvement, ...styles.changesLegend }}
                    component={"span"}
                    translationLabel="resources.version.fields.improvement"
                  />
                  <TranslatableParagraph
                    customStyling={{ ...styles.info, ...styles.changesLegend }}
                    component={"span"}
                    translationLabel="resources.version.fields.task"
                  />
                  <TranslatableParagraph
                    customStyling={{ ...styles.story, ...styles.changesLegend }}
                    component={"span"}
                    translationLabel="resources.version.fields.story"
                  />
                </div>
              ) : null}
            </div>
            {/* Tasks mapped out from tasks.js */}
            <ul style={{ listStyleType: "none", paddingLeft: "0px" }}>
              {sprint.tasks.map(task => {
                return (
                  <li style={styles.task} key={task.description}>
                    <span
                      style={
                        task.type.toLowerCase() === "bug"
                          ? styles.bug
                          : task.type.toLowerCase() === "improvement"
                          ? styles.improvement
                          : task.type.toLowerCase() === "task"
                          ? styles.info
                          : styles.story
                      }
                    />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      style={styles.issueLink}
                      href={JIRA_LINK + task.marp}
                    >
                      {task.marp}
                    </a>
                    {task.description}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default AdminVersion;
