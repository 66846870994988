/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import {
  Create,
  BooleanInput,
  TextInput,
  ReferenceInput,
  TabbedForm,
  FormTab,
  AutocompleteInput,
  required,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  SelectInput
} from "react-admin";
import RichTextInput from "../../components/RichTextEditor";
import { ToolbarOptions } from "../../constants";
import { nameFilter } from "../../utils/filters";
import { styles } from "../styles";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

// API
import { handleSingleRequest } from "../../services/api/restClient";
import { GET_LIST } from "../../services/api/types";

import GuidanceBox from "../../components/GuidanceBox/GuidanceBox";
import ShortDescriptionWeb from "../../components/GuidanceBox/images/products/shortDescription-web.png";
import ShortDescriptionMobile from "../../components/GuidanceBox/images/products/shortDescription-mobile.png";
import PresentationWeb from "../../components/GuidanceBox/images/products/presentation-web.png";
import PresentationMobile from "../../components/GuidanceBox/images/products/presentation-mobile.png";
import resources from "../../i18n/en.json";
import { PopupNotification } from "../../components/PopupNotification";

// Validations
import {
  productValidationName,
  productValidationID,
  productValidationExternalNumber,
  productValidationSlug,
  productValidationSirv
} from "./validators";

const validateStory = value => {
  const temp = document.createElement("div");
  temp.innerHTML = value;
  if (!value) {
    return "Required";
  } else if (temp.textContent.length > 400) {
    return "Character limit exceeded";
  } else {
    return undefined;
  }
};

// * Languages support @web
const thereCanBeOnlyOne = (value, formValues) => {
  if (value) {
    let checkForExistingLanguageSelected = formValues.translations.filter(
      item => item && item.language && item.language.id === value
    );
    if (checkForExistingLanguageSelected.length > 1) {
      return "There can be only one translation per language";
    } else {
      return undefined;
    }
  } else {
    return "Required";
  }
};

const CreateTitle = ({ record }) => (
  <TranslatableParagraph
    customStyling={{ display: "inline-block", margin: 0 }}
    translationLabel="resources.products.title.create"
    customLabel={record.name ? record.name : "Product without a name"}
  />
);

const ConfirmationToolbar = props => (
  <Toolbar {...props} style={styles.toolbar}>
    <SaveButton />
  </Toolbar>
);

export const ProductCreate = props => {
  // let [allLanguages, setAllLanguages] = useState([]);
  let [productList, setProductList] = useState([]);

  useEffect(() => {
    // grabEnabledLanguages();
    grabAllProducts();
  }, []);

  // const grabEnabledLanguages = async () => {
  //   try {
  //     const { data: allLanguages } = await handleSingleRequest(GET_LIST, "languages", {
  //       pagination: {
  //         page: "all"
  //       },
  //       sort: { field: "name", order: "ASC" },
  //       filter: { is_enabled: true }
  //     });
  //     setAllLanguages(
  //       allLanguages.map(translationItem => {
  //         return {
  //           id: translationItem.id,
  //           name: translationItem.international_name
  //         };
  //       })
  //     );
  //   } catch (error) {
  //     console.error("There was a problem with languages in products");
  //   }
  // };

  const grabAllProducts = async () => {
    try {
      const { data: productList } = await handleSingleRequest(GET_LIST, "products", {
        pagination: {
          page: "all"
        },
        sort: { field: "name", order: "ASC" },
        filter: { is_enabled: true }
      });
      setProductList(productList);
    } catch (error) {
      console.error("There was a problem with languages in products");
    }
  };

  return (
    <Create
      title={<CreateTitle />}
      actions={
        <PopupNotification
          message="Additional tabs will be accessible after product has been created"
          type="info"
          buttonLabel="Where can I find more tabs related to product ( popup - bottom )"
        />
      }
      {...props}
    >
      <TabbedForm redirect="show" toolbar={<ConfirmationToolbar />}>
        <FormTab label="resources.products.tabs.basic.name">
          <ReferenceInput
            label="resources.products.tabs.basic.category_name"
            source="category_id"
            reference="productCategories"
            variant="standard"
            allowEmpty
            validate={required()}
            filterToQuery={nameFilter}
            perPage={10}
          >
            <AutocompleteInput optionText="name" inputValueMatcher={() => null} />
          </ReferenceInput>
          <ReferenceInput
            label="resources.products.tabs.basic.product_type"
            source="product_type_id"
            reference="productTypes"
            validate={required()}
            variant="standard"
            filterToQuery={nameFilter}
            perPage={10}
          >
            <AutocompleteInput optionText="name" inputValueMatcher={() => null} />
          </ReferenceInput>
          <TextInput
            label="resources.products.tabs.basic.product_name"
            source="name"
            validate={value => productValidationName(value, productList)}
            variant="standard"
          />
          <TextInput
            label="resources.products.tabs.basic.url_slug"
            source="url_slug"
            validate={value => productValidationSlug(value, productList)}
            variant="standard"
          />
          <TextInput
            label="resources.products.tabs.basic.external_id"
            source="external_id"
            validate={value => productValidationID(value, productList)}
            variant="standard"
          />
          <TextInput
            label="resources.products.tabs.basic.external_number"
            source="external_number"
            validate={value => productValidationExternalNumber(value, productList)}
            variant="standard"
          />
          <TextInput
            label="resources.products.tabs.basic.sirv_url"
            source="sirv_url"
            validate={productValidationSirv}
            parse={value => {
              return value || "";
            }}
            variant="standard"
          />

          <BooleanInput
            label="resources.products.tabs.basic.is_visible_in_shop"
            source="is_visible_in_shop"
          />
          <BooleanInput label="resources.products.tabs.basic.has_content" source="has_content" />
          <BooleanInput
            label="resources.products.tabs.basic.show_discount"
            source="show_discount"
          />
          <RichTextInput label="Short description v2" source="story" validate={validateStory} />
          <GuidanceBox
            showInfoBox="none"
            showImageBox="flex"
            buttonLabel="Learn where short description v2 field will showcase"
            labelName={resources?.products?.tabs?.basic?.short_description}
            imageWeb={ShortDescriptionWeb}
            imageMobile={ShortDescriptionMobile}
          />
          <RichTextInput
            label="resources.products.tabs.basic.short_description"
            source="short_description"
            validate={required()}
          />
          <TextInput label="Product brand" source="brand" />
          {/* please don't change textInput for feed_name and feed_description*/}
          <TextInput label="Product Name for feed" source="feed_name" />
          <TextInput label="Product Description for feed" source="feed_description" />
        </FormTab>

        <FormTab label="resources.products.tabs.presentation.name">
          <GuidanceBox
            showInfoBox="none"
            showImageBox="flex"
            buttonLabel="Learn where presentation fields will showcase"
            labelName={resources?.products?.tabs?.presentation.name}
            imageWeb={PresentationWeb}
            imageMobile={PresentationMobile}
          />
          <RichTextInput
            label="resources.products.tabs.presentation.name"
            source="presentation"
            toolbar={[ToolbarOptions]}
            validate={required()}
          />
        </FormTab>

        {/* <FormTab label="resources.products.tabs.translations.name">
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
              return (
                <ArrayInput
                  addLabel={false}
                  variant="standard"
                  source="translations"
                  label="resources.products.tabs.translations.name"
                >
                  <SimpleFormIterator
                    disableReordering
                    TransitionProps={{ enter: false, exit: false }}
                  >
                    <SelectInput
                      source="language.id"
                      choices={allLanguages}
                      label="resources.products.tabs.translations.language_name"
                      validate={thereCanBeOnlyOne}
                    />
                    <TextInput
                      label="resources.products.tabs.basic.product_name"
                      source="name"
                      validate={required()}
                    />
                    <RichTextInput
                      label="resources.products.tabs.basic.short_description"
                      source="short_description"
                      style={{ width: "100%" }}
                      validate={required()}
                    />
                    <RichTextInput
                      label="resources.products.tabs.presentation.name"
                      source="presentation"
                      style={{ width: "100%" }}
                      validate={required()}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              );
            }}
          </FormDataConsumer>
        </FormTab> */}
      </TabbedForm>
    </Create>
  );
};
