import React, { useEffect, useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  GET_LIST,
  required
} from "react-admin";
import { handleSingleRequest } from "../../services/api/restClient";
import { validateTagName } from "./validators";

export const TagCreate = props => {
  const [tagList, setTagList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleSingleRequest(GET_LIST, "tags", {
          pagination: { page: "all" },
          sort: { field: "id", order: "DESC" },
          filter: {}
        });
        setTagList(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const validateTagNameRT = value => validateTagName(value, tagList);
  return (
    <Create title="resources.tags.fields.title_create_tag" {...props}>
      <SimpleForm variant="standard">
        <TextInput source="name" validate={[validateTagNameRT, required()]} />
        <ReferenceArrayInput
          source="categoryIds"
          reference="productCategories"
          sort={{ field: "name", order: "ASC" }}
          filterToQuery={name => {
            if (name) {
              return { name_ilike: name };
            }
          }}
          allowEmpty={true}
        >
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source="tagGroupIds"
          reference="tagGroups"
          sort={{ field: "name", order: "ASC" }}
          filterToQuery={name => {
            if (name) {
              return { name_ilike: name };
            }
          }}
          allowEmpty={true}
        >
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};
