import { orderBy } from "lodash";

const generateId = records => {
  let newId = 1;

  if (records.length > 0) {
    newId = orderBy(records, "id", "desc")[0].id + 1;
  }

  return newId;
};

export default generateId;
