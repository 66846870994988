import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { addField } from "ra-core";

import Button from "@material-ui/core/Button";
import ActionEdit from "@material-ui/icons/Create";
import ActionCancel from "@material-ui/icons/Cancel";
import TranslatableParagraph from "./TranslationComponents/TranslatableParagraph";

// import sanitizeRestProps from "./sanitizeRestProps";

export class ButtonToggle extends Component {
  handleChange = (event, value) => {
    const { onChange, input } = this.props;
    onChange && onChange();
    input.onChange(value);
  };

  render() {
    const {
      className,
      id,
      input,
      isRequired,
      label,
      source,
      resource,
      options,
      fullWidth,
      cancel,
      disabled,
      ...rest
    } = this.props;

    const { value } = input;

    return (
      <FormGroup className={className} {...rest}>
        <FormControlLabel
          htmlFor={id}
          style={{ margin: "0px" }}
          control={
            // <Switch
            //     id={id}
            //     color="primary"
            //     checked={!!value}
            //     onChange={this.handleChange}
            //     {...inputProps}
            //     {...options}
            // />
            <Button
              id={id}
              style={{
                // borderTopLeftRadius: "12px",
                margin: "5px",
                background: disabled ? "#999" : cancel ? "rgb(244, 67, 54)" : "rgb(52, 152, 219)",
                border: disabled
                  ? "#999"
                  : cancel
                  ? "1px solid rgb(244, 67, 54)"
                  : "1px solid rgb(52, 152, 219)",
                color: "rgba(255,255,255,0.85)"
              }}
              checked={!!value}
              disabled={disabled}
              onClick={() => this.handleChange(undefined, !value)}
            >
              {cancel ? <ActionCancel /> : <ActionEdit />}
              <TranslatableParagraph translationLabel="ra.action.edit" />
            </Button>
          }
        />
      </FormGroup>
    );
  }
}

ButtonToggle.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.object,
  disabled: PropTypes.bool
};

ButtonToggle.defaultProps = {
  options: {},
  disabled: false
};

export default addField(ButtonToggle);
