import React from "react";
import { Create, SimpleForm, TextInput, NumberInput, BooleanInput, required } from "react-admin";
import RichTextInput from "../../components/RichTextEditor";

export const AccordionCreate = props => (
  <Create {...props}>
    <SimpleForm variant="standard" redirect="list">
      <TextInput source="title" label="Title" validate={required()} />
      <RichTextInput source="heading_content" label="Heading content" />
      <RichTextInput source="content" label="Content" validate={required()} />
      <RichTextInput source="footer_contet" label="Footer content" />
      <BooleanInput label="Table with discount beneftits" source="has_dynamic_table" />
      <BooleanInput
        label="Table with regrut benefits"
        source="has_dynamic_table_discount_for_invites"
      />
      <NumberInput source="sort_order" label="Order" min="0" validate={required()} />
    </SimpleForm>
  </Create>
);
