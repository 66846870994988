import React, { useEffect } from "react";
import {
  Create,
  SimpleForm,
  FormDataConsumer,
  ReferenceField,
  TextInput,
  TextField,
  ReferenceInput,
  SelectInput,
  required
} from "react-admin";
import { Redirect } from "react-router-dom";
import { useForm, useField, Field } from "react-final-form";

import IsCategory from "./IsCategory";
import IsHomepage from "./IsHomepage";
import BannerInput from "../../components/BannerInput";
import ImagesInput from "../../components/ImagesInput";

import Editor from "../../components/ContentEditor/Editor";

const PageShowTitle = ({ record }) => `Content ${record.name}`;

const SetIsPublished = () => {
  const form = useForm();
  form.change("is_published", false);
  return null;
};

const SubReference = ({ translateChoice, children, ...props }) => (
  <ReferenceField {...props}>{children}</ReferenceField>
);

const UpdateFormField = props => {
  const field = useField();
  const form = useForm();

  useEffect(() => {
    if (props?.record?.bannersCarousel?.length === 0) {
      if (field.input?.value?.bannersCarousel.length !== 0) {
        form.change("images", props.record.bannersCarousel);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.input?.value?.bannersCarousel?.length]);
  return null;
};

export const PageContentsCreate = props => {
  const decodedSearchString = decodeURIComponent(props.location.search);
  // find object with props in search
  const searchStringParams = JSON.parse(
    decodedSearchString.substring(
      decodedSearchString.indexOf("{"),
      decodedSearchString.lastIndexOf("}") + 1
    )
  );
  const { page_id } = searchStringParams;

  const redirect = page_id ? `/pages/${page_id}/1` : "/pages";
  if (!page_id) {
    return <Redirect to="/pages" />;
  }

  return (
    <Create title={<PageShowTitle />} {...props}>
      <SimpleForm variant="standard" redirect={redirect}>
        <ReferenceInput
          filter={{ is_enabled: true }}
          label="Language"
          source="language_id"
          reference="languages"
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceField
          label="resources.pageContents.fields.name"
          source="page_id"
          reference="pages"
          variant="body1"
        >
          <TextField source="name" />
        </ReferenceField>
        <SetIsPublished />
        <TextInput source="name" label="resources.pageContents.fields.version_name" />
        {/* CATEGORY */}
        <>
          <br />
          <br />
          <hr style={{ width: "100%" }} />
          <br />
        </>
        <UpdateFormField />
        <ReferenceField addLabel={false} source="page_id" reference="pages" link={false}>
          <SubReference source="page_type_id" reference="pageTypes" link={false}>
            <IsCategory>
              <BannerInput />
            </IsCategory>
          </SubReference>
        </ReferenceField>
        {/* HOMEPAGE */}
        <ReferenceField addLabel={false} source="page_id" reference="pages" link={false}>
          <SubReference source="page_type_id" reference="pageTypes" link={false}>
            <IsHomepage>
              <BannerInput />
            </IsHomepage>
          </SubReference>
        </ReferenceField>
        <ReferenceField addLabel={false} source="page_id" reference="pages" link={false}>
          <SubReference source="page_type_id" reference="pageTypes" link={false}>
            <IsHomepage>
              <br />
              <br />
              <hr style={{ width: "100%" }} />
              <br />
              <ImagesInput source="images" />
            </IsHomepage>
          </SubReference>
        </ReferenceField>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            console.log("FORM DATA PC CREATE ->>", formData);
            return <Field name="content" component={Editor} contentData={formData} />;
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
