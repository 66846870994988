import { find } from "lodash";
import { handleSingleRequest } from "../restClient";
import { UPDATE } from "../types";

export const transformTagGroups = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params,
    include: ["tags", "productCategories"]
  }).then(({ data = [], included = [], relationships, ...rest }) => {
    data.tagIds = [];
    data.productCategoryIds = [];
    data.tagIds = included.filter(item => item.type === "tags").map(tag => tag.id);
    data.productCategoryIds = included
      .filter(item => item.type === "productCategories")
      .map(category => category.id);

    if (data.map) {
      data = data.map(record => {
        record.productCategoryIds = find(relationships, {
          id: record.id
        }).productCategories.data.map(item => item.id);

        return record;
      });
    }

    return { data, ...rest };
  });
};

export const transformTagGroupsCreate = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params
  }).then(({ data = [], included = [], ...rest }) => {
    if (params.data.tagIds) {
      params.relationships = {
        tags: {
          data: params.data.tagIds.map(id => ({ type: "tags", id }))
        }
      };
    }

    if (params.data.productCategoryIds) {
      params.relationships = {
        ...params.relationships,
        productCategories: {
          data: params.data.productCategoryIds.map(id => ({
            type: "productCategories",
            id
          }))
        }
      };
    }

    return handleSingleRequest(UPDATE, resource, {
      ...params,
      id: data.id
    }).then(({ data = [], included = [], ...rest }) => {
      return { data, ...rest };
    });
  });
};

export const transformTagGroupsUpdate = (type, resource, params) => {
  if (params.data.tagIds) {
    params.relationships = {
      tags: {
        data: params.data.tagIds.map(id => ({ type: "tags", id }))
      }
    };
  } else {
    params.relationships = {
      tags: []
    };
  }

  if (params.data.productCategoryIds) {
    params.relationships = {
      ...params.relationships,
      productCategories: {
        data: params.data.productCategoryIds.map(id => ({
          type: "productCategories",
          id
        }))
      }
    };
  } else {
    params.relationships = {
      ...params.relationships,
      productCategories: []
    };
  }

  return handleSingleRequest(type, resource, {
    ...params
  }).then(({ data = [], included = [], ...rest }) => {
    return { data, ...rest };
  });
};
