import React, { cloneElement, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import { toggleSidebar as toggleSidebarAction } from "ra-core";

import LoadingIndicator from "./LoadingIndicator";
import UserMenu from "./UserMenu";
// import Headroom from "./Headroom";
import CountrySelector from "../CountrySelector";
import ZepterLogo from "../../assets/images/zepter-logo-black.png";

const Title = () => <img src={ZepterLogo} style={{ maxHeight: "30px" }} alt="Zepter" />;

const styles = theme => ({
  toolbar: {
    paddingRight: 24
  },
  menuButton: {
    marginLeft: "0.5em",
    marginRight: "0.5em"
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(0deg)"
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    transform: "rotate(180deg)"
  },
  title: {
    flex: 1,
    paddingLeft: "5px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 16,
    marginLeft: "2rem"
  }
});

const headerStyle = {
  position: "sticky",
  top: 0,
  zIndex: 10000
};

class AppBar extends Component {
  state = {
    selectedAppId: localStorage.getItem("appId"),
    countries: JSON.parse(localStorage.getItem("countries")) || [],
    countryName: JSON.parse(localStorage.getItem("countries"))
      ? JSON.parse(localStorage.getItem("countries")).find(
          country => country.application_id === localStorage.getItem("appId")
        )
        ? JSON.parse(localStorage.getItem("countries")).find(
            country => country.application_id === localStorage.getItem("appId")
          ).international_name
        : undefined
      : undefined
  };

  handleCountryChange = ({ target: { value: newAppId } }) => {
    if (newAppId === this.state.selectedAppId) {
      return false;
    }

    this.setState({ selectedAppId: newAppId });
    localStorage.setItem("appId", newAppId);
    window.location.replace("/#/");
    window.location.reload();
  };

  render() {
    const {
      children,
      classes,
      className,
      logout,
      open,
      title,
      toggleSidebar,
      userMenu,
      width,
      ...rest
    } = this.props;
    const { selectedAppId, countries } = this.state;
    return (
      <div style={headerStyle}>
        <MuiAppBar
          className={className}
          color="secondary"
          position="static"
          id="appbar"
          locale="en"
          {...rest}
        >
          <Toolbar
            disableGutters
            variant={width === "xs" ? "regular" : "dense"}
            className={classes.toolbar}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleSidebar}
              className={classNames(classes.menuButton)}
            >
              <MenuIcon
                classes={{
                  root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed
                }}
              />
            </IconButton>
            <Title />
            <Typography
              variant="h2"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
            />
            <div className={classes.title} />
            <CountrySelector
              selectedAppId={selectedAppId}
              countries={countries}
              handleCountryChange={this.handleCountryChange}
            />
            <LoadingIndicator />
            {cloneElement(userMenu, { logout })}
          </Toolbar>
        </MuiAppBar>
      </div>
    );
  }
}

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  userMenu: PropTypes.node,
  width: PropTypes.string
};

AppBar.defaultProps = {
  userMenu: <UserMenu />
};

const enhance = compose(
  connect(null, {
    toggleSidebar: toggleSidebarAction
  }),
  withStyles(styles),
  withWidth()
);

export default enhance(AppBar);
