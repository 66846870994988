import React from "react";
import { Filter, TextInput, SelectInput, translate } from "react-admin";

const ListFilters = ({ translate, ...props }) => (
  <Filter variant="standard" {...props}>
    <TextInput
      label="resources.priceRules.filters.search_name"
      source="rule->name_ilike"
      alwaysOn
      style={{ minWidth: "350px" }}
    />
    <SelectInput
      label="resources.priceRules.fields.rule.rule_type"
      source="rule->rule_type"
      choices={[
        { id: "all", name: translate("resources.priceRules.fields.all") },
        { id: "searcher", name: "Searcher" },
        { id: "cart_amount", name: "Cart Amount" },
        { id: "promo_code", name: "Promo Code" }
      ]}
      allowEmpty={false}
      alwaysOn
    />
    <SelectInput
      source="rules_activation_status"
      label="resources.priceRules.fields.status"
      choices={[
        {
          id: "all",
          name: translate("resources.priceRules.fields.all")
        },
        {
          id: "active",
          name: translate("resources.priceRules.fields.active")
        },
        {
          id: "inactive",
          name: translate("resources.priceRules.fields.inactive")
        }
      ]}
      allowEmpty={false}
    />
  </Filter>
);

export default translate(ListFilters);
