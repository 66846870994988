import React from "react";
import { Filter, TextInput, translate } from "react-admin";

const UserFilters = ({ translate, ...rest }) => {
  const countryAppId = localStorage.getItem("appId");
  const countryName = JSON.parse(localStorage.getItem("countries")).find(
    country => country.application_id === countryAppId
  ).international_name;

  const isCountryJordanOrEgypt = countryName === "Jordan" || countryName === "Egypt";
  const isCountryKazakhstan = countryName === "Kazakhstan";

  return (
    <Filter variant="standard" {...rest}>
      <TextInput label="resources.users.filters.email_ilike" source="email_ilike" />
      <TextInput
        label="resources.users.filters.readable_name_ilike"
        source="readable_name_ilike"
        alwaysOn
      />
      <TextInput label="resources.users.filters.phone_ilike" source="phone_ilike" />
      <TextInput label="resources.users.filters.external_id" source="external_id_ilike" />
      {isCountryJordanOrEgypt ? (
        <TextInput label="resources.users.filters.personal_number" source="personal_number_ilike" />
      ) : null}
      {isCountryKazakhstan ? <TextInput label="ИИН" source="tax_id_ilike" /> : null}
    </Filter>
  );
};

export default translate(UserFilters);
