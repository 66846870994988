import React from "react";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import LineGraph from "../Graphs/LineGraph";
import PieGraph from "../Graphs/PieGraph";
// import TinyLineGraph from "../Graphs/TinyLineGraph";

import { withStyles } from "@material-ui/core/styles";

import CountUp from "../../../components/CountUp";

import CustomersIcon from "../../../assets/images/icon-customers.svg";
import OrdersIcon from "../../../assets/images/icon-orders.svg";
import TranslatableParagraph from "../../../components/TranslationComponents/TranslatableParagraph";

const returningCustomerRateColors = ["#3498db", "#FF4100"];

const styles = theme => ({
  chartWrapper: {
    display: "flex",
    justifyContent: "space-evenly",
    padding: "24px"
  },
  headerLabel: {
    fontWeight: "bold",
    borderBottom: "4px solid #3498db",
    width: "fit-content",
    margin: "12px 24px",
    fontSize: "11px"
  },
  headerData: {
    fontWeight: "bold",
    width: "fit-content",
    margin: "12px 24px",
    fontSize: "17px"
  },
  orange: {
    background: "#FF4100",
    width: "100%",
    height: "12px"
  },
  blue: {
    background: "#3498db",
    width: "100%",
    height: "12px"
  },
  cardContent: {
    padding: "0 50px"
  },
  cardStyle: {
    display: "flex",
    justifyContent: "center",
    height: "100%"
  }
});

class Analytics extends React.Component {
  render() {
    const {
      classes,
      totalOrdersData,
      totalOrders,
      averageOrderPerDayValues,
      averageOrderValue,
      totalSales,
      totalSalesData,
      returningCustomerRateData,
      customers,
      oldKey,
      newKey
    } = this.props;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card>
            <div className={classes.blue} />

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={classes.cardContent}>
                <p
                  style={{
                    fontSize: 30,
                    fontWeight: "bold",
                    lineHeight: 1,
                    margin: "20px 0 0"
                  }}
                >
                  <CountUp count={totalOrders} />
                </p>
                <TranslatableParagraph
                  customStyling={{ fontSize: 18, marginTop: "9px" }}
                  translationLabel="resources.dashboard.tabs.analytics.name"
                />
              </div>
              {/* <TinyLineGraph color="#3498db" /> */}
              <img src={OrdersIcon} style={{ paddingRight: "20px" }} alt="Orders" />
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <div className={classes.orange} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={classes.cardContent}>
                <p
                  style={{
                    fontSize: 30,
                    fontWeight: "bold",
                    lineHeight: 1,
                    margin: "20px 0 0"
                  }}
                >
                  <CountUp count={customers} />
                </p>
                <TranslatableParagraph
                  customStyling={{ fontSize: 18, marginTop: "9px" }}
                  translationLabel="resources.dashboard.tabs.mutual.customers"
                />
              </div>
              {/* <TinyLineGraph color="#FF4100" /> */}
              <img src={CustomersIcon} style={{ paddingRight: "20px" }} alt="Customers" />
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.cardStyle}>
            <div>
              <h4 className={classes.headerLabel}>
                <TranslatableParagraph translationLabel="resources.dashboard.tabs.analytics.totalSales" />
              </h4>
              <h2 className={classes.headerData}>{totalSales}</h2>
              <LineGraph data={totalSalesData} oldKey={oldKey} newKey={newKey} />
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.cardStyle}>
            <div>
              <TranslatableParagraph
                customStyling={{
                  fontWeight: "bold",
                  margin: "15px 30px",
                  width: "fit-content",
                  borderBottom: "4px solid #3489db"
                }}
                translationLabel="resources.dashboard.tabs.mutual.averageOrderValue"
              />
              <h2 className={classes.headerData}>{averageOrderValue}</h2>
              <LineGraph data={averageOrderPerDayValues} oldKey={oldKey} newKey={newKey} />
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.cardStyle}>
            <div>
              <h4 className={classes.headerLabel}>
                <TranslatableParagraph translationLabel="resources.dashboard.tabs.analytics.name" />
              </h4>
              <h2 className={classes.headerData}>&nbsp;</h2>
              {returningCustomerRateData &&
              returningCustomerRateData[0] &&
              returningCustomerRateData[0].value &&
              returningCustomerRateData[1] &&
              returningCustomerRateData[1].value ? (
                <PieGraph
                  data={returningCustomerRateData}
                  colors={returningCustomerRateColors}
                  oldKey={oldKey}
                  newKey={newKey}
                />
              ) : (
                <TranslatableParagraph
                  customStyling={{
                    minHeight: "245px",
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "18px"
                  }}
                  translationLabel="resources.dashboard.tabs.mutual.noData"
                />
              )}
            </div>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card className={classes.cardStyle}>
            <div>
              <h4 className={classes.headerLabel}>
                <TranslatableParagraph translationLabel="resources.dashboard.tabs.mutual.totalOrders" />
              </h4>
              <h2 className={classes.headerData}>{totalOrders}</h2>
              <LineGraph data={totalOrdersData} oldKey={oldKey} newKey={newKey} />
            </div>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Analytics);
