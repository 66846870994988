import { disallowBrackets } from "../../utils/validators/regex";

export const checkForBracketsNotAlowed = value => {
  if (!value) {
    return "Required";
  }

  if (value && disallowBrackets(value)) {
    return "Please remove brackets";
  }

  return undefined;
};
