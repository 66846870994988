import React, { useState } from "react";
import {
  ListBase,
  useListContext,
  Pagination,
  TopToolbar,
  CreateButton,
  EditButton,
  Title
} from "react-admin";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  Card
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles({
  elevatedCard: {
    zIndex: 1,
    borderRadius: "0 0 4px 4px",
    padding: "10px 0"
  },
  treeWrapper: {
    "& ul": {
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
      }
    }
  },
  treeListItem: {
    transition: "all 0s ease 0s",
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
});

const ProductCategoriesActions = () => {
  const { basePath } = useListContext();

  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

const ProductCategoriesPagination = () => {
  const { total } = useListContext();

  return <Pagination rowsPerPageOptions={[]} total={total} />;
};

export const ProductCategoriesList = props => {
  const classes = useStyles();

  return (
    <ListBase perPage={10000} sort={{ field: "order_number", order: "ASC" }} {...props}>
      <ProductCategoriesActions />
      <Card className={classes.elevatedCard}>
        <Tree />
        <ProductCategoriesPagination />
      </Card>
    </ListBase>
  );
};

const Tree = () => {
  const classes = useStyles();
  const { ids, data, defaultTitle } = useListContext();
  const [openChildren, setOpenChildren] = useState([]);
  const toggleNode = node =>
    setOpenChildren(state => {
      if (state.includes(node.id)) {
        return [
          ...state.splice(0, state.indexOf(node.id)),
          ...state.splice(state.indexOf(node.id) + 1, state.length)
        ];
      } else {
        return [...state, node.id];
      }
    });
  const nodes = ids.map(id => data[id]);
  const roots = nodes.filter(
    node => typeof node.parent_category_id === "undefined" || node.parent_category_id === null
  );
  const getChildNodes = root => nodes.filter(node => node.parent_category_id === root.id);

  return (
    <List component="div" className={classes.treeWrapper}>
      <Title defaultTitle={defaultTitle} />
      {roots.map(root => (
        <SubTree
          key={root.id}
          root={root}
          getChildNodes={getChildNodes}
          openChildren={openChildren}
          toggleNode={toggleNode}
          level={1}
        />
      ))}
    </List>
  );
};

const SubTree = ({ level, root, getChildNodes, openChildren, toggleNode }) => {
  const classes = useStyles();
  const childNodes = getChildNodes(root);
  const hasChildren = childNodes.length > 0;
  const open = openChildren.includes(root.id);

  return (
    <List disablePadding>
      <ListItem
        button={hasChildren}
        onClick={() => hasChildren && toggleNode(root)}
        style={{ paddingLeft: level * 45 - 30 }}
        className={classes.treeListItem}
      >
        {hasChildren && open && <ExpandLess style={{ marginRight: 10 }} />}
        {hasChildren && !open && <ExpandMore style={{ marginRight: 10 }} />}
        {!hasChildren && <div style={{ width: 34 }}>&nbsp;</div>}
        <ListItemText primary={root.name} />

        <ListItemSecondaryAction>
          <EditButton record={root} basePath="/productCategories" />
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {childNodes.map(node => (
            <SubTree
              key={node.id}
              root={node}
              getChildNodes={getChildNodes}
              openChildren={openChildren}
              toggleNode={toggleNode}
              level={level + 1}
            />
          ))}
        </List>
      </Collapse>
    </List>
  );
};
