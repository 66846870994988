import React, { Component } from "react";
import { translate } from "react-admin";
import compose from "recompose/compose";
import { Link } from "react-router-dom";

// import { get } from "lodash";

import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
// import { Datagrid, Pagination } from "ra-ui-materialui/lib/list";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination
} from "@material-ui/core";

import { formatPriceAmount } from "../../utils/formatters/formatPriceAmount";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";
import { defaultLabelDisplayedRows } from "./index";

const style = theme => ({
  root: {
    marginTop: "2%"
  },
  revenue: {
    color: theme.palette.primary.main
  },
  itemTextRight: {
    fontSize: "0.6em"
  },
  chip: {
    fontSize: "0.6em",
    color: theme.palette.primary.main
  },
  headerLabel: {
    fontWeight: "bold",
    borderBottom: "4px solid #3498db",
    width: "fit-content",
    margin: "12px 24px"
  },
  customerName: {
    textTransform: "uppercase",
    color: "#000"
  },
  contactInfo: {
    fontSize: "9px",
    color: "#A5A5A5"
  },
  tableHeader: {
    fontSize: "9px",
    fontWeight: "600"
  },
  tableCellHead: {
    padding: "0 24px"
  },
  tableCellBody: {
    padding: 0
  },
  tableCellBodyContent: {
    color: "rgba(0, 0, 0, 0.87)",
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "8px 24px",
    minHeight: 86
  },
  buyer: {
    fontWeight: "bold",
    textTransform: "uppercase"
  }
});

class LatestOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "asc",
      orderBy: "calories",
      selected: [],
      data: props.latestOrdersData,
      page: 0,
      rowsPerPage: 10
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.latestOrdersData !== prevProps.latestOrdersData) {
      this.setState({ data: this.props.latestOrdersData });
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  getSorting = (order, orderBy) =>
    order === "desc" ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { latestOrdersData, latestOrdersDataTotal, classes } = this.props;
    const { rowsPerPage, page } = this.state;

    return (
      <Card className={classes.root}>
        <TranslatableParagraph
          customStyling={{
            fontWeight: "bold",
            borderBottom: "4px solid #3489db",
            marginLeft: "1.25rem",
            width: "fit-content"
          }}
          translationLabel={`resources.dashboard.tabs.orders.title`}
        />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellHead}>
                <TranslatableParagraph
                  translationLabel={`resources.dashboard.tabs.customers.details`}
                />
              </TableCell>
              <TableCell className={classes.tableCellHead}>
                <TranslatableParagraph
                  translationLabel={`resources.dashboard.tabs.orders.revenue`}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {latestOrdersData.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map(order => {
              const buyerName = order.customer_name;

              const sellerName = order.seller_name ? order.seller_name : "No name";

              const cityName = order.ship_address;

              const formattedAmount =
                order.order_total != null ? formatPriceAmount(order.order_total) : 0;
              const amount =
                order.order_total != null ? `${formattedAmount} ${order.currency_code}` : "No data";

              return (
                <TableRow key={order.id}>
                  <TableCell className={classes.tableCellBody}>
                    <Link to={`/orders/${order.id}/show`} className={classes.tableCellBodyContent}>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                          }}
                        >
                          <TranslatableParagraph
                            customStyling={{ margin: "0px" }}
                            translationLabel={`resources.dashboard.tabs.orders.buyer`}
                          />
                          :<span className={classes.buyer}>{buyerName}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                          }}
                        >
                          <TranslatableParagraph
                            customStyling={{ margin: "0px" }}
                            translationLabel={`resources.dashboard.tabs.orders.seller`}
                          />
                          :{sellerName}
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                          }}
                        >
                          <TranslatableParagraph
                            customStyling={{ margin: "0px" }}
                            translationLabel={`resources.dashboard.tabs.orders.city`}
                          />
                          : {cityName}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                          }}
                        >
                          <TranslatableParagraph
                            customStyling={{ margin: "0px" }}
                            translationLabel={`resources.dashboard.tabs.orders.createdAt`}
                          />
                          : : {order.date}
                        </div>
                      </div>
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    <Link to={`/orders/${order.id}/show`} className={classes.tableCellBodyContent}>
                      <span className={classes.revenue}>{amount}</span>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={latestOrdersDataTotal}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={
            <TranslatableParagraph translationLabel="ra.navigation.page_rows_per_page" />
          }
          backIconButtonProps={{
            "aria-label": <TranslatableParagraph translationLabel="ra.navigation.next" />
          }}
          nextIconButtonProps={{
            "aria-label": <TranslatableParagraph translationLabel="ra.navigation.prev" />
          }}
          labelDisplayedRows={defaultLabelDisplayedRows}
          page={page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </Card>
    );
  }
}

const enhance = compose(withStyles(style), translate);
export default enhance(LatestOrders);
