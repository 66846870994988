import React from "react";
import {
  Create,
  SimpleForm,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  Toolbar,
  SaveButton
} from "react-admin";
import { useFormState } from "react-final-form";

import GuidanceBox from "../../components/GuidanceBox/GuidanceBox";

import {
  BlogCategoryNameValidationTextInput,
  BlogCategorySlugValidationTextInput
} from "./validators";

const CustomToolbar = props => {
  const { values: formData } = useFormState();

  let isDisabledSave =
    !formData.language_id || !formData.name || !formData.url_slug || !formData.order_number
      ? true
      : false;

  return (
    <Toolbar {...props}>
      <SaveButton disabled={isDisabledSave} redirect={"/blogCategories"} />
    </Toolbar>
  );
};

export const BlogCategoryCreate = props => {
  return (
    <Create {...props} title="resources.blogCategories.title.create">
      <SimpleForm
        variant="standard"
        source="id"
        redirect={`/blogCategories`}
        submitOnEnter={false}
        toolbar={<CustomToolbar />}
      >
        <GuidanceBox
          buttonLabel="Creating previously deleted blog categories"
          infoBoxText="If you would like to create an element that you previously deleted but validation is not
          allowing you to do so; please contact our IT support to fulfill this request for you."
        />
        <ReferenceInput
          filter={{ is_enabled: true }}
          label="resources.blogCategories.translations.language_name"
          source="language_id"
          reference="languages"
        >
          <SelectInput optionText="name" validate={required()} />
        </ReferenceInput>
        <BlogCategoryNameValidationTextInput />
        <BlogCategorySlugValidationTextInput />
        <BooleanInput label="resources.blogCategories.fields.enabled" source="enabled" />
        <NumberInput
          label="resources.blogCategories.fields.order_number"
          source="order_number"
          min="0"
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};
