import React from "react";
import { Link } from "react-router-dom";
import AddCircle from "@material-ui/icons/AddCircle";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
    padding: "6px 10px"
  }
};

const AddProductButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    variant="contained"
    component={Link}
    to={{
      pathname: "/productTags/create",
      search: `?source=${JSON.stringify({ tag_id: record.id })}`
    }}
    label="resources.tags.fields.assign_title"
    title="resources.tags.fields.title_create_tag"
  >
    <AddCircle />
  </Button>
);

export default withStyles(styles)(AddProductButton);
