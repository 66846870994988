import React from "react";
import { ReferenceInput, SelectInput, translate } from "react-admin";

const OrderStatus = ({ choices, translate, ...rest }) => {
  let selectionChoices = choices.map(choice => ({
    ...choice,
    name: choice.name
  }));

  return <SelectInput {...rest} choices={selectionChoices} variant="standard" />;
};

const OrderStatusSelectInput = OrderStatus;

const OrderStatusReferenceInput = props => (
  <ReferenceInput {...props}>
    <OrderStatusSelectInput optionText="name" />
  </ReferenceInput>
);

export default OrderStatusReferenceInput;
