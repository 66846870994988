import { handleSingleRequest } from "../restClient";
import { CREATE, GET_LIST, DELETE, UPDATE } from "../types";
const countryAppId = localStorage.getItem("appId");
const countriies = JSON.parse(localStorage.getItem("countries"));
const country = countriies && countriies.find(country => country.application_id === countryAppId);
const countryId = country ? country.id : null;

export const transformFooterCreateUpdate = (type, resource, params) => {
  const getFormData = params.data;
  const arrayOfTranslations = [];
  return handleSingleRequest(GET_LIST, "translations", {
    pagination: { page: "all" },
    sort: { field: "id", order: "DESC" },
    filter: {
      table_name: "footer_info"
    }
  }).then(async ({ data = {}, included = [], relationships, ...rest }) => {
    const defaultLanguage = await handleSingleRequest(GET_LIST, "languages", {
      pagination: {
        page: "all"
      },
      sort: { field: "name", order: "ASC" },
      filter: {
        is_default: true
      }
    });
    const firstTranslation = {
      language_id: getFormData.language?.id ? getFormData.language.id : defaultLanguage.data[0].id,
      table_name: "footer_info",
      table_row_id: countryId,
      id: getFormData.id ? getFormData.id : null,
      translation: {
        column_order: getFormData.column_order,
        column_title: getFormData.column_title,
        contentTranslations:
          getFormData.text_row?.length &&
          getFormData.text_row.map(row => {
            return {
              label: row.label ? row.label : "",
              text: row.text,
              order: row.order,
              has_url: row.has_url ? true : false,
              text_url: row.text_url ? row.text_url : null
            };
          })
      }
    };
    arrayOfTranslations.push(firstTranslation);

    if (getFormData.text_row?.length && getFormData.translations?.length) {
      getFormData.translations.map(translation => {
        return arrayOfTranslations.push({
          language_id: translation.language.id ? translation.language.id : translation.language,
          table_name: "footer_info",
          table_row_id: countryId,
          id: translation.id ? translation.id : null,
          translation: {
            column_order: getFormData.column_order,
            column_title: translation.column_title,
            contentTranslations: getFormData.text_row.map((row, index) => {
              return {
                label: translation.content[index].label ? translation.content[index].label : "",
                text: translation.content[index].text ? translation.content[index].text : "",
                order: row.order,
                has_url: row.has_url ? true : false,
                text_url: row.text_url ? row.text_url : null
              };
            })
          }
        });
      });
    }
    data = data.filter(item => item.translation.column_order === params.data.column_order);
    arrayOfTranslations.forEach(translationItem => {
      const checkForDuplicates = arrayOfTranslations.filter(
        oneOf => oneOf.language_id === translationItem.language_id
      );
      const checkIfItsExistingOne = translationItem?.id;
      if (checkForDuplicates?.length <= 1) {
        if (checkIfItsExistingOne) {
          handleSingleRequest(UPDATE, "translations", {
            id: checkIfItsExistingOne,
            data: { ...translationItem, id: checkIfItsExistingOne }
          });
        } else {
          handleSingleRequest(CREATE, "translations", {
            data: translationItem
          });
        }
      } else if (checkForDuplicates[0]?.id) {
        handleSingleRequest(DELETE, "translations", {
          id: checkForDuplicates[0].id,
          data: {}
        });
      }
    });
    data.forEach(translationItem => {
      const checkForDuplicates = data.filter(
        oneOf => oneOf.language_id === translationItem.language_id
      );

      const checkTranslations = arrayOfTranslations.find(
        item => item.language_id === translationItem.language_id
      );

      if (checkTranslations && checkForDuplicates?.length > 1) {
        handleSingleRequest(DELETE, "translations", {
          id: translationItem.id,
          data: {}
        });
      }
    });
    data = data && data[0] ? data[0] : arrayOfTranslations[0];
    if (!data.id) {
      const getNewSavedColumn = await handleSingleRequest(GET_LIST, "translations", {
        pagination: {
          page: "all"
        },
        sort: { field: "name", order: "ASC" },
        filter: {
          table_name: "footer_info",
          language_id: data.language_id
        }
      });
      const newData = getNewSavedColumn.data.filter(
        item =>
          item.translation.column_order === data.translation.column_order &&
          item.translation.column_title === data.translation.column_title
      )[0];
      return { data: newData, ...rest };
    } else {
      return { data, ...rest };
    }
  });
};

export const transformFooters = (type, resource, params) => {
  return handleSingleRequest(type, "translations", {
    ...params,
    sort: {},
    filter: {
      table_name: "footer_info"
    }
  }).then(async ({ data = {}, included = [], relationships, ...rest }) => {
    const allLanguages = await handleSingleRequest(GET_LIST, "languages", {
      pagination: {
        page: "all"
      },
      sort: { field: "name", order: "ASC" },
      filter: {
        is_enabled: true
      }
    });
    data = data.filter(item => {
      return allLanguages?.data?.find(language => language.id === item.language_id);
    });
    data = data.map(item => {
      const getLanguageName = allLanguages?.data?.find(
        language => language.id === item.language_id
      );
      return {
        ...item,
        translation: {
          ...item.translation,
          language_name: getLanguageName.international_name
        }
      };
    });
    const sortField = params.sort.field.substr(params.sort.field.indexOf(".") + 1);

    if (params?.sort?.order === "ASC") {
      if (sortField === "column_order") {
        data.sort((a, b) => {
          return a.translation[sortField] - b.translation[sortField];
        });
      } else if (sortField === "id") {
        data = data.sort((a, b) => {
          return a.translation.column_order - b.translation.column_order;
        });
      } else {
        data.sort((a, b) => {
          return b.translation.language_name.localeCompare(a.translation.language_name);
        });
      }
    } else if (params?.sort?.order === "DESC") {
      if (sortField === "column_order") {
        data.sort((a, b) => {
          return b.translation[sortField] - a.translation[sortField];
        });
      } else {
        data.sort((a, b) => {
          return a.translation.language_name.localeCompare(b.translation.language_name);
        });
      }
    }
    return {
      data,
      ...rest
    };
  });
};

export const transformFooter = (type, resource, params) => {
  return handleSingleRequest(type, "translations", {
    ...params
  }).then(async ({ data = {}, included = [], relationships, ...rest }) => {
    // get language international name
    let language = data.language_id;
    const allLanguages = await handleSingleRequest(GET_LIST, "languages", {
      pagination: {
        page: "all"
      },
      sort: { field: "name", order: "ASC" },
      filter: {
        is_enabled: true
      }
    });
    if (allLanguages?.data?.length && data.language_id) {
      const catcheLang = allLanguages.data.find(
        lang => lang.id === data.language_id
      )?.international_name;
      language = catcheLang
        ? { name: catcheLang, id: data.language_id }
        : { name: language, id: language };
    }
    // get all other translations international name
    const allTranslations = await handleSingleRequest(GET_LIST, "translations", {
      pagination: {
        page: "all"
      },
      sort: { field: "name", order: "ASC" },
      filter: {
        table_name: "footer_info",
        language_id_in: allLanguages?.data?.map(item => item.id)
      }
    });
    const languageOptions = allLanguages.data?.map(oneLang => {
      return { name: oneLang.international_name, id: oneLang.id };
    });
    const otherTranslations = allTranslations.data
      .filter(
        translationItem =>
          translationItem?.translation?.column_order === data?.translation?.column_order
      )
      .filter(obj => obj?.id !== params?.id)
      .map(item => {
        const content =
          item.translation.contentTranslations &&
          item?.translation?.contentTranslations?.map(string => {
            return {
              label: string?.label || "",
              text: string?.text || ""
            };
          });

        const mainContent = {
          id: item?.id,
          content: content,
          column_title: item?.translation?.column_title,
          language: allLanguages?.data?.find(lang => lang?.id === item?.language_id)
            ? {
                name: allLanguages?.data?.find(lang => lang?.id === item?.language_id)
                  .international_name,
                id: item?.language_id
              }
            : item?.language_id
        };
        return mainContent;
      });
    if (data?.translation?.contentTranslations) {
      data = {
        languageOptions: languageOptions,
        allTranslations: allTranslations.data,
        id: data.id,
        column_title: data.translation.column_title,
        column_order: data.translation.column_order,
        language: language,
        text_row: data?.translation?.contentTranslations.map(item => {
          return {
            order: item.order,
            label: item.label,
            text: item.text,
            has_url: item.has_url,
            text_url: item.text_url ? item.text_url : ""
          };
        }),
        translations: otherTranslations
      };
    } else {
      data = {
        languageOptions: languageOptions,
        allTranslations: allTranslations?.data,
        id: data?.id,
        column_title: data?.translation?.column_title,
        column_order: data?.translation?.column_order,
        language: language,
        text_row: [],
        translations: otherTranslations
      };
    }
    return { data, ...rest };
  });
};

export const transformFooterDelete = (type, resource, params) => {
  return handleSingleRequest(GET_LIST, "translations", {
    pagination: {
      page: "all"
    },
    sort: { field: "name", order: "ASC" },
    filter: {
      table_name: "footer_info"
    }
  }).then(async ({ data = {}, included = [], relationships, ...rest }) => {
    if (params.ids) {
      params.ids.forEach(oneId => {
        handleSingleRequest(DELETE, "translations", {
          id: oneId,
          data: {}
        });
      });
    } else {
      const mainTranslation = data.find(item => item.id === params.id);
      data = data.filter(
        translationItem =>
          translationItem.translation.column_order === mainTranslation.translation.column_order
      );
      data.forEach(item => {
        handleSingleRequest(DELETE, "translations", {
          id: item.id,
          data: {}
        });
      });
    }
    const allLanguages = await handleSingleRequest(GET_LIST, "languages", {
      pagination: {
        page: "all"
      },
      sort: { field: "name", order: "ASC" },
      filter: {
        is_default: true
      }
    });
    const allTranslations = await handleSingleRequest(GET_LIST, "translations", {
      pagination: {
        page: "all"
      },
      sort: { field: "name", order: "ASC" },
      filter: {
        table_name: "footer_info"
      }
    });
    return {
      data: allTranslations.data.filter(item => item.language_id === allLanguages.data[0].id),
      ...rest
    };
  });
};
