import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
  FormDataConsumer,
  required
} from "react-admin";
import { PriceLevels, NumberOfRegistrated } from "./ParameterConstants";

export const DiscountForNumberOfRegisteredCreate = props => (
  <Create {...props}>
    <SimpleForm variant="standard" redirect="list">
      <NumberInput source="sort_order" label="Order" min="0" validate={required()} />
      <p style={{ fontSize: "12px", paddingBottom: "0px", marginBottom: "10px", marginTop: 0 }}>
        * Label for table has to have 0 sort order
      </p>
      <FormDataConsumer>
        {({ formData }) => {
          if (formData.sort_order === 0) {
            return (
              <div style={{ display: "flex", flexDirection: "column", maxWidth: "256px" }}>
                <TextInput
                  source="number_of_registered"
                  label="Label first row"
                  validate={required()}
                />
                <TextInput
                  source="display_price_level"
                  label="Label second row"
                  validate={required()}
                />
              </div>
            );
          } else {
            return (
              <div style={{ display: "flex", flexDirection: "column", maxWidth: "256px" }}>
                <SelectInput
                  choices={NumberOfRegistrated}
                  source="number_of_registered"
                  label="Number of registered"
                  validate={required()}
                />
                <SelectInput
                  choices={PriceLevels}
                  source="price_level"
                  label="Price level"
                  validate={required()}
                />
                <TextInput
                  source="display_price_level"
                  label="Display price level"
                  validate={required()}
                />
              </div>
            );
          }
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);
