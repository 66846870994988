import React from "react";
import {
  Edit,
  TextInput,
  TabbedForm,
  FormTab,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  NumberInput,
  BooleanInput,
  required,
  useNotify,
  useRedirect,
  DELETE,
  HttpError,
  useRefresh
} from "react-admin";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";
import { styles } from "../styles";
import Button from "@material-ui/core/Button";

import { handleSingleRequest } from "../../services/api/restClient";
import { validateNameInput } from "../../utils/validators/index";

const ConfirmationToolbar = props => {
  return (
    <Toolbar {...props} style={styles.toolbar}>
      <SaveButton />
      <DeleteConfirmation
        undoable={false}
        caution={`If you delete ${
          props?.record?.column_title || "this translation"
        }, it'll also delete all translation related to it; To delete a singular translation please use: DELETE FOOTER TRANSLATION on Translations tab in Footer`}
      />
    </Toolbar>
  );
};

const thereCanBeOnlyOne = (value, formValues) => {
  if (value) {
    let checkForExistingLanguageSelected = formValues?.translations?.filter(
      item => item && item.language && item.language.id === value
    );
    if (
      checkForExistingLanguageSelected?.length > 1 ||
      (checkForExistingLanguageSelected?.length && formValues?.language?.id === value)
    ) {
      return "There can be only one language per column";
    } else {
      return undefined;
    }
  } else {
    return "Required";
  }
};

const checkURL = value => {
  if (value) {
    return value.charAt(0) !== "/"
      ? "Wrong format, try for example /categories/promotions"
      : undefined;
  } else {
    return "Required";
  }
};

const checkColumnOrder = (value, formValues) => {
  let regex = /^\+?(0|[1-9]\d*)$/gm;
  if (value && regex.test(value)) {
    const current = formValues.allTranslations?.filter(item => item.id === formValues.id)[0]
      ?.translation.column_order;
    const filtered = formValues.allTranslations
      ?.filter(item => item.translation.column_order === value)
      ?.filter(item => item.translation.column_order !== current);
    return filtered?.length ? "Column number taken" : undefined;
  } else if (!regex.test(value)) {
    return "Number can not be negative";
  } else {
    return "Required";
  }
};

const declineNegativeNumbers = value => {
  let regex = /^\+?(0|[1-9]\d*)$/gm;
  if (value && regex.test(value)) {
    return undefined;
  } else if (!regex.test(value)) {
    return "Number can not be negative";
  } else {
    return "Required";
  }
};

export const FooterEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("Footer has been created", { undoable: true });
    redirect("/footer");
  };

  const onFailure = () => {
    notify("Data has been delayed. Footer has been created; Please review");
    redirect("/footer");
  };

  const DeleteFooterTranslation = scopedFormData => {
    const refresh = useRefresh();
    const handleFooterDelete = async () => {
      try {
        await handleSingleRequest(DELETE, "translations", {
          data: {},
          id: scopedFormData?.record?.id
        });
      } catch (error) {
        console.log("Error delete tech data translation", error);
        throw new HttpError("Error while deleting translation for a footer");
      }
    };
    return (
      <Button
        variant="container"
        color="secondary"
        onClick={() => {
          handleFooterDelete();
          notify(
            `Translation ${
              scopedFormData?.record?.column_title || ""
            } has been deleted. Page will do a fast refresh.`
          );
          refresh();
        }}
      >
        Delete {/* scopedFormData?.record?.content[0]?.label */} footer translation
      </Button>
    );
  };

  return (
    <Edit onSuccess={onSuccess} onFailure={onFailure} {...props}>
      <TabbedForm redirect="show" toolbar={<ConfirmationToolbar />} variant="standard">
        <FormTab label="resources.footer.fields.column_content">
          <TextInput
            label="resources.footer.fields.column_title"
            source="column_title"
            validate={[required(), validateNameInput]}
          />
          <NumberInput
            min="1"
            source="column_order"
            label="resources.footer.fields.column_order"
            validate={checkColumnOrder}
          />
          <FormDataConsumer>
            {({ formData }) => (
              <SelectInput
                source="language.id"
                choices={formData.languageOptions}
                label="Language"
                validate={thereCanBeOnlyOne}
                variant="standard"
                style={{ minWidth: "256px" }}
              />
            )}
          </FormDataConsumer>
          <ArrayInput source="text_row" label="resources.footer.fields.text_row">
            <SimpleFormIterator disableReordering TransitionProps={{ enter: false, exit: false }}>
              <NumberInput
                min="1"
                source="order"
                label="resources.footer.fields.order"
                validate={declineNegativeNumbers}
              />
              <TextInput label="resources.footer.fields.label" source="label" />
              <TextInput label="resources.footer.fields.text" source="text" validate={required()} />
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource }) => {
                  return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <BooleanInput
                        label="resources.footer.fields.has_url_bool"
                        source={getSource("has_url")}
                        record={scopedFormData}
                      />
                      {scopedFormData?.has_url && (
                        <TextInput
                          label="resources.footer.fields.has_url"
                          source={getSource("text_url")}
                          validate={checkURL}
                          variant="standard"
                        />
                      )}
                    </div>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="resources.footer.translations.name">
          <ArrayInput source="translations">
            <SimpleFormIterator disableReordering TransitionProps={{ enter: false, exit: false }}>
              <FormDataConsumer>
                {({ formData, dispatch, scopedFormData, getSource, ...rest }) => {
                  return (
                    <>
                      {scopedFormData ? (
                        <DeleteFooterTranslation
                          // translationName={scopedFormData?.record?.content[0]?.label || ""}
                          record={scopedFormData}
                        />
                      ) : null}
                      <div>
                        <p style={{ color: "#bbb", fontSize: "11px" }}>
                          <TranslatableParagraph
                            textOnly
                            translationLabel="resources.footer.translations.original_title"
                          />
                          {formData?.column_title}
                        </p>
                        <TextInput
                          label="resources.footer.translations.column_title"
                          source={getSource("column_title")}
                          validate={required()}
                          variant="standard"
                        />
                        <br />
                        <SelectInput
                          source={getSource("language.id")}
                          choices={formData.languageOptions}
                          label="resources.footer.translations.language"
                          validate={thereCanBeOnlyOne}
                          variant="standard"
                        />
                        {formData?.text_row?.length &&
                          formData?.text_row.map((row, index) => {
                            return (
                              <div style={{ display: "flex", flexDirection: "column" }} key={index}>
                                {row?.label && (
                                  <div style={{ display: "flex", flexDirection: "column" }}>
                                    <p style={{ color: "#bbb", fontSize: "11px" }}>
                                      <TranslatableParagraph
                                        textOnly
                                        translationLabel="resources.footer.translations.original_label"
                                      />
                                      {formData?.text_row[index].label}
                                    </p>
                                    <TextInput
                                      label="resources.footer.translations.label_translation"
                                      source={getSource(`content[${index}].label`)}
                                      validate={required()}
                                      variant="standard"
                                    />
                                  </div>
                                )}
                                {row?.text && (
                                  <div style={{ display: "flex", flexDirection: "column" }}>
                                    <p style={{ color: "#bbb", fontSize: "11px" }}>
                                      <TranslatableParagraph
                                        textOnly
                                        translationLabel="resources.footer.translations.original_text"
                                      />
                                      {formData?.text_row[index].text}
                                    </p>
                                    <TextInput
                                      label="resources.footer.translations.text_translation"
                                      source={getSource(`content[${index}].text`)}
                                      validate={required()}
                                      variant="standard"
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
