import React from "react";
import {
  Show,
  TextField,
  Datagrid,
  TabbedShowLayout,
  Tab,
  ReferenceField,
  ReferenceArrayField,
  DateField,
  SingleFieldList,
  ChipField
} from "react-admin";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

const TagShowTitle = ({ record }) => (
  <TranslatableParagraph
    customStyling={{ display: "inline-block", margin: 0 }}
    translationLabel="resources.tags.fields.title_show"
    customLabel={record.name}
  />
);

export const TagShow = props => (
  <Show title={<TagShowTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="resources.tags.fields.details">
        <TextField source="id" />
        <TextField label="resources.tags.fields.name" source="name" />

        <ReferenceArrayField reference="productCategories" source="categoryIds" allowEmpty>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>

        <ReferenceArrayField reference="tagGroups" source="tagGroupIds" allowEmpty>
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>

        <DateField source="created_at" />
        <DateField source="updated_at" />
      </Tab>
      <Tab label="resources.tags.fields.assigned_products">
        <ReferenceArrayField
          addLabel={false}
          reference="productTags"
          source="productTagIds"
          allowEmpty
        >
          <Datagrid>
            <DateField label="resources.tags.fields.assigned_on" source="created_at" />
            <DateField label="resources.tags.fields.updated_on" source="updated_at" />
            <ReferenceField
              reference="products"
              label="resources.tags.fields.product_id"
              source="product_id"
              allowEmpty
            >
              <TextField source="external_number" />
            </ReferenceField>
            {/* <DeleteButton redirect={false} /> */}
          </Datagrid>
        </ReferenceArrayField>
        {/* <AddProductButton /> */}
      </Tab>
      {/* <Tab label="Assigned tag groups">
        <ReferenceArrayField
          addLabel={false}
          reference="tagGroups"
          source="tagGroupIds"
          allowEmpty
        >
          <Datagrid>
            <DateField label="Assigned on" source="created_at" />
            <DateField label="Updated on" source="updated_at" />
            <ReferenceField
              label="Name"
              reference="tagGroups"
              source="id"
              allowEmpty
            >
              <TextField source="name" />
            </ReferenceField>
          </Datagrid>
        </ReferenceArrayField>
      </Tab> */}
    </TabbedShowLayout>
  </Show>
);
