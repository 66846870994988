import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const vidUrl = process.env.REACT_APP_IMG_URL;

const styles = {
  root: { maxWidth: "100%", marginTop: "10px" }
};
const ImageField = withStyles(styles)(({ classes, record }) => {
  const src = record.rawFile
    ? record.src
    : record.src
    ? record.src.includes("https://gateway")
      ? record.src
      : `${vidUrl}${record.src}`
    : `${vidUrl}/attachments/${record.file_name}`;
  return (
    <video className={classes.root} controls>
      <source src={src} alt="" />
      Your browser does not support HTML5 video.
    </video>
  );
});

export default ImageField;
