import React, { Fragment } from "react";
// import ReactDOM from "react-dom";
import { Admin, Resource } from "react-admin";

import polyglotI18nProvider from "ra-i18n-polyglot";
// ! Languages
import englishMessages from "ra-language-english";
import russianMessages from "ra-language-russian";
import en from "./i18n/en.json";
import ru from "./i18n/ru.json";
import sr from "./i18n/sr.json";
import de from "./i18n/de.json";
import hr from "./i18n/hr.json";
import cs from "./i18n/cs.json";
import fr from "./i18n/fr.json";
import pl from "./i18n/pl.json";
import ro from "./i18n/ro.json";

import restClient from "./services/api/restClient";
import authProvider from "./services/auth/provider";

import "ContentTools/build/content-tools.min.css";
import "./App.css";
import "./datepicker.css";

import { withStyles } from "@material-ui/core/styles";

import ProductsIcon from "@material-ui/icons/FilterList";
import OrdersIcon from "@material-ui/icons/ShoppingCart";
import AuditsIcon from "@material-ui/icons/TrackChanges";
import UserIcon from "@material-ui/icons/People";
import TagIcon from "@material-ui/icons/LocalOffer";
import TagGroupIcon from "@material-ui/icons/Style";
import PagesIcon from "@material-ui/icons/FilterNone";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Payment from "@material-ui/icons/Payment";
// import ShopIcon from "@material-ui/icons/ShoppingBasket";
import SwapCallsIcon from "@material-ui/icons/SwapCalls";
import BlogsIcon from "@material-ui/icons/Book";
import BlogIcon from "@material-ui/icons/Bookmark";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import BenefitsIcon from "@material-ui/icons/LocalActivity";
import SettingsIcon from "@material-ui/icons/Settings";
// Notifications Tab Icon
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
// Languages Tab Icon
import GTranslateIcon from "@material-ui/icons/GTranslate";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import { editPhoneEmail, UsersList, UserShow } from "./resources/users";

import Dashboard from "./pages/dashboard";

import { OrdersList, OrderShow, OrderEdit } from "./resources/orders";

import { PagesList, PageEdit } from "./resources/pages";
import PageCreate from "./resources/pages/Create";

import { ShopList, ShopShow, ShopEdit, ShopCreate } from "./resources/shops";
import { ConfigurationsList } from "./resources/configurations";
import ConfigurationsEdit from "./resources/configurations/Edit";

import { AuditsList } from "./resources/audits";

import {
  // ProductPricesList,
  ProductPricesEdit,
  ProductPricesCreate
} from "./resources/productPrices";

import { PageContentsEdit, PageContentsCreate } from "./resources/pageContents";

import { ProductTypeShow } from "./resources/productTypes";

import { ProductCreate, ProductsList, ProductShow } from "./resources/products";
// import ProductCreate from "./resources/products/Create";
import ProductEdit from "./resources/products/Edit";

import {
  ProductCategoriesList,
  ProductCategoryShow,
  ProductCategoryCreate,
  ProductCategoryEdit
} from "./resources/productCategories";

import { ProductTagCreate } from "./resources/productTags";

import {
  TagGroupsList,
  TagGroupShow,
  TagGroupCreate,
  TagGroupEdit
} from "./resources/tagGroups/tagGroups";

import { TagsList, TagShow, TagEdit, TagCreate } from "./resources/tags";

import {
  TechDataEdit,
  TechDataShow,
  TechDataCreate
  // TechDataList
} from "./resources/techData";

import {
  priceRulesList,
  priceRulesShow
  // priceRulesEdit
  // priceRulesCreate
} from "./resources/priceRules";
import PriceRulesEdit from "./resources/priceRules/Edit";
import PriceRulesCreate from "./resources/priceRules/Create";

import {
  paymentRulesList,
  paymentRulesShow
  // paymentRulesEdit,
  // paymentRulesCreate
} from "./resources/paymentRules";
import PaymentRulesEdit from "./resources/paymentRules/Edit";
import PaymentRulesCreate from "./resources/paymentRules/Create";
import { FooterList, FooterShow, FooterEdit, FooterCreate } from "./resources/footer";
import { FeedList, FeedShow, FeedEdit, FeedCreate } from "./resources/productFeeds";
import {
  ImageCreate,
  ImageEdit
  // ImageShow,
  // ImagesList
} from "./resources/images";

import { BlogCategoryList, BlogCategoryEdit, BlogCategoryCreate } from "./resources/blogCategories";

import { BlogArticleCreate, BlogArticleEdit, BlogArticleList } from "./resources/blogs";

import { UserLevelsList, UserLevelsEdit } from "./resources/userLevels";

import {
  NotificationCategoriesCreate,
  NotificationCategoriesEdit,
  NotificationCategoriesList
} from "./resources/notificationCategories";

import {
  NotificationsList,
  NotificationsCreate,
  NotificationsEdit
} from "./resources/notifications";

import LanguagesList from "./resources/languages/List";
import LanguagesEdit from "./resources/languages/Edit";
// import LanguagesCreate from "./resources/languages/Create";

import Dns from "@material-ui/icons/Dns";

import { MuiThemeProvider } from "@material-ui/core/styles";
import { Theme } from "./Theme";

import Login from "./pages/Login";

import {
  // Feature,
  feature
} from "./components/Feature";
// feature(isoCode, "featureName")

import Layout from "./components/Layout";

import ZepterLogo from "./assets/images/zepter-logo-black.png";
import { BlogDetailsCreate, BlogDetailsEdit } from "./resources/blogDetails";

import customRoutes from "./resources/priceRules/customRoutes";
import { exportReducer } from "./redux/reducers";
import resources from "./resources/resources";
import AdminVersion from "./components//ReleaseRelated/AdminVersion";

// import polyglotI18nProvider from "ra-i18n-polyglot"
// import { NotificationAudiencesCreate, NotificationAudiencesList, NotificationAudiencesEdit } from "./resources/notificationAudiences"
import BestSellingList from "./resources/bestSellingProducts/List";
import BestSellingCreate from "./resources/bestSellingProducts/Create";
import BestSellingEdit from "./resources/bestSellingProducts/Edit";

import BenefitsList from "./resources/benefits/List";
import BenefitsCreate from "./resources/benefits/Create";
import BenefitsEdit from "./resources/benefits/Edit";

import { TableCreate, TableEdit, TableShow, TableList } from "./resources/frontDynamicTables";
import {
  AccordionCreate,
  AccordionEdit,
  AccordionShow,
  AccordionList
} from "./resources/accordionItems";

import {
  DiscountForNumberOfRegisteredCreate,
  DiscountForNumberOfRegisteredEdit,
  DiscountForNumberOfRegisteredShow,
  DiscountForNumberOfRegisteredList
} from "./resources/discountForNumberOfRegistered";

const messages = {
  en: { ...englishMessages, ...en, ra: { ...englishMessages.ra, ...en.ra } },
  ru: { ...russianMessages, ...ru },
  by: { ...ru },
  kz: { ...ru },
  sr: { ...sr },
  de: { ...de },
  au: { ...de },
  ch: { ...de },
  hr: { ...hr },
  cs: { ...cs },
  fr: { ...fr },
  pl: { ...pl },
  ro: { ...ro },
  // Languages without any translation -> Defaulting EN
  es: { ...en },
  sv: { ...en },
  sk: { ...en },
  it: { ...en },
  mk: { ...en },
  sl: { ...en },
  bg: { ...en },
  bs: { ...en },
  lv: { ...en },
  hu: { ...en }
};

const i18nProvider = polyglotI18nProvider(
  locale => messages[locale],
  (() => {
    // Get locale value from localStorage and assign it to local variable that will pass it forward to needing components
    // if (localStorage.getItem("locale")) {
    //   let pickedLanguage = localStorage.getItem("locale");
    //   return pickedLanguage;
    // } else {
    // If no locale value found do a default which will be English
    return "en";
    // }
  })()
);

const styles = theme => ({
  card: {
    minWidth: 300,
    background: "rgba(0,0,0,0.2)",
    border: "1px solid lightgray"
  },
  textField: {
    marginTop: 30,
    width: "100%",
    background: "rgba(255,255,255,0.8) !important",
    borderRadius: "4px !important",
    marginLeft: 0,
    marginRight: 0,
    padding: "0 5px"
  },
  formControl: {
    width: "100%"
  }
});

// If URL doesnt match with any existent || prevents !LoggedIn members to view dashboard
const invalidUrl = () => {
  window.location.replace("/#/login");
};
class App extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={Theme}>
        <Fragment>
          <Admin
            catchAll={invalidUrl}
            layout={Layout}
            loginPage={Login}
            i18nProvider={i18nProvider}
            theme={Theme}
            dataProvider={restClient}
            authProvider={authProvider}
            dashboard={Dashboard}
            customRoutes={customRoutes}
            customReducers={{ exportReducer }}
          >
            <Resource
              name="orders"
              icon={OrdersIcon}
              list={OrdersList}
              show={OrderShow}
              edit={OrderEdit}
            />
            <Resource
              name="productCategories"
              options={{ label: "Categories" }}
              icon={Dns}
              list={ProductCategoriesList}
              show={ProductCategoryShow}
              create={ProductCategoryCreate}
              edit={ProductCategoryEdit}
            />
            <Resource
              name="products"
              icon={ProductsIcon}
              list={ProductsList}
              edit={ProductEdit}
              show={ProductShow}
              create={ProductCreate}
            />
            <Resource
              name="bestSellingProducts"
              options={{ label: "resources.bestSellers.name" }}
              icon={ProductsIcon}
              list={BestSellingList}
              create={BestSellingCreate}
              edit={BestSellingEdit}
            />
            <Resource
              name="productPrices"
              options={{ label: "Product prices" }}
              icon={AttachMoney}
              // list={ProductPricesList}
              edit={feature("fullProductManagment") ? ProductPricesEdit : undefined}
              create={feature("fullProductManagment") ? ProductPricesCreate : undefined}
            />
            <Resource
              name="users"
              icon={UserIcon}
              list={UsersList}
              show={UserShow}
              edit={editPhoneEmail}
            />
            <Resource
              name="benefits"
              list={BenefitsList}
              create={BenefitsCreate}
              edit={BenefitsEdit}
            />
            <Resource
              name="shops"
              icon={ImportantDevicesIcon}
              list={ShopList}
              show={ShopShow}
              edit={ShopEdit}
              create={ShopCreate}
            />
            <Resource name="policies" />
            <Resource name="auditObjects" />
            <Resource name="bestSellingProductUpdates" />
            <Resource name="productTypes" show={ProductTypeShow} />
            <Resource name="countries" />
            <Resource name="shippingCountries" />
            <Resource name="currencies" />
            <Resource name="productInventory" />
            <Resource name="images" />
            <Resource name="vProductImages" create={ImageCreate} edit={ImageEdit} />
            <Resource name="orderStatuses" />
            <Resource name="priceLevels" />
            <Resource name="orderItems" />
            <Resource name="productTags" create={ProductTagCreate} />
            <Resource
              name="tagGroups"
              options={{ label: "Tag groups" }}
              icon={TagGroupIcon}
              list={TagGroupsList}
              show={TagGroupShow}
              create={TagGroupCreate}
              edit={TagGroupEdit}
            />
            <Resource
              name="tags"
              icon={TagIcon}
              list={TagsList}
              show={TagShow}
              edit={TagEdit}
              create={TagCreate}
            />
            <Resource name="payments" />
            <Resource name="paymentStatuses" />
            <Resource name="paymentMethods" />
            <Resource name="paymentTypes" />
            <Resource name="addresses" />
            <Resource name="userAddresses" />
            <Resource name="cities" />
            <Resource name="taxRates" />
            <Resource name="userTypes" />
            <Resource name="sellingPriceLevels" />
            <Resource name="shipments" />
            <Resource name="unitTypes" />
            <Resource name="roles" />
            <Resource name="priceLevels" />
            <Resource
              name="blogCategories"
              options={{ label: "resources.blogCategories.name" }}
              icon={BlogsIcon}
              list={BlogCategoryList}
              edit={BlogCategoryEdit}
              create={BlogCategoryCreate}
            />
            <Resource name="blogDetails" edit={BlogDetailsEdit} create={BlogDetailsCreate} />
            <Resource
              name="blogArticles"
              icon={BlogIcon}
              options={{ label: "resources.blogs.name" }}
              list={BlogArticleList}
              edit={BlogArticleEdit}
              create={BlogArticleCreate}
            />

            <Resource
              name="techDataLists"
              create={TechDataCreate}
              edit={TechDataEdit}
              show={TechDataShow}
            />
            <Resource name="headTags" />
            <Resource name="headTagTypes" />

            <Resource
              name={resources.PRICE_RULES}
              options={{ label: "resources.priceRules.name" }}
              icon={AttachMoney}
              list={priceRulesList}
              show={priceRulesShow}
              edit={PriceRulesEdit}
              create={PriceRulesCreate}
            />

            <Resource
              name={resources.PAYMENT_RULES}
              options={{ label: "resources.paymentRules.name" }}
              icon={Payment}
              list={paymentRulesList}
              show={paymentRulesShow}
              edit={PaymentRulesEdit}
              create={PaymentRulesCreate}
            />

            <Resource name="pageTypes" />
            <Resource
              name="pages"
              icon={PagesIcon}
              list={PagesList}
              edit={PageEdit}
              create={PageCreate}
            />
            <Resource name="pageContents" edit={PageContentsEdit} create={PageContentsCreate} />
            <Resource
              name="notificationCategories"
              options={{ label: "Notification categories" }}
              icon={NotificationsActiveIcon}
              list={NotificationCategoriesList}
              edit={NotificationCategoriesEdit}
              create={NotificationCategoriesCreate}
            />
            <Resource
              name="notifications"
              options={{ label: "Push notifications" }}
              icon={NotificationsActiveIcon}
              list={NotificationsList}
              create={NotificationsCreate}
              edit={NotificationsEdit}
            />
            <Resource
              name="footer"
              icon={TagIcon}
              list={FooterList}
              show={FooterShow}
              edit={FooterEdit}
              create={FooterCreate}
            />
            <Resource
              name="userLevels"
              icon={SupervisedUserCircleIcon}
              options={{ label: "Affiliate User Levels" }}
              list={UserLevelsList}
              edit={UserLevelsEdit}
            />
            <Resource
              name="productFeeds"
              icon={TagIcon}
              list={FeedList}
              show={FeedShow}
              edit={FeedEdit}
              create={FeedCreate}
            />
            <Resource
              name="frontDynamicTables"
              options={{ label: "Table of benefits" }}
              icon={BenefitsIcon}
              list={TableList}
              show={TableShow}
              edit={TableEdit}
              create={TableCreate}
            />
            <Resource
              name="accordionItems"
              options={{ label: "Accordion of benefits" }}
              icon={BenefitsIcon}
              list={AccordionList}
              show={AccordionShow}
              edit={AccordionEdit}
              create={AccordionCreate}
            />
            <Resource
              name="discountForNumberOfRegistereds"
              options={{ label: "Table Discount For Number Of Registered" }}
              icon={BenefitsIcon}
              list={DiscountForNumberOfRegisteredList}
              show={DiscountForNumberOfRegisteredShow}
              edit={DiscountForNumberOfRegisteredEdit}
              create={DiscountForNumberOfRegisteredCreate}
            />
            <Resource
              name="languages"
              options={{ label: "resources.languages.name" }}
              icon={GTranslateIcon}
              list={LanguagesList}
              edit={LanguagesEdit}
            />
            <Resource
              name="configurations"
              icon={SettingsIcon}
              list={ConfigurationsList}
              edit={ConfigurationsEdit}
            />
            <Resource name="audits" icon={AuditsIcon} list={AuditsList} />
            <Resource
              name="admin-version"
              options={{ label: `resources.version.name` }}
              icon={SwapCallsIcon}
              list={AdminVersion}
            />
          </Admin>
          <div className="footer">
            <h4 style={{ marginTop: "0", marginBottom: "10px" }}>
              <i>Powered by</i>{" "}
            </h4>
            <img src={ZepterLogo} style={{ maxWidth: "80px" }} alt="Zepter International" />
          </div>
        </Fragment>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
