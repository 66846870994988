import React from "react";
import {
  List,
  TextField,
  DateField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ReferenceField
} from "react-admin";
import CustomizableDatagrid from "../../components/CustomizableDatagrid";

export const ShopList = props => (
  <List {...props} exporter={false}>
    <CustomizableDatagrid
      rowClick="show"
      defaultColumns={[
        "name",
        "domain",
        "client_name",
        "start_date",
        "end_date",
        "categoryIds",
        "homepage_id"
      ]}
    >
      <TextField source="id" />
      <TextField label="resources.shops.fields.name" source="name" />
      <TextField label="resources.shops.fields.domain" source="domain" />
      <TextField label="resources.shops.fields.client_name" source="client_name" />
      <ReferenceField
        label="resources.shops.fields.homepage_name"
        source="homepage_id"
        reference="pages"
        link={false}
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField label="resources.shops.fields.start_date" source="start_date" />
      <DateField label="resources.shops.fields.end_date" source="end_date" />
      <ReferenceArrayField
        addLabel={false}
        reference="productCategories"
        source="categoryIds"
        label="resources.shops.fields.categories"
        allowEmpty
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField label="resources.shops.fields.created_at" source="created_at" />
      <DateField label="resources.shops.fields.updated_at" source="updated_at" />
    </CustomizableDatagrid>
  </List>
);
