import React from "react";
import { Link } from "react-router-dom";
import { TopToolbar, CreateButton, List, TextField, DateField, BooleanField } from "react-admin";
import moment from "moment";
import ListFilters from "./Filters";
import Button from "@material-ui/core/Button";

import CustomizableDatagrid from "../../components/CustomizableDatagrid";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

const ruleStyle = {
  minHeight: "42px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff",
  padding: "0 5px"
};

const PriceRuleTypeField = ({ record }) => {
  return (
    <span>
      {record.rule.coupon_code ? (
        <p style={{ whiteSpace: "nowrap" }}>Promo Code</p>
      ) : record.rule.rule_type === "cart_amount" ? (
        <p style={{ whiteSpace: "nowrap" }}>Cart Amount</p>
      ) : (
        <p style={{ whiteSpace: "nowrap" }}>Searcher</p>
      )}
    </span>
  );
};

const PriceRuleStartDateField = ({ record }) => {
  return (
    <span>
      {record.rule.coupon_code ? (
        <p style={{ lineHeight: 1 }}>{record.rule.start_date + " " + record.rule.start_time}</p>
      ) : (
        <p style={{ lineHeight: 1 }}>{record.rule.start_date}</p>
      )}
    </span>
  );
};

const PriceRuleEndDateField = ({ record }) => {
  return (
    <span>
      {record.rule.coupon_code ? (
        <p style={{ lineHeight: 1 }}>{record.rule.end_date + " " + record.rule.end_time}</p>
      ) : (
        <p style={{ lineHeight: 1 }}>{record.rule.end_date}</p>
      )}
    </span>
  );
};

const PriceRuleStatusField = ({ record }) => {
  if (record.status === "active") {
    return (
      <div style={{ ...ruleStyle, backgroundColor: "green" }}>
        {" "}
        <TranslatableParagraph translationLabel="resources.priceRules.fields.active" />
      </div>
    );
  } else {
    return (
      <div style={{ ...ruleStyle, backgroundColor: "red" }}>
        {" "}
        <TranslatableParagraph translationLabel="resources.priceRules.fields.inactive" />
      </div>
    );
  }
};

const PriceRulesActions = ({
  basePath,
  filters,
  filterValues,
  resource,
  showFilter,
  displayedFilters
}) => (
  <TopToolbar>
    <Link style={{ textDecoration: "none" }} to="/priceRules/weights">
      <Button size="small" variant="outlined" color="primary" style={{ minHeight: 34 }}>
        <TranslatableParagraph
          customStyling={{ margin: "0px" }}
          translationLabel="resources.priceRules.customLabels.reorder_weights"
        />
      </Button>
    </Link>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button"
      })}
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

export const priceRulesList = props => (
  <List
    exporter={false}
    bulkActionButtons={false}
    actions={<PriceRulesActions />}
    filters={<ListFilters />}
    sort={{ field: "rule.end_date", order: "DESC" }}
    {...props}
  >
    <CustomizableDatagrid rowClick="show">
      <TextField source="id" />
      <TextField label="resources.priceRules.fields.rule.name" source="rule.name" />
      <PriceRuleTypeField
        label="resources.priceRules.fields.rule.rule_type"
        source="rule.rule_type"
      />
      <TextField label="resources.priceRules.fields.rule.coupon_code" source="rule.coupon_code" />
      <PriceRuleStartDateField
        label="resources.priceRules.fields.rule.start_date"
        source="rule.start_date"
      />
      <PriceRuleEndDateField
        label="resources.priceRules.fields.rule.end_date"
        source="rule.end_date"
      />
      <DateField source="created_at" label="resources.priceRules.fields.created_at" />
      <DateField source="updated_at" label="resources.priceRules.fields.updated_at" />
      <TextField label="resources.priceRules.fields.rule.dl_level" source="rule.dl_level" />
      <BooleanField
        label="resources.priceRules.fields.rule.promoted_price"
        source="rule.promoted_price"
      />
      <PriceRuleStatusField label="resources.priceRules.fields.status" source="status" />
    </CustomizableDatagrid>
  </List>
);
