import React from "react";
import {
  Show,
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  TabbedShowLayout,
  Tab,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  DateField,
  Datagrid,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  required,
  Toolbar,
  SaveButton
} from "react-admin";
import CustomizableDatagrid from "../../components/CustomizableDatagrid";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";
import { styles } from "../styles";
import { validateNameInput } from "../../utils/validators/index";

export const TagGroupsList = props => {
  return (
    <List
      title={
        <TranslatableParagraph
          customStyling={{ display: "inline-block", margin: 0 }}
          translationLabel="resources.tagGroups.fields.title"
        />
      }
      exporter={false}
      bulkActionButtons={false}
      {...props}
    >
      <CustomizableDatagrid defaultColumns={["name", "productCategoryIds"]} rowClick="show">
        <TextField label="resources.tagGroups.fields.name" source="name" sortable={false} />
        <ReferenceArrayField
          addLabel={false}
          reference="productCategories"
          label="resources.tagGroups.fields.productCategoryIds"
          source="productCategoryIds"
          allowEmpty
        >
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>

        <DateField label="resources.tagGroups.fields.created_at" source="created_at" />
        <DateField label="resources.tagGroups.fields.updated_at" source="updated_at" />
      </CustomizableDatagrid>
    </List>
  );
};
const TagGroupShowTitle = ({ record }) => `Tag Group ${record.name}`;

export const TagGroupShow = props => {
  return (
    <Show title={<TagGroupShowTitle />} {...props}>
      <TabbedShowLayout>
        <Tab label="Basic">
          <TextField source="name" />
          <DateField source="created_at" />
          <DateField source="updated_at" />
        </Tab>
        <Tab label="resources.tagGroups.fields.productCategoryIds">
          <ReferenceArrayField
            addLabel={false}
            reference="productCategories"
            source="productCategoryIds"
            label="resources.tagGroups.fields.productCategoryIds"
            allowEmpty
          >
            <Datagrid rowClick="show">
              <TextField label="resources.tagGroups.fields.name" source="name" />
              <DateField label="resources.tagGroups.fields.created_at" source="created_at" />
              <DateField label="resources.tagGroups.fields.updated_at" source="updated_at" />
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
        <Tab label="resources.tagGroups.fields.tagIds">
          <ReferenceArrayField addLabel={false} reference="tags" source="tagIds" allowEmpty>
            <Datagrid rowClick="show">
              <TextField label="resources.tagGroups.fields.name" source="name" />
              <DateField label="resources.tagGroups.fields.created_at" source="created_at" />
              <DateField label="resources.tagGroups.fields.updated_at" source="updated_at" />
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const TagGroupEditTitle = ({ record }) => `Edit ${record.name}`;

const ConfirmationToolbar = props => (
  <Toolbar {...props} style={styles.toolbar}>
    <SaveButton />
    <DeleteConfirmation undoable={false} />
  </Toolbar>
);

export const TagGroupEdit = props => (
  <Edit title={<TagGroupEditTitle />} undoable={false} {...props}>
    <SimpleForm variant="standard" toolbar={<ConfirmationToolbar />}>
      <TextInput source="name" validate={[required(), validateNameInput]} />
      <ReferenceArrayInput
        source="productCategoryIds"
        reference="productCategories"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={name => {
          if (name) {
            return { name_ilike: name };
          }
        }}
        allowEmpty={true}
      >
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="tagIds"
        reference="tags"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={name => {
          if (name) {
            return { name_ilike: name };
          }
        }}
        allowEmpty={true}
      >
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export const TagGroupCreate = props => (
  <Create
    title={
      <TranslatableParagraph
        customStyling={{ display: "inline-block", margin: 0 }}
        translationLabel="resources.tagGroups.fields.titleCreate"
      />
    }
    {...props}
  >
    <SimpleForm variant="standard">
      <TextInput source="name" validate={[required(), validateNameInput]} />
      <ReferenceArrayInput
        source="productCategoryIds"
        reference="productCategories"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={name => {
          if (name) {
            return { name_ilike: name };
          }
        }}
        allowEmpty={true}
      >
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="tagIds"
        label="resources.tagGroups.fields.tagIds"
        reference="tags"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={name => {
          if (name) {
            return { name_ilike: name };
          }
        }}
        allowEmpty={true}
      >
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);
