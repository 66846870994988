import React, { useEffect, useState } from "react";
import { Filter, TextInput, SelectInput, DateInput, translate } from "react-admin";

import { handleSingleRequest } from "../../services/api/restClient";
import { GET_LIST } from "../../services/api/types";

const ListFilters = ({ translate, ...props }) => {
  const [resourceObjects, setResourceObjects] = useState([]);

  useEffect(() => {
    handleSingleRequest(GET_LIST, "auditObjects", {
      pagination: {
        page: "all"
      },
      sort: {},
      filter: {}
    })
      .then(({ data }) => {
        const resources = data.map(resource => ({ id: resource.name, name: resource.name }));

        setResourceObjects(resources);
      })
      .catch(error => console.log(error));
  }, []);

  return (
    <Filter variant="standard" {...props}>
      <SelectInput
        source="object"
        choices={resourceObjects}
        allowEmpty={false}
        resettable
        alwaysOn
      />
      <SelectInput
        source="action"
        choices={[
          { id: "CREATE", name: "CREATE" },
          { id: "UPDATE", name: "UPDATE" },
          { id: "DELETE", name: "DELETE" }
        ]}
        allowEmpty={false}
        resettable
        alwaysOn
      />
      <TextInput label="User Email" source="user_email_ilike" style={{ width: 200 }} alwaysOn />
      <DateInput
        label="Changes after (including date)"
        style={{ width: 200 }}
        source="changed_gt"
        alwaysOn
      />
      <DateInput
        label="Changes before (excluding date)"
        style={{ width: 200 }}
        source="changed_lt"
        alwaysOn
      />
    </Filter>
  );
};

export default translate(ListFilters);
