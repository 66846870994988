import React, { Component } from "react";
import { FormDataConsumer } from "react-admin";
import ReactPlayer from "react-player";

import PlaceholderImage from "../../assets/images/placeholder.png";

import ButtonToggle from "../ButtonToggle";

import Button from "@material-ui/core/Button";
import ActionDelete from "@material-ui/icons/Delete";
import TranslatableParagraph from "../TranslationComponents/TranslatableParagraph";

const fileUrl = process.env.REACT_APP_IMG_URL;

const styles = {
  video: { position: "absolute", bottom: -10 }
};

const BannerButtonToggle = ({ formData, bannerIndex, saveBanner }) => {
  return (
    <ButtonToggle
      source={"bannersCarousel[" + bannerIndex + "].show"}
      onChange={e => {
        saveBanner(formData.bannersCarousel[bannerIndex]);
      }}
    />
  );
};

export class RegularBanner extends Component {
  state = {
    oldBanner: {}
  };

  renderBanner = (
    { banner_type_class_name, banner_type },
    src,
    index,
    removeField,
    banner_title,
    fields,
    className,
    saveBanner
  ) => (
    <div
      className={className}
      style={{
        backgroundImage: `url(${encodeURI(src)})`,
        backgroundColor: banner_type === "video" ? "white" : "",
        backgroundSize: "cover"
      }}
    >
      <div
        style={{ display: "flex", flex: "1", height: "inherit" }}
        className={`${banner_type_class_name} dark-block`}
      />

      <div className="centered-content">
        <div
          style={{ width: "40%", height: "100%", zIndex: 9999 }}
          className={`${banner_type_class_name} dark-background`}
        >
          <div className={"banner " + banner_type_class_name}>
            <div dangerouslySetInnerHTML={{ __html: banner_title || null }} />
          </div>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "0%"
          }}
        >
          {banner_type === "video" && (
            <ReactPlayer
              className="banner-video"
              style={styles.video}
              url={src}
              width="100%"
              height=""
              controls
            />
          )}
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              display: "flex"
            }}
          >
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <BannerButtonToggle
                  formData={formData}
                  bannerIndex={index}
                  saveBanner={saveBanner}
                />
              )}
            </FormDataConsumer>
            <Button
              style={{
                // borderTopLeftRadius: "12px",
                margin: "5px",
                width: "100%",
                background: "#f44336",
                border: "1px solid #f44336",
                color: "rgba(255,255,255,0.85)"
              }}
              onClick={() => {
                removeField(index, fields);
              }}
            >
              <ActionDelete />
              <TranslatableParagraph translationLabel="ra.action.delete" />
            </Button>
          </div>
        </div>
      </div>
      <div className="transparent-block" style={{ display: "block", height: "100%", flex: "1" }} />
    </div>
  );

  render() {
    const { record, index, removeField, fields, saveBanner } = this.props;
    const srcVideo =
      record && record.videoRaw
        ? record.videoRaw.src && record.videoRaw.src.includes("https://gateway")
          ? record.videoRaw.src
          : record.videoRaw.src && record.videoRaw.src.includes("blob:")
          ? `${record.videoRaw.src}`
          : `${fileUrl}${record.videoRaw.src}`
        : PlaceholderImage;
    const src4K =
      record && record.imageRaw4K
        ? record.imageRaw4K.src && record.imageRaw4K.src.includes("https://gateway")
          ? record.imageRaw4K.src
          : record.imageRaw4K.src && record.imageRaw4K.src.includes("blob:")
          ? `${record.imageRaw4K.src}`
          : `${fileUrl}${record.imageRaw4K.src}`
        : PlaceholderImage;
    const src =
      record && record.imageRawDesktop
        ? record.imageRawDesktop.src && record.imageRawDesktop.src.includes("https://gateway")
          ? record.imageRawDesktop.src
          : record.imageRawDesktop.src && record.imageRawDesktop.src.includes("blob:")
          ? `${record.imageRawDesktop.src}`
          : `${fileUrl}${record.imageRawDesktop.src}`
        : PlaceholderImage;
    const srcMobile =
      record && record.imageRawMobile
        ? record.imageRawMobile.src && record.imageRawMobile.src.includes("https://gateway")
          ? record.imageRawMobile.src
          : record.imageRawMobile.src && record.imageRawMobile.src.includes("blob:")
          ? `${record.imageRawMobile.src}`
          : `${fileUrl}${record.imageRawMobile.src}`
        : PlaceholderImage;

    return (
      <div
        style={{
          width: "100%"
        }}
      >
        {record.banner_type !== "video" &&
          this.renderBanner(
            record,
            src4K,
            index,
            removeField,
            record.banner_title,
            fields,
            "banner-4K",
            saveBanner
          )}
        {record.banner_type !== "video" &&
          this.renderBanner(
            record,
            src,
            index,
            removeField,
            record.banner_title,
            fields,
            "banner-desktop",
            saveBanner
          )}
        {record.banner_type === "video" &&
          this.renderBanner(
            record,
            srcVideo,
            index,
            removeField,
            record.banner_title,
            fields,
            "banner-video",
            saveBanner
          )}
        {this.renderBanner(
          record,
          srcMobile,
          index,
          removeField,
          record.banner_title,
          fields,
          "banner-mobile",
          saveBanner
        )}
      </div>
    );
  }
}

export default RegularBanner;
