import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Reorder from "react-reorder";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveIcon from "@material-ui/icons/Save";

import "./dragged.css";

import { fetchEnd, fetchStart, showNotification, GET_LIST, UPDATE_MANY } from "react-admin";

import restClient from "../../services/api/restClient";
import { styles } from "../styles";
import resources from "../resources";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

class DraggableList extends Component {
  state = {
    dataLoaded: false,
    stickyButtonOn: false,
    saving: false,
    orderedList: []
  };

  componentDidMount = () => {
    const { dispatch } = this.props;
    restClient(GET_LIST, resources.PRICE_RULES, {
      pagination: {
        page: "all",
        pageSize: 10000
      },
      // add sort from back once it has been set up
      sort: { field: "weight", order: "DESC" },
      filter: {}
    })
      .then(res => {
        this.setState({
          orderedList: res.data
            ? res.data
                .sort((a, b) => {
                  return a.rule.weight - b.rule.weight;
                })
                .map((rule, index) => {
                  return {
                    ...rule,
                    weight: index + 1,
                    rule: {
                      ...rule.rule,
                      weight: index + 1
                    }
                  };
                })
            : [],
          dataLoaded: true
        });
      })
      .catch(error => {
        dispatch(showNotification(error.message, "error"));
      })
      .finally(() => {
        dispatch(fetchEnd());
      });
    window.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = () => {
    if (window.scrollY > 36) {
      this.setState({ stickyButtonOn: true });
    } else {
      this.setState({ stickyButtonOn: false });
    }
  };

  updateRules = (event, previousIndex, nextIndex, fromId, toId) => {
    let { orderedList } = this.state;

    // Set desired weight to dropped price rule
    orderedList[previousIndex].rule.weight = orderedList[nextIndex].rule.weight || 0;
    orderedList[previousIndex].weight = orderedList[nextIndex].rule.weight || 0;

    // Check if price rule was pulled up, or down
    // and add/reduce the weights for rules in between
    if (previousIndex - nextIndex > 0) {
      for (let i = nextIndex; i < previousIndex; i++) {
        orderedList[i].rule.weight += 1;
        orderedList[i].weight += 1;
      }
    } else {
      for (let i = nextIndex; i > previousIndex; i--) {
        orderedList[i].rule.weight -= 1;
        orderedList[i].weight -= 1;
      }
    }

    this.setState({ orderedList });
  };

  saveRules = () => {
    const { orderedList } = this.state;
    const { dispatch } = this.props;
    const listIds = orderedList.map(item => item.id);

    this.setState({ saving: true });

    dispatch(fetchStart());

    // We are sending updateOrigin param to be specific about from where the
    // request for update is coming from. This is needed here because of the
    // ending transformer for price rules update which needs this information
    restClient(UPDATE_MANY, resources.PRICE_RULES, {
      ids: listIds,
      data: orderedList,
      updateOrigin: "reorderWeights",
      forceSave: true
    })
      .catch(error => {
        dispatch(showNotification(error.message, "error"));
      })
      .finally(() => {
        this.setState({ saving: false });
        dispatch(fetchEnd());
        dispatch(
          showNotification(
            <TranslatableParagraph translationLabel="resources.priceRules.draggableList.save_popup" />
          )
        );
      });
  };

  render() {
    const { orderedList, dataLoaded, stickyButtonOn, saving } = this.state;
    if (dataLoaded) {
      return (
        <Paper style={styles.priceRulesDraggable.wrapper}>
          <Button
            size={stickyButtonOn ? "medium" : "small"}
            variant="contained"
            color="primary"
            style={
              stickyButtonOn
                ? styles.priceRulesDraggable.stickySaveBtn
                : styles.priceRulesDraggable.saveBtn
            }
            disabled={saving}
            onClick={this.saveRules}
          >
            <SaveIcon
              style={
                stickyButtonOn
                  ? styles.priceRulesDraggable.saveIconSticky
                  : styles.priceRulesDraggable.saveIcon
              }
            />
            {/** Loading indicator */}
            {saving && <CircularProgress style={styles.priceRulesDraggable.btnSavingIndicator} />}
            {!stickyButtonOn && (
              <TranslatableParagraph
                component="span"
                translationLabel="resources.priceRules.draggableList.save_button"
              />
            )}
          </Button>
          <Link to="/priceRules" style={styles.priceRulesDraggable.backButtonLink}>
            <Button size="small" variant="outlined" color="primary">
              <ArrowBackIcon style={{ width: 16 }} />
              <TranslatableParagraph
                component="span"
                translationLabel="resources.priceRules.draggableList.back_to_rules"
              />
            </Button>
          </Link>
          <Reorder
            reorderId="priceRules-list"
            component="ul"
            holdTime={0}
            touchHoldTime={0}
            mouseHoldTime={0}
            onReorder={this.updateRules.bind(this)}
            draggedClassName="dragged"
            listClass="my-list"
            itemClass="list-item"
            selectedKey="id"
            autoScroll={true}
            disabled={false}
            disableContextMenus={true}
            style={{ marginTop: "56px" }}
          >
            {orderedList
              .sort((a, b) => a.rule.weight - b.rule.weight)
              .map(item => (
                <Paper
                  className="draggable-item"
                  style={styles.priceRulesDraggable.paper}
                  key={item.rule.name}
                >
                  <div style={styles.priceRulesDraggable.ruleDescriptionContainer}>
                    <div style={styles.priceRulesDraggable.ruleContainer}>
                      <TranslatableParagraph
                        customStyling={{
                          margin: "0.25rem 0.25rem 0.25rem 0rem"
                        }}
                        translationLabel="resources.priceRules.draggableList.rule_name"
                        customLabel={":"}
                      />
                      {item.rule.name}
                    </div>
                    <div style={styles.priceRulesDraggable.ruleContainer}>
                      <TranslatableParagraph
                        customStyling={{
                          margin: "0.25rem 0.25rem 0.25rem 0rem"
                        }}
                        translationLabel="resources.priceRules.draggableList.rule_price_level"
                        customLabel={":"}
                      />
                      {item.rule.dl_level}
                    </div>
                  </div>
                  <div style={styles.priceRulesDraggable.ruleWeightContainer}>
                    <TranslatableParagraph
                      customStyling={{ margin: "0.25rem 0.25rem 0.25rem 0rem" }}
                      translationLabel="resources.priceRules.draggableList.rule_weight"
                      customLabel={":"}
                    />
                    {item.weight}
                  </div>
                </Paper>
              ))}
          </Reorder>
        </Paper>
      );
    }
    return (
      <Paper style={styles.priceRulesDraggable.wrapper}>
        {/** Loading indicator */}
        <CircularProgress style={styles.priceRulesDraggable.dataLoadingIndicator} />
      </Paper>
    );
  }
}

export default connect()(DraggableList);
