import React, { Component } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
// import TranslatableParagraph from "./TranslationComponents/TranslatableParagraph";

const styles = theme => ({
  removeButton: {},
  removeIcon: {
    color: theme.palette.accent1Color
  },
  aspect: {
    fontSize: "0.8571428571428571rem",
    fontFamily: `Ubuntu Regular,-apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif`,
    color: "#757575"
  }
});

const imgUrl = process.env.REACT_APP_IMG_URL;

export class FileInputPreview extends Component {
  state = {
    aspectRatioWarning: "",
    sizeWarning: ""
  };

  gcd = (a, b) => (b === 0 ? a : this.gcd(b, a % b));

  displayWarings = () => {
    const { file, ratio, imageSize } = this.props;
    const img = new Image();

    img.src = file.rawFile
      ? file.src
      : file.src
      ? file.src.includes("https://gateway")
        ? file.src
        : `${imgUrl}${file.src}`
      : `${imgUrl}/images/${file.file_name}`;

    img.onload = () => {
      const width = img.naturalWidth,
        height = img.naturalHeight;

      const w = width;
      const h = height;
      const r = this.gcd(w, h);

      let state = {};

      const aspectRatio = `${w / r}:${h / r}`;
      if (ratio && ratio !== aspectRatio) {
        state = {
          aspectRatioWarning: `Current aspect ratio: <strong>${w / r}:${
            h / r
          }</strong>. <br /> Recommended aspect ratio: <strong>${ratio}</strong>`
        };
      } else {
        state = {
          aspectRatioWarning: ""
        };
      }

      const size = `${w}:${h}`;
      if (imageSize && !imageSize.includes(size)) {
        state = {
          ...state,
          sizeWarning: `Current image size: <strong>${w}:${h}px</strong>.<br /> Recommended image sizes: <strong>${imageSize.map(
            (image, index) => ` ${image}px`
          )}</strong>.`
        };
      } else {
        state = {
          ...state,
          sizeWarning: ""
        };
      }

      this.setState(state);
    };
  };

  componentDidMount() {
    this.displayWarings();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.displayWarings();
    }
  }

  componentWillUnmount() {
    const { file, revokeObjectURL } = this.props;

    if (file.preview) {
      revokeObjectURL ? revokeObjectURL(file.preview) : window.URL.revokeObjectURL(file.preview);
    }
  }

  render() {
    const {
      children,
      classes = {},
      className,
      onRemove,
      revokeObjectURL,
      file,
      imageSize,
      ...rest
    } = this.props;

    const { aspectRatioWarning, sizeWarning } = this.state;

    return (
      <div className={className} {...rest}>
        <IconButton className={classes.removeButton} onClick={onRemove}>
          <RemoveCircle className={classes.removeIcon} />
        </IconButton>
        {children}
        <div>
          {aspectRatioWarning && (
            <span
              className={classes.aspect}
              dangerouslySetInnerHTML={{ __html: aspectRatioWarning }}
            />
          )}
          {aspectRatioWarning && sizeWarning && <br />}
          {sizeWarning && (
            <span className={classes.aspect} dangerouslySetInnerHTML={{ __html: sizeWarning }} />
          )}
        </div>
      </div>
    );
  }
}

FileInputPreview.propTypes = {
  children: PropTypes.element.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  file: PropTypes.object,
  onRemove: PropTypes.func.isRequired,
  revokeObjectURL: PropTypes.func
};

FileInputPreview.defaultProps = {
  file: undefined
};

export default withStyles(styles)(FileInputPreview);
