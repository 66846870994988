import React, { Fragment, useEffect, useState } from "react";
import { concat, uniq, get, compact } from "lodash";
import {
  ReferenceManyField,
  Datagrid,
  DateField,
  EditButton,
  CloneButton,
  TextField,
  Button,
  translate,
  ReferenceField
} from "react-admin";
import { Button as RegularButton } from "@material-ui/core";

import DeleteButton from "../../../components/DeleteButton";
import { handleSingleRequest } from "../../../services/api/restClient";

import { GET_LIST, UPDATE } from "../../../services/api/types";
import DeleteConfirmation from "../../../components/DeleteConfirmation";

const SortedContentList = ({ savedContentId, ids, children, ...rest }) => {
  const sortedIds = compact(uniq(concat(savedContentId, ids)));

  return children({ ids: sortedIds, ...rest });
};

const PostContent = ({ formData, record, ...rest }) => {
  const savedContentId = get(record, "content_id");
  const [publishedContentIds, setPublishedContentIds] = useState([]);
  const [publishedContent, setPublishedContent] = useState([]);

  useEffect(() => {
    getPublishedContents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishedContentIds.length]);

  const getPublishedContents = async () => {
    try {
      // With ZEM-359 and ZEM-87 flow for page contents were changed and multiple content in different language can be published now.
      // Old flow: PAGE had content_id prop and based on that id content is displayed on web.
      // New flow: PAGE CONTENT now has is_published prop, and based on that is displayed on web.
      // This is the way to migrate from old to new flow.
      if (savedContentId) {
        await handleSingleRequest(UPDATE, "pages", {
          id: record.id,
          data: {
            content_id: null
          }
        });
        await handleSingleRequest(UPDATE, "pageContents", {
          id: savedContentId,
          data: {
            is_published: true
          }
        });
      }
      const { data } = await handleSingleRequest(GET_LIST, "pageContents", {
        pagination: { page: "all" },
        sort: { field: "id", order: "ASC" },
        filter: {
          page_id: record.id,
          is_published: true
        }
      });

      const ids = data.map(item => item.id);
      setPublishedContentIds(ids);
      setPublishedContent(data);
    } catch (error) {
      console.log("[Error get published content]", error);
    }
  };

  const handlePublishContent = async contentId => {
    try {
      const { data } = await handleSingleRequest(UPDATE, "pageContents", {
        id: contentId,
        data: {
          is_published: true
        }
      });

      const publishedContentLangId = data.language_id;
      const isContentLanguagePublished = publishedContent.find(
        item => item.language_id === publishedContentLangId && item.is_published
      );

      if (isContentLanguagePublished) {
        await handleUnpublishContent(isContentLanguagePublished.id);
      }

      setPublishedContentIds(oldIds => [...oldIds, data.id]);
    } catch (error) {
      console.log("[Error publishing page content]", error);
    }
  };

  const handleUnpublishContent = async contentId => {
    try {
      const { data } = await handleSingleRequest(UPDATE, "pageContents", {
        id: contentId,
        data: {
          is_published: false
        }
      });

      const ids = publishedContentIds.filter(id => id !== data.id);
      setPublishedContentIds(ids);
    } catch (error) {
      console.log("[Error unpublishing page content]", error);
    }
  };

  const RenderProps = ({ children, ...rest }) => children(rest);

  if (!record) {
    return null;
  }

  return (
    <ReferenceManyField
      record={record}
      {...rest}
      reference="pageContents"
      target="page_id"
      label=""
    >
      <SortedContentList>
        {({ translate, total, ...restProps }) => (
          <Datagrid {...restProps}>
            <TextField label="resources.pages.edit.postContents.name" source="name" />
            <ReferenceField
              label="Language"
              source="language_id"
              reference="languages"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <DateField label="resources.pages.edit.postContents.created_at" source="created_at" />
            <DateField label="resources.pages.edit.postContents.updated_at" source="updated_at" />
            <RenderProps>
              {rowProps => {
                const isPublished = publishedContentIds.includes(rowProps.record.id);
                return isPublished ? (
                  <Fragment>
                    <CloneButton {...rowProps} />
                    <EditButton {...rowProps} />
                    <Button
                      disabled={true}
                      label="resources.pages.edit.postContents.buttons.published"
                    />
                    <RegularButton
                      color="secondary"
                      disabled={publishedContentIds.length < 2}
                      variant="contained"
                      onClick={() => handleUnpublishContent(rowProps.record.id)}
                    >
                      Unpublish
                    </RegularButton>
                  </Fragment>
                ) : (
                  <Fragment>
                    <CloneButton {...rowProps} />
                    <EditButton {...rowProps} />
                    <DeleteConfirmation
                      undoable={false}
                      redirect={`/pages/${record.id}`}
                      {...rowProps}
                      disabled={(publishedContentIds.length < 2 && isPublished) || total < 2}
                    />
                    <RegularButton
                      color="primary"
                      onClick={() => handlePublishContent(rowProps.record.id)}
                      variant="contained"
                    >
                      Publish
                    </RegularButton>
                  </Fragment>
                );
              }}
            </RenderProps>
          </Datagrid>
        )}
      </SortedContentList>
    </ReferenceManyField>
  );
};

export default translate(PostContent);
