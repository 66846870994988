import React, { Component } from "react";
import { connect } from "react-redux";
import { List, TextField, ReferenceField, DateField, crudGetList } from "react-admin";
import { get, find } from "lodash";
import CustomizableDatagrid from "../../components/CustomizableDatagrid/index";

import DefaultListActions from "../../components/DefaultListActions";
import BulkActionButtons from "./BulkActionButtons";
import OrderFilters from "./Filters";

import OrderStatusReferenceField from "./OrderStatusReferenceField";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";
import EmailAndPhoneField from "./EmailAndPhoneField";

export const EmailStatus = props => {
  const invoiceStatus = props.record.invoice_status;
  var status = (function (invoiceStatus) {
    switch (invoiceStatus) {
      case "N":
        return "Not sent";
      case "Y":
        return "Sent";
      case "C":
        return "Created, not sent";
      case "E":
        return "Error";
      case "F":
        return "Foreign, do not send";
      case "O":
        return "Obsolete sent";
      default:
        return null;
    }
  })(invoiceStatus);
  return status;
};

const styles = {
  orderValidityBox: {
    borderRadius: "10px",
    width: "fit-content",
    minWidth: "90px",
    padding: "0px 5px"
  },
  validOrder: {
    backgroundColor: "rgba(52, 152, 219, 0.12)",
    color: "rgb(52,152,219)"
  },
  invalidOrder: { backgroundColor: "rgba(244, 67, 54, 0.12)", color: "#f44336" },
  processingOrder: { backgroundColor: "rgba(20, 67, 54, 0.12)", color: "rgb(20,120, 40)" }
};

const OrderValidity = props => {
  let orderMissingExternalNumber = props?.record?.external_number;
  let orderStatus = props?.record?.last_update_by;

  if (!orderMissingExternalNumber && orderStatus === "ERP") {
    return (
      <div style={{ ...styles.orderValidityBox, ...styles.invalidOrder }}>
        <TranslatableParagraph
          component="p"
          customStyling={{ textAlign: "center", minWidth: "140px", padding: "5px" }}
          translationLabel="resources.orders.fields.invalid_order"
        />
      </div>
    );
  } else if (!orderMissingExternalNumber && (orderStatus === "PEN" || orderStatus === "API")) {
    return (
      <div style={{ ...styles.orderValidityBox, ...styles.processingOrder }}>
        <TranslatableParagraph
          component="p"
          customStyling={{ textAlign: "center", minWidth: "140px", padding: "5px" }}
          translationLabel="resources.orders.fields.processing_order"
        />
      </div>
    );
  } else {
    return (
      <div style={{ ...styles.orderValidityBox, ...styles.validOrder }}>
        <TranslatableParagraph
          component="p"
          customStyling={{ textAlign: "center", minWidth: "140px", padding: "5px" }}
          translationLabel="resources.orders.fields.valid_order"
        />
      </div>
    );
  }
};

const PaymentWithShippingHandling = props => {
  let orderPayment = props?.record?.payment.amount;
  let orderShippingHandling = props?.record?.shipping_handling;

  return <p>{orderPayment + orderShippingHandling}</p>;
};

class OrdersListNoTranslation extends Component {
  state = {
    gotOrderUnknownId: false
  };

  componentDidMount() {
    this.props.crudGetList("orderStatuses", { page: 1, perPage: 100 }, { field: "id" }, {});
  }

  render() {
    // Grab country iso code
    const countryAppId = localStorage.getItem("appId");
    const countryIsoCode = JSON.parse(localStorage.getItem("countries")).find(
      country => country.application_id === countryAppId
    ).iso_code_2;

    const { unknownOrderStatusId, crudGetList, ...rest } = this.props;
    return unknownOrderStatusId ? (
      <List
        {...rest}
        actions={<DefaultListActions />}
        bulkActionButtons={<BulkActionButtons />}
        filters={<OrderFilters orderUnknownId={unknownOrderStatusId} />}
        sort={{ field: "rid", order: "DESC" }}
        filter={{ order_status_id_nin: unknownOrderStatusId }}
      >
        <CustomizableDatagrid
          defaultColumns={[
            "order_number",
            "customer_id",
            "seller_id",
            "order_status_id",
            "invoice_status",
            "payment.amount",
            "payment.provider_name",
            "coupon_code",
            "paymentMethod.name"
          ]}
          rowClick="show"
        >
          <OrderValidity label="Order Validity" />
          <TextField source="rid" />
          <TextField label="resources.orders.fields.order_number" source="order_number" />
          <TextField label="resources.orders.fields.external_number" source="external_number" />
          <TextField label="resources.orders.fields.referral_number" source="referral_number" />
          <DateField label="resources.orders.fields.created_at" source="created_at" />
          <DateField label="resources.orders.fields.updated_at" source="updated_at" />
          <TextField label="resources.orders.fields.last_update_by" source="last_update_by" />
          <ReferenceField
            source="customer_id"
            reference="users"
            link="show"
            label="resources.orders.fields.customer_id"
          >
            <EmailAndPhoneField />
          </ReferenceField>
          <ReferenceField
            source="seller_id"
            reference="users"
            link="show"
            sortable={false}
            allowEmpty
            label="resources.orders.fields.seller_id"
          >
            <EmailAndPhoneField />
          </ReferenceField>
          <OrderStatusReferenceField
            source="order_status_id"
            reference="orderStatuses"
            label="resources.orders.fields.order_status_id"
            link={false}
          />
          <EmailStatus label="resources.orders.fields.invoice_status" source="invoice_status" />
          <TextField label="resources.orders.fields.shipping_price" source="shipping_price" />
          <TextField label="resources.orders.fields.sales_tax" source="sales_tax" />
          {countryIsoCode === "de" ? (
            <PaymentWithShippingHandling label="Payment with Shipping Handling" />
          ) : (
            <TextField source="payment.amount" label="resources.orders.fields.payment_amount" />
          )}
          <TextField source="currency_code" label="resources.orders.fields.payment_currency" />
          <TextField
            source="paymentMethod.name"
            label="resources.orders.fields.paymentMethod.name"
          />
          <TextField label="Credit Card Provider" source="provider_name" />
          <TextField label="resources.orders.fields.address_line1" source="address_line1" />
          <TextField label="resources.orders.fields.zip_code" source="zip_code" />
          <ReferenceField
            label="resources.orders.fields.city_id"
            source="city_id"
            reference="cities"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        </CustomizableDatagrid>
      </List>
    ) : null;
  }
}

export const OrdersList = connect(
  state => ({
    unknownOrderStatusId: (
      find(get(state, "admin.resources.orderStatuses.data", []), ["name", "Unknown"]) || {}
    ).id
  }),
  {
    crudGetList
  }
)(OrdersListNoTranslation);
