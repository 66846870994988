import React from "react";
import { List, TextField, DateField } from "react-admin";

import CustomizableDatagrid from "../../components/CustomizableDatagrid";

export const NotificationCategoriesList = props => (
  <List exporter={false} bulkActionButtons={false} {...props}>
    <CustomizableDatagrid rowClick="edit" defaultColumns={["name", "created_at", "updated_at"]}>
      <TextField label="resources.notificationCategories.fields.name" source="name" />
      <DateField label="resources.notificationCategories.fields.created_at" source="created_at" />
      <DateField label="resources.notificationCategories.fields.updated_at" source="updated_at" />
    </CustomizableDatagrid>
  </List>
);
