import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  TextField,
  Datagrid,
  ReferenceField,
  ReferenceManyField,
  // FormDataConsumer,
  DateField,
  Edit,
  translate,
  TabbedForm,
  FormTab,
  Pagination,
  Toolbar,
  SaveButton,
  Labeled,
  BooleanInput
} from "react-admin";
import Switch from "@material-ui/core/Switch";
import { feature } from "../../components/Feature";

// eslint-disable-next-line no-unused-vars
import { isEmpty, forIn } from "lodash";
import OrderStatusReferenceInput from "./OrderStatusReferenceInput";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";
// import { EmailStatus } from "./List";

const paymentOptionStyles = {
  fieldStyle: {
    marginTop: "1.5em"
  },
  labelStyle: {
    color: "#0000008a",
    fontSize: "0.75rem",
    fontWeight: 500
  }
};

const OrderShowTitle = ({ record, translate }) =>
  translate("resources.orders.show.title", {
    orderNumber: record.order_number
  });

const TranslatedTitle = translate(OrderShowTitle);

const PaymentOptionFieldBase = ({ record, translate }) => {
  if (!record || isEmpty(record.paymentOption)) {
    return null;
  }

  const { paymentOption } = record;

  var paymentOptionText = (function (type) {
    switch (type) {
      case "cashDetails":
        return (
          <Labeled label="resources.orders.fields.paymentOption">
            <TranslatableParagraph translationLabel="resources.orders.paymentOptions.cashDetails" />
          </Labeled>
        );
      case "bankCreditPayments":
        return (
          <Labeled label="resources.orders.fields.paymentOption">
            <TranslatableParagraph
              textOnly
              translationLabel="resources.orders.paymentOptions.bankCreditPayments"
            />
          </Labeled>
        );
      case "advancePayments":
        return (
          <Labeled label="resources.orders.fields.paymentOption">
            <TranslatableParagraph
              textOnly
              translationLabel="resources.orders.paymentOptions.advancePayments"
            />
          </Labeled>
        );
      case "cashUponDelivery":
        return (
          <Labeled label="resources.orders.fields.paymentOption">
            <TranslatableParagraph translationLabel="resources.orders.paymentOptions.cashUponDelivery" />
          </Labeled>
        );
      case "Cash":
        return (
          <Labeled label="resources.orders.fields.paymentOption">
            <TranslatableParagraph translationLabel="resources.orders.paymentOptions.cash" />
          </Labeled>
        );
      case "CreditCard":
        return (
          <Labeled label="resources.orders.fields.paymentOption">
            <TranslatableParagraph translationLabel="resources.orders.paymentOptions.creditCard" />
          </Labeled>
        );
      default:
        return null;
    }
  })(paymentOption.type);

  const KeyFieldPairs = {
    id: (
      <TextField
        key="id"
        record={record}
        label="resources.orders.fields.id"
        source="paymentOption.id"
      />
    ),
    rid: <TextField key="rid" record={record} source="paymentOption.rid" />,
    payment_type_id: (
      <TextField
        key="payment_type_id"
        record={record}
        label="resources.orders.fields.payment_type_id"
        source="paymentOption.payment_type_id"
      />
    ),
    created_at: (
      <DateField
        key="created_at"
        record={record}
        label="testing"
        source="paymentOption.created_at"
      />
    ),
    updated_at: (
      <DateField
        key="updated_at"
        record={record}
        label="resources.orders.fields.updated_at"
        source="paymentOption.updated_at"
      />
    ),
    delivery_date: (
      <DateField key="delivery_date" record={record} source="paymentOption.delivery_date" />
    ),
    currency_code: (
      <TextField
        key="currency_code"
        record={record}
        label={<TranslatableParagraph translationLabel="resources.orders.fields.currency_code" />}
        source="paymentOption.currency_code"
      />
    ),
    month_duration: (
      <TextField
        key="month_duration"
        record={record}
        label="resources.orders.fields.month_duration"
        source="paymentOption.month_duration"
      />
    ),
    payment_date: (
      <DateField
        key="payment_date"
        record={record}
        label="resources.orders.fields.payment_date"
        source="paymentOption.payment_date"
      />
    ),
    first_instalment: (
      <TextField
        key="first_instalment"
        record={record}
        label="resources.orders.fields.first_instalment"
        source="paymentOption.first_instalment"
      />
    ),
    first_instalment_at: (
      <DateField
        key="first_instalment_at"
        record={record}
        label="resources.orders.fields.first_instalment_at"
        source="paymentOption.first_instalment_at"
      />
    ),
    remaining_instalment: (
      <TextField
        key="remaining_instalment"
        record={record}
        label="resources.orders.fields.remaining_instalment"
        source="paymentOption.remaining_instalment"
      />
    ),
    last_instalment_at: (
      <DateField
        key="last_instalment_at"
        record={record}
        label="resources.orders.fields.last_instalment_at"
        source="paymentOption.last_instalment_at"
      />
    ),
    month_numbers: (
      <TextField
        key="month_numbers"
        record={record}
        label="resources.orders.fields.month_numbers"
        source="paymentOption.month_numbers"
      />
    ),
    payment_day_in_month_of_installments: (
      <TextField
        key="payment_day_in_month_of_installments"
        record={record}
        label="resources.orders.fields.payment_day_in_month_of_instalments"
        source="paymentOption.payment_day_in_month_of_installments"
      />
    )
  };

  const renderFields = props => {
    let fields = [];
    let needsToBeAdded = "needsToBeAdded";
    forIn(record.paymentOption, (value, key) => {
      if (KeyFieldPairs[key]) {
        fields.push(
          <div key={key} style={paymentOptionStyles.fieldStyle}>
            <span style={paymentOptionStyles.labelStyle}>
              {
                <TranslatableParagraph
                  translationLabel={`resources.orders.fields.${key ? key : needsToBeAdded}`}
                />
              }{" "}
              {KeyFieldPairs[key]}
            </span>
          </div>
        );
      }
    });

    return fields;
  };

  return (
    <Fragment>
      <span>{paymentOptionText}</span>
      {renderFields()}
    </Fragment>
  );
};

// const PaymentOptionField = translate(PaymentOptionFieldBase);

// TODO [start] Fields needed for Edit view when data such as paymentOption is not available (@backend lacking in data - communicate with backend team in the future)
const PaymentAmountNumber = props => {
  const { record } = props;
  if (record && record.payment && record.payment.amount && !record.paymentOption) {
    return (
      <Labeled label="resources.orders.fields.payment_amount">
        <TextField {...props} />
      </Labeled>
    );
  } else {
    return null;
  }
};

const SalesTaxNumber = props => {
  const { record } = props;
  if (record && !record.paymentOption) {
    return (
      <Labeled label="resources.orders.fields.sales_tax">
        <TextField {...props} />
      </Labeled>
    );
  } else {
    return null;
  }
};

const ShippingTaxNumber = props => {
  const { record } = props;
  if (record && !record.paymentOption) {
    return (
      <Labeled label="resources.orders.fields.shipping_price">
        <TextField {...props} />
      </Labeled>
    );
  } else {
    return null;
  }
};

const PaymentMethodName = props => {
  const { record } = props;
  if (record && record.paymentMethod && record.paymentMethod.name && !record.paymentOption) {
    return (
      <Labeled label="resources.orders.fields.paymentMethod.name">
        <TextField {...props} />
      </Labeled>
    );
  } else {
    return null;
  }
};

const PaymentProviderName = props => {
  const { record } = props;
  if (record && record.payment && record.payment.provider_name) {
    return (
      <Labeled label="Credit Card Provider">
        <TextField style={{ textTransform: "uppercase" }} {...props} />
      </Labeled>
    );
  } else {
    return null;
  }
};

export const PaymentAmountWithHandling = props => {
  const { record } = props;
  if (record && record.shipping_handling && record.payment && record.payment.amount) {
    let combinedPrice = record.payment.amount + record.shipping_handling;
    return (
      <div style={{ margin: "8px 0px" }}>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.54)",
            margin: "8px 0px",
            fontWeight: "normal"
          }}
        >
          <TranslatableParagraph
            translationLabel="resources.orders.fields.payment_amount_with_shipping_handling"
            textOnly
          />
        </span>
        <span
          style={{
            fontSize: 12,
            color: "rgba(0,0,0,0.86)"
          }}
        >
          {combinedPrice}
        </span>
      </div>
    );
  } else {
    return null;
  }
};

// TODO [end]

const PostEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const DummySlider = () => <Switch color="primary" />;

const DispatchToErp = ({ currentValue }) => {
  return <BooleanInput label="Dispatch to erp" source="dispatch_to_erp" disabled={currentValue} />;
};

const OrderEditNoTranslation = props => {
  const { translate, ...rest } = props;

  // Grab country iso code
  const countryAppId = localStorage.getItem("appId");
  const countryIsoCode = JSON.parse(localStorage.getItem("countries")).find(
    country => country.application_id === countryAppId
  ).iso_code_2;

  return (
    <Edit title={<TranslatedTitle />} {...rest} undoable={false}>
      <TabbedForm toolbar={<PostEditToolbar />}>
        <FormTab label="resources.orders.tabs.order_details">
          <TextField label="resources.orders.fields.order_number" source="order_number" />
          <OrderStatusReferenceInput
            source="order_status_id"
            reference="orderStatuses"
            label="resources.orders.fields.order_status_id"
          />
          <ReferenceField
            source="customer_id"
            reference="users"
            link="show"
            label="resources.orders.fields.customer_id"
          >
            <TextField source="email" />
          </ReferenceField>
          <ReferenceField
            source="seller_id"
            reference="users"
            link="show"
            sortable={false}
            allowEmpty
            label="resources.orders.fields.seller_id"
          >
            <TextField source="email" />
          </ReferenceField>
          {countryIsoCode === "de" ? (
            <TextField
              label="resources.orders.fields.shipping_handling"
              source="shipping_handling"
            />
          ) : null}
          <TextField label="resources.orders.fields.shipping_price" source="shipping_price" />
          <TextField label="resources.orders.fields.sales_tax" source="sales_tax" />
          {/* Combined price with Shipping handling */}
          {countryIsoCode === "de" ? <PaymentAmountWithHandling /> : null}
          <TextField label="resources.orders.fields.payment_amount" source="payment.amount" />
          <TextField
            label="resources.orders.fields.paymentMethod.name"
            source="paymentMethod.name"
          />
          <PaymentProviderName source="payment.provider_name" />
          <ReferenceManyField
            label="resources.orders.fields.paymentInfo"
            reference="payments"
            target="order_id"
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="rid" sortable={false} />
              <ReferenceField
                label="resources.orders.fields.paymentMethod.name"
                source="payment_method_id"
                reference="paymentMethods"
                link={false}
                sortable={false}
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField
                source="provider_name"
                style={{ textTransform: "uppercase" }}
                label="Credit Card Provider"
              />

              <ReferenceField
                source="status_id"
                reference="paymentStatuses"
                link={false}
                sortable={false}
                label="resources.orders.fields.payment_status"
              >
                <TextField source="name" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
          <TextField label="resources.orders.fields.address_line1" source="address_line1" />
          <TextField label="resources.orders.fields.zip_code" source="zip_code" />
          <ReferenceField
            label="resources.orders.fields.city_id"
            source="city_id"
            reference="cities"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <DispatchToErp currentValue={props?.orders?.dispatch_to_erp} />
        </FormTab>
        <FormTab label="resources.orders.tabs.payment_details">
          <PaymentAmountNumber source="payment.amount" />
          <SalesTaxNumber source="sales_tax" />
          <ShippingTaxNumber source="shipping_price" />
          <PaymentMethodName source="paymentMethod.name" />
          <PaymentProviderName source="payment.provider_name" />
          <PaymentOptionFieldBase />
          <ReferenceManyField
            label="resources.orders.fields.shippingAddress"
            reference="shipments"
            target="order_id"
          >
            <Datagrid>
              <TextField
                label="resources.orders.fields.address_line1"
                source="address_line1"
                sortable={false}
              />
              <TextField
                label="resources.orders.fields.zip_code"
                source="zip_code"
                sortable={false}
              />
              <ReferenceField
                reference="cities"
                source="city_id"
                link={false}
                sortable={false}
                label="resources.orders.fields.city_id"
              >
                <TextField source="name" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="resources.orders.tabs.item_details">
          <ReferenceManyField
            addLabel={false}
            reference="orderItems"
            target="order_id"
            pagination={<Pagination />}
          >
            <Datagrid>
              <DummySlider label="resources.orders.fields.order_prepared" />
              <ReferenceField
                source="product_id"
                reference="products"
                label="resources.orders.fields.external_number"
                link="show"
                sortable={false}
              >
                <TextField source="external_number" />
              </ReferenceField>
              <ReferenceField
                source="product_id"
                reference="products"
                label="resources.orders.fields.product_name"
                link="show"
                sortable={false}
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField
                label="resources.orders.fields.quantity"
                source="quantity"
                sortable={false}
              />
              <TextField label="resources.orders.fields.price" source="price" sortable={false} />
              <TextField
                label="resources.orders.fields.currency"
                source="currency_code"
                sortable={false}
              />
              {feature("priceRulesDiscountCodeParameter") ? (
                <TextField
                  label="resources.orders.fields.discount_code"
                  source="coupon_code"
                  sortable={false}
                />
              ) : null}
              <ReferenceField
                source="price_level_id"
                reference="priceLevels"
                label="resources.orders.fields.price_levels"
                link={false}
                sortable={false}
              >
                <TextField source="name" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const mapStateToProps = (state, props) => ({
  orders: state?.admin?.resources?.orders?.data?.[props?.id]
});

export const OrderEdit = translate(connect(mapStateToProps, null)(OrderEditNoTranslation));
