import { find, get, reduce } from "lodash";
import { handleSingleRequest } from "../restClient";

export const transformUserList = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params,
    include: ["sellingPriceLevels", "userAddresses.address.stateOrProvince", "userRoles.role"]
  }).then(({ data = [], included = [], ...rest }) => {
    data.map(record => {
      const roleId = get(find(included, { type: "userRoles", user_id: record.id }), "role_id");

      record["userRole"] = get(find(included, { type: "roles", id: roleId }), "name");

      const userAddressesIds = reduce(
        included,
        (userAddressesIds, value) => {
          if (value["address_id"] && value["user_id"] === record.id) {
            userAddressesIds.push(value["address_id"]);
          }
          return userAddressesIds;
        },
        []
      );

      let defaultAddressId = "";

      record["defaultAddress"] = reduce(
        included,
        (defaultAddress, address) => {
          userAddressesIds.forEach(id => {
            if (address.id === id && address.is_default === true) {
              defaultAddress = address;
              defaultAddressId = id;
            }
          });
          return defaultAddress;
        },
        {}
      );

      const addressLine1 = get(
        find(included, { type: "addresses", id: defaultAddressId }),
        "address_line1"
      );
      const addressLine2 = get(
        find(included, { type: "addresses", id: defaultAddressId }),
        "address_line2"
      );

      record["userAddressLines"] = addressLine2 ? `${addressLine1} ${addressLine2}` : addressLine1;

      const stateOrProvinceId = get(
        find(included, ["id", defaultAddressId]),
        "state_or_province_id"
      );

      record["stateOrProvince"] = get(find(included, ["id", stateOrProvinceId]), "name");

      record["sellingPriceLevels"] = reduce(
        included,
        (sellingPriceLevelIds, sellingPriceLevel) => {
          if (sellingPriceLevel.user_id === record.id && sellingPriceLevel.price_level_id) {
            sellingPriceLevelIds.push(sellingPriceLevel.price_level_id);
          }
          return sellingPriceLevelIds;
        },
        []
      );

      return record;
    });
    return { data, ...rest };
  });
};

export const transformUserItem = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params,
    include: [
      "userAddresses.address.stateOrProvince",
      "affiliates",
      "priceLevels",
      "sellingPriceLevels"
    ]
  }).then(({ data = {}, included = [], ...rest }) => {
    const userAddressesIds = reduce(
      included,
      (userAddressesIds, value) => {
        if (value["address_id"]) {
          userAddressesIds.push(value["address_id"]);
        }
        return userAddressesIds;
      },
      []
    );

    let defaultAddressId = "";

    data["defaultAddress"] = reduce(
      included,
      (defaultAddress, record) => {
        userAddressesIds.forEach(id => {
          if (record.id === id && record.is_default === true) {
            defaultAddress = record;
            defaultAddressId = id;
          }
        });
        return defaultAddress;
      },
      {}
    );

    const addressLine1 = get(find(included, ["id", defaultAddressId]), "address_line1");

    const addressLine2 = get(find(included, ["id", defaultAddressId]), "address_line2");

    data["userAddressLines"] = addressLine2 ? `${addressLine1} ${addressLine2}` : addressLine1;

    const stateOrProvinceId = get(find(included, ["id", defaultAddressId]), "state_or_province_id");

    data["stateOrProvince"] = get(find(included, ["id", stateOrProvinceId]), "name");

    if (find(included, "affilate_path")) {
      // yes, it's 'affilate_path', not 'affiliate_path' according to the DB
      data["affiliateData"] = find(included, ["id", data.id]);
    }

    data["priceLevel"] = find(included, ["id", data.price_level_id]);

    data["sellingPriceLevels"] = reduce(
      included,
      (sellingPriceLevelIds, record) => {
        if (record.user_id === data.id && record.price_level_id) {
          sellingPriceLevelIds.push(record.price_level_id);
        }
        return sellingPriceLevelIds;
      },
      []
    );

    // data["last_update_by"] = 'API';

    return { data, ...rest };
  });
};

export const transformUserUpdate = (type, resource, params) => {
  params.data.last_update_by = "API";
  return handleSingleRequest(type, resource, {
    ...params
  })
    .then(({ data = [], included = [], ...rest }) => {
      return { data, ...rest };
    })
    .catch(error => {
      throw new Error(
        "User with said phone or email already exists, please contact IT support for further assistance.",
        error
      );
    });
};
