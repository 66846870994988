/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  NumberInput,
  List,
  required,
  Toolbar,
  SaveButton
} from "react-admin";

import CustomizableDatagrid from "../../components/CustomizableDatagrid";

import classes from "./styles.module.css";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { styles } from "../styles";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

const Title = ({ record }) => (
  <TranslatableParagraph
    customStyling={{ display: "inline-block", margin: 0 }}
    translationLabel="resources.paymentRules.customLabels.editTitle"
    customLabel={record.id}
  />
);

const ConfirmationToolbar = props => (
  <Toolbar {...props} style={styles.toolbar}>
    <SaveButton />
    <DeleteConfirmation undoable={false} />
  </Toolbar>
);

class PaymentRulesEdit extends Component {
  componentWillUnmount = () => {
    window.location.reload();
  };

  validateInputs = values => {
    const errors = {};
    // Cart amount
    if (values.order_amount_min < 0) {
      errors.order_amount_min = [
        <TranslatableParagraph
          customStyling={{ margin: "0px" }}
          translationLabel="resources.paymentRules.customLabels.negative_input"
        />
      ];
    }
    if (values.order_amount_max < 0) {
      errors.order_amount_max = [
        <TranslatableParagraph
          customStyling={{ margin: "0px" }}
          translationLabel="resources.paymentRules.customLabels.negative_input"
        />
      ];
    }

    // go through all values and skip current one we are editing
    if (this.props.data) {
      Object.keys(this.props.data).forEach(element => {
        // eslint-disable-next-line eqeqeq
        if (this.props.id != element) {
          let minValue = this.props.data[element].order_amount_min;
          let maxValue = this.props.data[element].order_amount_max;
          if (
            (values.order_amount_min >= minValue && values.order_amount_min <= maxValue) ||
            (minValue >= values.order_amount_min && minValue <= values.order_amount_max)
          ) {
            errors.order_amount_min = [
              <TranslatableParagraph
                customStyling={{ margin: "0px" }}
                translationLabel="resources.paymentRules.customLabels.rule_overlap"
              />
            ];
          }
          if (
            (values.order_amount_max >= minValue && values.order_amount_max <= maxValue) ||
            (maxValue >= values.order_amount_min && maxValue <= values.order_amount_max)
          ) {
            errors.order_amount_max = [
              <TranslatableParagraph
                customStyling={{ margin: "0px" }}
                translationLabel="resources.paymentRules.customLabels.rule_overlap"
              />
            ];
          }
        }
      });
    }

    // installments
    if (values.max_instalments < 0) {
      errors.max_instalments = [
        <TranslatableParagraph
          customStyling={{ margin: "0px" }}
          translationLabel="resources.paymentRules.customLabels.negative_input"
        />
      ];
    }
    return errors;
  };

  render() {
    const { dispatch, ...rest } = this.props;

    return (
      <div className={classes.PageWrapper}>
        <div className={classes.ColumnLeft}>
          <Edit title={<Title />} undoable={false} {...rest}>
            <SimpleForm
              variant="standard"
              toolbar={<ConfirmationToolbar />}
              validate={this.validateInputs}
            >
              <TextInput
                label="resources.paymentRules.fields.name"
                source="name"
                validate={required()}
              />
              <NumberInput
                label="resources.paymentRules.fields.order_amount_min"
                source="order_amount_min"
                validate={required()}
              />
              <NumberInput
                label="resources.paymentRules.fields.order_amount_max"
                source="order_amount_max"
                validate={required()}
              />
              <NumberInput
                label="resources.paymentRules.fields.max_installments"
                source="max_instalments"
                validate={required()}
              />
            </SimpleForm>
          </Edit>
        </div>
        <div className={classes.ColumnRight}>
          <TranslatableParagraph
            customStyling={{ fontSize: "25px", fontWeight: "bold" }}
            translationLabel="resources.paymentRules.customLabels.other_rules"
          />
          <List exporter={false} bulkActionButtons={false} {...rest} actions={null}>
            <CustomizableDatagrid>
              <TextField label="resources.paymentRules.fields.name" source="name" />
              <TextField
                label="resources.paymentRules.fields.order_amount_min"
                source="order_amount_min"
              />
              <TextField
                label="resources.paymentRules.fields.order_amount_max"
                source="order_amount_max"
              />
              <TextField
                label="resources.paymentRules.fields.max_installments"
                source="max_instalments"
              />
            </CustomizableDatagrid>
          </List>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.admin.resources.maxInstalments.data
  };
}

export default connect(mapStateToProps, null)(PaymentRulesEdit);
