import React from "react";
import { Filter, TextInput } from "react-admin";

const BlogFilters = ({ translate, ...rest }) => (
  <Filter variant="standard" {...rest}>
    <TextInput label={"resources.blogs.filters.title"} source="title_ilike" alwaysOn />
  </Filter>
);

export default BlogFilters;
