export const styles = {
  toolbar: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  filedsWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "stretch",
    flexDirection: "column",
    maxWidth: "260px"
  },
  rolesContainer: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `flex-start`,
    backgroundColor: `#ebedee`,
    boxShadow: "0px 0px 10px #ebedee",
    padding: "10px",
    margin: `15px 0px`,
    borderRadius: `4px`,
    maxWidth: `250px`
  },
  rolesMessage: {
    color: `#171717`,
    padding: `0 10px`,
    textAlign: `left`,
    fontSize: `13px`
  },
  textCounter: {
    color: "darkgray",
    fontSize: "12px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    margin: "0px"
  },
  warningContainer: {
    boxShadow: "rgb(235 237 238) 0px 0px 10px",
    fontWeight: "bold",
    lineHeight: "24px",
    fontSize: "12px",
    backgroundImage: "linear-gradient(45deg, rgb(235, 237, 238) 85%, rgb(255, 102, 102) 15%)",
    padding: "10px",
    borderRadius: "5px",
    width: "100%",
    minWidth: "256px",
    position: "relative",
    display: "flex"
  },
  warningText: {
    flex: "0 1 90%"
  },
  warningIcon: {
    width: "20px",
    position: "absolute",
    right: "5px",
    top: "5px",
    color: "white"
  },
  heading: {
    position: "absolute",
    top: "50%",
    left: "30%",
    width: "100%",
    height: "200px"
  },
  productsCategories: {
    wrapper: {
      borderLeft: "6px solid #e0e0e0",
      padding: "12px",
      margin: "12px 0",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      width: "240px"
    },
    label: {
      position: "absolute",
      fontSize: "12px",
      width: "260px",
      top: 0
    },
    inputField: {
      marginTop: "28px",
      width: "100%"
    },
    quantityOperator: {
      display: "flex",
      flexDirection: "column",
      width: "256px"
    }
  },
  priceRulesDraggable: {
    wrapper: {
      paddingRight: "40px",
      position: "relative"
    },
    title: {
      color: "rgba(0, 0, 0, 0.54)"
    },
    ruleContainer: {
      margin: 8,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    ruleDescriptionContainer: {
      flex: 1,
      margin: 8
    },
    ruleWeightContainer: {
      fontSize: 16,
      margin: 16,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    paper: {
      display: "flex",
      flexDirection: "horizontal",
      alignItems: "center",
      fontSize: 14,
      padding: 8,
      margin: 8
    },
    backButtonLink: {
      textDecoration: "none",
      position: "absolute",
      right: 0
    },
    saveBtn: {
      marginLeft: 40,
      marginTop: 40
    },
    stickySaveBtn: {
      position: "fixed",
      marginLeft: 40
    },
    btnSavingIndicator: {
      width: "20px",
      height: "20px",
      position: "absolute"
    },
    dataLoadingIndicator: {
      position: "absolute",
      margin: "16px auto",
      left: "50%",
      right: "50%"
    },
    saveIconSticky: {
      width: 32,
      marginRight: 0,
      transition: "all .5s"
    },
    saveIcon: {
      width: 16,
      marginRight: 8,
      transition: "all .3s"
    }
  }
};
