import React from "react";
import { Link } from "react-router-dom";
import AddCircle from "@material-ui/icons/AddCircle";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
    padding: "6px 10px"
  }
};

const AddImageButton = ({ classes, record }) => (
  <Button
    className={classes.button}
    variant="contained"
    component={Link}
    to={{
      pathname: "/vProductImages/create",
      search: `?source=${JSON.stringify({ product_id: record.id })}`
    }}
    label="ra.custom_files.imageInput.action_button"
  >
    <AddCircle />
  </Button>
);

export default withStyles(styles)(AddImageButton);
