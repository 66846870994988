class Dialog {
  constructor(dialog, restClient) {
    this._dialog = dialog;
    this._restClient = restClient;
    this.imageFile = null;

    this._dialog.addEventListener("imageuploader.fileready", this._onFileReady);
    this._dialog.addEventListener("imageuploader.cancelupload", this._onCancelUpload);
    this._dialog.addEventListener("imageuploader.clear", this._onClear);
    this._dialog.addEventListener("imageuploader.mount", this._onMount);
    this._dialog.addEventListener("imageuploader.rotatecw", this._onRotateCv);
    this._dialog.addEventListener("imageuploader.rotateccw", this._onRotateCcv);
    this._dialog.addEventListener("imageuploader.save", this._onSave);
    this._dialog.addEventListener("imageuploader.unmount", this._onUmmount);
  }

  _onFileReady = ev => {
    console.log("_onFileReady");
    var file = ev.detail().file;
    var reader = new FileReader();

    var img = new Image();

    img.src = window.URL.createObjectURL(file);

    img.onload = () => {
      var width = img.naturalWidth,
        height = img.naturalHeight;

      console.log("SSS width", width);
      console.log("SSS height", height);
    };

    reader.onload = event => {
      this.imagePath = event.target.result;
      this._dialog.populate(event.target.result);
    };

    this.imageFile = file;
    reader.readAsDataURL(file);
  };

  _onCancelUpload = () => {
    console.log("_onCancelUpload");
  };

  _onClear = () => {
    console.log("_onClear");
    this.imageFile = null;
    this._dialog.clear();
  };

  _onMount = () => {
    console.log("_onMount");
  };

  _onRotateCv = () => {
    console.log("_onRotateCv");
  };

  _onRotateCcv = () => {
    console.log("_onRotateCcv");
  };

  _onSave = ev => {
    console.log("_onSave");

    this._dialog.busy(true);

    this._restClient("CREATE", "contentImages", {
      data: this.imageFile
    })
      .then(json =>
        this._dialog.save(json.fullUrl, [], {
          alt: "Image"
        })
      )
      .catch(error => {
        new window.ContentTools.FlashUI("no");
        this._dialog.busy(false);
        this._dialog.clear();
      });

    return;
  };

  _onUmmount = () => {
    console.log("_onUmmount");
  };
}

export default Dialog;
