/* 
  TODO: Add all resources here
  so we have them all exported from one place
*/
const resources = {
  PAYMENT_RULES: "maxInstalments",
  PRICE_RULES: "priceRules",
  USERS: "users",
  LANUGAGES: "languages"
};

export default resources;
