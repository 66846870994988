import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { addField } from "ra-core";

import ActionCancel from "@material-ui/icons/Cancel";

// import sanitizeRestProps from "./sanitizeRestProps";

export class ButtonToggle extends Component {
  handleChange = (_, value) => {
    const { onChange, input } = this.props;
    onChange && onChange();
    input.onChange(value);
  };

  render() {
    const {
      className,
      id,
      input,
      isRequired,
      label,
      source,
      resource,
      options,
      fullWidth,
      cancel,
      ...rest
    } = this.props;

    const { value } = input;

    return (
      <FormGroup className={className} {...rest}>
        <FormControlLabel
          htmlFor={id}
          style={{ margin: "0px", display: "flex", justifyContent: "flex-end" }}
          control={
            // <Switch
            //     id={id}
            //     color="primary"
            //     checked={!!value}
            //     onChange={this.handleChange}
            //     {...inputProps}
            //     {...options}
            // />

            <div onClick={() => this.handleChange(undefined, !value)} style={{ zIndex: 999 }}>
              <ActionCancel style={{ width: "40px", height: "40px" }} />
            </div>
          }
        />
      </FormGroup>
    );
  }
}

ButtonToggle.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.object
};

ButtonToggle.defaultProps = {
  options: {}
};

export default addField(ButtonToggle);
