import { find, concat, compact, uniq, get } from "lodash";
import { handleSingleRequest } from "../restClient";

export const transformOrderList = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params,
    filter: { ...params.filter },
    include: uniq(
      compact(concat(params.include, ["payments", "payments.paymentMethod", "paymentResponses"]))
    )
  }).then(async ({ data = [], included = [], ...rest }) => {
    data.map(record => {
      record["payment"] = find(included, {
        type: "payments",
        order_id: record.id
      });

      let paymentResponse = find(included, {
        type: "paymentResponses",
        order_id: record.id
      });

      if (paymentResponse && paymentResponse.provider_name) {
        record.provider_name = paymentResponse.provider_name.toUpperCase();
      }

      if (record.payment && record.payment.payment_method_id) {
        record["paymentMethod"] = find(included, {
          type: "paymentMethods",
          id: record.payment.payment_method_id
        });
      }

      if (find(included, ["type", "cities"])) {
        record["city"] = find(included, {
          type: "cities",
          id: record.city_id
        });
      }

      if (find(included, ["type", "users"])) {
        record["customer"] = find(included, {
          type: "users",
          id: record.customer_id
        });

        record["seller"] = find(included, {
          type: "users",
          id: record.seller_id
        });
      }

      return record;
    });

    return { data, ...rest };
  });
};

export const transformOrderItem = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params,
    include: uniq(
      compact(
        concat(params.include, [
          "payments",
          "payments.paymentMethod",
          "payments.bankCreditPayments.paymentType",
          "payments.advancePayments.paymentType",
          "payments.cashDetails.paymentType",
          "payments.cashUponDelivery.paymentType",
          "customer.userAddresses.address",
          "paymentResponses"
        ])
      )
    )
  }).then(({ data = {}, included = [], ...rest }) => {
    data["payment"] = find(included, ["order_id", data.id]);

    let paymentResponse = find(included, {
      type: "paymentResponses",
      order_id: data.id
    });

    if (paymentResponse && paymentResponse.provider_name) {
      data.provider_name = paymentResponse.provider_name.toUpperCase();
    }

    const paymentMethodId = get(
      find(included, { type: "payments", order_id: data.id }),
      "payment_method_id"
    );

    data["paymentMethod"] = find(included, {
      type: "paymentMethods",
      id: paymentMethodId
    });

    const paymentOptions = [
      "cashDetails",
      "bankCreditPayments",
      "advancePayments",
      "cashUponDelivery"
    ];

    data["paymentOption"] = find(included, includedValue =>
      paymentOptions.includes(includedValue.type)
    );

    // if (data.paymentMethod.type == "paymentMethods" && !data.advancePayments && data.paymentMethod.name === "Cash" && data.paymentMethod.description == "Cash payment") {
    //   data["paymentOption"] = {
    //     type: data.paymentMethod.name
    //     }
    // }

    // if (data.paymentMethod.type == "paymentMethods" && data.paymentMethod.name == "CreditCard") {
    //   data["paymentOption"] = {
    //     type: data.paymentMethod.name
    //     }
    // }

    const customerAddressId = get(
      find(included, { type: "userAddresses", user_id: data.customer_id }),
      "address_id"
    );

    data["customerAddress"] = find(included, {
      type: "addresses",
      id: customerAddressId
    });

    return { data, ...rest };
  });
};
