import React, { Component, Fragment } from "react";
import { ArrayInput, TextInput, FormDataConsumer } from "react-admin";
import { useForm } from "react-final-form";

import Grid from "@material-ui/core/Grid";

import Dialog from "@material-ui/core/Dialog";

import RichTextInput from "ra-input-rich-text";
import CustomFormIterator from "./CustomFormIterator";

import ButtonToggle from "./ButtonToggle";

import CustomImageField from "./CustomImageField";
import RoundButtonToggle from "./RoundButtonToggle";
import ImageInput from "./ImageInput";
import TranslatableParagraph from "./TranslationComponents/TranslatableParagraph";

const imgUrl = process.env.REACT_APP_IMG_URL;

const SaveImageButtonToggle = ({ formData, imageIndex, saveImage }) => {
  return (
    <ButtonToggle
      source={"images[" + imageIndex + "].show"}
      onChange={e => {
        saveImage(formData.images[imageIndex]);
      }}
    />
  );
};

class ImagesPreview extends Component {
  render() {
    const { images, saveImage } = this.props;

    return (
      <div className="slider-container">
        <Grid container spacing={3}>
          {images &&
            images.map((image, index) => {
              const src =
                image && image.imageRawDesktop
                  ? image.imageRawDesktop.src &&
                    image.imageRawDesktop.src.includes("https://gateway")
                    ? image.imageRawDesktop.src
                    : image.imageRawDesktop.src && image.imageRawDesktop.src.includes("blob:")
                    ? `${image.imageRawDesktop.src}`
                    : `${imgUrl}${image.imageRawDesktop.src}`
                  : undefined;
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={index > 1 ? (index > 4 ? 12 : 4) : 6}
                >
                  <div
                    style={{
                      background: `url(${src}) center / cover no-repeat`,
                      display: "inline-block",
                      width: "100%",
                      border:
                        image.imageRawDesktop && image.imageRawDesktop.src
                          ? "none"
                          : "1px solid rgba(0,0,0,0.3)",
                      // height: index > 1 ? (index > 4 ? "40vw" : "20vw") : "25vw",
                      verticalAlign: "middle",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      backgroundPosition: "center"
                    }}
                    className={"image-div image-div-" + index}
                  >
                    <div
                      className="image-div-title"
                      dangerouslySetInnerHTML={{
                        __html: image.imageTitle
                      }}
                    />
                    <div
                      className="image-div-title"
                      dangerouslySetInnerHTML={{
                        __html: image.imageSubtitle
                      }}
                    />
                  </div>
                  <div style={{ position: "relative", width: "100%" }}>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        display: "flex"
                      }}
                    >
                      <FormDataConsumer>
                        {({ formData, ...rest }) => (
                          <SaveImageButtonToggle
                            formData={formData}
                            imageIndex={index}
                            saveImage={saveImage}
                          />
                        )}
                      </FormDataConsumer>
                    </div>
                  </div>
                </Grid>
              );
            })}
        </Grid>
      </div>
    );
  }
}

const ImageRoundButtonToggle = ({ imageIndex, bannerInputState }) => {
  const form = useForm();

  const revertImage = index => {
    const { savedImage = {} } = bannerInputState;

    form.change("images[" + index + "].imageTitle", savedImage.imageTitle);
    form.change("images[" + index + "].imageSubtitle", savedImage.imageSubtitle);
    form.change("images[" + index + "].imageLink", savedImage.imageLink);
    form.change("images[" + index + "].imageRawMobile", savedImage.imageRawMobile);
    form.change("images[" + index + "].imageRawDesktop", savedImage.imageRawDesktop);
    form.change("images[" + index + "].show", false);
  };

  return (
    <RoundButtonToggle
      source={"images[" + imageIndex + "].cancel"}
      onChange={e => {
        revertImage(imageIndex);
      }}
    />
  );
};

export default class ImagesInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextId: 0,
      ids: [],
      fields: {},
      savedImage: {}
    };
  }

  setFields = fields => {
    this.setState({
      fields: fields,
      nextId: fields ? fields.length : 0,
      ids: fields && fields.length ? fields.map((record, index) => index) : []
    });
  };

  saveImage = image => {
    this.setState({ savedImage: image });
  };

  render() {
    return (
      <Fragment>
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <div style={{ maxWidth: "100%" }}>
              <ImagesPreview images={formData && formData.images} saveImage={this.saveImage} />
            </div>
          )}
        </FormDataConsumer>
        <ArrayInput source="images" label=" ">
          <CustomFormIterator setFields={this.setFields} ids={this.state.ids}>
            {arrayfieldProps => {
              const { fields, index } = arrayfieldProps;
              const image = fields.value[index];

              if (!image.show) return null;

              return (
                <Dialog fullWidth maxWidth="md" open={image.show}>
                  <div
                    style={{
                      position: "sticky",
                      right: "0",
                      top: "0",
                      zIndex: 99999
                    }}
                  >
                    <ImageRoundButtonToggle imageIndex={index} bannerInputState={this.state} />
                  </div>
                  <div style={{ padding: "15px" }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TranslatableParagraph translationLabel="resources.pageContents.fields.title" />
                      </Grid>
                      <Grid item xs={12}>
                        <div>
                          <RichTextInput
                            source={"images[" + index + "].imageTitle"}
                            label={false}
                            variant="standard"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={{
                            color: "rgba(0, 0, 0, 0.54)",
                            fontSize: "0.8rem",
                            marginBottom: 0
                          }}
                        >
                          <TranslatableParagraph translationLabel="resources.pageContents.fields.subtitle" />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div>
                          <RichTextInput
                            source={"images[" + index + "].imageSubtitle"}
                            label={false}
                            variant="standard"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div>
                          <TextInput
                            multiline
                            fullWidth
                            source={"images[" + index + "].imageLink"}
                            label="resources.pageContents.fields.link"
                            variant="standard"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div>
                          <ImageInput
                            ratio={"5:3"}
                            imageSize={["750:450"]}
                            source={"images[" + index + "].imageRawMobile"}
                            label="resources.pageContents.fields.image_mobile"
                            accept=".jpg,.jpeg,.png"
                          >
                            <CustomImageField source="src" />
                          </ImageInput>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div>
                          <ImageInput
                            ratio={index > 1 ? (index > 4 ? "7:3" : "5:3") : "5:3"}
                            imageSize={
                              index > 1 ? (index > 4 ? ["1400:600"] : ["450:270"]) : ["675:405"]
                            }
                            source={"images[" + index + "].imageRawDesktop"}
                            label={"resources.pageContents.fields.image_desktop"}
                            accept=".jpg,.jpeg,.png"
                          >
                            <CustomImageField source="src" />
                          </ImageInput>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end"
                          }}
                        >
                          <ButtonToggle source={"images[" + index + "].show"} label="Done" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Dialog>
              );
            }}
          </CustomFormIterator>
        </ArrayInput>
      </Fragment>
    );
  }
}
