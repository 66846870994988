import { validateLettersNumbersSpaces } from "../../utils/validators/regex";

export const validateNotificationCategoryName = (value, categoryList, savedName) => {
  // Search db for input value
  const categoryNameExist = categoryList?.find(
    categoryName => categoryName?.name?.toUpperCase() === value?.toUpperCase()
  );

  //   Save currently placed name -> Edit Phase
  if (categoryNameExist && value === savedName) {
    return undefined;
  }
  //   If input has value
  if (value) {
    // Search input value for dashes both at start and end
    if (value.startsWith("-") || value.endsWith("-")) {
      return "Please make sure your input is not starting or ending in -";
    }

    // Search input value for spaces both at the start and end
    if (value.startsWith(" ") || value.endsWith(" ")) {
      return "Please remove white-spaces from start as well as the end";
    }
    // If input value does not exist in db and it has not passed validation
    if (!validateLettersNumbersSpaces(value) && !categoryNameExist) {
      return "Notification category name can only have letters and numbers";
    }

    // If input value exists in db
    if (categoryNameExist) {
      return "Notification category name already exists";
    }
  }

  //   If input has no value and is required
  if (!value) {
    return "Notification category name is a required field";
  }

  return undefined;
};
