import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceArrayInput,
  ReferenceInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  required,
  Toolbar,
  SaveButton
} from "react-admin";

import { validateNameInput } from "../../utils/validators/index";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { styles } from "../styles";
const ShopEditTitle = ({ record }) => `Edit ${record.name}`;

const ConfirmationToolbar = props => (
  <Toolbar {...props} style={styles.toolbar}>
    <SaveButton />
    <DeleteConfirmation undoable={false} />
  </Toolbar>
);

export const ShopEdit = props => (
  <Edit title={<ShopEditTitle />} undoable={false} {...props}>
    <SimpleForm variant="standard" toolbar={<ConfirmationToolbar />}>
      <TextInput
        label="resources.shops.fields.name"
        source="name"
        validate={[required(), validateNameInput]}
      />
      <TextInput label="resources.shops.fields.domain" source="domain" />
      <TextInput
        label="resources.shops.fields.client_name"
        source="client_name"
        validate={required()}
      />
      <DateInput
        label="resources.shops.fields.start_date"
        source="start_date"
        validate={required()}
      />
      <DateInput label="resources.shops.fields.end_date" source="end_date" />
      <ReferenceInput
        source="homepage_id"
        reference="pages"
        filterToQuery={name => {
          if (name) {
            return { name_ilike: name };
          }
        }}
        label="resources.shops.fields.homepage_name"
        perPage={20}
        allowEmpty
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput
        label="resources.shops.fields.categories"
        source="categoryIds"
        reference="productCategories"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={name => {
          if (name) {
            return { name_ilike: name };
          }
        }}
        allowEmpty
      >
        <AutocompleteArrayInput
          optionText="name"
          options={{
            suggestionsContainerProps: {
              modifiers: {
                computeStyle: {
                  enabled: true,
                  gpuAcceleration: false
                },
                applyStyle: {
                  enabled: true
                }
              }
            }
          }}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);
