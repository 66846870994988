import React, { useState, useEffect } from "react";
import {
  Edit,
  BooleanInput,
  NumberInput,
  Toolbar,
  SaveButton,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  TextInput,
  required
} from "react-admin";

import { handleSingleRequest } from "../../services/api/restClient";

import DeleteConfirmation from "../../components/DeleteConfirmation";

import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";
import GuidanceBox from "../../components/GuidanceBox/GuidanceBox";
import { minValue } from "../../utils/validators/";
import { styles } from "../styles";
import { GET_LIST } from "../../services/api/types";

import {
  BlogCategoryNameValidationTextInput,
  BlogCategorySlugValidationTextInput
} from "./validators";

const ConfirmationToolbar = props => (
  <Toolbar {...props} style={styles.toolbar}>
    <SaveButton />
    <DeleteConfirmation undoable={false} />
  </Toolbar>
);

const Title = ({ record }) => (
  <TranslatableParagraph
    translationLabel="resources.blogCategories.title.edit"
    customLabel={record.name}
  />
);

export const BlogCategoryEdit = props => {
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    getLanguages();
  }, []);

  const getLanguages = async () => {
    try {
      const { data } = await handleSingleRequest(GET_LIST, "languages", {
        pagination: {
          page: "all"
        },
        sort: { field: "id", order: "DESC" },
        filter: {
          is_enabled: true
        }
      });

      setLanguages(data);
    } catch (error) {
      console.log("[Error get languages (blog category edit)]", error);
    }
  };

  const thereCanBeOnlyOne = (value, formValues) => {
    if (value) {
      let checkForExistingLanguageSelected = formValues.translations.filter(
        item => item && item.language && item.language.id === value
      );
      if (checkForExistingLanguageSelected.length > 1) {
        return "There can be only one translation per language";
      } else {
        return undefined;
      }
    } else {
      return "Required";
    }
  };

  return (
    <Edit {...props} title={<Title />} undoable={false}>
      <TabbedForm variant="standard" toolbar={<ConfirmationToolbar />}>
        <FormTab label="basic">
          <GuidanceBox
            buttonLabel="Creating previously deleted blog categories"
            infoBoxText="If you would like to create an element that you previously deleted but validation is not
          allowing you to do so; please contact our IT support to fulfill this request for you."
          />
          <BlogCategoryNameValidationTextInput />
          <BlogCategorySlugValidationTextInput />
          <BooleanInput label="resources.blogCategories.fields.enabled" source="enabled" />
          <NumberInput
            label="resources.blogCategories.fields.order_number"
            source="order_number"
            validate={minValue(0)}
          />
        </FormTab>
        <FormTab label="translations">
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
              return (
                <ArrayInput
                  variant="standard"
                  source="translations"
                  label="resources.products.tabs.translations.name"
                >
                  <SimpleFormIterator
                    disableReordering
                    TransitionProps={{ enter: false, exit: false }}
                  >
                    <SelectInput
                      source="language.id"
                      choices={languages}
                      label="resources.blogCategories.translations.language_name"
                      validate={thereCanBeOnlyOne}
                    />
                    <TextInput
                      label="resources.blogCategories.translations.category_name"
                      source="name"
                      validate={required()}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              );
            }}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
