export const styles = {
  dashboardVersion: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: 24
  },
  versionContainer: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "7.5px",
    marginTop: "10px",
    minWidth: "100%"
  },
  date: {
    backgroundColor: "#050A24",
    padding: "5px 10px",
    fontSize: "13px",
    color: "#f8f8f8",
    textAlign: "center",
    marginRight: "1rem",
    display: "flex",
    justifyContent: "space-between"
  },

  version: {
    fontSize: 35,
    color: "#010101",
    textTransform: "uppercase",
    borderBottom: "5px Dotted black"
  },

  issueLink: {
    margin: `0px 10px`,
    minWidth: "100px"
  },

  changesLegendLayout: {
    display: "flex",
    padding: "5px 10px",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f9f9f9",
    border: "5px solid #f2f2f2",
    borderRadius: "7.5px"
  },

  changesLegend: {
    minWidth: "100px",
    textTransform: "uppercase",
    justifyContent: "center",
    borderRadius: "7.5px"
  },

  bug: {
    textAlign: `center`,
    padding: `5px`,
    backgroundColor: `red`,
    marginRight: `10px`,
    borderRadius: "7.5px",
    minWidth: "30px",
    fontWeight: "bold",
    color: "#fff"
  },

  improvement: {
    textAlign: `center`,
    padding: `5px`,
    backgroundColor: `green`,
    marginRight: `10px`,
    borderRadius: "7.5px",
    minWidth: "30px",
    fontWeight: "bold",
    color: "#fff"
  },

  info: {
    textAlign: `center`,
    padding: `5px`,
    backgroundColor: `#33B2FF`,
    marginRight: `10px`,
    borderRadius: "7.5px",
    minWidth: "30px",
    fontWeight: "bold",
    color: "#fff"
  },

  story: {
    textAlign: `center`,
    padding: `5px`,
    backgroundColor: `#00cc00`,
    marginRight: `10px`,
    borderRadius: "7.5px",
    minWidth: "30px",
    fontWeight: "bold",
    color: "#fff"
  },

  task: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "0.5rem 0px",
    backgroundColor: "#f9f9f9",
    padding: "10px",
    borderRadius: "7.5px"
  },

  mapLegend: {
    display: `flex`,
    justifyContent: `flex-end`,
    alignItems: `center`,
    fontSize: `12px`,
    color: "#fff"
  }
};
