import { handleSingleRequest } from "../restClient";

// This transformer doesn't modify data now, it was used before to mock data fetch
export const transformBenefits = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params
  }).then(({ data = [], included = [], relationships, ...rest }) => {
    return { data, ...rest };
  });
};

export const transformBenefit = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params,
    include: ["benefitFormFields", "benefitProducts", "benefitCategories"]
  }).then(({ data = {}, included = [], relationships, ...rest }) => {
    const benefitFormFields = included
      .filter(item => item.type === "benefitFormFields")
      .map(({ field_name, field_type }) => ({
        field_name,
        field_type
      }));
    data.form_fields = benefitFormFields.length > 0 ? benefitFormFields : null;

    const benefitProducts = included
      .filter(item => item.type === "benefitProducts")
      .map(item => item.product_id);
    data.product_ids = benefitProducts.length > 0 ? benefitProducts : null;

    const benefitCategories = included
      .filter(item => item.type === "benefitCategories")
      .map(item => item.product_category_id);
    data.category_ids = benefitCategories.length > 0 ? benefitCategories : null;

    return { data, ...rest };
  });
};
