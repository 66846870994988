import React, { useState, useEffect } from "react";
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceArrayField,
  Datagrid,
  DateField,
  ReferenceField,
  TextField,
  DeleteButton,
  required,
  Toolbar,
  SaveButton,
  GET_LIST,
  FormDataConsumer
} from "react-admin";
import AddProductButton from "./AddProductButton";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { styles } from "../styles";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";
import { handleSingleRequest } from "../../services/api/restClient";
import { validateTagName } from "./validators";

const TagEditTitle = ({ record }) => (
  <TranslatableParagraph
    customStyling={{ display: "inline-block", margin: 0 }}
    translationLabel="resources.tags.fields.title_edit"
    customLabel={record.name}
  />
);

const ConfirmationToolbar = props => (
  <Toolbar {...props} style={styles.toolbar}>
    <SaveButton />
    <DeleteConfirmation undoable={false} />
  </Toolbar>
);

export const TagEdit = props => {
  const [tagList, setTagList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleSingleRequest(GET_LIST, "tags", {
          pagination: { page: "all" },
          sort: { field: "id", order: "DESC" },
          filter: {}
        });
        setTagList(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const TagName = ({ formData }) => {
    const [savedName, setSavedName] = useState(formData?.name);

    const validateTagNameRT = value => validateTagName(value, tagList, savedName);

    return (
      <TextInput
        variant="standard"
        source="name"
        label="Name"
        style={{ minWidth: "256px" }}
        validate={[required(), validateTagNameRT]}
      />
    );
  };

  return (
    <Edit title={<TagEditTitle />} undoable={false} {...props}>
      <TabbedForm variant="standard" toolbar={<ConfirmationToolbar />}>
        <FormTab label="resources.tags.fields.basic">
          <FormDataConsumer>
            {({ formData }) => {
              return <TagName formData={formData} />;
            }}
          </FormDataConsumer>
          <ReferenceArrayInput
            source="categoryIds"
            reference="productCategories"
            sort={{ field: "name", order: "ASC" }}
            filterToQuery={name => {
              if (name) {
                return { name_ilike: name };
              }
            }}
            allowEmpty={true}
          >
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            source="tagGroupIds"
            reference="tagGroups"
            sort={{ field: "name", order: "ASC" }}
            filterToQuery={name => {
              if (name) {
                return { name_ilike: name };
              }
            }}
            allowEmpty={true}
          >
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
        </FormTab>
        <FormTab label="resources.tags.fields.assigned_products">
          <ReferenceArrayField
            addLabel={false}
            reference="productTags"
            source="productTagIds"
            allowEmpty
          >
            <Datagrid>
              <DateField label="resources.tags.fields.assigned_on" source="created_at" />
              <DateField label="resources.tags.fields.updated_on" source="updated_at" />
              <ReferenceField
                label="resources.tags.fields.product_id"
                reference="products"
                source="product_id"
                allowEmpty
              >
                <TextField source="name" />
              </ReferenceField>
              <DeleteButton undoable={false} redirect={false} />
            </Datagrid>
          </ReferenceArrayField>
          <AddProductButton />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
