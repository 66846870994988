import React from "react";
import { ReferenceField } from "react-admin";

const OrderStatus = props => {
  return props.record[props.source];
};

const OrderStatusReferenceField = props => {
  return (
    <ReferenceField {...props}>
      <OrderStatus source="name" />
    </ReferenceField>
  );
};

export default OrderStatusReferenceField;
