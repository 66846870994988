import React, { Component } from "react";

import ReactDOM from "react-dom";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { userLogin, translate } from "react-admin";
import { withStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import ZepterLogo from "../../assets/images/zepter-logo-black.png";
import LoginBG from "../../assets/images/bg-intro.jpg";

const Title = () => <img src={ZepterLogo} style={{ maxHeight: "30px" }} alt="Zepter" />;

const styles = theme => ({
  card: {
    minWidth: 300,
    background: "rgba(255,255,255,0.2)",
    border: "1px solid lightgray"
  },
  textField: {
    marginTop: 30,
    width: "100%",
    background: "rgba(255,255,255,0.8) !important",
    borderRadius: "4px !important",
    marginLeft: 0,
    marginRight: 0,
    padding: "0 5px"
  },
  formControl: {
    width: "100%"
  }
});

class Login extends Component {
  state = {
    selectedAppId: "none",
    countries: null,
    email: "",
    password: "",
    apps: null,
    errors: [],
    token: null
  };

  submit = e => {
    e.preventDefault();
    // gather your data/credentials here

    const username = this.state.email;
    const password = this.state.password;
    const authUrl = process.env.REACT_APP_AUTH_URL;
    const clientId = process.env.REACT_APP_CLIENT_ID || "zepter-admin";
    const request = new Request(authUrl, {
      method: "POST",
      body: JSON.stringify({
        data: { attributes: { login: username, password }, type: "login" }
      }),
      headers: new Headers({
        "Content-Type": "application/json",
        "Client-Id": clientId
      })
    });

    return fetch(request)
      .then(response => {
        return response.json();
      })
      .then(response => {
        if (response.errors) {
          this.setState({ errors: response.errors });
          return;
        }

        if (response.included) {
          const applications = response.included
            .filter(item => item.type === "applications")
            .map(app => app.attributes);

          const userApplications = response.included
            .filter(item => item.type === "userApplications")
            .map(app => app.attributes);

          const countries = response.included
            .filter(item => item.type === "countries")
            .map(country => country.attributes);

          let countriesApps = userApplications.map(userApp => {
            // find app using userApp
            let app = applications.find(i => i.id === userApp.application_id);
            let country = countries.find(i => i.id === app.country_id);

            country["application_id"] = app.id;

            return country;
          });

          this.setState({
            token: response.data.attributes.session_id,
            countries: countriesApps
          });
          localStorage.setItem("countries", JSON.stringify(countriesApps));
        }
      });

    // Dispatch the userLogin action (injected by connect)
  };

  handleChangeEmail = e => {
    this.setState({ email: e.target.value });
  };

  handleChangePassword = e => {
    this.setState({ password: e.target.value });
  };

  handleCountryChange = ({ target: { value: newAppId } }) => {
    const { token } = this.state;
    this.props.userLogin({ newAppId, token });

    // window.location.replace("/");
    // window.location.reload(true);
    setTimeout(() => window.location.reload());
  };

  renderSelect = countries => {
    const { classes } = this.props;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100vh",
          background: `url(${LoginBG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
        className="Login-card-4 MuiCard-root-7 MuiPaper-elevation1-11 MuiPaper-rounded-9 MuiPaper-root-8"
      >
        <Card className={classes.card}>
          <CardContent>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Select
                value={this.state.selectedAppId}
                onChange={this.handleCountryChange}
                autoWidth
                style={{
                  background: "white",
                  width: "100%",
                  padding: "5px 5px 0px 5px",
                  borderRadius: "4px !important"
                }}
              >
                <MenuItem value={"none"} disabled>
                  Please select a country to continue
                </MenuItem>
                {countries
                  .sort((a, b) => (a.international_name > b.international_name ? 1 : -1))
                  .map((country, index) => (
                    <MenuItem
                      key={index}
                      value={country.application_id}
                      style={{
                        width: "100%"
                      }}
                    >
                      {country.international_name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  render() {
    const { classes, translate } = this.props;
    const { countries, selectedAppId } = this.state;

    if (countries && (!selectedAppId || selectedAppId === "none")) {
      return this.renderSelect(countries);
    }

    return (
      <form onSubmit={this.submit} style={{ marginBottom: "-110px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
            background: `url(${LoginBG})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}
          className="Login-card-4 MuiCard-root-7 MuiPaper-elevation1-11 MuiPaper-rounded-9 MuiPaper-root-8"
        >
          <Card className={classes.card}>
            <CardContent>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Title />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel
                    ref={ref => {
                      this.labelRef = ReactDOM.findDOMNode(ref);
                    }}
                    htmlFor="component-outlined"
                  >
                    {/* Email */}
                  </InputLabel>
                  <Input
                    id="email"
                    type="email"
                    className={classes.textField}
                    value={this.state.email}
                    onChange={this.handleChangeEmail}
                  />
                </FormControl>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <FormControl className={classes.formControl} variant="outlined">
                  <InputLabel
                    ref={ref => {
                      this.labelRef = ReactDOM.findDOMNode(ref);
                    }}
                    htmlFor="passwrod"
                  >
                    {/* Password */}
                  </InputLabel>
                  <Input
                    id="password"
                    type="password"
                    className={classes.textField}
                    value={this.state.password}
                    onChange={this.handleChangePassword}
                  />
                </FormControl>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Button
                  className="MuiButton-label-80 MuiButton-containedPrimary-91"
                  style={{
                    width: "100%",
                    backgroundColor: "#3498db",
                    color: "#FFF",
                    marginTop: 30
                  }}
                  type="submit"
                >
                  Sign in
                </Button>
              </div>
            </CardContent>
          </Card>
          {this.state.errors.length ? (
            this.state.errors.map(error => (
              <p
                key={error.title}
                onClick={() => {
                  this.setState({ errors: [] });
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "red",
                  border: "2px solid red",
                  background: "rgba(255,255,255,0.45)",
                  padding: "0.5rem 1rem",
                  width: "max-content",
                  borderRadius: "4px"
                }}
              >
                {<span>{translate(`errorMessages.${error.title}`)}</span>}
              </p>
            ))
          ) : (
            <p
              onClick={() => {
                this.setState({ errors: [] });
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                color: "transparent",
                border: "2px solid transparent",
                padding: "0.5rem 1rem",
                width: "max-content",
                minHeight: "38px",
                borderRadius: "4px"
              }}
            >
              {" "}
            </p>
          )}
        </div>
      </form>
    );
  }
}

const enhance = compose(withStyles(styles), translate);

export default connect(store => ({}), { userLogin })(enhance(Login));
