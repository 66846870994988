import React from "react";
import { List, TextField, DateField, BooleanField, NumberField } from "react-admin";

import CustomizableDatagrid from "../../components/CustomizableDatagrid";

export const BlogCategoryList = props => (
  <List
    title="resources.blogCategories.title.list"
    {...props}
    exporter={false}
    bulkActionButtons={false}
  >
    <CustomizableDatagrid
      rowClick="edit"
      defaultColumns={["name", "url_slug", "enabled", "order_number"]}
    >
      <TextField label="resources.blogCategories.fields.name" source="name" />
      <TextField label="resources.blogCategories.fields.url_slug" source="url_slug" />
      <BooleanField label="resources.blogCategories.fields.enabled" source="enabled" />
      <NumberField label="resources.blogCategories.fields.order_number" source="order_number" />
      <DateField label="resources.blogCategories.fields.created_at" source="created_at" />
      <DateField label="resources.blogCategories.fields.updated_at" source="updated_at" />
    </CustomizableDatagrid>
  </List>
);
