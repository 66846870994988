import { handleSingleRequest, handleFilesUpload } from "../restClient";
import { CREATE, GET_LIST } from "../types";

import { bannerTextStylesChoices } from "../../../constants/bannerStyles";
import HttpError from "../HttpError";

import includes from "lodash/includes";
import moment from "moment";

const fetchImages = async (imageRaw, device, params, image, banner) => {
  if (imageRaw && imageRaw.rawFile) {
    const imageUploadParams = imageRaw.rawFile;

    const imageTypeName = imageUploadParams.name.split(".").pop().toLowerCase();
    const imageTypeParams = {
      pagination: { page: 1, perPage: 1 },
      sort: { field: "id", order: "DESC" },
      filter: { name: imageTypeName }
    };
    const imageTypeResponse = await handleSingleRequest(GET_LIST, "imageTypes", imageTypeParams);

    if (imageTypeResponse.data.length < 1) {
      throw new HttpError("errorMessages.unsupportedImageType");
    }

    const imageTypeId = imageTypeResponse.data[0].id;
    const imageUploadedResponse = await handleFilesUpload(CREATE, "images", {
      data: imageUploadParams
    });
    const imageFileName = imageUploadedResponse.url.replace("/images/", "");
    const imageSaveParams = {
      data: {
        name: imageUploadedResponse.filename,
        file_name: imageFileName,
        image_type_id: imageTypeId
      }
    };
    const imageSaved = await handleSingleRequest(CREATE, "images", imageSaveParams);

    return {
      src: `/images/${imageSaved.data.file_name}`
    };
  } else {
    // TODO
    if (image) {
      if (params.data.images) {
        const index = params.data.images.indexOf(image);
        return {
          src: params.data.images[index][`imageRaw${device}`]
            ? params.data.images[index][`imageRaw${device}`].src
            : undefined
        };
      }
    }
    if (banner) {
      if (params.data.bannersCarousel) {
        const index = params.data.bannersCarousel.indexOf(banner);
        return {
          src: params.data.bannersCarousel[index][`imageRaw${device}`]
            ? params.data.bannersCarousel[index][`imageRaw${device}`].src
            : undefined
        };
      }
    }
    if (params.data[`imageRaw${device}`]) {
      return {
        src: params.data[`imageRaw${device}`] ? params.data[`imageRaw${device}`].src : undefined
      };
    }
  }
};

const fetchVideos = async videoRaw => {
  if (videoRaw && videoRaw.rawFile) {
    const videoUploadParams = videoRaw.rawFile;

    const videoUploadedResponse = await handleFilesUpload(CREATE, "attachments", {
      data: videoUploadParams
    });
    const videoFileName = videoUploadedResponse.url.replace("/attachments/", "");
    const videoSaveParams = {
      data: {
        name: videoUploadedResponse.filename,
        file_name: videoFileName
      }
    };
    const videoSaved = await handleSingleRequest(CREATE, "attachments", videoSaveParams);

    return {
      src: `/attachments/${videoSaved.data.file_name}`
    };
  }
};

export const transformPageContentUpdate = async (type, resource, params) => {
  let images = [];
  let bannersCarousel = [];
  try {
    images = await Promise.all(
      params.data.images.map(async image => {
        return {
          imageRawDesktop: await fetchImages(image.imageRawDesktop, "Desktop", params, image),
          imageRawMobile: await fetchImages(image.imageRawMobile, "Mobile", params, image),
          imageTitle: image.imageTitle,
          imageSubtitle: image.imageSubtitle,
          imageLink: image.imageLink
        };
      })
    ).then(response => response);

    const content = JSON.parse(params.data.content);

    // files upload

    const allFiles = params.data.files || [];

    const filesForUpload = [];
    const oldFiles = [];

    // get new files record ids
    let newFilesRecordIds = [];

    allFiles.forEach((file, index) => {
      if (file && file.hasOwnProperty("rawFile")) {
        newFilesRecordIds.push(index);
      }
    });

    allFiles.forEach((file, index) => {
      if (file && file.hasOwnProperty("rawFile")) {
        filesForUpload[index] = file;
      } else {
        if (file && newFilesRecordIds.length > 0 && includes(newFilesRecordIds, file.recordId)) {
          return;
        } else {
          oldFiles[index] = file;
        }
      }
    });

    const fileUploads = [];
    filesForUpload.forEach(file => {
      fileUploads.push(
        handleFilesUpload(CREATE, "attachments", {
          data: file.rawFile
        })
      );
    });

    const uploadedFiles = await Promise.all(fileUploads);

    let fileDataUpload = [];
    if (uploadedFiles.length) {
      uploadedFiles.forEach(data => {
        fileDataUpload.push(
          handleSingleRequest(CREATE, "attachments", {
            data: {
              name: data.filename,
              file_name: data.url
            }
          })
        );
      });
    }

    let uploadedFilesWithIds = uploadedFiles.map((element, index) => element);
    uploadedFilesWithIds.forEach(
      (file, index) => (file["recordId"] = parseInt(Object.keys(filesForUpload)[index]))
    );

    const allPageContentFiles = oldFiles.concat(uploadedFilesWithIds);

    // files upload end
    // let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    // let localISOTime = new Date(Date.now() - tzoffset).toISOString();
    // let inAdvance =
    // let inAdvanceEndDate = new Date(Date.now() - tzoffset);
    // let inAdvance = inAdvanceEndDate.setDate(
    //   inAdvanceEndDate.getFullYear() + 1
    // );

    bannersCarousel = await Promise.all(
      params.data.bannersCarousel.map(async banner => {
        if (!banner.banner_type) {
          banner.banner_type = "image";
        }
        if (banner.banner_type === "image") {
          let imageRaw4K =
            (await fetchImages(banner.imageRaw4K, "4K", params, undefined, banner)) || "";
          let imageRawDesktop =
            (await fetchImages(banner.imageRawDesktop, "Desktop", params, undefined, banner)) || "";
          let imageRawMobile =
            (await fetchImages(banner.imageRawMobile, "Mobile", params, undefined, banner)) || "";

          return {
            ...banner,
            show: false,
            imageRaw4K,
            imageRawDesktop,
            imageRawMobile,
            banner_start_at: banner.banner_start_at
              ? banner.banner_start_at
              : moment.utc().format(),
            banner_end_at: banner.banner_end_at
              ? banner.banner_end_at
              : moment().add(7, "year").format()
          };
        } else if (banner.banner_type === "video") {
          if (banner.videoRaw.rawFile) {
            let videoRaw = (await fetchVideos(banner.videoRaw)) || "";
            let imageRawMobile =
              (await fetchImages(banner.imageRawMobile, "Mobile", params, undefined, banner)) || "";
            return {
              ...banner,
              show: false,
              videoRaw,
              imageRawMobile
            };
          }
          return banner;
        }
      })
    ).then(response => response);

    content.images = images;
    content.bannersCarousel = bannersCarousel;
    content.files = allPageContentFiles;

    if (content.banner) {
      let { banner_title, banner_subtitle, banner_text, imageRawDesktop, imageRawMobile } =
        content.banner;
      banner_title =
        (banner_title ? banner_title : "") +
        (banner_subtitle ? banner_subtitle : "") +
        (banner_text ? banner_text : "");

      let banner = {
        banner_title,
        show: false,
        imageRawDesktop,
        imageRawMobile,
        banner_type_class_name: bannerTextStylesChoices[3].id
      };

      if (content.bannersCarousel && content.bannersCarousel.length) {
        content.bannersCarousel = [...content.bannersCarousel, banner];
      } else {
        content.bannersCarousel = [banner];
      }
      content.banner = undefined;
    }

    params.data.content = JSON.stringify({ ...content });

    return handleSingleRequest(type, resource, {
      ...params
    }).then(({ data = [], included = [], ...rest }) => {
      const parsedContent = JSON.parse(data.content);

      return {
        data: {
          ...data,
          bannersCarousel:
            parsedContent.bannersCarousel && parsedContent.bannersCarousel.length
              ? parsedContent.bannersCarousel
              : [],
          images: parsedContent.images.length ? parsedContent.images : [],
          files: allPageContentFiles
        },
        ...rest
      };
    });
  } catch (error) {
    console.log("ERROR", error);
  }
};

export const transformPageContent = (type, resource, params) => {
  // let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  // let localISOTime = (new Date(Date.now() - tzoffset)).toISOString();
  // let inAdvance =
  // let inAdvanceEndDate = new Date(Date.now() - tzoffset);
  // let inAdvance = inAdvanceEndDate.setDate(inAdvanceEndDate.getFullYear() + 1)

  return handleSingleRequest(type, resource, {
    ...params
  }).then(({ data = [], included = [], ...rest }) => {
    let parsedContent = JSON.parse(data.content);

    if (parsedContent.banner) {
      let { banner_title, banner_subtitle, banner_text, imageRawDesktop, imageRawMobile } =
        parsedContent.banner;
      banner_title =
        (banner_title ? banner_title : "") +
        (banner_subtitle ? banner_subtitle : "") +
        (banner_text ? banner_text : "");

      let banner = {
        banner_title,
        show: false,
        imageRawDesktop,
        imageRawMobile,
        // eslint-disable-next-line no-use-before-define
        banner_start_at: banner?.banner_start_at,
        // eslint-disable-next-line no-use-before-define
        banner_end_at: banner?.banner_end_at
      };

      if (parsedContent?.bannersCarousel?.length) {
        parsedContent.bannersCarousel = [...parsedContent.bannersCarousel, banner];
      } else {
        parsedContent.bannersCarousel = [banner];
      }
      parsedContent.banner = undefined;
    }

    let updatedBannersCarousel =
      parsedContent.bannersCarousel && parsedContent.bannersCarousel.length
        ? [...parsedContent.bannersCarousel]
        : [];

    // Sometimes we send cancel property with banner object which later on reading can break page
    // Removed cancel property when reading data because some banners already have them
    updatedBannersCarousel = updatedBannersCarousel.map(banner => {
      const updatedBanner = { ...banner };
      delete updatedBanner.cancel;

      return updatedBanner;
    });

    return {
      data: {
        ...data,
        bannersCarousel: updatedBannersCarousel,
        images: parsedContent.images && parsedContent.images.length ? parsedContent.images : [],
        files: parsedContent.files && parsedContent.files.length ? parsedContent.files : []
      },
      ...rest
    };
  });
};

export const transformPageContentMany = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params,
    include: ["languages"]
  }).then(({ data = [], included = [], ...rest }) => {
    const dataWithContent = data.map(record => {
      const parsedContent = JSON.parse(record.content);
      let bannersCarousel = parsedContent?.bannersCarousel;

      const validBanners = bannersCarousel?.filter(banner => {
        return banner !== null;
      });

      return {
        ...record,
        bannersCarousel: parsedContent?.bannersCarousel?.length ? [...validBanners] : [],
        images: parsedContent?.images?.length ? parsedContent.images : [],
        files: parsedContent?.files?.length ? parsedContent.files : []
      };
    });

    return {
      data: dataWithContent,
      ...rest
    };
  });
};

export const transformPageContentDelete = (type, resource, params) => {
  const { content, created_at, deleted_at, id, name, page_id, updated_at } = params.previousData;
  params.previousData = {
    content,
    created_at,
    deleted_at,
    id,
    name,
    page_id,
    updated_at
  };
  return handleSingleRequest(type, resource, {
    ...params
  }).then(({ data = [], included = [], ...rest }) => {
    return { data, ...rest };
  });
};
