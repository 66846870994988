import React, { Component, Fragment } from "react";
import { crudUpdateMany, translate } from "react-admin";
import { connect } from "react-redux";
import compose from "recompose/compose";
import ReactToPrint from "react-to-print";

import Button from "@material-ui/core/Button";

import Confirm from "../../../components/CustomConfirm";

import PrintSimpleList from "../PrintSimpleList";
import PrintAdvancedList from "../PrintAdvancedList";

// Info button beside Print button
const InfoButton = props => {
  return (
    <p
      style={{
        padding: "5px 10px",
        fontSize: "16px",
        borderRadius: "5px",
        margin: "0px",
        backgroundColor: "white",
        color: "#3498db",
        border: "1px solid #3498db",
        minWidth: "100%"
      }}
    >
      Due to number of orders you want to print, please allow up to{" "}
      {props.numberOfOrders < 7
        ? "10"
        : props.numberOfOrders >= 7 && props.numberOfOrders <= 44
        ? "15"
        : props.numberOfOrders <= 59
        ? "35"
        : props.numberOfOrders >= 60
        ? "60"
        : null}{" "}
      seconds for all information to display correctly.
    </p>
  );
};
class PrintButton extends Component {
  state = {
    simplePrintDialogOpen: false,
    advancedPrintDialogOpen: false,
    hideConfirmDialogButton: true
  };

  handleSimplePrint = () => {
    this.setState({ simplePrintDialogOpen: true });
  };

  handleAdvancedPrint = () => {
    this.setState({ advancedPrintDialogOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ simplePrintDialogOpen: false, advancedPrintDialogOpen: false });
  };

  render() {
    const { simplePrintDialogOpen, advancedPrintDialogOpen } = this.state;

    return (
      <Fragment>
        <Button
          onClick={this.handleSimplePrint}
          style={{
            backgroundColor: "#3498db",
            color: "white"
            // border: "1px solid white"
          }}
        >
          {"Simple Print View"}
        </Button>
        <Button
          onClick={this.handleAdvancedPrint}
          style={{
            backgroundColor: "#3498db",
            color: "white",
            // border: "1px solid #3498db",
            marginLeft: "10px"
          }}
        >
          {"Advance Print View"}
        </Button>
        <Confirm
          // Simple Print Option
          isOpen={simplePrintDialogOpen}
          title={
            <ReactToPrint
              trigger={() => (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  {this.props.selectedIds && this.props.selectedIds.length === 1 ? null : (
                    <InfoButton numberOfOrders={this.props.selectedIds.length} />
                  )}
                  <Button
                    style={{
                      backgroundColor: "#3498db",
                      color: "white",
                      // border: "1px solid #3498db",
                      marginTop: "5px"
                    }}
                  >
                    {"Print"}
                  </Button>
                </div>
              )}
              content={() => this.componentRefSimple}
            />
          }
          content={<PrintSimpleList ref={el => (this.componentRefSimple = el)} {...this.props} />}
          onClose={this.handleDialogClose}
          hideCloseDialogButton
          hideConfirmDialogButton
        />
        <Confirm
          // Advanced Print Option
          isOpen={advancedPrintDialogOpen}
          title={
            <ReactToPrint
              trigger={() => (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  {this.props.selectedIds && this.props.selectedIds.length === 1 ? null : (
                    <InfoButton numberOfOrders={this.props.selectedIds.length} />
                  )}
                  <Button
                    style={{
                      backgroundColor: "#3498db",
                      color: "white",
                      // border: "1px solid #3498db",
                      marginTop: "5px"
                    }}
                  >
                    {"Print"}
                  </Button>
                </div>
              )}
              content={() => this.componentRefAdvanced}
            />
          }
          content={
            <PrintAdvancedList ref={el => (this.componentRefAdvanced = el)} {...this.props} />
          }
          onClose={this.handleDialogClose}
          hideCloseDialogButton
          hideConfirmDialogButton
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  orderStatuses: state.admin.resources["orderStatuses"].data,
  orders: state.admin.resources["orders"].data
});

const enhance = compose(connect(mapStateToProps, { crudUpdateMany }), translate);

const EnhancedPrintButton = enhance(PrintButton);

export default EnhancedPrintButton;
