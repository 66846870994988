import React from "react";
import {
  List,
  TextField,
  ReferenceField,
  DateField,
  CreateButton,
  RefreshButton,
  Toolbar
} from "react-admin";

import CustomizableDatagrid from "../../components/CustomizableDatagrid";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

const CreationToolbar = props => (
  <Toolbar
    {...props}
    style={{
      padding: "0px",
      backgroundColor: "transparent",
      justifyContent: "flex-end",
      width: "100%",
      marginTop: "0px"
    }}
  >
    <CreateButton
      label="resources.bestSellers.customFields.modify"
      size="small"
      variant="outlined"
      color="primary"
      style={{ minHeight: 34 }}
    />
    <RefreshButton />
  </Toolbar>
);

const ListTitle = () => {
  return <TranslatableParagraph translationLabel="resources.bestSellers.name" />;
};

const BestsellerOrderField = props => {
  const {
    record: { order }
  } = props;

  return <div>{order + 1}</div>;
};

const BestSellingList = props => {
  return (
    <List
      {...props}
      title={<ListTitle />}
      perPage={25}
      bulkActionButtons={false}
      exporter={false}
      actions={<CreationToolbar />}
      sort={{ field: "created_at", order: "ASC" }}
    >
      <CustomizableDatagrid
        defaultColumns={[
          "order",
          "name",
          "external_number",
          "product_id",
          "created_at",
          "updated_at"
        ]}
      >
        <BestsellerOrderField source="order" label="Order" />
        <ReferenceField
          link={false}
          label="resources.products.fields.name"
          source="product_id"
          reference="products"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          link={false}
          label="resources.products.fields.external_number"
          source="product_id"
          reference="products"
          sortable={false}
        >
          <TextField source="external_number" />
        </ReferenceField>
        <TextField
          source="product_id"
          label="resources.products.fields.external_id"
          sortable={false}
        />
        <DateField source="created_at" label="resources.pages.fields.created_at" />
        <DateField source="updated_at" label="resources.pages.fields.updated_at" />
      </CustomizableDatagrid>
    </List>
  );
};

export default BestSellingList;
