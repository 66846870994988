import React from "react";
import { Show, SimpleShowLayout, TextField, ArrayField, Datagrid } from "react-admin";

const FeedShowTitle = ({ record }) => `Feed ${record.name}`;

export const FeedShow = props => (
  <Show title={<FeedShowTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="Feed ID" source="id" />
      <TextField label="Feed Name" source="name" />
      <TextField label="File type" source="file_type" />
      <TextField label="File url" source="file_url" />
      <TextField label="Created At" source="created_at" />
      <TextField label="Updated At" source="updated_at" />
      <ArrayField label="" source="template.attr_map">
        <Datagrid>
          <TextField label="Template fields" source="attribute_name" />
        </Datagrid>
      </ArrayField>
      <p style={{ fontSize: "14px" }}>Products that aren't included into feed</p>
      <div style={{ overflow: "auto", height: "500px", display: "flex", position: "relative" }}>
        <ArrayField source="data_errors">
          <Datagrid>
            <TextField label="Product external no." source="product_id" />
            <TextField label="Field name" source="field_name" />
            <TextField label="Error message" source="error_message" />
          </Datagrid>
        </ArrayField>
      </div>
    </SimpleShowLayout>
  </Show>
);
