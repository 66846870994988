const zboziTemplate = {
  xml_wrapper: "SHOP",
  xmlns: "http://www.zbozi.cz/ns/offer/1.0",
  xml_product_wrapper: "SHOP_ITEM",
  attr_map: [
    {
      attribute_name: "ITEM_ID",
      field_name: "id"
    },
    {
      attribute_name: "PRODUCTNAME",
      field_name: "feed_name",
      char_limit: 225
    },
    {
      attribute_name: "DESCRIPTION",
      field_name: "feed_description",
      char_limit: 1024
    },
    {
      attribute_name: "PRICE_VAT",
      field_name: "member_price",
      with_currency: false
    },
    {
      attribute_name: "URL",
      field_name: "product_url"
    },
    {
      attribute_name: "IMGURL",
      field_name: "image_url"
    },
    {
      attribute_name: "PRODUCTNO",
      field_name: "external_number"
    },
    {
      attribute_name: "DELIVERY_DATE",
      field_name: "in_stock",
      sync_with_stock: true,
      true_value: "7",
      false_value: "0"
    }
  ]
};

const facebookCsvTemplate = {
  attr_map: [
    {
      attribute_name: "id",
      field_name: "id"
    },
    {
      attribute_name: "title",
      field_name: "feed_name",
      char_limit: 200
    },
    {
      attribute_name: "description",
      field_name: "feed_description",
      char_limit: 9999
    },
    {
      attribute_name: "availability",
      field_name: "in_stock",
      sync_with_stock: true,
      true_value: "in stock",
      false_value: "out of stock"
    },
    {
      attribute_name: "condition",
      field_name: "condition",
      default_value: "new"
    },
    {
      attribute_name: "price",
      field_name: "member_price",
      with_currency: true
    },
    {
      attribute_name: "link",
      field_name: "product_url"
    },
    {
      attribute_name: "image_link",
      field_name: "image_url"
    },
    {
      attribute_name: "brand",
      field_name: "brand"
    }
  ]
};

const googleMerchentCsvTemplate = {
  attr_map: [
    {
      attribute_name: "id",
      field_name: "id"
    },
    {
      attribute_name: "mpn",
      field_name: "external_number"
    },
    {
      attribute_name: "title",
      field_name: "feed_name",
      char_limit: 150
    },
    {
      attribute_name: "description",
      field_name: "feed_description",
      char_limit: 5000
    },
    {
      attribute_name: "availability",
      field_name: "in_stock",
      sync_with_stock: true,
      true_value: "in_stock",
      false_value: "out_of_stock"
    },
    {
      attribute_name: "condition",
      field_name: "condition",
      default_value: "new"
    },
    {
      attribute_name: "price",
      field_name: "member_price",
      with_currency: true
    },
    {
      attribute_name: "link",
      field_name: "product_url"
    },
    {
      attribute_name: "image_link",
      field_name: "image_url"
    },
    {
      attribute_name: "brand",
      field_name: "brand"
    }
  ]
};

export { zboziTemplate, facebookCsvTemplate, googleMerchentCsvTemplate };
