import React from "react";
import {
  List,
  TextField,
  DateField,
  BooleanField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField
} from "react-admin";

import CustomizableDatagrid from "../../components/CustomizableDatagrid";
import ProductFilters from "./Filters";
import ListActions from "./ListActions";

export const ProductsList = props => {
  return (
    <List
      exporter={false}
      bulkActionButtons={false}
      actions={<ListActions />}
      filters={<ProductFilters />}
      sort={{ field: "created_at", order: "DESC" }}
      {...props}
    >
      <CustomizableDatagrid
        defaultColumns={[
          "name",
          "category_id",
          "url_slug",
          "tag_ids",
          "external_number",
          "has_content",
          "is_visible_in_shop",
          "product_inventory.stock_available",
          "product_inventory.stock_total"
        ]}
        // expand={<StockEdit />}
        rowClick="show"
      >
        {/* <LogRecordField /> */}
        <TextField source="id" />
        <TextField label="resources.products.fields.name" source="name" />
        <ReferenceField
          source="category_id"
          reference="productCategories"
          link={false}
          allowEmpty
          label="resources.products.fields.category_name"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField label="resources.products.fields.url_slug" source="url_slug" />
        <TextField label="resources.products.fields.stock_number" source="stock_number" />
        <DateField label="resources.products.fields.created_at" source="created_at" />
        <DateField label="resources.products.fields.updated_at" source="updated_at" />
        {/* <DateField source="deleted_at" /> */}
        {/* <ReferenceField
          source="unit_type_id"
          reference="unitTypes"
          link={false}
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField> */}
        <ReferenceArrayField
          label="resources.products.fields.tags"
          reference="tags"
          source="tag_ids"
          allowEmpty
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField label="resources.products.fields.external_id" source="external_id" />
        <TextField label="resources.products.fields.external_number" source="external_number" />
        <BooleanField label="resources.products.fields.show_discount" source="show_discount" />
        <BooleanField label="resources.products.fields.has_content" source="has_content" />
        <BooleanField
          label="resources.products.fields.is_visible_in_shop"
          source="is_visible_in_shop"
        />
        <TextField
          source="product_inventory.stock_available"
          label="resources.products.fields.available_stock"
        />
        <TextField
          source="product_inventory.stock_total"
          label="resources.products.fields.stock_total"
        />
      </CustomizableDatagrid>
    </List>
  );
};
