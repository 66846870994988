export const nameFilter = filterValue => (filterValue ? { name_ilike: `${filterValue}%` } : {});

export const externalNumberFilter = filterValue =>
  filterValue ? { external_number_ilike: `${filterValue}%` } : {};

export const emailFilter = filterValue => (filterValue ? { email_ilike: `${filterValue}%` } : {});
export const cuNumberFilter = filterValue =>
  filterValue ? { external_id_ilike: `${filterValue}%` } : {};

export const bestSellingProductsFilter = sku => {
  if (sku) {
    return {
      external_number_ilike: sku,
      has_content: true,
      is_visible_in_shop: true
    };
  } else {
    return {
      has_content: true,
      is_visible_in_shop: true
    };
  }
};
