import { handleSingleRequest, handleFilesUpload } from "../restClient";
import { GET_LIST, GET_ONE, CREATE, UPDATE } from "../types";
import HttpError from "../HttpError";

export const transformProductImageCreate = async (type, resource, params) => {
  const { imageRaw, overwrite } = params.data;

  params.data.image_order = Number(params.data.image_order);

  const imageUploadParams = imageRaw.rawFile;

  const imageTypeName = imageUploadParams.name.split(".").pop().toLowerCase();

  const imageTypeParams = {
    pagination: { page: 1, perPage: 1 },
    sort: { field: "id", order: "DESC" },
    filter: { name: imageTypeName }
  };

  try {
    const imageTypeResponse = await handleSingleRequest(GET_LIST, "imageTypes", imageTypeParams);

    if (imageTypeResponse.data.length < 1) {
      throw new HttpError("errorMessages.unsupportedImageType");
    }

    const imageTypeId = imageTypeResponse.data[0].id;

    const imageUploadedResponse = await handleFilesUpload(CREATE, "images", {
      data: imageUploadParams,
      overwrite: overwrite
    });

    const imageFileName = imageUploadedResponse.url.replace("/images/", "");

    const productResponse = await handleSingleRequest(GET_ONE, "products", {
      id: params.data.product_id,
      include: "productImages",
      filter: {
        is_visible_in_shop_in: "true,false"
      }
    });

    if (overwrite && productResponse.included.length > 0) {
      let imageSaved = await handleSingleRequest(GET_LIST, "images", {
        filter: {
          name: imageUploadedResponse.filename,
          file_name: imageFileName,
          image_type_id: imageTypeId
        },
        pagination: { page: "all" },
        sort: { field: "id", order: "DESC" }
      });
      if (imageSaved.data[0]) {
        imageSaved = imageSaved.data[0];

        const productImage = productResponse.included.find(item => item.image_id === imageSaved.id);

        const updateParams = {
          id: productImage.id,
          data: {
            ...productImage,
            image_order: params.data.image_order,
            name: params.data.name
          },
          previousData: productImage
        };

        return handleSingleRequest(UPDATE, "productImages", updateParams).then(
          ({ data = [], included = [], ...rest }) => {
            return { data, ...rest };
          }
        );
      }
    }
    const imageSaveParams = {
      data: {
        name: imageUploadedResponse.filename,
        file_name: imageFileName,
        image_type_id: imageTypeId
      }
    };
    // save the uploaded image reference to the API to get the image's id
    const imageSaved = await handleSingleRequest(CREATE, "images", imageSaveParams);

    const savedImageId = imageSaved.data.id;

    const productImageCreateParams = {
      data: {
        image_order: Number(params.data.image_order),
        product_id: params.data.product_id,
        image_id: savedImageId
      }
    };

    return handleSingleRequest(CREATE, "productImages", productImageCreateParams).then(
      ({ data = {}, included = [], ...rest }) => {
        return { data, ...rest };
      }
    );
  } catch (error) {
    console.log("error: ", error);
    throw new HttpError(error);
  }
};

export const transformProductImageDelete = async (type, resource, params) => {
  let url = window.location.href.split("/");

  const product_id = url[url.length - 2];

  try {
    const product = await handleSingleRequest(GET_ONE, "products", {
      id: product_id,
      include: "productImages",
      filter: {
        is_visible_in_shop_in: "true,false"
      }
    });

    const productImage = product.included.find(product => product.image_id === params.id);

    const deleteParams = {
      id: productImage.id,
      previousData: productImage
    };

    const image = await handleSingleRequest(GET_LIST, "images", {
      filter: { id: productImage.image_id },
      sort: { field: "id", order: "DESC" },
      pagination: {
        page: "all"
      }
    });

    const imageDeleteParams = {
      id: image.data[0].id,
      previousData: image.data[0]
    };

    return handleSingleRequest(type, "productImages", deleteParams).then(
      ({ data = [], included = [], ...rest }) => {
        return handleSingleRequest(type, "images", imageDeleteParams).then(response => {
          return { data, ...rest };
        });
      }
    );
  } catch (error) {}
};

export const transformProductImageUpdate = async (type, resource, params) => {
  params.data.image_order = Number(params.data.image_order);

  try {
    const productResponse = await handleSingleRequest(GET_ONE, "products", {
      id: params.data.product_id,
      include: "productImages",
      filter: {
        is_visible_in_shop_in: "true,false"
      }
    });

    const productImage = productResponse.included.find(item => item.image_id === params.id);

    const updateParams = {
      id: productImage.id,
      data: {
        ...productImage,
        image_order: params.data.image_order,
        name: params.data.name
      },
      previousData: productImage
    };

    return handleSingleRequest(type, "productImages", updateParams).then(
      ({ data = [], included = [], ...rest }) => {
        return { data, ...rest };
      }
    );
  } catch (error) {}
};
