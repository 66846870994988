import React from "react";
import { Show, TextField, SimpleShowLayout, NumberField, ShowController, List } from "react-admin";

import CustomizableDatagrid from "../../components/CustomizableDatagrid";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

import classes from "./styles.module.css";

const Title = ({ record }) => (
  <TranslatableParagraph
    customStyling={{ display: "inline-block", margin: 0 }}
    translationLabel="resources.paymentRules.customLabels.showTitle"
    customLabel={record.name}
  />
);

export const paymentRulesShow = props => {
  return (
    <ShowController {...props}>
      {controllerProps => {
        const { translate, ...restControllerProps } = controllerProps;

        return (
          <div className={classes.PageWrapper}>
            <div className={classes.ColumnLeft}>
              <Show title={<Title />} {...props} {...restControllerProps}>
                <SimpleShowLayout>
                  <TextField label="resources.paymentRules.fields.name" source="name" />
                  <NumberField
                    label="resources.paymentRules.fields.order_amount_min"
                    source="order_amount_min"
                  />
                  <NumberField
                    label="resources.paymentRules.fields.order_amount_max"
                    source="order_amount_max"
                  />
                  <NumberField
                    label="resources.paymentRules.fields.max_installments"
                    source="max_instalments"
                  />
                </SimpleShowLayout>
              </Show>
            </div>
            <div className={classes.ColumnRight}>
              <TranslatableParagraph
                customStyling={{ fontSize: "25px", fontWeight: "bold" }}
                translationLabel="resources.paymentRules.customLabels.other_rules"
              />
              <List exporter={false} bulkActionButtons={false} {...props} actions={null}>
                <CustomizableDatagrid rowClick="show">
                  <TextField label="resources.paymentRules.fields.name" source="name" />
                  <TextField
                    label="resources.paymentRules.fields.order_amount_min"
                    source="order_amount_min"
                  />
                  <TextField
                    label="resources.paymentRules.fields.order_amount_max"
                    source="order_amount_max"
                  />
                  <TextField
                    label="resources.paymentRules.fields.max_installments"
                    source="max_instalments"
                  />
                </CustomizableDatagrid>
              </List>
            </div>
          </div>
        );
      }}
    </ShowController>
  );
};
