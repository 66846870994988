import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { addHeaders } from "../../../services/api/restClient";
import { csvUrlReceived } from "../../../redux/actions";

import Button from "@material-ui/core/Button";
import DownloadIcon from "@material-ui/icons/GetApp";

import getIncludes from "./includes";
import getFiltersAndSort from "./filters";
import getFields from "./fields";

import TranslationParagraph from "../../TranslationComponents/TranslatableParagraph";

const PAGE_SIZE = 50000;

const CustomExport = ({
  resource,
  selection,
  location: { search },
  total,
  csvUrlReceived,
  userCsvUrls
}) => {
  const includes = getIncludes(resource, selection);
  const filtersAndSort = getFiltersAndSort(resource, search);
  const { fields, aliases } = getFields(resource, selection);

  const onExportData = async () => {
    let preparedFiltersAndSort = "";
    if (filtersAndSort.sort.sort) {
      // eslint-disable-next-line prettier/prettier
      preparedFiltersAndSort += `&sort=${filtersAndSort.sort.sort}&order=${filtersAndSort.sort.order}`;
    }
    Object.keys(filtersAndSort.filter).forEach(key => {
      preparedFiltersAndSort += `&filter[${key}]=${filtersAndSort.filter[key]}`;
    });

    let preparedFields = "";
    Object.keys(fields).forEach(key => {
      preparedFields += fields[key].length ? `&fields[${key}]=${fields[key].join(",")}` : "";
    });

    let preparedIncludes = includes.length ? `&include=${includes.join(",")}` : "";

    let pagesCount = 1;
    if (total > PAGE_SIZE) {
      pagesCount = Math.ceil(total / PAGE_SIZE);
    }

    const preparedAliases = aliases.join("&");

    const urls = [];

    for (let i = 1; i <= pagesCount; i++) {
      urls.push(
        `${process.env.REACT_APP_API_URL}/${resource}?page[size]=${PAGE_SIZE}&page[number]=${i}${preparedIncludes}${preparedFields}${preparedFiltersAndSort}&${preparedAliases}`
      );
    }

    const options = {
      acceptCsv: true
    };
    addHeaders(options);

    Promise.all(
      urls.map(async url => {
        fetch(url, options)
          .then(response => response.text())
          .then(response => {
            // CSV url needs to be stored in the state
            // so user can browse through the other admin pages
            // Once the csv is ready, root parent will initiate download
            csvUrlReceived(response, resource);
          })
          .catch(e => {
            console.error(e);
          });
      })
    );
  };

  return (
    <Button
      size="large"
      color="primary"
      style={{ marginRight: 16, padding: 8 }}
      disabled={!!userCsvUrls.length}
      onClick={onExportData}
    >
      <DownloadIcon style={{ width: 16 }} />
      <TranslationParagraph
        customStyling={{ marginLeft: "5px" }}
        translationLabel="resources.users.custom.export_data"
      />
    </Button>
  );
};

CustomExport.propTypes = {
  resource: PropTypes.string.isRequired,
  selection: PropTypes.object,
  location: PropTypes.object,
  total: PropTypes.number,
  csvUrlReceived: PropTypes.func,
  userCsvUrls: PropTypes.array
};

CustomExport.defaultProps = {
  selection: {}
};

const mapStateToProps = state => ({
  userCsvUrls: state.exportReducer.csvUrls
});
const mapDispatchToProps = dispatch => ({
  csvUrlReceived: (csvUrl, resource) => {
    dispatch(csvUrlReceived(csvUrl, resource));
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomExport));
