import React, { Component } from "react";
import PrintSimpleItem from "./PrintSimpleItem";

class PrintSimpleList extends Component {
  render() {
    const { orders, selectedIds } = this.props;
    const selectedOrders = Object.values(orders).filter(order => selectedIds.includes(order.id));
    return (
      <table style={{ width: "100%", padding: "0.5rem" }}>
        <tbody>
          {selectedOrders.map(order => {
            return <PrintSimpleItem key={order.id} order={order} />;
          })}
        </tbody>
      </table>
    );
  }
}

export default PrintSimpleList;
