import { handleSingleRequest } from "../restClient";
import moment from "moment";

export const transformPriceRules = (type, resource, params) => {
  // Transformer for price rules list, if filter by Rule type is used
  if (params.filter["rule->rule_type"] && params.filter["rule->rule_type"] !== "all") {
    if (params.filter["rule->rule_type"] === "searcher") {
      params.filter["rule->coupon_code"] = "";
    } else if (params.filter["rule->rule_type"] === "promo_code") {
      params.filter["rule->rule_type"] = "searcher";
      params.filter["rule->coupon_code_ne"] = "";
    }
  } else {
    delete params.filter["rule->rule_type"];
  }

  // Transformer for price rules list, if filter by Status is used
  if (
    params.filter["rules_activation_status"] &&
    params.filter["rules_activation_status"] !== "all"
  ) {
    params.filter.status = params.filter["rules_activation_status"];
  }

  params["admin_search"] = true;
  delete params.filter["rules_activation_status"];

  return handleSingleRequest(type, resource, {
    ...params
  }).then(({ data = [], included = [], ...rest }) => {
    data.forEach(record => {
      if (record.rule.product_ids?.length > 1) {
        record.rule.product_ids = [...new Set(record.rule.product_ids)];
      }
    });
    return { data, ...rest };
  });
};

export const transformPriceRule = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params
  }).then(({ data = {}, included = [], relationships, ...rest }) => {
    if (data.rule?.product_ids?.length > 1) {
      data.rule.product_ids = [...new Set(data.rule.product_ids)];
    }
    return { data, ...rest };
  });
};

export const transformPriceRulesCreateUpdate = (type, resource, params) => {
  if (params.updateOrigin && params.updateOrigin === "reorderWeights") {
    delete params.updateOrigin;
  } else {
    // For cart_amount rule type categories and products info should be empty
    if (params.data.rule.rule_type === "cart_amount") {
      params.data.rule.coupon_code = "";
      params.data.rule.category_ids = [];
      params.data.rule.product_ids = [];
      params.data.rule.product_quantity = { value: null, operator: "" };
    } else if (params.data.rule.rule_type === "promo_code") {
      const startTime = moment(params.data.rule.start_date)
        .format("YYYY-MM-DDTHH:mm:ss.sssZ")
        .slice(11, 16);
      const endTime = moment(params.data.rule.end_date)
        .format("YYYY-MM-DDTHH:mm:ss.sssZ")
        .slice(11, 16);
      params.data.rule.rule_type = "searcher";
      params.data.rule.start_time = startTime;
      params.data.rule.end_time = endTime;
      params.data.rule.start_date = moment(params.data.rule.start_date)
        .format("YYYY-MM-DDTHH:mm:ss.sssZ")
        .slice(0, 10);
      params.data.rule.end_date = moment(params.data.rule.end_date)
        .format("YYYY-MM-DDTHH:mm:ss.sssZ")
        .slice(0, 10);
      params.data.rule.payment_options = [
        "cash",
        "card",
        "rentall",
        "cash_on_delivery",
        "card_on_delivery",
        "advance_payment",
        "banking_credit",
        "bank_transfer",
        "onetime_payment"
      ];
      setTimeout(function () {
        params.data.rule.rule_type = "promo_code";
      }, 500);
    } else if (params.data.rule.rule_type === "searcher") {
      params.data.rule.coupon_code = "";
      // under comment for purpose of MARP-650
      // params.data.rule.cart_amount = { value: null, operator: "" };
      if (params.data.rule.cart_amount && params.data.rule.cart_amount.value) {
        params.data.rule.combine_products = false;
      }
    }

    // These values needs to be set to null if they are not present when creating/editing price rule
    // Problem occurs when removing products/categories which existed, in that case we have value
    // of [] instead null, and that needs to be transformed to prevent issues on frontend part
    if (params.data.rule.category_ids && params.data.rule.category_ids.length === 0) {
      params.data.rule.category_ids = null;
    }

    if (params.data.rule.product_ids && params.data.rule.product_ids.length === 0) {
      params.data.rule.product_ids = null;
    }

    if (params.data.rule.parent_rules && params.data.rule.parent_rules.length === 0) {
      params.data.rule.parent_rules = null;
    }
  }

  if (params.data.rule.product_ids?.length > 1) {
    params.data.rule.product_ids = [...new Set(params.data.rule.product_ids)];
  }

  return handleSingleRequest(type, resource, {
    ...params
  }).then(({ data = [], included = [], ...rest }) => {
    return { data, ...rest };
  });
};
