import React from "react";
import { Filter, TextInput, ReferenceInput, AutocompleteInput, translate } from "react-admin";

const ProductFilters = ({ translate, ...rest }) => {
  return (
    <Filter variant="standard" {...rest}>
      <TextInput label={translate("resources.products.filters.name_ilike")} source="name_ilike" />
      <ReferenceInput
        label="resources.products.filters.category_name_ilike"
        source="category_id"
        reference="productCategories"
        allowEmpty
        filterToQuery={name => {
          if (name) {
            return { name_ilike: name };
          }
        }}
        perPage={10}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput
        label={translate("resources.products.filters.external_number_ilike")}
        source="external_number_ilike"
      />
    </Filter>
  );
};

export default translate(ProductFilters);
