import { handleSingleRequest } from "../restClient";

// Transformers
// GET_ONE, GET_MANY, GET_MANY_REFERENCE
export const transformHeadTags = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params
  }).then(({ data = [], included = [], relationships, ...rest }) => {
    if (data.length && data.length > 0) {
      data = data.map(item => {
        return {
          ...item,
          metadata_description: item.meta_data.description,
          metadata_keywords: item.meta_data.keywords,
          metadata_title: item.meta_data.title
        };
      });
    } else if (data && !Array.isArray(data)) {
      data.metadata_description = data.meta_data.description;
      data.metadata_keywords = data.meta_data.keywords;
      data.metadata_title = data.meta_data.title;
    }

    return { data, ...rest };
  });
};
