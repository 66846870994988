export const feedTypes = [
  { id: "text/csv", name: "Custom CSV Feed" },
  { id: "application/xml", name: "Custom XML Feed" },
  { id: "facebookCsv", name: "Facebook CSV Feed" },
  { id: "googleMCsv", name: "Google Merchant CSV Feed" },
  { id: "zbozi", name: "Zbozi XML Feed" }
];

export const feedFields = [
  { id: "id", name: "Product id" },
  { id: "external_number", name: "Product External Number" },
  { id: "feed_name", name: "Product Name" },
  { id: "feed_description", name: "Product Decription" },
  { id: "rrp_price", name: "Product Regular Price" },
  { id: "member_price", name: "Product Member Price" },
  { id: "brand", name: "Product Brend" },
  { id: "category_name", name: "Product Category" },
  { id: "facebook_category", name: "Product Facebook Category" },
  { id: "google_category", name: "Product Google Category" },
  { id: "image_url", name: "Product Image URL" },
  { id: "product_url", name: "Product Page URL" },
  { id: "in_stock", name: "Product Availability or Delivery Time" },
  { id: "condition", name: "Product Condition" }
];
