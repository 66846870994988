import React from "react";
import {
  List,
  TextField,
  DateField,
  ReferenceField,
  BooleanField,
  CreateButton,
  RefreshButton,
  Toolbar
} from "react-admin";
import ListFilters from "./Filters";
import CustomizableDatagrid from "../../components/CustomizableDatagrid";

const ruleStyle = {
  minHeight: "42px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff",
  padding: "0 5px"
};

const CreationToolbar = props => (
  <Toolbar
    {...props}
    style={{
      padding: "0px",
      backgroundColor: "transparent",
      justifyContent: "flex-end",
      width: "100%",
      marginTop: "0px"
    }}
  >
    <CreateButton
      label="Create"
      size="small"
      variant="outlined"
      color="primary"
      style={{ minHeight: 34 }}
    />
    <RefreshButton />
  </Toolbar>
);

const StatusField = props => {
  const {
    record: { enabled }
  } = props;

  return (
    <div style={{ ...ruleStyle, backgroundColor: enabled ? "green" : "red" }}>
      {enabled ? "Enabled" : "Disabled"}
    </div>
  );
};

const BenefitsList = props => {
  return (
    <List
      {...props}
      title="Benefits"
      perPage={10}
      bulkActionButtons={false}
      exporter={false}
      actions={<CreationToolbar />}
      filters={<ListFilters />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <CustomizableDatagrid
        defaultColumns={[
          "sort",
          "name",
          "created_at",
          "updated_at",
          "enabled",
          "start_date",
          "end_date",
          "one_time",
          "price_level",
          // "cart_quantity",
          "forms"
        ]}
        rowClick="edit"
      >
        <TextField source="sort" label="Order" />
        <TextField source="name" label="Name" />
        <DateField source="created_at" label="Created At" />
        <DateField source="updated_at" label="Updated At" />
        <StatusField source="enabled" label="Status" />
        <DateField source="start_date" label="Start Date" />
        <DateField source="end_date" label="End Date" />
        <BooleanField source="one_time" label="One Time" />
        <ReferenceField
          source="price_level_id"
          reference="priceLevels"
          label="Price Level"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        {/* <TextField source="cart_quantity" label="Cart Quantity" /> */}
        <TextField source="forms" label="Form Count" />
      </CustomizableDatagrid>
    </List>
  );
};

export default BenefitsList;
