import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  required,
  FormDataConsumer,
  BooleanInput,
  minLength,
  ArrayInput,
  SimpleFormIterator,
  NumberInput,
  regex,
  SelectArrayInput,
  ReferenceArrayInput,
  Toolbar,
  SaveButton,
  maxLength
} from "react-admin";
import { useForm } from "react-final-form";
import { emailFilter } from "../../utils/filters";
import { rolesValidation, userIdsValidation, userLevelsValidation } from "../../utils/validators";
import AutocompleteArrayInput from "../../components/CustomAutocompleteArrayInput";
// Icon for field User levels and Become member if not appropritate roles are selected.
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { schedulingType, appVersion, days, every } from "./ParameterConstants";
import { styles } from "../styles";
import PushNotificationDialog from "./PushNotificationDialog";
import ErrorIcon from "@material-ui/icons/Error";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

const validateRoles = [rolesValidation];
const validateUserIds = [userIdsValidation];
const validateUserLevels = [userLevelsValidation];

const PostCreateToolbar = props => {
  const form = useForm();

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        // Disable Active, Published && Notification Sent field(s) upon Cloning
        if (
          formData &&
          (formData.published === true ||
            formData.active === true ||
            formData.notification_sent === true)
        ) {
          form.change("published", false);
          form.change("active", false);
          form.change("notification_sent", false);
        }

        return (
          <Toolbar
            {...props}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start"
            }}
          >
            <SaveButton
              style={{ padding: "10px", borderRadius: "2.5px" }}
              label="resources.notifications.buttons.create"
              submitOnEnter={true}
              onClick={() => {
                form.change("published", false);
                form.change("active", false);
                form.change("notification_sent", false);
                form.change("created_at", new Date());
                form.change("updated_at", null);
              }}
              disabled={
                // Handle User field empty
                (formData && formData.user_id && formData.user_id.length === 0) ||
                // Handle Role field empty
                (formData && formData.role_id && formData.role_id.length === 0) ||
                // Handle if Affiliate, level field empty
                (formData &&
                  formData.role_id &&
                  formData.role_id.includes("40e505de-2451-11e8-b34c-37a6db76516b") &&
                  formData.level_id &&
                  formData.level_id.length === 0)
                  ? true
                  : false
              }
            />
          </Toolbar>
        );
      }}
    </FormDataConsumer>
  );
};

const NotificationType = ({ formData }) => {
  const form = useForm();

  return (
    <SelectInput
      label="resources.notifications.fields.scheduling_type"
      style={{ width: "256px", marginRight: "100%" }}
      validate={required()}
      onChange={() => form.change("schedule_cron", null)} // Wipe schedule_cron value when user selectes One Time Only push option
      choices={schedulingType}
      defaultValue={
        formData.schedule_cron && formData.schedule_cron.length !== ""
          ? schedulingType[1].id
          : !formData.schedule_at
          ? null
          : schedulingType[0].id
      }
      source="scheduling_type"
      variant="standard"
      onClick={() => {
        form.change("published", false);
        form.change("active", false);
        form.change("notification_sent", false);
        form.change("created_at", new Date());
        form.change("updated_at", null);
      }}
    />
  );
};

const RecurringSchedulingType = ({ formData }) => {
  const form = useForm();

  return (
    <ArrayInput
      onClick={() => {
        form.change("schedule_at", null);
      }}
      label="resources.notifications.fields.scheduling_cron"
      source="schedule_cron"
    >
      <SimpleFormIterator
        label="resources.notifications.fields.scheduling_information"
        disableAdd={formData.schedule_cron && formData.schedule_cron[0] ? true : false}
      >
        <DateTimeInput
          label="resources.notifications.fields.start_at"
          source="start_at"
          validate={required()}
          variant="standard"
        />
        <DateTimeInput
          label="resources.notifications.fields.end_at"
          source="end_at"
          validate={required()}
          variant="standard"
        />
        <SelectInput
          label="resources.notifications.fields.every"
          validate={required()}
          choices={every}
          source="every"
          variant="standard"
        />
        <SelectInput
          label="resources.notifications.fields.period"
          validate={required()}
          choices={days}
          source="period"
          variant="standard"
        />
        {formData.schedule_cron &&
        formData.schedule_cron[0] &&
        formData.schedule_cron[0].period &&
        formData.schedule_cron[0].period === "month" ? (
          <NumberInput
            label="resources.notifications.fields.period_month"
            source="period_opt"
            defaultValue={null}
            helperText={
              <TranslatableParagraph translationLabel="resources.notifications.fields.period_month" />
            }
            variant="standard"
          />
        ) : null}
        <TextInput
          validate={[
            maxLength(5),
            minLength(5),
            required(),
            regex(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
          ]}
          source="at"
          label="resources.notifications.fields.at"
          style={{ paddingTop: "15px" }}
          helperText={
            <TranslatableParagraph translationLabel="resources.notifications.fields.at_helper" />
          }
          variant="standard"
        />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

const SpecificUsersPromotion = ({ formData }) => {
  const form = useForm();

  return (
    <BooleanInput
      label="resources.notifications.fields.isSpecificUserPromotion"
      source="isSpecificUserPromotion"
      onChange={() => {
        !formData.isSpecificUserPromotion
          ? form.change("role_id", null)
          : form.change("user_id", null);
      }}
    />
  );
};

const RolesSelectionInput = ({ formData }) => {
  const form = useForm();

  return (
    <AutocompleteArrayInput
      label={
        formData.role_id && formData.role_id.length ? (
          <TranslatableParagraph
            customStyling={{ margin: "0px" }}
            translationLabel="resources.notifications.fields.selected_roles"
          />
        ) : (
          <TranslatableParagraph
            customStyling={{ margin: "0px" }}
            translationLabel="resources.notifications.fields.select_role"
          />
        )
      }
      optionText="name"
      source="role_id"
      validate={validateRoles}
      choices={[
        { id: "40e505de-2451-11e8-b34c-37a6db76516b", name: "Affiliate" },
        { id: "40d3ec7c-2451-11e8-b34b-df8ee6486630", name: "Member" },
        { id: "40c210ba-2451-11e8-b34a-ff279e1b43e6", name: "Registered" }
        // Guest option is opted out for Notification
      ]}
      onChange={() => {
        formData.role_id &&
        formData.role_id.includes("40e505de-2451-11e8-b34c-37a6db76516b") &&
        !formData.role_id.includes("40d3ec7c-2451-11e8-b34b-df8ee6486630") &&
        !formData.role_id.includes("40b098a8-2451-11e8-b349-6b57a44afd96") &&
        !formData.role_id.includes("40c210ba-2451-11e8-b34a-ff279e1b43e6")
          ? form.change("level_id", null)
          : console.log("User levels have been cleared");
      }}
      disabled={formData.isSpecificUserPromotion ? true : false}
      style={{ width: "256px" }}
      variant="standard"
    />
  );
};

export const NotificationsCreate = props => {
  const userOptionRenderer = props => {
    return `${props.email} ${props.external_id ? `(${props.external_id})` : ""}`;
  };

  return (
    <Create title="resources.notifications.title.create" {...props}>
      <SimpleForm variant="standard" toolbar={<PostCreateToolbar />}>
        <ReferenceInput
          source="category_id"
          reference="notificationCategories"
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput
          source="description"
          validate={[required(), maxLength(256), minLength(3)]}
          multiline
          resettable
          helperText={
            <TranslatableParagraph translationLabel="resources.notifications.helpers.helperValDescription" />
          }
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            let text;
            if (!formData.description) {
              text = "0/256";
              // Definately needs to be reformatted / too long
            } else if (formData.description && formData.description.length > 256) {
              text = (
                <TranslatableParagraph translationLabel="resources.notifications.helpers.helperValExceeded" />
              );
            } else {
              text = formData.description.length + "/256";
            }

            return (
              <div>
                {formData && formData.description === "" ? null : (
                  <p style={styles.textCounter}>{text}</p>
                )}
              </div>
            );
          }}
        </FormDataConsumer>
        <TextInput
          source="title"
          validate={[
            regex(/^\S(?!.*\s{2})[a-zA-Z  0-9]*$/),
            maxLength(40),
            minLength(3),
            required()
          ]}
          multiline
          resettable
          helperText={
            <TranslatableParagraph translationLabel="resources.notifications.helpers.helperValTitle" />
          }
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            let text;
            if (!formData.title) {
              text = "0/40";
              // Definately needs to be reformatted / too long
            } else if (
              formData.title.includes("@") ||
              formData.title.includes("#") ||
              formData.title.includes("$") ||
              formData.title.includes("%") ||
              formData.title.includes(":") ||
              formData.title.includes(";") ||
              formData.title.includes("&") ||
              formData.title.includes("!") ||
              formData.title.includes(".") ||
              formData.title.includes(",")
            ) {
              text = (
                <TranslatableParagraph translationLabel="resources.notifications.helpers.helperValForbid" />
              );
            } else {
              text = formData.title.length + "/40";
            }
            return (
              <div>
                {formData && formData.title === "" ? null : (
                  <p style={styles.textCounter}>{text}</p>
                )}
              </div>
            );
          }}
        </FormDataConsumer>
        <TextInput
          source="body"
          validate={[minLength(3), maxLength(40), required()]}
          multiline
          resettable
          helperText={
            <TranslatableParagraph translationLabel="resources.notifications.helpers.helperValBody" />
          }
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            let text;
            if (!formData.body) {
              text = "0/40";
            } else if (formData.body.length > 40) {
              text = (
                <TranslatableParagraph translationLabel="resources.notifications.helpers.helperValExceeded2" />
              );
            } else {
              text = formData.body.length + "/40";
            }
            return (
              <div>
                {formData && formData.body === "" ? null : <p style={styles.textCounter}>{text}</p>}
              </div>
            );
          }}
        </FormDataConsumer>
        <SelectInput
          label="resources.notifications.fields.mobile_application"
          choices={appVersion}
          source="mobile_app_version"
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <>
                {/* Find all mini components and set fixed width instead of adding inline styles, will be forgotten somewhere */}
                <NotificationType formData={formData} />

                {formData &&
                formData.scheduling_type &&
                formData.scheduling_type === "one_time_only" ? (
                  <DateTimeInput
                    label="resources.notifications.fields.scheduling_at"
                    source="schedule_at"
                    style={{ width: "256px" }}
                    validate={required()}
                    variant="standard"
                  />
                ) : formData.scheduling_type === "recurring" ? (
                  <RecurringSchedulingType formData={formData} />
                ) : null}
              </>
            );
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <div style={styles.productsCategories.wrapper}>
                <SpecificUsersPromotion formData={formData} />

                <ReferenceArrayInput
                  label={
                    formData && formData.user_id && formData.user_id.length ? (
                      <TranslatableParagraph
                        customStyling={{ margin: "0px" }}
                        translationLabel="resources.notifications.fields.select_users"
                      />
                    ) : (
                      <TranslatableParagraph
                        customStyling={{ margin: "0px" }}
                        translationLabel="resources.notifications.fields.search_users"
                      />
                    )
                  }
                  validate={validateUserIds}
                  source="user_id"
                  reference="users"
                  filterToQuery={emailFilter}
                >
                  <AutocompleteArrayInput
                    disabled={!formData.isSpecificUserPromotion}
                    optionText={userOptionRenderer}
                  />
                </ReferenceArrayInput>

                <RolesSelectionInput formData={formData} />

                <ReferenceArrayInput
                  label={
                    formData &&
                    formData.role_id &&
                    formData.role_id.includes("40e505de-2451-11e8-b34c-37a6db76516b") &&
                    !formData.role_id.includes("40d3ec7c-2451-11e8-b34b-df8ee6486630") &&
                    !formData.role_id.includes("40b098a8-2451-11e8-b349-6b57a44afd96") &&
                    !formData.role_id.includes("40c210ba-2451-11e8-b34a-ff279e1b43e6")
                      ? "resources.users.fields.user_level_id"
                      : "resources.priceRules.customLabels"
                  }
                  source="level_id"
                  reference="userLevels"
                  sort={{ field: "name", order: "ASC" }}
                  allowEmpty={true}
                  validate={validateUserLevels}
                >
                  {formData &&
                  formData.role_id &&
                  formData.role_id.includes("40e505de-2451-11e8-b34c-37a6db76516b") &&
                  !formData.role_id.includes("40d3ec7c-2451-11e8-b34b-df8ee6486630") &&
                  !formData.role_id.includes("40b098a8-2451-11e8-b349-6b57a44afd96") &&
                  !formData.role_id.includes("40c210ba-2451-11e8-b34a-ff279e1b43e6") ? (
                    <SelectArrayInput
                      optionText="name"
                      options={{ disabled: false }}
                      style={{ width: "256px" }}
                      variant="standard"
                    />
                  ) : (
                    ((formData.level_id = null),
                    (
                      <>
                        <div
                          style={{
                            ...styles.rolesContainer,
                            backgroundColor: "#ebedee",
                            borderRadius: `4px`,
                            margin: `15px 0px`,
                            boxShadow: "0px 0px 10px #ebedee",
                            width: "256px",
                            padding: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                          }}
                        >
                          <SupervisorAccountIcon
                            style={{
                              verticalAlign: `middle`,
                              marginRight: "5px"
                            }}
                          />
                          <TranslatableParagraph
                            customStyling={{ fontSize: "14px" }}
                            translationLabel="resources.notifications.fields.testing"
                          />
                        </div>
                      </>
                    ))
                  )}
                </ReferenceArrayInput>
                {(formData && formData.user_id && formData.user_id.length === 0) ||
                // Handle Role field empty
                (formData && formData.role_id && formData.role_id.length === 0) ||
                // Handle if Affiliate, level field empty
                (formData &&
                  formData.role_id &&
                  formData.role_id.includes("40e505de-2451-11e8-b34c-37a6db76516b") &&
                  formData.level_id &&
                  formData.level_id.length === 0) ? (
                  <div style={styles.warningContainer}>
                    <TranslatableParagraph translationLabel="resources.notifications.fields.error_label" />
                    <ErrorIcon style={styles.warningIcon} />
                  </div>
                ) : null}
              </div>
            );
          }}
        </FormDataConsumer>

        <BooleanInput
          label="resources.notifications.fields.override_consent"
          source="override_consent"
          defaultValue={false}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <>
                {formData.override_consent === true ? (
                  <PushNotificationDialog
                    override_consent={formData.override_consent}
                    popupDialog={
                      <TranslatableParagraph translationLabel="resources.notifications.popup_dialog.message" />
                    }
                  />
                ) : null}
              </>
            );
          }}
        </FormDataConsumer>

        <BooleanInput
          label="resources.notifications.fields.published"
          source="published"
          options={{ disabled: true }}
        />
        <BooleanInput
          label="resources.notifications.fields.active"
          source="active"
          options={{ disabled: true }}
        />
      </SimpleForm>
    </Create>
  );
};
