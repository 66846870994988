import { handleSingleRequest } from "../restClient";
import { find } from "lodash";
import { UPDATE } from "../types";
import moment from "moment";
// import { CREATE, GET_ONE } from "../types";

export const transformShops = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params,
    include: ["productCategories"]
  }).then(({ data = [], included = [], relationships, ...rest }) => {
    if (typeof data.map === "function") {
      // Assign product ids to tags
      data.map(record => {
        record.categoryIds = find(relationships, {
          id: record.id
        }).productCategories.data.map(item => item.id);

        return record;
      });
    } else {
      data.categoryIds = included
        .filter(record => record.type === "productCategories")
        .map(productCategory => productCategory.id);
    }

    return { data, ...rest };
  });
};

export const transformShopsCreate = (type, resource, params) => {
  if (params.data.categoryIds) {
    params.relationships = {
      ...params.relationships,
      productCategories: {
        data: params.data.categoryIds.map(id => ({
          type: "productCategories",
          id
        }))
      }
    };
  }
  if (params.data.end_date) {
    params.data.end_date = moment(params.data.end_date).format();
  } else {
    params.data.end_date = null;
  }

  if (params.data.start_date) {
    params.data.start_date = moment(params.data.start_date).format();
  } else {
    params.data.start_date = null;
  }

  //UPDATE
  if (params.data.id) {
    return handleSingleRequest(type, resource, {
      ...params,
      id: params.data.id
    }).then(({ data = [], included = [], relationships, ...rest }) => {
      return { data, ...rest };
    });
  }

  //CREATE
  return handleSingleRequest(type, resource, {
    ...params
  }).then(({ data = [], included = [], relationships, ...rest }) => {
    if (params.data.categoryIds) {
      const id = data.id;
      return handleSingleRequest(UPDATE, resource, {
        ...params,
        id
      }).then(({ data = [], included = [], relationships, ...rest }) => {
        return { data, ...rest };
      });
    }
    return { data, ...rest };
  });
};
