import React from "react";
import { Link } from "react-router-dom";
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
  SaveButton,
  DeleteButton
} from "react-admin";
import Button from "@material-ui/core/Button";

// import { bestSellingProductsFilter } from "../../utils/filters";

import { handleSingleRequest } from "../../services/api/restClient";
import { GET_LIST } from "../../services/api/types";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

// * Variable declaraction for later use | #vardecs
let existingBestSellingProducts = [];

// * Read product_id field and showcase logic depending on input value | $showCaseLog
const bestSellingProductValidator = (value, formValues) => {
  if (value) {
    if (formValues.product_id) {
      let selectedProduct = existingBestSellingProducts.find(
        product => product.product_id === formValues.product_id
      );

      if (selectedProduct && value === selectedProduct.product_id) {
        return (
          <TranslatableParagraph
            textOnly
            translationLabel="resources.bestSellers.errors.sameProduct"
          />
        );
      } else {
        return undefined;
      }
    } else {
      return <TranslatableParagraph textOnly translationLabel="ra.validation.required" />;
    }
  } else {
    return <TranslatableParagraph textOnly translationLabel="ra.validation.required" />;
  }
};

const PostEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton redirect={"/bestSellingProducts"} />
    <DeleteButton
      style={{ marginLeft: "1rem", backgroundColor: "crimson", color: "#f9f9f9", padding: "8px" }}
    />
    {/* Additional link back to the List | Ease of access */}
    <Link style={{ textDecoration: "none", marginLeft: "1rem" }} to="/bestSellingProducts">
      <Button size="medium" variant="outlined" color="primary">
        Back to List
      </Button>
    </Link>
  </Toolbar>
);

class BestSellingEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      triggerHelper: true,
      existingBestSellingProducts: false
    };
  }

  componentDidMount() {
    handleSingleRequest(GET_LIST, "bestSellingProducts", {
      pagination: {
        page: "all"
      },
      sort: { field: "created_at", order: "DESC" },
      filter: {}
    })
      .then(({ data }) => {
        existingBestSellingProducts = data;
      })
      .catch(error => console.log(error));
  }

  componentWillUnmount() {
    existingBestSellingProducts = [];
  }

  render() {
    const EditTitle = ({ record }) => {
      return (
        <TranslatableParagraph
          translationLabel="resources.bestSellers.title.edit"
          customLabel={
            record && record.product_id ? (
              record.product_id + " (Product ID)"
            ) : (
              <TranslatableParagraph
                textOnly
                translationLabel="resources.bestSellers.customFields.nameLess"
              />
            )
          }
        />
      );
    };
    return (
      <Edit {...this.props} title={<EditTitle />}>
        <SimpleForm variant="standard" toolbar={<PostEditToolbar />}>
          <ReferenceInput
            label="resources.priceRules.fields.rule.product_ids"
            source="product_id"
            reference="products"
            sort={{ field: "has_content", order: "ASC" }}
            filterToQuery={sku => {
              if (sku) {
                return {
                  external_number_ilike: sku,
                  has_content: true,
                  is_visible_in_shop: true
                };
              } else {
                return {
                  has_content: true,
                  is_visible_in_shop: true
                };
              }
            }}
            validate={bestSellingProductValidator}
            defaultValue=""
          >
            <AutocompleteInput optionText="external_number" />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    );
  }
}

export default BestSellingEdit;
