import React from "react";
import { Show, TabbedShowLayout, Tab, ArrayField, Datagrid, TextField } from "react-admin";

const FooterShowTitle = ({ record }) => `Footer ${record.column_title}`;

export const FooterShow = props => (
  <Show title={<FooterShowTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="Column content">
        <TextField source="column_title" />
        <TextField source="column_order" />
        <TextField source="language.name" />
        <ArrayField source="text_row">
          <Datagrid>
            <TextField source="order" />
            <TextField source="label" />
            <TextField source="text" />
            <TextField source="has_url" />
            <TextField source="text_url" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="Other translations">
        <ArrayField source="translations">
          <Datagrid>
            <TextField source="column_title" />
            <TextField source="language.name" />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
