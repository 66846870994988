import move from "lodash-move";
import { findIndex } from "lodash";

const flatten = (root, key) => {
  let flattened = [Object.assign({}, root)];
  delete flattened[0][key];

  if (root[key] && root[key].length > 0) {
    return flattened.concat(
      root[key].map(child => flatten(child, key)).reduce((a, b) => a.concat(b), [])
    );
  }

  return flattened;
};

const findNode = (tree, id, node) => {
  var result;

  if (!node) {
    node = tree[0];
  }

  if (node.id === id) {
    result = node;
  } else if (Array.isArray(node.children) && node.children.length > 0) {
    for (let i = 0; i < node.children.length; i++) {
      result = findNode(tree, id, node.children[i]);

      if (result) break;
    }
    return result;
  }

  return result;
};

const findNodeParent = (tree, id, node) => {
  var result;

  if (!node) {
    node = tree[0];
  }

  if (id === 1) {
    return null;
  }

  if (Array.isArray(node.children) && node.children.length > 0) {
    for (let i = 0; i < node.children.length; i++) {
      if (node.children[i].id === id) {
        result = node;
        break;
      }
    }

    if (!result) {
      for (let i = 0; i < node.children.length; i++) {
        result = findNodeParent(tree, id, node.children[i]);

        if (result) break;
      }
    }
  }

  return result;
};

const addNode = (tree, parentId, childId) => {
  let parentNode = findNode(tree, parentId);

  if (!parentNode) {
    throw new Error("Couldn't find parent!");
  } else {
    parentNode.children = [...parentNode.children, { id: childId, children: [] }];
  }

  return tree;
};

const addNodes = (tree, parentId, childId, additionalChildrenNodes) => {
  let parentNode = findNode(tree, parentId);

  let children = [];

  for (let i = 1; i <= additionalChildrenNodes; i++) {
    children.push({ id: childId + i, children: [] });
  }

  if (!parentNode) {
    throw new Error("Couldn't find parent!");
  } else {
    parentNode.children = [
      ...parentNode.children,
      {
        id: childId,
        children
      }
    ];
  }

  return tree;
};

const deleteNode = (tree, parentId, nodeId) => {
  let treeParent = findNode(tree, parentId);

  if (!treeParent) {
    throw new Error("Couldn't find parent!");
  }

  treeParent.children = treeParent.children.filter(child => child.id !== nodeId);

  return tree;
};

const nodeOrder = (tree, parentId, nodeId, newOrderIndex) => {
  const treeParent = findNode(tree, parentId);
  const orderIndex = findIndex(treeParent.children, ["id", nodeId]);

  treeParent.children = move(treeParent.children, orderIndex, newOrderIndex);

  return tree;
};

export { flatten, findNode, findNodeParent, addNode, addNodes, deleteNode, nodeOrder };
