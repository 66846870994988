import React, { Component, Fragment } from "react";
import { handleSingleRequest } from "../../services/api/restClient";
import { GET_LIST } from "../../services/api/types";
import { feature } from "../../components/Feature";
import moment from "moment";
import { formatDate } from "../../utils/formatters/formatDate";

// PDF section component
export const PdfSectionName = props => {
  return (
    <p
      style={{
        textTransform: "uppercase",
        borderBottom: "1px solid black",
        fontWeight: "bold",
        margin: "2rem 0px"
      }}
    >
      {props.sectionName}
    </p>
  );
};

const loadCountryRelevantDetails = () => {
  // Get current country name
  const countryAppId = localStorage.getItem("appId");
  const countryIsoCode = JSON.parse(localStorage.getItem("countries")).find(
    country => country.application_id === countryAppId
  ).iso_code_2;

  const countryInternationalName = JSON.parse(localStorage.getItem("countries")).find(
    country => country.application_id === countryAppId
  ).international_name;

  return {
    countryAppId: countryAppId,
    countryIsoCode: countryIsoCode,
    countryInternationalName: countryInternationalName
  };
};

export default class PrintAdvancedItem extends Component {
  state = {
    orderItems: [],
    products: [],
    rentalAndCity: [],
    rentalAndCityDetails: [],
    shipments: [],
    shipmentsIncluded: [],
    isLoaded: false
  };

  componentDidMount() {
    handleSingleRequest(GET_LIST, "orderItems", {
      pagination: { page: "all" },
      sort: { field: "id", order: "DESC" },
      filter: { order_id: this.props.order.id },
      include: ["product"]
    }).then(({ data, included }) => {
      this.setState({ orderItems: data, products: included });
    });

    handleSingleRequest(GET_LIST, "orders", {
      pagination: { page: "all" },
      sort: { field: "id", order: "DESC" },
      filter: { id: this.props.order.id },
      include: ["payments.advancePayments", "city"]
    }).then(({ data, included }) => {
      this.setState({ rentalAndCity: data, rentalAndCityDetails: included, isLoaded: true });
    });

    handleSingleRequest(GET_LIST, "shipments", {
      pagination: { page: "all" },
      sort: { field: "id", order: "DESC" },
      filter: { order_id: this.props.order.id },
      include: ["city"]
    }).then(({ data, included }) => {
      this.setState({ shipments: data, shipmentsIncluded: included });
    });
  }

  render() {
    const { countryIsoCode, countryInternationalName } = loadCountryRelevantDetails();
    const { order } = this.props;
    // Grab pickupOffice data for the first and only item in the list
    const pickupOffice = this.state.shipments[0];
    // Information coming from order includes
    const [city, payment, rentalOrInstallment] = this.state.rentalAndCityDetails;
    // Information coming from shipments includes
    const [shipmentCity] = this.state.shipmentsIncluded;
    let upperCasedIso = countryIsoCode.toUpperCase();
    // Currency formatter
    let currencyTransformer = Intl.NumberFormat(`${countryIsoCode}-${upperCasedIso}`, {
      style: "currency",
      currency: order.currency_code
    });

    return (
      <Fragment className="advancedPrintView">
        {this.state.isLoaded ? (
          <>
            <h3
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                border: "1px solid black",
                borderTop: "3px solid black",
                textAlign: "center",
                padding: "5px",
                marginTop: "1rem"
              }}
            >
              Information for: {order.order_number}
            </h3>
            <PdfSectionName sectionName="Buyer Information" />
            <table style={{ width: "100%", minWidth: "450px" }}>
              <thead>
                <tr>
                  <th colSpan={1} align="left">
                    Order number
                  </th>
                  <th colSpan={2} align="left">
                    Customer name
                  </th>
                  <th colSpan={2} align="left">
                    Phone
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={1} align="left">
                    {order && order.order_number ? order.order_number : " - "}
                  </td>
                  <td colSpan={1}>
                    {order && order.first_name && order.last_name
                      ? order.first_name + " " + order.last_name
                      : order && order.first_name
                      ? order.first_name
                      : order && order.last_name
                      ? order.last_name
                      : " - "}
                  </td>
                  <td colSpan={2} align="left">
                    {order && order.phone ? order.phone : " - "}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* Payment Information PDF */}
            <PdfSectionName sectionName="Payment Information" />
            <table style={{ width: "100%", minWidth: "450px", textAlign: "left" }}>
              <thead>
                <tr>
                  <th colSpan={6}>Payment Currency</th>
                  <th colSpan={6}>Payment Amount</th>
                  <th colSpan={6}>Payment Method</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ borderBottom: "1px solid" }}>
                  <td colSpan={6}>{order && order.currency_code ? order.currency_code : " - "}</td>
                  <td colSpan={6}>
                    {order && order.payment && order.payment.amount
                      ? currencyTransformer.format(order.payment.amount)
                      : " - "}
                  </td>
                  <td colSpan={6}>
                    {order && order.paymentMethod && order.paymentMethod.name
                      ? order.paymentMethod.name
                      : " - "}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* Rental payment information */}
            {rentalOrInstallment ? (
              <>
                {/* Decide whether or not is it Rental or Installment payment */}
                <PdfSectionName
                  sectionName={
                    rentalOrInstallment && order.paymentMethod.name === "Cash"
                      ? `Installments Information`
                      : "Rental Information"
                  }
                />
                <table style={{ width: "100%", minWidth: "450px", textAlign: "left" }}>
                  <thead>
                    <tr>
                      <th colSpan={1}>Payment months</th>
                      <th colSpan={1}>Payment day in month</th>
                      <th colSpan={1}>First installment</th>
                      <th colSpan={1}>Remaining installment</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={1}>
                        {rentalOrInstallment && rentalOrInstallment.month_numbers
                          ? rentalOrInstallment.month_numbers
                          : " - "}
                      </td>
                      <td colSpan={1}>
                        {rentalOrInstallment &&
                        rentalOrInstallment.payment_day_in_month_of_installments
                          ? rentalOrInstallment.payment_day_in_month_of_installments
                          : " - "}
                      </td>
                      <td colSpan={1}>
                        {rentalOrInstallment && rentalOrInstallment.first_instalment
                          ? currencyTransformer.format(rentalOrInstallment.first_instalment)
                          : " - "}
                      </td>
                      <td colSpan={1}>
                        {rentalOrInstallment && rentalOrInstallment.remaining_instalment
                          ? currencyTransformer.format(rentalOrInstallment.remaining_instalment)
                          : " - "}
                      </td>
                    </tr>
                    <tr>
                      <th colSpan={1}>First instalment date</th>
                      <th colSpan={1}>Last instalment date</th>
                      <th colSpan={1}>Delivery date</th>
                    </tr>
                    <tr>
                      <td colSpan={1}>
                        {rentalOrInstallment && rentalOrInstallment.first_instalment_at
                          ? moment(rentalOrInstallment.first_instalment_at).format("MM/DD/YYYY")
                          : " - "}
                      </td>
                      <td colSpan={1}>
                        {rentalOrInstallment && rentalOrInstallment.last_instalment_at
                          ? moment(rentalOrInstallment.last_instalment_at).format("MM/DD/YYYY")
                          : " - "}
                      </td>

                      <td colSpan={1}>
                        {rentalOrInstallment && rentalOrInstallment.delivery_date
                          ? moment(rentalOrInstallment.delivery_date).format("MM/DD/YYYY")
                          : " - "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : null}
            {/* Shipping Information PDF  */}
            <PdfSectionName
              sectionName={
                pickupOffice && pickupOffice.warehouse_id === null
                  ? "Shipping Information"
                  : "Pickup Office Information"
              }
            />
            <table style={{ width: "100%", minWidth: "450px", textAlign: "left" }}>
              <thead>
                <tr>
                  <th colSpan={1}>
                    {pickupOffice && pickupOffice.warehouse_id === null
                      ? "Shipping Address"
                      : "Pickup Office Address"}
                  </th>
                  <th colSpan={1}>
                    {pickupOffice && pickupOffice.warehouse_id === null
                      ? "Shipping City"
                      : "Pickup Office City"}
                  </th>
                  <th colSpan={1}>
                    {pickupOffice && pickupOffice.warehouse_id === null
                      ? "Shipping Country"
                      : "Pickup Office Country"}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={1}>
                    {pickupOffice && pickupOffice.warehouse_id
                      ? pickupOffice.address_line1
                      : order && order.address_line1
                      ? order.address_line1
                      : " - "}
                  </td>
                  <td colSpan={1}>
                    {pickupOffice && pickupOffice.warehouse_id !== undefined && shipmentCity
                      ? shipmentCity.name
                      : city && city.name
                      ? city.name
                      : " - "}
                  </td>
                  <td colSpan={1}>{countryInternationalName ? countryInternationalName : " - "}</td>
                </tr>
              </tbody>
            </table>
            <PdfSectionName sectionName="Products in the order" />
            <table border={1} style={{ width: "100%", minWidth: "450px", textAlign: "left" }}>
              <thead>
                <tr>
                  <th colSpan={1}>Created at</th>
                  <th colSpan={1}>External number</th>
                  <th colSpan={1}>Product name</th>
                  <th colSpan={1}>Order quantity</th>
                  {feature("priceRulesDiscountCodeParameter") ? (
                    <th colSpan={1}>Discount code</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {this.state.orderItems.map(orderItem => {
                  const product = this.state.products.find(
                    product => product.id === orderItem.product_id
                  );

                  return (
                    <tr key={orderItem.id}>
                      <td colSpan={1}>{formatDate(order.created_at)}</td>
                      <td colSpan={1}>{product.external_number}</td>
                      <td colSpan={1}>{product.name}</td>
                      <td colSpan={1}>{orderItem.quantity}</td>
                      {feature("priceRulesDiscountCodeParameter") ? (
                        <td colSpan={1}>{orderItem.coupon_code}</td>
                      ) : null}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <p>{`Order ${order.order_number} is loading, please wait..`}</p>
        )}
      </Fragment>
    );
  }
}
