import React, { useState, useEffect } from "react";
import {
  TextField,
  Show,
  DateField,
  BooleanField,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  ShowController
} from "react-admin";
import moment from "moment";
import { handleSingleRequest } from "../../services/api/restClient";
import { GET_LIST } from "../../services/api/types";

const UserShowTitle = ({ record }) => `User ${record.readable_name}`;

const styles = {
  ruleContainer: {
    display: "flex",
    gap: "10px",
    flexWrap: "wrap",
    maxWidth: "800px",
    justifyContent: "flex-start",
    alignItems: "stretch"
  },
  rule: {
    padding: "5px",
    fontSize: 12,
    minWidth: "80px",
    minHeight: "30px",
    borderRadius: "5px",
    boxShadow: "5px 5px 10px rgba(19, 22, 22, .15)",
    cursor: "pointer",
    color: "#f2f2f2",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  ruleLegend: {
    minWidth: "fit-content",
    display: "flex",
    gap: "10px",
    margin: "1rem 0rem",
    alignItems: "center",
    fontSize: "12px"
  },
  bold: {
    fontWeight: "600",
    textDecoration: "underline"
  },
  inactiveTab: {
    backgroundColor: "rgba(244, 67, 54, 0.50)"
  },
  activeTab: {
    backgroundColor: "rgba(52, 152, 219, 0.50)"
  },
  futureTab: {
    backgroundColor: "rgba(255, 140, 0, 0.50)"
  },
  legend: {
    width: "15px",
    height: "15px",
    borderRadius: "5px"
  }
};

export const UserShow = props => {
  const countryAppId = localStorage.getItem("appId");
  const countryName = JSON.parse(localStorage.getItem("countries")).find(
    country => country.application_id === countryAppId
  ).international_name;
  const isCountryCzechiaOrSlovakia = countryName === "Czechia" || countryName === "Slovakia";
  const isCountryInternational = countryName === "International";
  const isCountryKazakhstan = countryName === "Kazakhstan";

  const [foundPriceRules, setFoundPriceRules] = useState([]);
  const [userLevels, setUserLevels] = useState([]);

  useEffect(() => {
    handleSingleRequest(GET_LIST, "priceRules", {
      pagination: {
        page: "all"
      },
      sort: {},
      filter: {}
    })
      .then(({ data }) => {
        setFoundPriceRules(data);
      })
      .catch(error => console.log(error));

    handleSingleRequest(GET_LIST, "userLevels", {
      pagination: {
        page: "all"
      },
      sort: {},
      filter: {}
    })
      .then(({ data }) => {
        setUserLevels(data);
      })
      .catch(error => console.log(error));
  }, []);

  return (
    <ShowController {...props}>
      {controllerProps => {
        let currentHref = window?.location?.href;
        let nonSSL = "http://";
        let priceRulesEnv;
        let countryPrefixFromURL;

        if (currentHref.includes("-dev")) {
          countryPrefixFromURL = currentHref.slice(
            currentHref.indexOf("-") + 5,
            currentHref.indexOf(".")
          );
        } else {
          countryPrefixFromURL = currentHref.slice(
            currentHref.indexOf("-") + 1,
            currentHref.indexOf(".")
          );
        }

        if (
          currentHref.includes(countryPrefixFromURL) ||
          currentHref.includes("admin.zepter.app")
        ) {
          priceRulesEnv = currentHref.replace(`/users/${controllerProps?.record?.id}/show/3`, "");
        } else if (countryPrefixFromURL.includes("localhost:3000")) {
          priceRulesEnv = `${nonSSL}localhost:3000/#`;
        } else if (countryPrefixFromURL.includes("localhost:3001")) {
          priceRulesEnv = `${nonSSL}localhost:3001/#`;
        }

        let currentlyViewedUserId = controllerProps?.record?.id;
        let currentlyViewedUserLevel = userLevels.find(
          element => element.id === controllerProps?.record?.user_level_id
        )?.parent_level_id;
        let currentlyViewedUserRole = userLevels.find(
          element => element.id === controllerProps?.record?.user_level_id
        )?.user_role_id;

        const ShowcaseFoundPriceRules = () => {
          // Format current local time to a price rule date for later comparison
          const currentTime = moment().format("YYYY-MM-DD");
          let foundRules = [];

          foundPriceRules.map(singlePriceRule => {
            let userListIds = singlePriceRule?.rule?.user_ids;
            let userListRoleIds = singlePriceRule?.rule?.user_role_ids;

            let ruleCategory;
            // 0 - Future | 1 - Active | 2 - Inactive
            if (
              singlePriceRule?.rule?.start_date > currentTime &&
              singlePriceRule?.rule?.end_date > currentTime
            ) {
              ruleCategory = "0";
            } else if (
              singlePriceRule?.rule?.start_date <= currentTime &&
              singlePriceRule?.rule?.end_date >= currentTime
            ) {
              ruleCategory = "1";
            } else {
              ruleCategory = "2";
            }

            userListIds?.forEach(userId => {
              if (userId === currentlyViewedUserId) {
                foundRules.push({
                  id: singlePriceRule?.id,
                  name: singlePriceRule?.rule?.name,
                  expires: singlePriceRule?.rule?.end_date,
                  starting: singlePriceRule?.rule?.start_date,
                  category: ruleCategory
                });
              }
            });

            userListRoleIds?.forEach(userRole => {
              if (userRole === currentlyViewedUserRole) {
                foundRules.push({
                  id: singlePriceRule?.id,
                  name: singlePriceRule?.rule?.name,
                  expires: singlePriceRule?.rule?.end_date,
                  starting: singlePriceRule?.rule?.start_date,
                  category: ruleCategory
                });
              }
            });
          });

          const ids = foundRules.map(o => o.id);
          const filtered = foundRules.filter(({ id }, index) => !ids.includes(id, index + 1));
          // Filter order of the rules to be active to expired
          filtered.sort(function (a, b) {
            return a.category - b.category;
          });

          return filtered.map(foundRule => {
            return (
              <a
                style={{
                  ...styles.rule,
                  backgroundColor:
                    foundRule?.starting > currentTime && foundRule?.expires > currentTime
                      ? "rgba(255, 140, 0, 0.12)"
                      : foundRule?.starting <= currentTime && foundRule?.expires >= currentTime
                      ? "rgba(52, 152, 219, 0.12)"
                      : "rgba(244, 67, 54, 0.12)",
                  boxShadow: "rgb(235 237 238) 0px 0px 10px",
                  color:
                    foundRule?.starting > currentTime && foundRule?.expires > currentTime
                      ? "#ffa500"
                      : foundRule?.starting < currentTime && foundRule?.expires < currentTime
                      ? "#f44336"
                      : "rgb(52,152,219)"
                }}
                href={`${priceRulesEnv}/priceRules/${foundRule?.id}`}
              >
                <strong>{foundRule?.name}</strong>
              </a>
            );
          });
        };
        const readableName = controllerProps?.record?.readable_name;
        const readableEmail = controllerProps?.record?.email;

        return (
          <Show title={<UserShowTitle />} {...props} {...controllerProps}>
            <TabbedShowLayout>
              <Tab label="resources.users.fields.personalInfoTab">
                <TextField source="id" />
                <TextField source="rid" />
                <TextField label="resources.users.fields.first_name" source="first_name" />
                <TextField label="resources.users.fields.last_name" source="last_name" />
                <TextField label="resources.users.fields.company_name" source="company_name" />
                <TextField
                  source="tax_id"
                  label={
                    isCountryCzechiaOrSlovakia
                      ? "DIČ"
                      : isCountryKazakhstan
                      ? "ИИН"
                      : "resources.users.fields.tax"
                  }
                />
                {isCountryCzechiaOrSlovakia && (
                  <TextField source="company_registration_number" label="IČO" />
                )}
                <TextField label="resources.users.fields.username" source="username" />
                <TextField label="resources.users.fields.email" source="email" />
                <DateField label="resources.users.fields.birth_date" source="birth_date" />
                <TextField label="resources.users.fields.middle_name" source="middle_name" />
                <TextField label="resources.users.fields.phone" source="phone" />
                <TextField label="resources.users.fields.readable_name" source="readable_name" />
                <TextField
                  label="resources.users.fields.patronimic_name"
                  source="patronimic_name"
                />
                <TextField label="resources.users.fields.timezone" source="timezone" />
                <TextField label="resources.users.fields.language" source="language" />
                <TextField
                  label="resources.users.fields.userAddressLines"
                  source="userAddressLines"
                />
                <ReferenceField
                  label="resources.users.fields.defaultAddress.city_id"
                  source="defaultAddress.city_id"
                  reference="cities"
                  link={false}
                  sortable={false}
                  allowEmpty
                >
                  <TextField source="name" />
                </ReferenceField>
                <TextField
                  label="resources.users.fields.defaultAddress.zip_code"
                  source="defaultAddress.zip_code"
                />
                <TextField
                  label="resources.users.fields.stateOrProvince"
                  source="stateOrProvince"
                />
                <ReferenceField
                  label="resources.users.fields.country"
                  source="country_id"
                  reference={isCountryInternational ? "shippingCountries" : "countries"}
                  link={false}
                >
                  <TextField source="international_name" />
                </ReferenceField>
              </Tab>
              <Tab label="resources.users.fields.detailsTab">
                <DateField label="resources.users.fields.created_at" source="created_at" />
                <DateField label="resources.users.fields.updated_at" source="updated_at" />
                <BooleanField label="resources.users.fields.adult_consent" source="adult_consent" />
                <BooleanField
                  label="resources.users.fields.tutorial_viewed"
                  source="tutorial_viewed"
                />
                <BooleanField label="resources.users.fields.is_club_live" source="is_club_live" />
                <ReferenceField
                  label="resources.users.fields.user_level_id"
                  source="user_level_id"
                  reference="userLevels"
                  link={false}
                >
                  <TextField source="name" />
                </ReferenceField>
                <BooleanField label="resources.users.fields.is_active" source="is_active" />
                <ReferenceField
                  label="resources.users.fields.price_level_id"
                  source="price_level_id"
                  reference="priceLevels"
                  link={false}
                >
                  <TextField source="name" />
                </ReferenceField>
                <ReferenceArrayField
                  label="resources.users.fields.sellingPriceLevels"
                  source="sellingPriceLevels"
                  reference="priceLevels"
                >
                  <SingleFieldList>
                    <ChipField source="name" />
                  </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceField
                  label="resources.users.fields.user_type_id"
                  source="user_type_id"
                  reference="userTypes"
                  link={false}
                >
                  <TextField source="name" />
                </ReferenceField>
                <TextField label="resources.users.fields.external_id" source="external_id" />
                <TextField
                  label="resources.users.fields.source_application"
                  source="source_application"
                />
                <TextField label="resources.users.fields.device_id" source="device_id" />
                <ReferenceField
                  label="resources.users.fields.country"
                  source="country_id"
                  reference={isCountryInternational ? "shippingCountries" : "countries"}
                  link={false}
                >
                  <TextField source="international_name" />
                </ReferenceField>
                <TextField
                  label="resources.users.fields.referral_number"
                  source="referral_number"
                />
                <TextField label="resources.users.fields.last_update_by" source="last_update_by" />
              </Tab>
              <Tab label="resources.users.fields.affiliateTab">
                <TextField
                  label="resources.users.fields.affiliateData.affiliate_path"
                  source="affiliateData.affilate_path"
                />
                <TextField
                  label="resources.users.fields.affiliateData.turn_over_currency_code"
                  source="affiliateData.turn_over_currency_code"
                />
                <TextField
                  label="resources.users.fields.affiliateData.turn_over_amount_group"
                  source="affiliateData.turn_over_amount_group"
                />
                <TextField
                  label="resources.users.fields.affiliateData.turn_over_amount_personal"
                  source="affiliateData.turn_over_amount_personal"
                />
                <TextField
                  label="resources.users.fields.affiliateData.turn_over_min_rel_level"
                  source="affiliateData.turn_over_min_rel_level"
                />
                <DateField
                  label="resources.users.fields.affiliateData.start_date"
                  source="affiliateData.start_date"
                />
                <DateField
                  label="resources.users.fields.affiliateData.created_at"
                  source="affiliateData.created_at"
                />
                <DateField
                  label="resources.users.fields.affiliateData.updated_at"
                  source="affiliateData.updated_at"
                />
                <DateField
                  label="resources.users.fields.affiliateData.deleted_at"
                  source="affiliateData.deleted_at"
                />
                <TextField
                  label="resources.users.fields.affiliateData.external_id"
                  source="affiliateData.external_id"
                />
              </Tab>

              {ShowcaseFoundPriceRules()?.length ? (
                <Tab label="Price Rules Affiliation">
                  <p style={{ fontSize: "12px" }}>
                    All price rules in which <span style={styles.bold}>{readableName}</span> has
                    been selected either by email, role or user level.
                  </p>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div style={styles.ruleLegend}>
                      <span style={{ ...styles.futureTab, ...styles.legend }}></span>
                      <span>Future rules</span>
                    </div>
                    <div style={styles.ruleLegend}>
                      <span style={{ ...styles.activeTab, ...styles.legend }}></span>
                      <span>Active rules</span>
                    </div>
                    <div style={styles.ruleLegend}>
                      <span style={{ ...styles.inactiveTab, ...styles.legend }}></span>
                      <span>Expired rules</span>
                    </div>
                  </div>
                  <div style={styles.ruleContainer}>
                    <ShowcaseFoundPriceRules />
                  </div>
                </Tab>
              ) : null}
            </TabbedShowLayout>
          </Show>
        );
      }}
    </ShowController>
  );
};
