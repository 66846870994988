import React from "react";
import { List, TextField, BooleanField } from "react-admin";

import CustomizableDatagrid from "../../components/CustomizableDatagrid";

const LanguagesList = props => (
  <List
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: "international_name", order: "ASC" }}
    {...props}
  >
    <CustomizableDatagrid rowClick="edit">
      <TextField
        label="resources.languages.fields.international_name"
        source="international_name"
      />
      <TextField label="resources.languages.fields.local_name" source="name" />
      <TextField label="resources.languages.fields.iso_code" source="iso_639_1" />
      <BooleanField label="resources.languages.fields.is_enabled" source="is_enabled" />
      <BooleanField label="resources.languages.fields.is_active" source="is_active" />
      <BooleanField label="resources.languages.fields.is_default" source="is_default" />
    </CustomizableDatagrid>
  </List>
);

export default LanguagesList;
