import React from "react";

export default class Counter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: 0
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.count !== prevProps.count) {
      this.interval && clearInterval(this.interval);
      if (this.state.number < this.props.count)
        this.interval = setInterval(() => this.increment(), this.props.duration);
      else {
        this.interval = setInterval(() => this.decrement(), this.props.duration);
      }
    }
  }

  componentDidMount() {
    this.interval && clearInterval(this.interval);
    if (this.state.number < this.props.count)
      this.interval = setInterval(() => this.increment(), this.props.duration);
    else {
      this.interval = setInterval(() => this.decrement(), this.props.duration);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  increment = () => {
    this.state.number < this.props.count
      ? this.setState(prevState => ({
          number: prevState.number + 1
        }))
      : clearInterval(this.interval);
  };

  decrement = () => {
    this.props.count < this.state.number
      ? this.setState(prevState => ({
          number: prevState.number - 1
        }))
      : clearInterval(this.interval);
  };

  render() {
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    return isChrome ? <span>{this.state.number}</span> : <span>{this.props.count}</span>;
  }
}
