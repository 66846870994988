import { handleSingleRequest, handleFilesUpload } from "../restClient";
import { CREATE, GET_LIST, DELETE } from "../types";
import { find, concat, compact, uniq } from "lodash";
import HttpError from "../HttpError";

export const transformProductCategoryUpdate = async (type, resource, params) => {
  if (params.data.tagGroupIds) {
    params.relationships = {
      ...params.relationships,
      tagGroups: {
        data: params.data.tagGroupIds.map(id => ({
          type: "tagGroups",
          id
        }))
      }
    };
  }

  const { imageRaw, overwrite, ...rest } = params.data;

  if (imageRaw && imageRaw.rawFile) {
    const imageUploadParams = imageRaw.rawFile;

    // get the image extension
    const imageTypeName = imageUploadParams.name.split(".").pop().toLowerCase();

    const imageTypeParams = {
      pagination: { page: 1, perPage: 1 },
      sort: { field: "id", order: "DESC" },
      filter: { name: imageTypeName }
    };

    try {
      const imageTypeResponse = await handleSingleRequest(GET_LIST, "imageTypes", imageTypeParams);

      // if no image type found throw an error
      if (imageTypeResponse.data.length < 1) {
        throw new HttpError("errorMessages.unsupportedImageType");
      }

      // if image type found, take it for later use
      const imageTypeId = imageTypeResponse.data[0].id;

      // upload the image to Gateway
      const imageUploadedResponse = await handleFilesUpload(CREATE, "images", {
        data: imageUploadParams,
        overwrite
      });

      const imageFileName = imageUploadedResponse.url.replace("/images/", "");

      const imageList = await handleSingleRequest(GET_LIST, "images", {
        filter: {
          name: imageUploadedResponse.filename,
          file_name: imageFileName,
          image_type_id: imageTypeId
        },
        pagination: { page: "all" },
        sort: { field: "id", order: "DESC" }
      });

      if (overwrite && imageList.data.length > 0) {
        const image = imageList.data[0];

        const productCategoryCreateParams = {
          ...params,
          data: {
            ...rest,
            image_id: image.id
          }
        };

        // create the product category record (with the image_id property)
        return handleSingleRequest(type, resource, productCategoryCreateParams)
          .then(({ data = {}, included = [], ...rest }) => {
            return { data, ...rest };
          })
          .catch(error => console.log("ERROR:", error));
      }

      const imageSaveParams = {
        data: {
          name: imageUploadedResponse.filename,
          file_name: imageFileName,
          image_type_id: imageTypeId
        }
      };

      // save the uploaded image reference to the API to get the image's id
      const imageSaved = await handleSingleRequest(CREATE, "images", imageSaveParams);

      const savedImageId = imageSaved.data.id;

      const productCategoryCreateParams = {
        ...params,
        data: {
          ...rest,
          image_id: savedImageId
        }
      };

      // create the product category record (with the image_id property)
      return handleSingleRequest(type, resource, productCategoryCreateParams)
        .then(({ data = {}, included = [], ...rest }) => {
          return { data, ...rest };
        })
        .catch(error => console.log("ERROR:", error));
    } catch (error) {
      console.log("error: ", error);
      throw new HttpError(error);
    }
  } else {
    const productCategoryCreateParams = {
      ...params,
      data: {
        ...rest
      }
    };

    const allLanguages = await handleSingleRequest(GET_LIST, "languages", {
      filter: {
        is_enabled: true
      },
      pagination: { page: "all" },
      sort: { field: "id", order: "DESC" }
    });
    const allLanguagesIds = allLanguages.data.map(item => item.id);
    // get all translations for comparison
    const allTranslations = await handleSingleRequest(GET_LIST, "translations", {
      filter: {
        table_row_id: productCategoryCreateParams.data.id,
        language_id_in: allLanguagesIds
      },
      pagination: { page: "all" },
      sort: { field: "id" }
    });

    // update or create depending on whether there is existing translation or not
    productCategoryCreateParams.data.translations &&
      productCategoryCreateParams.data.translations.forEach(translationItem => {
        let translationType = type;
        let translationParams = {
          data: {
            table_row_id: productCategoryCreateParams.data.id,
            language_id: translationItem.language.id,
            table_name: "product_categories",
            translation: {
              name: translationItem.name,
              description: translationItem.description
            }
          }
        };
        let itemExistsUpdate = allTranslations.data.find(
          getItem => getItem.id === translationItem.id
        );
        if (itemExistsUpdate) {
          translationParams = {
            ...translationParams,
            id: translationItem.id
          };
        } else {
          translationType = CREATE;
        }
        handleSingleRequest(translationType, "translations", translationParams);
      });
    // delete if old new record don't have a translation from old
    allTranslations.data.forEach(translationItem => {
      let itemDontExistsDelete = productCategoryCreateParams.data.translations.find(
        getItem => getItem.id === translationItem.id
      );
      if (!itemDontExistsDelete) {
        handleSingleRequest(DELETE, "translations", {
          data: {},
          id: translationItem.id
        });
      }
    });
    productCategoryCreateParams.data.translations = [];
    // end translations

    // create the product category record (with the image_id property)
    return handleSingleRequest(type, resource, productCategoryCreateParams)
      .then(({ data = {}, included = [], ...rest }) => {
        return { data, ...rest };
      })
      .catch(error => {
        throw new HttpError(error);
      });
  }
};

export const transformProductCategoryCreate = async (type, resource, params) => {
  const { imageRaw, overwrite, ...rest } = params.data;

  const imageUploadParams = imageRaw.rawFile;
  // console.log("REST", rest);

  // get the image extension
  const imageTypeName = imageUploadParams.name.split(".").pop().toLowerCase();

  const imageTypeParams = {
    pagination: { page: 1, perPage: 1 },
    sort: { field: "id", order: "DESC" },
    filter: { name: imageTypeName }
  };

  // get image type with the given extension
  try {
    const imageTypeResponse = await handleSingleRequest(GET_LIST, "imageTypes", imageTypeParams);

    // if no image type found throw an error
    if (imageTypeResponse.data.length < 1) {
      throw new HttpError("errorMessages.unsupportedImageType");
    }

    // if image type found, take it for later use
    const imageTypeId = imageTypeResponse.data[0].id;

    // upload the image to Gateway
    const imageUploadedResponse = await handleFilesUpload(CREATE, "images", {
      data: imageUploadParams,
      overwrite
    });

    const imageFileName = imageUploadedResponse.url.replace("/images/", "");

    const imageList = await handleSingleRequest(GET_LIST, "images", {
      filter: {
        name: imageUploadedResponse.filename,
        file_name: imageFileName,
        image_type_id: imageTypeId
      },
      pagination: { page: "all" },
      sort: { field: "id", order: "DESC" }
    });

    if (overwrite && imageList.data.length > 0) {
      const image = imageList.data[0];

      const productCategoryCreateParams = {
        data: {
          ...rest,
          image_id: image.id
        }
      };

      // create the product category record (with the image_id property)
      return handleSingleRequest(type, resource, productCategoryCreateParams)
        .then(({ data = {}, included = [], ...rest }) => {
          return { data, ...rest };
        })
        .catch(error => console.log("ERROR:", error));
    }

    const imageSaveParams = {
      data: {
        name: imageUploadedResponse.filename,
        file_name: imageFileName,
        image_type_id: imageTypeId
      }
    };

    // save the uploaded image reference to the API to get the image's id
    const imageSaved = await handleSingleRequest(CREATE, "images", imageSaveParams);

    const savedImageId = imageSaved.data.id;

    const productCategoryCreateParams = {
      data: {
        ...rest,
        image_id: savedImageId
      }
    };

    // create the product category record (with the image_id property)
    return handleSingleRequest(type, resource, productCategoryCreateParams)
      .then(({ data = {}, included = [], ...rest }) => {
        return { data, ...rest };
      })
      .catch(error => console.log("ERROR:", error));
  } catch (error) {
    console.log("error: ", error);
    throw new HttpError(error);
  }
};

export const transformProductCategory = (type, resource, params) => {
  const contentImagesUploadUrl = process.env.REACT_APP_IMG_URL;
  return handleSingleRequest(type, resource, {
    ...params,
    include: uniq(compact(concat(params.include, ["images", "tagGroups"])))
  }).then(async ({ data = {}, included = [], ...rest }) => {
    const existingImageData = find(included, {
      type: "images",
      id: data.image_id
    });

    const existingImageUrl = existingImageData
      ? `${contentImagesUploadUrl}/images/${existingImageData.file_name}`
      : undefined;

    if (!data["imageRaw"]) {
      data["imageRaw"] = [];
      data["imageRaw"] = {
        src: existingImageUrl
      };
    }

    // Tag groups
    data.tagGroupIds = included
      .filter(item => item.type === "tagGroups")
      .map(tagGroup => tagGroup.id);

    // manage translations, get translations and put them into category object for show part
    const allLanguages = await handleSingleRequest(GET_LIST, "languages", {
      filter: {
        is_enabled: true
      },
      pagination: { page: "all" },
      sort: { field: "id", order: "DESC" }
    });
    const allLanguagesIds = allLanguages.data.map(item => item.id);
    const categoryTranslations = await handleSingleRequest(GET_LIST, "translations", {
      filter: { table_row_id: data.id, language_id_in: allLanguagesIds },
      pagination: { page: "all" },
      sort: { field: "id" }
    });
    if (categoryTranslations.data && categoryTranslations.data.length > 0) {
      const constructTranslations = categoryTranslations.data.map(translationItem => {
        let findLanguage = allLanguages.data.find(
          language => translationItem.language_id === language.id
        );
        return {
          id: translationItem.id,
          language: { id: findLanguage.id, name: findLanguage.name },
          name: translationItem.translation.name,
          description: translationItem.translation.description
        };
      });
      data.translations = constructTranslations;
    } else {
      data.translations = [];
    }
    // end translations

    return { data, ...rest };
  });
};

export const transformProductCategories = (type, resource, params) => {
  const contentImagesUploadUrl = process.env.REACT_APP_IMG_URL;
  return handleSingleRequest(type, resource, {
    ...params,
    include: uniq(compact(concat(params.include, ["images", "tagGroups"])))
  }).then(async ({ data = {}, included = [], relationships, ...rest }) => {
    data = data.map(record => {
      const existingImageData = find(included, {
        type: "images",
        id: record.image_id
      });

      const existingImageUrl = existingImageData
        ? `${contentImagesUploadUrl}/images/${existingImageData.file_name}`
        : undefined;

      if (!record["imageRaw"]) {
        record["imageRaw"] = [];
        record["imageRaw"] = {
          src: existingImageUrl
        };
      }

      record.tagGroupIds = find(relationships, {
        id: record.id
      }).tagGroups.data.map(item => item.id);

      return record;
    });

    // ! @stax double check
    // manage translations for edit or create part
    let idFromUrl = window.location.hash.replace("#/productCategories/", "").split("/")[0];
    if (idFromUrl !== "#") {
      const allLanguages = await handleSingleRequest(GET_LIST, "languages", {
        filter: {
          is_enabled: true
        },
        pagination: { page: "all" },
        sort: { field: "id", order: "DESC" }
      });
      const allLanguagesIds = allLanguages.data.map(item => item.id);
      const getCategoryTranslationsParams = {
        filter: {
          table_row_id: idFromUrl,
          language_id_in: allLanguagesIds
        },
        pagination: { page: "all" },
        sort: { field: "id", order: "DESC" }
      };
      const categoryTranslations = await handleSingleRequest(
        GET_LIST,
        "translations",
        getCategoryTranslationsParams
      );
      if (categoryTranslations.data && categoryTranslations.data.length > 0) {
        const constructTranslations = categoryTranslations.data.map(translationItem => {
          return {
            id: translationItem.id,
            language: { id: translationItem.language_id },
            name: translationItem.translation.name,
            description: translationItem.translation.description
          };
        });
        data.find(category => category.id === idFromUrl).translations = constructTranslations;
      }
    }
    // end translations

    return { data, ...rest };
  });
};
