import React, { Component } from "react";
import { find } from "lodash";

import ExpandIcon from "@material-ui/icons/ExpandMore";
import { ReactComponent as PdfIcon } from "./assets/pdf-icon-black.svg";
import { ReactComponent as PdfIconWhite } from "./assets/pdf-icon-white.svg";

// const contentFilesUploadUrl = process.env.REACT_APP_IMG_URL;

export default class LayoutRenderer extends Component {
  state = { activeIndex: 0 };

  renderRegion = region => {
    if (!region) return null;

    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(region, "text/html");
    const imageUrl = process.env.REACT_APP_IMG_URL;

    htmlDoc.querySelectorAll("img").forEach(img => {
      const imgUrlOld = new URL(img.src);
      if (imgUrlOld.hostname === window.location.hostname) {
        img.src = imageUrl + imgUrlOld.pathname;
      }
    });

    return htmlDoc.body.innerHTML;
  };

  renderRoot = ({ children }, record) => {
    return (
      <div className="container-fluid">
        {children.map((child, index) => this.renderRecord(child, index))}
      </div>
    );
  };

  renderLegalHeader = ({ children }, record, index) => {
    const { regions, files } = this.props;

    let recordFile = files.length > 0 && find(files, file => file && file.recordId === record.id);

    let fullFileUrl = recordFile ? recordFile.fullUrl : "#";

    return (
      <div className="row" key={index}>
        {/* {children.map((child, index) => this.renderRecord(child, index))} */}
        <div className="col-md-12 legal-header">
          <div
            data-editable
            data-name={`er-${children[0].id}`}
            dangerouslySetInnerHTML={{
              __html: this.renderRegion(regions[`er-${children[0].id}`])
            }}
          />
          <div className="legal-header-document">
            <a href={fullFileUrl} target="_blank" rel="noreferrer">
              <div>
                <PdfIconWhite />
              </div>
            </a>
            <div
              data-editable
              data-name={`er-${children[1].id}`}
              dangerouslySetInnerHTML={{
                __html: this.renderRegion(regions[`er-${children[1].id}`])
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  renderCollapsableElement = (node, record, index) => {
    const { activeIndex } = this.state;
    const { regions } = this.props;
    const noChildren = node.children.length === 0;

    // if there is child element render record
    // if not child render region

    return noChildren ? (
      <div>No child elements</div>
    ) : (
      <div key={index} className="row">
        <div className="col-md-12 accordion">
          <div className="card">
            <div className="card-header" onClick={e => this.handleClick(e, index)}>
              <div
                // className="column"
                data-editable
                data-name={`er-${node.children[0].id}`}
                dangerouslySetInnerHTML={{
                  __html: this.renderRegion(regions[`er-${node.children[0].id}`])
                }}
              />
              <ExpandIcon />
            </div>
            <div
              className={activeIndex === index ? "card-body active" : "card-body"}
              data-editable
              // active={activeIndex === index}
              data-name={`er-${node.children[1].id}`}
              dangerouslySetInnerHTML={{
                __html: this.renderRegion(regions[`er-${node.children[1].id}`])
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  renderSeparatorElement = ({ children }, record, index) => {
    const { regions } = this.props;
    const noChildren = children.length === 0;

    return noChildren ? (
      <div key={index} className="row">
        <div className="col-md-12">
          <div
            className="line"
            data-editable
            data-name={record.name}
            dangerouslySetInnerHTML={{
              __html: this.renderRegion(regions[record.name])
            }}
          />
        </div>
      </div>
    ) : (
      <div key={index} className="row">
        <div>{children.map((child, index) => this.renderRecord(child, index))}</div>
      </div>
    );
  };

  renderDocumentElement = ({ children }, record, index) => {
    const { regions, files } = this.props;

    let recordFile = files.length > 0 && find(files, file => file && file.recordId === record.id);

    let fullFileUrl = recordFile ? recordFile.fullUrl : "#";

    return (
      <div className="row" key={index}>
        {children.map((child, index) => this.renderRecord(child, index))}
        <div className="document">
          <a href={fullFileUrl} target="_blank" rel="noreferrer">
            <PdfIcon />
          </a>
          <div
            data-editable
            data-name={`er-${record.id}`}
            dangerouslySetInnerHTML={{
              __html: this.renderRegion(regions[record.name])
            }}
          />
        </div>
      </div>
    );
  };

  renderRow = ({ children }, record, index) => {
    return (
      <div className="row" key={index}>
        {children.map((child, index) => this.renderRecord(child, index))}
      </div>
    );
  };

  renderColumn = ({ children }, record, index) => {
    const { regions } = this.props;
    const noChildren = children.length === 0;

    let classes = Object.keys(record.size)
      .map(key => (record.size[key] ? `col-${key}-${record.size[key]}` : ""))
      .join(" ");

    return noChildren ? (
      <div
        key={index}
        className={classes}
        data-editable
        data-name={record.name}
        dangerouslySetInnerHTML={{
          __html: this.renderRegion(regions[record.name])
        }}
      />
    ) : (
      <div key={index} className={classes}>
        {children.map((child, index) => this.renderRecord(child, index))}
      </div>
    );
  };

  renderRecord = (node, index) => {
    const {
      layout: { records }
    } = this.props;

    const record = find(records, ["id", node.id]);

    switch (record.type) {
      case "root":
        return this.renderRoot(node, record);
      case "row":
        return this.renderRow(node, record, index);
      case "column":
        return this.renderColumn(node, record, index);
      case "legal-header":
        return this.renderLegalHeader(node, record, index);
      case "collapsable":
        return this.renderCollapsableElement(node, record, index);
      case "separator":
        return this.renderSeparatorElement(node, record, index);
      case "document":
        return this.renderDocumentElement(node, record, index);
      default:
        return null;
    }
  };

  handleClick = (e, index) => {
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const {
      layout,
      layout: { tree }
    } = this.props;

    if (!layout) return null;

    return tree ? this.renderRecord(tree[0]) : null;
  }
}
