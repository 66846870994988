const layoutDefaults = {
  defaultColumn: { size: { xs: 12, sm: 0, md: 0, lg: 6, xl: 0 } },
  defaultRow: {},
  layout: {
    tree: [{ id: 1, children: [] }],
    records: [{ id: 1, type: "root" }]
  }
};

export default layoutDefaults;
