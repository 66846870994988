import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import onlyUpdateForKeys from "recompose/onlyUpdateForKeys";
import { sanitizeListRestProps } from "ra-core";
import ExportWithOptions from "../../components/ExportWithOptions";
import { ExportButton } from "react-admin";
import { TopToolbar, CreateButton } from "ra-ui-materialui";

const Actions = ({
  bulkActions,
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  ...rest
}) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <ExportWithOptions />
      {/* Bulk Actions Button */}
      {bulkActions &&
        cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems
        })}
      {/* Create Method */}
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button"
        })}
      {hasCreate && <CreateButton basePath={basePath} />}
      {exporter && (
        <ExportButton
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
          label="ra.action.export_data"
        />
      )}
    </TopToolbar>
  );
};

Actions.propTypes = {
  bulkActions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  basePath: PropTypes.string,
  className: PropTypes.string,
  currentSort: PropTypes.object,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  resource: PropTypes.string,
  onUnselectItems: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  showFilter: PropTypes.func
};

Actions.defaultProps = {
  selectedIds: []
};

export default onlyUpdateForKeys([
  "resource",
  "filters",
  "displayedFilters",
  "filterValues",
  "selectedIds"
])(Actions);
