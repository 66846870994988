import React, { cloneElement } from "react";
import {
  Show,
  TextField,
  SimpleShowLayout,
  ReferenceArrayField,
  ReferenceManyField,
  DateField,
  SingleFieldList,
  ChipField,
  BooleanField,
  SelectField,
  NumberField,
  ShowController,
  ArrayField
} from "react-admin";
import { ruleTypes, priceLevels, comparatorOperators } from "./ParameterConstants";
import { FormSeperator } from "./FormSeperator";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

const dateFieldsStyle = {
  color: "rgba(0, 0, 0, 0.54)",
  fontSize: "0.85rem",
  fontFamily: "Proxima Nova Regular",
  lineHeight: 1,
  transform: "translate(0, 1.5px) scale(0.75)",
  transformOrigin: "top left"
};

const Title = ({ record }) => (
  <TranslatableParagraph
    translationLabel="resources.priceRules.customLabels.showTitle"
    customLabel={record.rule.name}
  />
);

const StringToNonClickableLabelObject = ({ record, children, ...rest }) =>
  cloneElement(children, {
    ...rest,
    record: { label: record },
    onClick: e => {
      e.preventDefault();
    }
  });

const StringToNonClickableLabelObjectUserTypes = ({ record, children, ...rest }) =>
  cloneElement(children, {
    ...rest,
    record: { label: record && record.description },
    onClick: e => {
      e.preventDefault();
    }
  });

const StringToNonClickableLabelObjectUserRolesAndLevels = ({ record, children, ...rest }) =>
  cloneElement(children, {
    ...rest,
    record: { label: record && record.name },
    onClick: e => {
      e.preventDefault();
    }
  });

const CustomChip = ({ record, children, ...rest }) =>
  cloneElement(children, {
    ...rest,
    record: {
      userEmail: record ? record.email : null
    },
    onClick: e => {
      e.preventDefault();
    }
  });

export const priceRulesShow = props => {
  return (
    <ShowController {...props}>
      {({ translate, ...controllerProps }) => {
        const hasPromoCode =
          controllerProps.record &&
          controllerProps.record.rule &&
          controllerProps.record.rule.coupon_code;
        const hasUserIds =
          controllerProps.record &&
          controllerProps.record.rule &&
          controllerProps.record.rule.user_ids &&
          controllerProps.record.rule.user_ids.length > 0;
        const startDate =
          controllerProps.record &&
          controllerProps.record.rule &&
          controllerProps.record.rule.start_date;
        const endDate =
          controllerProps.record &&
          controllerProps.record.rule &&
          controllerProps.record.rule.end_date;
        const startTime =
          controllerProps.record &&
          controllerProps.record.rule &&
          controllerProps.record.rule.start_time;
        const endTime =
          controllerProps.record &&
          controllerProps.record.rule &&
          controllerProps.record.rule.end_time;
        return (
          <Show title={<Title />} {...props} {...controllerProps}>
            <SimpleShowLayout>
              <TextField
                label={
                  <TranslatableParagraph translationLabel="resources.priceRules.fields.rule.name" />
                }
                source="rule.name"
              />

              {hasPromoCode ? (
                <>
                  <TranslatableParagraph
                    customStyling={{
                      fontSize: "10px",
                      fontWeight: "400",
                      color: "rgba(0, 0, 0, 0.54)"
                    }}
                    translationLabel="resources.priceRules.fields.rule.rule_type"
                  />
                  <TranslatableParagraph
                    customStyling={{ margin: "0px", fontSize: "14px" }}
                    translationLabel="resources.priceRules.fields.rule.coupon_code"
                  />
                </>
              ) : (
                <SelectField
                  label="resources.priceRules.fields.rule.rule_type"
                  source="rule.rule_type"
                  choices={ruleTypes}
                />
              )}

              <ArrayField
                label="resources.priceRules.fields.rule.order_type"
                source="rule.order_type"
              >
                <SingleFieldList>
                  <StringToNonClickableLabelObject>
                    <ChipField source="label" />
                  </StringToNonClickableLabelObject>
                </SingleFieldList>
              </ArrayField>

              {hasPromoCode && (
                <TextField
                  label="resources.priceRules.fields.rule.coupon_code"
                  source="rule.coupon_code"
                />
              )}

              <ArrayField
                label="resources.priceRules.fields.rule.payment_options"
                source="rule.payment_options"
              >
                <SingleFieldList>
                  <StringToNonClickableLabelObject>
                    <ChipField source="label" />
                  </StringToNonClickableLabelObject>
                </SingleFieldList>
              </ArrayField>
              {hasUserIds && (
                <ReferenceArrayField
                  label="resources.priceRules.fields.rule.user_ids"
                  source="rule.user_ids"
                  reference="users"
                >
                  <SingleFieldList>
                    <CustomChip>
                      <ChipField source="userEmail" />
                    </CustomChip>
                  </SingleFieldList>
                </ReferenceArrayField>
              )}

              {!hasUserIds && (
                <ReferenceArrayField
                  label="resources.priceRules.fields.rule.user_type_ids"
                  source="rule.user_type_ids"
                  reference="userTypes"
                >
                  <SingleFieldList>
                    <StringToNonClickableLabelObjectUserTypes>
                      <ChipField source="label" />
                    </StringToNonClickableLabelObjectUserTypes>
                  </SingleFieldList>
                </ReferenceArrayField>
              )}

              {!hasUserIds && (
                <ReferenceArrayField
                  label="resources.priceRules.fields.rule.user_role_ids"
                  source="rule.user_role_ids"
                  reference="roles"
                >
                  <SingleFieldList>
                    <StringToNonClickableLabelObjectUserRolesAndLevels>
                      <ChipField source="label" />
                    </StringToNonClickableLabelObjectUserRolesAndLevels>
                  </SingleFieldList>
                </ReferenceArrayField>
              )}

              {!hasUserIds &&
              controllerProps.record &&
              !controllerProps.record.rule.user_role_ids.length ? null : (
                <ReferenceArrayField
                  label="resources.priceRules.fields.rule.user_level_ids"
                  source="rule.user_level_ids"
                  reference="userLevels"
                >
                  <SingleFieldList>
                    <StringToNonClickableLabelObjectUserRolesAndLevels>
                      <ChipField source="label" />
                    </StringToNonClickableLabelObjectUserRolesAndLevels>
                  </SingleFieldList>
                </ReferenceArrayField>
              )}

              {controllerProps.record && controllerProps.record.rule.rule_type === "searcher" && (
                <ReferenceArrayField source="rule.category_ids" reference="productCategories">
                  <SingleFieldList>
                    <ChipField
                      label="resources.priceRules.fields.rule.categories_ids"
                      source="name"
                    />
                  </SingleFieldList>
                </ReferenceArrayField>
              )}

              {controllerProps.record && controllerProps.record.rule.rule_type === "searcher" && (
                <BooleanField
                  source="rule.include_subcategories"
                  label="resources.priceRules.fields.rule.include_subcategories"
                />
              )}

              {controllerProps.record && controllerProps.record.rule.rule_type === "searcher" && (
                <ReferenceManyField
                  label="resources.priceRules.fields.rule.product_ids"
                  reference="products"
                >
                  <SingleFieldList>
                    <ChipField source="external_number" />
                  </SingleFieldList>
                </ReferenceManyField>
              )}
              {controllerProps.record &&
                !hasPromoCode &&
                controllerProps.record.rule.rule_type === "cart_amount" && (
                  <SelectField
                    label="resources.priceRules.fields.rule.cart_amount.operator"
                    source="rule.cart_amount.operator"
                    choices={comparatorOperators}
                  />
                )}

              {controllerProps.record &&
                !hasPromoCode &&
                controllerProps.record.rule.rule_type === "cart_amount" && (
                  <NumberField
                    label="resources.priceRules.fields.rule.cart_amount.value"
                    source="rule.cart_amount.value"
                  />
                )}

              {controllerProps.record &&
                !hasPromoCode &&
                controllerProps.record.rule.rule_type === "searcher" && (
                  <SelectField
                    label="resources.priceRules.fields.rule.product_quantity.operator"
                    source="rule.product_quantity.operator"
                    choices={comparatorOperators}
                  />
                )}

              {controllerProps.record &&
                !hasPromoCode &&
                controllerProps.record.rule.rule_type === "searcher" && (
                  <NumberField
                    label="resources.priceRules.fields.rule.product_quantity.value"
                    source="rule.product_quantity.value"
                  />
                )}

              {controllerProps.record &&
                !hasPromoCode &&
                controllerProps.record.rule.rule_type === "searcher" && (
                  <SelectField
                    label="resources.priceRules.fields.rule.product_amount.operator"
                    source="rule.product_amount.operator"
                    choices={comparatorOperators}
                  />
                )}

              {controllerProps.record &&
                !hasPromoCode &&
                controllerProps.record.rule.rule_type === "searcher" && (
                  <NumberField
                    label="resources.priceRules.fields.rule.product_amount.value"
                    source="rule.product_amount.value"
                  />
                )}

              {controllerProps.record &&
                !hasPromoCode &&
                controllerProps.record.rule.rule_type === "searcher" && (
                  <BooleanField
                    label="resources.priceRules.fields.rule.combine_products"
                    source="rule.combine_products"
                  />
                )}

              {controllerProps.record &&
                !hasPromoCode &&
                controllerProps.record.rule.rule_type === "searcher" && (
                  <BooleanField
                    label="resources.priceRules.fields.rule.is_bonus"
                    source="rule.is_bonus"
                  />
                )}

              {controllerProps.record &&
                !hasPromoCode &&
                controllerProps.record.rule.rule_type === "searcher" && (
                  <NumberField
                    label="resources.priceRules.fields.rule.xx_percentage"
                    source="rule.xx_percentage"
                  />
                )}

              {controllerProps.record &&
              !hasPromoCode &&
              (controllerProps.record.rule.user_role_ids.includes(
                "40e505de-2451-11e8-b34c-37a6db76516b"
              ) ||
                controllerProps.record.rule.user_role_ids.includes(
                  "40d3ec7c-2451-11e8-b34b-df8ee6486630"
                ) ||
                !controllerProps.record.rule.user_role_ids.length) ? null : (
                <BooleanField
                  label="resources.priceRules.fields.rule.become_member"
                  source="rule.become_member"
                />
              )}

              {!hasPromoCode && (
                <ReferenceArrayField
                  label="resources.priceRules.fields.rule.parent_rules"
                  source="rule.parent_rules"
                  reference="priceRules"
                >
                  <SingleFieldList>
                    <ChipField source="rule.name" />
                  </SingleFieldList>
                </ReferenceArrayField>
              )}

              {!hasPromoCode && (
                <NumberField
                  label="resources.priceRules.fields.rule.club_membership_age.value"
                  source="rule.club_membership_age.value"
                />
              )}

              {!hasPromoCode && (
                <SelectField
                  label="resources.priceRules.fields.membership_since"
                  source="rule.club_membership_age.operator"
                  choices={comparatorOperators}
                />
              )}

              <FormSeperator />

              <SelectField
                label="resources.priceRules.fields.rule.dl_level"
                source="rule.dl_level"
                choices={priceLevels}
              />

              <TextField label="resources.priceRules.fields.rule.message" source="rule.message" />

              {!hasPromoCode && (
                <DateField
                  label="resources.priceRules.fields.rule.start_date"
                  source="rule.start_date"
                />
              )}

              {!hasPromoCode && (
                <DateField
                  label="resources.priceRules.fields.rule.end_date"
                  source="rule.end_date"
                />
              )}

              {hasPromoCode && (
                <div>
                  <TranslatableParagraph
                    customStyling={dateFieldsStyle}
                    translationLabel="resources.priceRules.fields.rule.start_date"
                  />
                  <p
                    style={{
                      color: "#000000de",
                      fontSize: "0.75rem",
                      fontWeight: 400
                    }}
                  >
                    {startDate} {startTime}
                  </p>
                </div>
              )}

              {hasPromoCode && (
                <div>
                  <TranslatableParagraph
                    customStyling={dateFieldsStyle}
                    translationLabel="resources.priceRules.fields.rule.end_date"
                  />
                  <p
                    style={{
                      color: "#000000de",
                      fontSize: "0.75rem",
                      fontWeight: 400
                    }}
                  >
                    {endDate} {endTime}
                  </p>
                </div>
              )}

              {hasPromoCode && (
                <TextField
                  label="resources.priceRules.fields.rule.min_cart_amount"
                  source="rule.min_cart_amount"
                />
              )}
              {controllerProps.record &&
                !hasPromoCode &&
                controllerProps.record.rule.rule_type === "searcher" && (
                  <BooleanField
                    label="resources.priceRules.fields.rule.promoted_price"
                    source="rule.promoted_price"
                  />
                )}

              {!hasPromoCode && (
                <NumberField
                  label="resources.priceRules.fields.rule.min_installments"
                  source="rule.min_installments"
                />
              )}

              {!hasPromoCode && (
                <NumberField
                  label="resources.priceRules.fields.rule.max_installments"
                  source="rule.max_installments"
                />
              )}
            </SimpleShowLayout>
          </Show>
        );
      }}
    </ShowController>
  );
};
