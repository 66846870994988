import React from "react";
import { Create, SimpleForm, TextInput, NumberInput, required } from "react-admin";

export const TableCreate = props => (
  <Create {...props}>
    <SimpleForm variant="standard" redirect="list">
      <TextInput source="key" label="Key" validate={required()} />
      <TextInput source="value" label="Value" validate={required()} />
      <NumberInput source="sort_order" label="Order" min="0" validate={required()} />
    </SimpleForm>
  </Create>
);
