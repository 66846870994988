import React from "react";
import { List, TextField, Datagrid, RichTextField, BooleanField } from "react-admin";

export const AccordionList = props => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField label="Title" source="title" />
      <RichTextField label="Heading content" source="heading_content" />
      <RichTextField label="Content" source="content" />
      <RichTextField label="Footer content" source="footer_contet" />
      <BooleanField label="Table with discount beneftits" source="has_dynamic_table" />
      <BooleanField
        label="Table with regrut benefits"
        source="has_dynamic_table_discount_for_invites"
      />
      <TextField label="Order" source="sort_order" />
    </Datagrid>
  </List>
);
