import React from "react";
import {
  TabbedForm,
  FormTab,
  Edit,
  TextInput,
  SelectInput,
  NumberInput,
  DateInput,
  BooleanInput,
  ArrayInput,
  ReferenceInput,
  ReferenceArrayInput,
  SimpleFormIterator,
  required,
  minValue,
  maxValue
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import AutocompleteArrayInput from "../../components/CustomAutocompleteArrayInput";
import { BannerToolbarOptions } from "../../constants";
import { externalNumberFilter, nameFilter } from "../../utils/filters";
import { noWhiteSpaceValidation } from "../../utils/validators";
import { fieldTypes } from "./constants";
import { dateParser, validateCategoriesProducts } from "./Create";

const BenefitsEdit = props => {
  const transform = data => {
    return {
      ...data,
      form_fields: data.form_fields.map((field, index) => ({ ...field, order: index + 1 }))
    };
  };

  return (
    <Edit {...props} transform={transform}>
      <TabbedForm variant="standard">
        <FormTab label="Basic">
          <TextInput source="name" validate={[required(), noWhiteSpaceValidation]} />
          <TextInput source="display_name" validate={[required(), noWhiteSpaceValidation]} />
          <RichTextInput toolbar={[BannerToolbarOptions]} source="description" />
          <BooleanInput label="One Time" source="one_time" />
          <DateInput label="Start date" source="start_date" parse={dateParser} />
          <DateInput label="End date" source="end_date" parse={dateParser} />
          <BooleanInput label="Benefit enabled" source="enabled" />
          <ReferenceInput
            source="price_level_id"
            reference="priceLevels"
            sort={{ field: "name", order: "ASC" }}
            filterToQuery={name => {
              if (name) {
                return { name_ilike: name };
              }
            }}
            validate={required()}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          {/* <NumberInput
            label="Cart quantity"
            source="cart_quantity"
            min={0}
            step={1}
            validate={minValue(0)}
          /> */}
          <ReferenceArrayInput
            label="Products"
            source="product_ids"
            reference="products"
            sort={{ field: "name", order: "ASC" }}
            filterToQuery={externalNumberFilter}
            allowEmpty
            validate={validateCategoriesProducts}
          >
            <AutocompleteArrayInput optionText="external_number" invoked={true} />
          </ReferenceArrayInput>
          <ReferenceArrayInput
            label="Categories"
            source="category_ids"
            reference="productCategories"
            sort={{ field: "name", order: "ASC" }}
            filterToQuery={nameFilter}
            allowEmpty
            validate={validateCategoriesProducts}
          >
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
          <NumberInput
            label="Order"
            source="sort"
            min={1}
            step={1}
            validate={[required(), minValue(1)]}
            style={{ marginTop: 25 }}
          />
          {/* <NumberInput
            label="Grace period"
            source="grace_period"
            min={0}
            step={1}
            validate={[required(), minValue(0)]}
          /> */}
          <BooleanInput label="Validation" source="validate" />
        </FormTab>
        <FormTab label="Form Fields">
          <NumberInput
            label="Form count"
            source="forms"
            min={1}
            max={6}
            step={1}
            validate={[required(), minValue(1), maxValue(6)]}
          />
          <ArrayInput source="form_fields" style={{ width: 500 }}>
            <SimpleFormIterator
              TransitionProps={{ enter: false, exit: false }}
              disableAdd
              disableRemove
              disableReordering
            >
              <TextInput label="Field name" source="field_name" validate={required()} disabled />
              <SelectInput
                label="Field type"
                source="field_type"
                choices={fieldTypes}
                validate={required()}
                disabled
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default BenefitsEdit;
