import React from "react";
import {
  Show,
  ReferenceField,
  SimpleShowLayout,
  TextField,
  RichTextField,
  NumberField
} from "react-admin";

const TechDataTitle = ({ record }) => `Tech Data ${record.label}`;

export const TechDataShow = props => (
  <Show title={<TechDataTitle />} {...props}>
    <SimpleShowLayout redirect="show">
      <TextField label="resources.products.tabs.tech_data.name" source="id" />
      <ReferenceField
        label="resources.products.tabs.tech_data.product"
        source="product_id"
        reference="products"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField label="resources.products.tabs.tech_data.label" source="label" />
      <NumberField
        label="resources.products.tabs.tech_data.tech_data_order"
        source="tech_data_order"
      />
      <RichTextField
        label="resources.products.tabs.tech_data.tech_data_value"
        source="tech_data_value"
      />
    </SimpleShowLayout>
  </Show>
);
