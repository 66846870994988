import React, { Fragment } from "react";
import { List, TextField, Datagrid } from "react-admin";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import DefaultListActions from "../../components/DefaultListActions";

const BulkActionButtons = props => {
  return (
    <Fragment>
      <div style={{ marginRight: "0.5rem" }}>
        <DeleteConfirmation {...props} undoable={false} caution />
      </div>
    </Fragment>
  );
};

export const FeedList = props => (
  <List
    {...props}
    exporter={false}
    actions={<DefaultListActions />}
    bulkActionButtons={<BulkActionButtons />}
  >
    <Datagrid rowClick="show">
      <TextField label="Feed ID" source="id" />
      <TextField label="Feed Name" source="name" />
      <TextField label="File type" source="file_type" />
      <TextField label="File url" source="file_url" />
      <TextField label="Created At" source="created_at" />
      <TextField label="Updated At" source="updated_at" />
    </Datagrid>
  </List>
);
