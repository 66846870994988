import React /* { Component } */ from "react";
// import { connect } from "react-redux";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  FormDataConsumer,
  // useSetLocale,
  SaveButton,
  Toolbar
  // useNotify
} from "react-admin";
import { useForm } from "react-final-form";

// Icons
// import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
// Styles
// import { styles } from "../styles";
// import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";
import GuidanceBox from "../../components/GuidanceBox/GuidanceBox";

const LanguagesEdit = props => {
  let HowToSetLanguages = (
    <>
      You have three fields which will be needed to activate a language successfully. <hr></hr>
      <hr></hr>
      <b>Show language in Translations tab as an option</b>: Needed to make language available to be
      translated within Admin platform <hr></hr>
      <hr></hr>
      <b>Show language in language picker on Webshop and Mobile Application</b>: Makes language
      accessible to be picked on Webshop as well as Mobile application<hr></hr>
      <hr></hr>
      <b>Make language default for visitors on Webshop and Mobile Application</b>: Makes language{" "}
      available to be picked on Webshop as well as Mobile application dropdown after which certain
      if not all content will be transalted into picked language.
    </>
  );

  // const notify = useNotify();
  // const setLocale = useSetLocale();

  const LocaleSwitcher = props => {
    // const setLocale = useSetLocale();
    return (
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <Toolbar
              {...props}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start"
              }}
            >
              <SaveButton
                style={{ padding: "10px", borderRadius: "2.5px" }}
                label="Save"
                submitOnEnter={true}
                // onClick={() =>
                //   formData.is_enabled && formData.is_default && formData.is_active
                //     ? setLocale(formData.iso_639_1) &&
                //       localStorage.setItem("locale", formData.iso_639_1)
                //     : notify(
                //         `If you wanted to have ${formData.international_name} as a default language. Please check default field`
                //       )
                // }
              />
            </Toolbar>
          );
        }}
      </FormDataConsumer>
    );
  };

  const LanguagesValidation = props => {
    const form = useForm();
    return (
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (formData && !formData.is_enabled) {
            form.change("is_active", false);
            form.change("is_default", false);
          } else if (!formData.is_active) {
            form.change("is_default", false);
          }
          return (
            <>
              <BooleanInput
                label="resources.languages.fields.is_enabled"
                source="is_enabled"
                style={{ minWidth: "100%" }}
              />
              <BooleanInput
                defaultValue={false}
                label="resources.languages.fields.is_active"
                source="is_active"
                disabled={formData && formData.is_enabled ? false : true}
              />
              <BooleanInput
                label="resources.languages.fields.is_default"
                disabled={formData && formData.is_enabled && formData.is_active ? false : true}
                source="is_default"
              />
            </>
          );
        }}
      </FormDataConsumer>
    );
  };

  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<LocaleSwitcher />} variant="standard">
        <TextInput label="resources.languages.fields.local_name" source="name" disabled={true} />
        <TextInput
          label="resources.languages.fields.international_name"
          source="international_name"
          disabled={true}
        />
        <TextInput label="resources.languages.fields.iso_code" source="iso_639_1" disabled={true} />
        <GuidanceBox
          buttonLabel="How to properly activate languages"
          infoBoxText={HowToSetLanguages}
        />

        <LanguagesValidation />
        {/* <div style={styles.rolesContainer}>
          <SupervisorAccountIcon style={{ verticalAlign: `middle` }} />{" "}
          <p style={{ fontSize: "14px", marginTop: 0, padding: 5 }}>
            In order to activate translation to your liking, please check the next fields:{" "}
            <b>
              <TranslatableParagraph
                textOnly
                translationLabel="resources.languages.fields.is_enabled"
              />{" "}
            </b>
            ,
            <b>
              <TranslatableParagraph
                textOnly
                translationLabel="resources.languages.fields.is_default"
              />{" "}
              and{" "}
              <b>
                <TranslatableParagraph
                  textOnly
                  translationLabel="resources.languages.fields.is_active"
                />{" "}
              </b>
            </b>
          </p>
        </div> */}
      </SimpleForm>
    </Edit>
  );
};

export default LanguagesEdit;

// export default connect(null, { changeLocale: changeLocaleAction })(LanguagesEdit);
