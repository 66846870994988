import React, { useState } from "react";
import { List, TextField, DateField, Button } from "react-admin";
import MuiButton from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CompareIcon from "@material-ui/icons/FilterNone";
import moment from "moment";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ListFilters from "./Filters";
import CustomizableDatagrid from "../../components/CustomizableDatagrid";

const editorTitle = { marginBottom: 10, fontWeight: 700 };

const CompareChanges = props => {
  const {
    record: {
      action,
      object,
      user_email,
      changed,
      old_value: { data: oldData },
      new_value: { data: newData }
    }
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Button label="Compare" onClick={handleClickOpen}>
        <CompareIcon />
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="compare-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="compare-dialog-title">
          Compare changes on <strong>{object}</strong> object after <strong>{action}</strong> action
          by <strong>{user_email}</strong>, time of change{" "}
          <strong>{`${moment(changed).format("MM/DD/YYYY, h:mm:ss A")}`}</strong>.
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div style={editorTitle}>Old Data</div>
              <Editor value={oldData} mode="view" />
            </Grid>
            <Grid item xs={6}>
              <div style={editorTitle}>New Data</div>
              <Editor value={newData} mode="view" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MuiButton
            variant="contained"
            color="primary"
            onClick={handleClose}
            style={{ marginRight: 16, marginBottom: 10 }}
          >
            Close
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const AuditsList = props => {
  return (
    <List {...props} bulkActionButtons={false} exporter={false} filters={<ListFilters />}>
      <CustomizableDatagrid>
        <TextField source="object" />
        <TextField source="action" />
        <TextField source="user_email" />
        <DateField source="changed" label="Time of change" showTime />
        <CompareChanges label="Compare Changes" />
      </CustomizableDatagrid>
    </List>
  );
};
