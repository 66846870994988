import React, { Component } from "react";
import scrollToElement from "scroll-to-element";
import LineStyle from "@material-ui/icons/LineStyle";
import Fab from "@material-ui/core/Fab";
import ContentTools from "ContentTools/build/content-tools";
import dataProvider from "../../services/api/restClient";

import includes from "lodash/includes";

import { LayoutEditor, LayoutRenderer } from "../LayoutEditor";
import ImageUploader from "./ImageUploader";
import restClient from "../../services/api/restClient";

const ContentEdit = window.ContentEdit;

ContentTools.IMAGE_UPLOADER = dialog => new ImageUploader(dialog, restClient);

ContentTools.StylePalette.add([
  new ContentTools.Style("Margin all sides", "m-1"),
  new ContentTools.Style("Margin top", "m-t-1"),
  new ContentTools.Style("Margin left", "m-l-1"),
  new ContentTools.Style("Margin right", "m-r-1"),
  new ContentTools.Style("Margin bottom ", "m-b-1"),
  new ContentTools.Style("Large margin all sides", "m-2"),
  new ContentTools.Style("Large margin top", "m-t-2"),
  new ContentTools.Style("Large margin left", "m-l-2"),
  new ContentTools.Style("Large margin right", "m-r-2"),
  new ContentTools.Style("Large margin bottom", "m-b-2"),
  new ContentTools.Style("Iframe Full Width", "iframeWidth", ["iframe"]),
  new ContentTools.Style("Iframe 500px Height", "iframeHeight", ["iframe"])
]);

// Monkeypatch to enable responsive images
ContentEdit.Image.prototype.mount = function () {
  this._domElement = document.createElement("img");
  let classes = "";
  if (this.a && this.a["class"]) {
    classes += ` ${this.a["class"]}`;
  }
  if (this._attributes["class"]) {
    classes += ` ${this._attributes["class"]}`;
  }
  this._domElement.setAttribute("class", classes);
  this._domElement.setAttribute("src", this._attributes["src"]);
  return ContentEdit.Image.__super__.mount.call(this);
};

class Editor extends Component {
  state = {
    editor: null,
    layout: null,
    regions: null,
    canEditLayout: true,
    isEditingLayout: false
  };

  constructor(props) {
    super(props);

    this.rootRef = React.createRef();
  }

  initState() {
    const {
      contentData: { content, page_id }
    } = this.props;

    const { regions, layout } = content ? JSON.parse(content) : {};

    if (!layout) {
      this.setState({ regions }, () => {
        if (page_id) {
          this.checkForTemplate(page_id);
        } else {
          if (!regions)
            this.setState({ regions: {}, layout: {} }, () => {
              this.initContentEditor();
            });
        }
      });
    } else {
      this.setState({ regions, layout }, () => {
        this.initContentEditor();
      });
    }
  }

  componentDidMount() {
    this.initState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.contentData.content !== this.props.contentData.content) {
      let { editor } = this.state;

      if (editor) {
        editor.destroy();
      }
      this.setState(
        {
          editor: null,
          canEditLayout: true,
          isEditingLayout: false
        },
        () => {
          this.initState();
        }
      );
    }

    if (prevState.canEditLayout !== this.state.canEditLayout) {
      this.props.saveButtonHandler(!this.state.canEditLayout);
    }
  }

  componentWillUnmount() {
    const { editor } = this.state;

    editor && editor.destroy();
  }

  initContentEditor = () => {
    var editorCls = ContentTools.EditorApp.getCls();
    var rootNode = this.rootRef.current;

    editorCls.prototype.mount = function () {
      this._domElement = editorCls.createDiv(["ct-app"]);
      rootNode.appendChild(this._domElement, null);
      this._addDOMEventListeners();
    };

    const editor = ContentTools.EditorApp.get();
    editor.init("*[data-editable]", "data-name");

    editor.addEventListener("saved", this.saveContent);
    editor.addEventListener("start", () => this.setState({ canEditLayout: false }));
    editor.addEventListener("stop", () => this.setState({ canEditLayout: true }));

    this.setState({ editor });
  };

  saveContent = ev => {
    const { layout } = this.state;
    const regions = ev.detail().regions;
    let allRegions;
    let htmlMap;

    if (Object.keys(regions).length === 0) {
      return;
    }

    allRegions = this.state.editor.regions();
    htmlMap = {};

    for (var name in allRegions) {
      let regionHtml = allRegions[name].html();

      // Very ugly way to strip hostname from images
      if (regionHtml.length > 0) {
        regionHtml = regionHtml.replace(process.env.REACT_APP_IMG_URL, "");
      }

      htmlMap[name] = regionHtml;
    }

    this.props.input.onChange(JSON.stringify({ regions: htmlMap, layout }));
  };

  editLayout = e => {
    e.preventDefault();
    this.state.editor.destroy();
    this.setState({ editor: null, isEditingLayout: true }, () => {
      setTimeout(() => {
        scrollToElement(".static-content");
      }, 300);
    });
  };

  saveLayout = layout => {
    const { regions } = this.state;

    let allowedEditableRegions = ["column", "separator", "collapsable", "legal-header", "document"];

    let editableRegions = layout.records
      .filter(record => includes(allowedEditableRegions, record.type))
      .map(record => record.name);

    layout.records.forEach(record => {
      if (record.children) {
        record.children.forEach(child => editableRegions.push(child.name));
      }
    });

    let newRegions = editableRegions.reduce((acc, regionName) => {
      if (regions[regionName]) {
        acc[regionName] = regions[regionName];
      }

      return acc;
    }, {});

    const data = JSON.stringify({ regions: newRegions, layout });

    this.props.input.onChange(data);
  };

  resetLayout = () => {
    this.setState({ isEditingLayout: false }, () => {
      this.initContentEditor();
    });
  };

  renderLayoutEditorIgnition = () => (
    <Fab
      onClick={this.editLayout}
      style={{
        position: "fixed",
        top: "100px",
        right: "10px",
        width: "48px",
        height: "48px"
      }}
      color="primary"
      aria-label="Edit"
    >
      <LineStyle />
    </Fab>
  );

  render() {
    const { layout, regions, canEditLayout, isEditingLayout } = this.state;
    const { contentData } = this.props;

    if (!layout) return null;

    return isEditingLayout ? (
      <div className="static-content" style={{ minHeight: 200 }} ref={this.rootRef}>
        <div className="content">
          <LayoutEditor
            layout={layout}
            regions={regions}
            save={this.saveLayout}
            reset={this.resetLayout}
            pageId={contentData.id}
            files={contentData.files || []}
          />
        </div>
      </div>
    ) : (
      <div className="static-content" ref={this.rootRef} style={{ minHeight: 200 }}>
        {canEditLayout ? this.renderLayoutEditorIgnition() : null}
        <div className="content">
          <LayoutRenderer layout={layout} regions={regions} files={contentData.files} />
        </div>
      </div>
    );
  }

  // TODO remove dev basic auth from here before production ;)
  checkForTemplate = page_id => {
    page_id &&
      this.loadPage(page_id).then(pageData => {
        if (pageData.template_url) {
          window.alert(
            "Found old template file which will be transformed to new layout editing format."
          );
        } else {
          this.setState({ layout: {} }, () => {
            this.initContentEditor();
          });

          return;
        }
      });
  };

  loadPage = pageId => {
    return dataProvider("GET_ONE", "pages", { id: pageId }).then(response => response.data);
  };
}

export default Editor;
