import { handleSingleRequest } from "../restClient";
import { UPDATE, CREATE } from "../types";
import { find } from "lodash";

export const transformTags = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params,
    include: ["productTags", "tagGroups", "productCategories"]
  }).then(({ data = [], included = [], relationships, ...rest }) => {
    if (typeof data.map === "function") {
      // Assign product ids to tags
      data.map(async record => {
        record.productIds = included
          .filter(tag => tag.type === "productTags")
          .filter(productTag => productTag.tag_id === record.id)
          .map(productTag => productTag.product_id);

        // Assign productTag ids to tags
        record.productTagIds = included
          .filter(tag => tag.type === "productTags")
          .map(productTag => productTag.id);

        record.tagGroupIds = find(relationships, {
          id: record.id
        }).tagGroups.data.map(item => item.id);

        record.categoryIds = find(relationships, {
          id: record.id
        }).productCategories.data.map(item => item.id);

        return record;
      });
    } else {
      // Assign product ids to tag
      data.productIds = included
        .filter(tag => tag.type === "productTags")
        .filter(productTag => productTag.tag_id === data.id)
        .map(productTag => productTag.product_id);

      // Assign productTag ids to tag
      data.productTagIds = included
        .filter(tag => tag.type === "productTags")
        .map(productTag => productTag.id);

      // Assign tagGroup ids to tag
      data.tagGroupIds = included
        .filter(tag => tag.type === "tagGroups")
        .map(tagGroup => tagGroup.id);

      data.categoryIds = included
        .filter(record => record.type === "productCategories")
        .map(productCategory => productCategory.id);
    }
    // console.log(included.filter(tag => tag.type === "tagGroups"));

    return { data, ...rest };
  });
};

export const transformTagsCreate = (type, resource, params) => {
  if (params.data.tagGroupIds) {
    params.relationships = {
      tagGroups: {
        data: params.data.tagGroupIds.map(id => ({ type: "tagGroups", id }))
      }
    };
  }

  if (params.data.productIds) {
    params.relationships = {
      ...params.relationships,
      products: {
        data: params.data.productIds.map(id => ({ type: "products", id }))
      }
    };
  }

  if (params.data.productIds) {
    params.relationships = {
      ...params.relationships,
      products: {
        data: params.data.productIds.map(id => ({ type: "products", id }))
      }
    };
  }

  if (params.data.categoryIds) {
    params.relationships = {
      ...params.relationships,
      productCategories: {
        data: params.data.categoryIds.map(id => ({
          type: "productCategories",
          id
        }))
      }
    };
  }

  if (type === CREATE) {
    return handleSingleRequest(type, resource, {
      ...params
    }).then(({ data = [], included = [], ...rest }) => {
      return handleSingleRequest(UPDATE, resource, {
        ...params,
        id: data.id
      }).then(({ data2 = [], included2 = [], ...rest2 }) => {
        return { data, ...rest };
      });
    });
  }

  return handleSingleRequest(type, resource, {
    ...params
  }).then(({ data = [], included = [], ...rest }) => {
    return { data, ...rest };
  });
};

export const transformTagsDelete = (type, resource, params) => {
  const updateParams = {
    ...params,
    data: params.previousData,
    relationships: {
      tagGroups: {
        data: []
      },
      products: {
        data: []
      }
    }
  };

  return handleSingleRequest(UPDATE, resource, updateParams).then(
    ({ data = [], included = [], ...rest }) => {
      return handleSingleRequest(type, resource, {
        ...params
      }).then(({ data = [], included = [], ...rest }) => {
        return { data, ...rest };
      });
    }
  );
};
