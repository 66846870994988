import React, { Component, Fragment } from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { refreshView } from "ra-core";
import {
  fetchEnd,
  fetchStart,
  showNotification,
  Button,
  SaveButton,
  FormWithRedirect,
  TextInput,
  ReferenceInput,
  SelectInput,
  CREATE,
  UPDATE,
  ArrayInput,
  SimpleFormIterator,
  required
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import restClient from "../../services/api/restClient";
import { maxLength } from "../../utils/validators";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

const styles = {
  button: {
    marginTop: "1em",
    padding: "6px 10px"
  }
};

class HeadTagsQuickCreateButton extends Component {
  state = {
    showDialog: false
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleSubmit = values => {
    const { dispatch, record, resource } = this.props;
    let requestPreparedKeywords = [];

    if (values.metadata_keywords && values.metadata_keywords.length) {
      requestPreparedKeywords = values.metadata_keywords.map(k => k.keyword);
    }

    let requestData = {
      type_id: values.type_id,
      meta_data: {
        title: values.metadata_title,
        description: values.metadata_description,
        keywords: requestPreparedKeywords
      }
    };

    dispatch(fetchStart());

    restClient(CREATE, "headTags", { data: requestData })
      .then(({ data }) => {
        restClient(UPDATE, resource, {
          id: record.id,
          data: { ...record, head_tag_id: data.id }
        }).then(() => {
          this.setState({ showDialog: false });
          dispatch(push(`/${resource}/${record.id}`));
          dispatch(refreshView());
        });
      })
      .catch(error => {
        dispatch(showNotification(error.message, "error"));
      })
      .finally(() => {
        dispatch(fetchEnd());
      });
  };

  render() {
    const { showDialog } = this.state;
    const { classes, resource } = this.props;

    return (
      <Fragment>
        <Button
          onClick={this.handleClick}
          variant="contained"
          label="ra.custom_files.action_buttons.seo_tags_button"
          className={classes.button}
        >
          <IconContentAdd />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Create head tags"
        >
          <DialogTitle>
            <TranslatableParagraph translationLabel="resources.products.tabs.seo_tags.metadata_main" />
          </DialogTitle>
          <FormWithRedirect
            variant="standard"
            resource="headTags"
            save={this.handleSubmit}
            toolbar={null}
            render={({ handleSubmitWithRedirect, pristine, saving }) => (
              <>
                <DialogContent>
                  <TranslatableParagraph translationLabel="resources.products.tabs.seo_tags.metadata_title" />
                  <ReferenceInput
                    source="type_id"
                    label="resources.products.tabs.seo_tags.headtags"
                    reference="headTagTypes"
                    filter={{ name: resource }}
                    validate={required()}
                  >
                    <SelectInput
                      optionText="name"
                      style={{ width: "256px", display: "flex" }}
                      variant="standard"
                    />
                  </ReferenceInput>
                  <TextInput
                    label="resources.products.tabs.seo_tags.title"
                    source="metadata_title"
                    defaultValue=""
                    validate={[maxLength(80)]}
                    style={{ width: "256px", display: "flex" }}
                    variant="standard"
                  />
                  <TextInput
                    multiline
                    label="resources.products.tabs.seo_tags.description"
                    source="metadata_description"
                    defaultValue=""
                    validate={[maxLength(250)]}
                    variant="standard"
                    fullWidth
                  />
                  <ArrayInput
                    label="resources.products.tabs.seo_tags.keywords"
                    source="metadata_keywords"
                  >
                    <SimpleFormIterator>
                      <TextInput
                        label="resources.products.tabs.seo_tags.keywords"
                        source="keyword"
                        style={{ marginLeft: 8 }}
                        variant="standard"
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </DialogContent>
                <DialogActions>
                  <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    pristine={pristine}
                    saving={saving}
                  />
                  <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                    <IconCancel />
                  </Button>
                </DialogActions>
              </>
            )}
          />
        </Dialog>
      </Fragment>
    );
  }
}

export default connect()(withStyles(styles)(HeadTagsQuickCreateButton));
