export const bannerTextStylesChoices = [
  { id: "banner-dark", name: "Dark" },
  { id: "banner-light-left", name: "Light left" },
  {
    id: "banner-light-right",
    name: "Light right"
  },
  {
    id: "banner-category",
    name: "Category banner"
  }
];

export const bannerTypesChoices = [
  {
    id: "image",
    name: "Image"
  },
  {
    id: "video",
    name: "Video"
  }
];
