import React, { Fragment } from "react";

import ResendEmailButton from "./bulk-actions/ResendEmailButton";
import PrintButton from "./bulk-actions/PrintButton";

const BulkActionButtons = props => {
  return (
    <Fragment>
      <div style={{ marginRight: "0.5rem" }}>
        <PrintButton {...props} />
      </div>
      <ResendEmailButton {...props} />
    </Fragment>
  );
};

export default BulkActionButtons;
