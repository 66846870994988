import React, { useState } from "react";
import {
  Show,
  ReferenceField,
  translate,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  TextField,
  Datagrid,
  BooleanField,
  RichTextField,
  DateField,
  ReferenceArrayField,
  NumberField,
  ArrayField
} from "react-admin";
import { Tabs, Tab as TabMUI } from "@material-ui/core";
import isObject from "lodash/isObject";

// import { makeStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";

import { feature } from "../../components/Feature";
// import moment from "moment";

// Fetch data components
// import { handleSingleRequest } from "../../services/api/restClient";
// import { GET_LIST } from "react-admin";

import ImageField from "../../components/CustomImageField";
import HeadTagsTable from "../common/HeadTagsTable";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

const EditTitle = ({ record, translate }) => (
  <TranslatableParagraph
    customStyling={{ display: "inline-block", margin: 0 }}
    translationLabel="resources.products.title.show"
    customLabel={record.name ? record.name : "Product without a name"}
  />
);

// const useStyles = makeStyles(theme => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 300
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   }
// }));

// ? Country picker
// const FilterPricesByCountrySelection = ({ record, formData }) => {
//   // ? Styles
//   const styles = {
//     table: {
//       width: "100%"
//     },

//     thead: {
//       fontSize: "10px",
//       display: "flex",
//       justifyContent: "space-between",
//       alignItems: "center",
//       textAlign: "left",
//       paddingBottom: "20px",
//       borderBottom: "0.5px solid lightgray"
//     },

//     tbody: {
//       width: "100%",
//       minWidth: "100%",
//       display: "flex",
//       justifyContent: "space-between",
//       alignItems: "flex-start",
//       flexDirection: "column"
//     },

//     tr: {
//       width: "200px",
//       fontSize: "11px",
//       fontWeight: "300",
//       color: "rgba(0, 0, 0, 0.87)"
//     },

//     tdPrices: {
//       width: "100%",
//       minWidth: "100%",
//       display: "flex",
//       justifyContent: "space-between",
//       alignItems: "center",
//       fontSize: "13px"
//     },

//     td: {
//       width: "200px",
//       fontSize: "0.75rem",
//       margin: "5px 0px",
//       paddingBottom: "10px",
//       borderBottom: "1px solid lightgray"
//     }
//   };

//   // ? State
//   const [productPrices, setProductPrices] = useState([]);
//   const [listCountries, setListCountries] = useState([]);
//   const [selectedFieldOrder, setSelectedFieldOrder] = useState("created_at");
//   const [selectFieldSort, setSelectFieldSort] = useState("ASC");
//   const [triggerPrices, setTriggerPrices] = useState(null);
//   const [currentlySelectedCountry, setCurrentlySelectedCountry] = useState("International");
//   let productId = record.id;
//   const classes = useStyles();

//   // ? Trigger price fetch
//   const handleChange = event => {
//     setTriggerPrices(event.target.value);
//   };

//   // ? Fetch for countries -> API
//   useEffect(() => {
//     const requestOptions = {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/vnd.api+json",
//         Accept: "application/vnd.api+json",
//         "Accept-Encoding": "gzip, deflate, br",
//         "Accept-Language": "en-GB,en;q=0.9",
//         Host: "gateway-dev.zepter.app",
//         Origin: "http://localhost:3000",
//         Connection: "keep-alive",
//         Referer: "http://localhost:3000/",
//         "application-id": "34c289e8-731a-11e9-aa1d-17bf8e1dd31c",
//         "client-id": "zepter-admin",
//         "session-id": "368c1ca6-26ff-469e-a6ec-5471edc67dcf"
//       }
//     };
//     fetch(`https://gateway-dev.zepter.app/v1/api/countries?page%5Bnumber%5D=all`, requestOptions)
//       .then(response => response.json())
//       .then(({ data }) => {
//         setListCountries(data);
//         const countryAppId = localStorage.getItem("appId");
//         // ? Grab international name of a country
//         const internationalCode = JSON.parse(localStorage.getItem("countries")).find(
//           country => country.application_id === countryAppId
//         )?.international_name;

//         const upperCasedIso = JSON.parse(localStorage.getItem("countries"))
//           .find(country => country.application_id === countryAppId)
//           .iso_code_2.toUpperCase();
//         // ? Get current country iso code
//         const countryIsoCode = JSON.parse(localStorage.getItem("countries"))
//           .find(country => country.application_id === countryAppId)
//           .id.toLowerCase();

//         setTriggerPrices(countryIsoCode);
//       })
//       .catch(err => console.log(err));
//   }, []);

//   // ? Fetch for productPrices
//   useEffect(() => {
//     handleSingleRequest(GET_LIST, "productPrices", {
//       pagination: { page: "all" },
//       sort: { field: selectedFieldOrder, order: selectFieldSort },
//       filter: { product_id: productId, country_id: triggerPrices }
//     })
//       .then(({ data }) => {
//         setProductPrices(data);
//       })
//       .catch(err => console.log(err));
//   }, [triggerPrices]);

//   // ? Map out all countries that you get from API call (GW was replaced with API due to backend and recognised IDS)
//   const mappedCountries = listCountries.map(country => {
//     return (
//       <MenuItem
//         style={{ minHeight: "40px", fontWeight: "bold", lineHeight: "150%" }}
//         key={country.id}
//         value={country.id}
//         onClick={() => {
//           setTriggerPrices(country.id);
//           setCurrentlySelectedCountry(country.attributes.international_name);
//         }}
//       >
//         {country.attributes.international_name}
//       </MenuItem>
//     );
//   });

//   // ? List out all fields necessary for the pricing stylesheet
//   const productPricesFields = productPrices
//     .sort((a, b) => (a.selectedFieldOrder > b.selectedFieldOrder ? 1 : -1))
//     .map(price => {
//       return (
//         <tr style={styles.tdPrices} key={price.id}>
//           <td style={styles.td}>
//             {price && price.created_at ? moment(price.created_at).format("DD/MM/YYYY") : "No data"}
//           </td>
//           <td style={styles.td}>
//             {price && price.start_date ? moment(price.start_date).format("DD/MM/YYYY") : "No data"}
//           </td>
//           <td style={styles.td}>
//             {price && price.updated_at ? moment(price.updated_at).format("DD/MM/YYYY") : "No data"}
//           </td>
//           <td style={styles.td}>
//             {price && price.end_date ? moment(price.end_date).format("DD/MM/YYYY") : "No data"}
//           </td>
//           <td style={styles.td}>
//             {price && price.erp_price_type ? price.erp_price_type : "No data"}
//           </td>
//           <td style={styles.td}>
//             {price && price.erp_net_price ? price.erp_net_price : "No data"}
//           </td>
//           <td style={styles.td}>
//             {price && price.original_net_price ? price.original_net_price : "No data"}
//           </td>
//           <td style={styles.td}>
//             {price && price.erp_net_price ? price.erp_net_price : "No data"}
//           </td>
//           <td style={styles.td}>
//             {price && (price.deposit_company_price || !price.deposit_company_price)
//               ? price.deposit_company_price
//               : "No data"}
//           </td>
//           <td style={styles.td}>
//             {price && (price.deposit_person_price || !price.deposit_person_price)
//               ? price.deposit_person_price
//               : "No data"}
//           </td>
//         </tr>
//       );
//     });

//   // ? Re-trigger sort by productPrice field(s)
//   const swapSort = () => {
//     if (selectFieldSort === "DESC") {
//       setSelectFieldSort("ASC");
//     } else {
//       setSelectFieldSort("DESC");
//     }
//   };

//   return (
//     <>
//       {/* Countries drop down */}
//       <FormControl className={classes.formControl}>
//         <InputLabel id="productPriceCountry-trigger-label">
//           Select a country to get product prices
//         </InputLabel>
//         <Select
//           labelId="productPriceCountry-trigger-label"
//           id="productPriceCountry-trigger"
//           defaultValue={"34c234d4-731a-11e9-aa1b-0f98b1febff2"}
//           onChange={handleChange}
//         >
//           {mappedCountries && mappedCountries.length ? (
//             mappedCountries
//           ) : (
//             <span>No countries to be showcased</span>
//           )}
//         </Select>
//       </FormControl>
//       {/* Prices showcase */}
//       {productPrices && productPrices.length ? (
//         <>
//           <p style={{ padding: "5px", fontSize: "12px", color: "rgba(0,0,0,0.87)" }}>
//             Currently fields are ordered by:{" "}
//             <span style={{ fontWeight: "bold" }}>{selectedFieldOrder}</span> field, and are in{" "}
//             <span style={{ fontWeight: "bold" }}>{selectFieldSort}</span> order.
//           </p>
//           <table style={styles.table}>
//             <thead style={styles.thead}>
//               <tr>
//                 <th
//                   style={styles.tr}
//                   onClick={() => {
//                     setSelectedFieldOrder("created_at");
//                     swapSort();
//                   }}
//                 >
//                   Created At
//                 </th>
//               </tr>
//               <tr>
//                 <th
//                   style={styles.tr}
//                   onClick={() => {
//                     setSelectedFieldOrder("start_date");
//                     swapSort();
//                   }}
//                 >
//                   Start Date
//                 </th>
//               </tr>
//               <tr>
//                 <th
//                   style={styles.tr}
//                   onClick={() => {
//                     setSelectedFieldOrder("updated_at");
//                     swapSort();
//                   }}
//                 >
//                   Updated At
//                 </th>
//               </tr>
//               <tr>
//                 <th
//                   style={styles.tr}
//                   onClick={() => {
//                     setSelectedFieldOrder("end_date");
//                     swapSort();
//                   }}
//                 >
//                   End Date
//                 </th>
//               </tr>
//               <tr>
//                 <th
//                   style={styles.tr}
//                   onClick={() => {
//                     setSelectedFieldOrder("erp_price_type");
//                     swapSort();
//                   }}
//                 >
//                   ERP Price Type
//                 </th>
//               </tr>
//               <tr>
//                 <th
//                   style={styles.tr}
//                   onClick={() => {
//                     setSelectedFieldOrder("net_price");
//                     swapSort();
//                   }}
//                 >
//                   Net price
//                 </th>
//               </tr>
//               <tr>
//                 <th
//                   style={styles.tr}
//                   onClick={() => {
//                     setSelectedFieldOrder("original_net_price");
//                     swapSort();
//                   }}
//                 >
//                   Original net price
//                 </th>
//               </tr>
//               <tr>
//                 <th
//                   style={styles.tr}
//                   onClick={() => {
//                     setSelectedFieldOrder("erp_net_price");
//                     swapSort();
//                   }}
//                 >
//                   ERP net price
//                 </th>
//               </tr>
//               <tr>
//                 <th
//                   style={styles.tr}
//                   onClick={() => {
//                     setSelectedFieldOrder("rental_deposit_company");
//                     swapSort();
//                   }}
//                 >
//                   Rental deposit company
//                 </th>
//               </tr>
//               <tr>
//                 <th
//                   style={styles.tr}
//                   onClick={() => {
//                     setSelectedFieldOrder("rental_deposit_person");
//                     swapSort();
//                   }}
//                 >
//                   Rental deposit person
//                 </th>
//               </tr>
//             </thead>
//             <tbody style={styles.tbody}>{productPricesFields}</tbody>
//           </table>
//         </>
//       ) : (
//         // ! Fallback if no prices are found in the api call
//         <p>
//           No prices found for {currentlySelectedCountry}. Please chose other country or report this
//           through appropriate channels.{" "}
//         </p>
//       )}
//     </>
//   );
// };

const TranslatedTitle = translate(EditTitle);

const CustomPdfField = ({ classes, record }) => {
  if (!record.file_path) {
    return null;
  }
  let pdfName;
  if (record && record.file_path) {
    if (!isObject(record.file_path)) {
      pdfName = record.file_path
        .slice(46)
        .replaceAll("/", "")
        .replaceAll("-", " ")
        .replaceAll("_", " ")
        .replace(".pdf", "")
        .toUpperCase();
    } else {
      pdfName = record?.file_path?.rawFile?.path;
    }
  }
  return (
    <div style={{ marginTop: "30px" }}>
      <a href={`${process.env.REACT_APP_IMG_URL}${record.file_path}`}>
        {pdfName ? pdfName : record.name}
      </a>
    </div>
  );
};

const CustomAdditionalPdfField = ({ classes, record }) => {
  if (!record.additional_file_path) {
    return null;
  }

  let pdfName = "";
  if (record && record.additional_file_path) {
    if (!isObject(record.additional_file_path)) {
      pdfName = record.additional_file_path.substring(
        record.additional_file_path.lastIndexOf("/") + 1
      );
    } else {
      pdfName = record.additional_file_path?.rawFile?.path;
    }
  }

  return (
    <div style={{ marginTop: "30px" }}>
      <a href={`${process.env.REACT_APP_IMG_URL}${record.additional_file_path}`}>
        {pdfName || record.name}
      </a>
    </div>
  );
};

export const ProductShow = props => {
  const [activeTechDataTab, setActiveTechDataTab] = useState(0);
  const [activePDFTab, setActivePDFTab] = useState(0);

  const handleChangeTechDataTab = (event, value) => {
    setActiveTechDataTab(value);
  };

  const handleChangePDFTab = (event, value) => {
    setActivePDFTab(value);
  };

  return (
    <Show title={<TranslatedTitle />} {...props}>
      <TabbedShowLayout redirect="show">
        <Tab label="resources.products.tabs.basic.name">
          <ReferenceField
            label="resources.products.tabs.basic.category_name"
            source="category_id"
            reference="productCategories"
            allowEmpty
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="resources.products.tabs.basic.product_type"
            source="product_type_id"
            reference="productTypes"
            link="show"
          >
            <TextField label="resources.products.tabs.basic.name" source="name" />
          </ReferenceField>
          <TextField label="resources.products.tabs.basic.product_name" source="name" />
          <TextField label="resources.products.tabs.basic.url_slug" source="url_slug" />
          <TextField
            label="resources.products.tabs.basic.external_id"
            source="external_id"
            sortable={false}
          />
          <TextField
            label="resources.products.tabs.basic.external_number"
            source="external_number"
            sortable={false}
          />
          <TextField label="resources.products.tabs.basic.sirv_url" source="sirv_url" />
          <BooleanField
            label="resources.products.tabs.basic.is_visible_in_shop"
            source="is_visible_in_shop"
          />
          <BooleanField label="resources.products.tabs.basic.has_content" source="has_content" />
          <BooleanField
            label="resources.products.tabs.basic.show_discount"
            source="show_discount"
          />
          <RichTextField label="Short description v2" source="story" />
          <RichTextField
            label="resources.products.tabs.basic.short_description"
            source="short_description"
          />
          <TextField label="Product brand" source="brand" />
          <TextField label="Product Name for feed" source="feed_name" />
          <RichTextField label="Product Description for feed" source="feed_description" />
        </Tab>
        {/* <Tab label="resources.products.tabs.description.name">
          <RichTextField label="resources.products.tabs.description.name" source="description" />
        </Tab> */}
        {/* <Tab label="resources.products.tabs.story.name">
          <RichTextField label="resources.products.tabs.story.name" source="story" />
        </Tab> */}
        <Tab label="resources.products.tabs.presentation.name">
          <RichTextField label="resources.products.tabs.presentation.name" source="presentation" />
        </Tab>
        <Tab label="resources.products.tabs.price.name">
          {/* {internationalCode === "International" ? (
            <FilterPricesByCountrySelection />
          ) : ( */}
          <ReferenceManyField
            addLabel={false}
            reference="productPrices"
            target="product_id"
            sort={{ field: "end_date", order: "ASC" }}
          >
            <Datagrid>
              <DateField label="resources.products.tabs.price.created_at" source="created_at" />
              <DateField label="resources.products.tabs.price.start_date" source="start_date" />
              <DateField label="resources.products.tabs.price.updated_at" source="updated_at" />
              <DateField label="resources.products.tabs.price.end_date" source="end_date" />
              <TextField
                label="resources.products.tabs.price.erp_price_type"
                source="erp_price_type"
              />
              <TextField label="resources.products.tabs.price.net_price" source="net_price" />
              <TextField
                label="resources.products.tabs.price.original_net_price"
                source="original_net_price"
              />
              <TextField
                label="resources.products.tabs.price.erp_net_price"
                source="erp_net_price"
              />
              <TextField
                label="resources.products.tabs.price.deposit_company"
                source="deposit_company_price"
              />
              <TextField
                label="resources.products.tabs.price.deposit_person"
                source="deposit_person_price"
              />
              <BooleanField
                label="resources.products.tabs.price.affiliate_only"
                source="affiliate_only"
              />
            </Datagrid>
          </ReferenceManyField>
          {/* )} */}
        </Tab>
        <Tab label="resources.products.tabs.tech_data.name">
          <Tabs
            value={activeTechDataTab}
            onChange={handleChangeTechDataTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <TabMUI
              label={
                <TranslatableParagraph
                  textOnly
                  translationLabel="resources.products.tabs.tech_data.name"
                />
              }
            />
            <TabMUI
              label={
                <TranslatableParagraph
                  textOnly
                  translationLabel="resources.products.tabs.translations.name"
                />
              }
            />
          </Tabs>

          {activeTechDataTab === 0 && (
            <ReferenceManyField
              perPage={100}
              addLabel={false}
              reference="techDataLists"
              target="product_id"
              sort={{ field: "tech_data_order", order: "ASC" }}
            >
              <Datagrid>
                <NumberField
                  label="resources.products.tabs.tech_data.tech_data_order"
                  source="tech_data_order"
                />
                <TextField label="resources.products.tabs.tech_data.label" source="label" />
                <RichTextField
                  label="resources.products.tabs.tech_data.tech_data_value"
                  source="tech_data_value"
                />
              </Datagrid>
            </ReferenceManyField>
          )}

          {activeTechDataTab === 1 && (
            <ArrayField
              source="techDataTranslations"
              variant="standard"
              label="resources.products.tabs.translations.name"
              style={{ width: "100%" }}
            >
              <Datagrid>
                <TextField
                  source="language.name"
                  label="resources.products.tabs.translations.language_name"
                />

                <TextField
                  source="tech_data_order"
                  label="resources.products.tabs.tech_data.tech_data_order"
                />
                <TextField source="label" label="resources.products.tabs.tech_data.label" />
                <RichTextField
                  source="tech_data_value"
                  label="resources.products.tabs.tech_data.tech_data_value"
                />
              </Datagrid>
            </ArrayField>
          )}
        </Tab>
        <Tab label="resources.products.tabs.assigned_tags.name">
          <ReferenceArrayField
            addLabel={false}
            reference="productTags"
            source="productTag_ids"
            allowEmpty
          >
            <Datagrid>
              <DateField
                label="resources.products.tabs.assigned_tags.assigned_on"
                source="created_at"
              />
              <DateField
                label="resources.products.tabs.assigned_tags.updated_on"
                source="updated_at"
              />
              <ReferenceField
                label="resources.products.tabs.assigned_tags.tag_name"
                reference="tags"
                source="tag_id"
                allowEmpty
              >
                <TextField source="name" />
              </ReferenceField>
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
        <Tab label="resources.products.tabs.images.name">
          <ReferenceArrayField
            addLabel={false}
            source="productImage_ids"
            reference="vProductImages"
          >
            <Datagrid>
              <TextField label="resources.products.tabs.images.image_order" source="image_order" />
              <TextField label="resources.products.tabs.images.name" source="name" />
              <ImageField />
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
        {feature("seoTags") && (
          <Tab label="resources.products.tabs.seo_tags.name">
            <ReferenceField
              addLabel={false}
              link={false}
              reference="headTags"
              source="head_tag_id"
              allowEmpty={true}
            >
              <HeadTagsTable />
            </ReferenceField>
          </Tab>
        )}
        <Tab label="resources.products.tabs.documents.name">
          <Tabs
            value={activePDFTab}
            onChange={handleChangePDFTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <TabMUI
              label={
                <TranslatableParagraph
                  textOnly
                  translationLabel="resources.products.tabs.user_manual.name"
                />
              }
            />
            <TabMUI
              label={
                <TranslatableParagraph
                  textOnly
                  translationLabel="resources.products.tabs.additional_document.name"
                />
              }
            />
          </Tabs>
          {activePDFTab === 0 && <CustomPdfField source="file_path" title="file_path" />}
          {activePDFTab === 1 && (
            <CustomAdditionalPdfField source="additional_file_path" title="additional_file_path" />
          )}
        </Tab>
        <Tab label="resources.products.tabs.translations.name">
          <ArrayField
            source="translations"
            variant="standard"
            label="resources.products.tabs.translations.name"
          >
            <Datagrid>
              <TextField
                source="language.name"
                label="resources.products.tabs.translations.language_name"
              />
              <TextField source="name" label="resources.products.tabs.basic.product_name" />
              <RichTextField
                source="short_description"
                label="resources.products.tabs.basic.short_description"
              />
              <RichTextField
                source="presentation"
                label="resources.products.tabs.presentation.name"
              />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
