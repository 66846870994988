import moment from "moment";
// import ReactDOMServer from "react-dom/server";
import { handleSingleRequest, handleFilesUpload } from "../restClient";
import { CREATE, UPDATE, DELETE, GET_LIST } from "../types";

import HttpError from "../HttpError";

// import LayoutRenderer from "../../../components/LayoutEditor/LayoutRenderer";

const contentImagesUploadUrl = process.env.REACT_APP_IMG_URL;

// const generateHTML = props =>
//   ReactDOMServer.renderToString(LayoutRenderer(props));

// Transformers

const createUpdateTranslations = async (
  type,
  tableRowId,
  languageId,
  transName,
  transShortText,
  transBlogContent,
  translations = []
) => {
  try {
    if (type === CREATE) {
      const { data } = await handleSingleRequest(CREATE, "translations", {
        data: {
          table_row_id: tableRowId,
          language_id: languageId,
          table_name: "blogArticles",
          translation: {
            name: transName,
            description: transShortText,
            blogContent: transBlogContent
          }
        }
      });
      return data;
    }

    if (type === UPDATE) {
      const allLanguages = await handleSingleRequest(GET_LIST, "languages", {
        filter: {
          is_enabled: true
        },
        pagination: { page: "all" },
        sort: { field: "id", order: "DESC" }
      });
      const allLanguagesIds = allLanguages.data.map(item => item.id);
      const allTranslations = await handleSingleRequest(GET_LIST, "translations", {
        filter: { table_row_id: tableRowId, language_id_in: allLanguagesIds },
        pagination: { page: "all" },
        sort: { field: "id" }
      });
      translations.forEach(translationItem => {
        let translationType = type;
        let translationParams = {
          data: {
            table_row_id: tableRowId,
            language_id: translationItem.language.id,
            table_name: "blogArticles",
            translation: {
              name: translationItem.name,
              description: translationItem.description,
              blogContent: translationItem.blogContent
            }
          }
        };
        let itemExistsUpdate = allTranslations.data.find(
          getItem => getItem.id === translationItem.id
        );
        if (itemExistsUpdate) {
          translationParams = {
            ...translationParams,
            id: translationItem.id
          };
        } else {
          translationType = CREATE;
        }
        handleSingleRequest(translationType, "translations", translationParams);
      });
      allTranslations.data.forEach(translationItem => {
        let itemDontExistsDelete = translations.find(getItem => getItem.id === translationItem.id);
        if (!itemDontExistsDelete) {
          handleSingleRequest(DELETE, "translations", {
            data: {},
            id: translationItem.id
          });
        }
      });
    }
  } catch (error) {
    console.log(`[Error ${type} translations on blog articles]`, error);
  }
};

// name is equal to blog's title prop
// descirption is equal to blog's short_description prop
// blogContent is equal to blog's text prop
export const transformBlogArticlesCreateUpdate = async (type, resource, params) => {
  if (!params.data.blog_category_id) {
    params.data.blog_category_id = null;
  }

  const { image } = params.data;

  if (type === "CREATE" || "UPDATE") {
    if (type === "UPDATE") {
      const { data: defaultLanguage } = await handleSingleRequest(GET_LIST, "languages", {
        filter: {
          is_enabled: true,
          is_default: true
        },
        pagination: { page: "all" },
        sort: { field: "id", order: "DESC" }
      });
      const defaultLanguageTranslation = params.data?.translations.find(
        item => item?.language?.id === defaultLanguage[0]?.id
      );

      if (defaultLanguageTranslation) {
        params.data.text = defaultLanguageTranslation.blogContent;
        params.data.short_text = defaultLanguageTranslation.description;
        params.data.title = defaultLanguageTranslation.name;
      }
    }

    if (image && image.rawFile) {
      const imageUploadParams = image.rawFile;
      // Lower case in case uploaded image has uppercase extension
      const imageTypeName = imageUploadParams.name.split(".").pop().toLowerCase();
      const imageTypeParams = {
        pagination: { page: 1, perPage: 1 },
        sort: { field: "id", order: "DESC" },
        filter: { name: imageTypeName }
      };

      try {
        const imageTypeResponse = await handleSingleRequest(
          GET_LIST,
          "imageTypes",
          imageTypeParams
        );

        // if no image type found throw an error
        if (imageTypeResponse.data.length < 1) {
          throw new HttpError("errorMessages.unsupportedImageType");
        }

        // if image type found, take it for later use
        const imageTypeId = imageTypeResponse.data[0].id;

        // upload the image to Gateway
        const imageUploadedResponse = await handleFilesUpload(CREATE, "images", {
          data: imageUploadParams
        });

        const imageFileName = imageUploadedResponse.url.replace("/images/", "");

        const imageSaveParams = {
          data: {
            name: imageUploadedResponse.filename,
            file_name: imageFileName,
            image_type_id: imageTypeId
          }
        };

        // save the uploaded image reference to the API to get the image's id
        const imageSaved = await handleSingleRequest(CREATE, "images", imageSaveParams);

        params.data.image = `${contentImagesUploadUrl}/images/${imageSaved.data.file_name}`;

        return handleSingleRequest(type, resource, {
          ...params
        }).then(async ({ data = [], included = [], relationships, ...rest }) => {
          await createUpdateTranslations(
            type,
            data.id,
            params.data.language_id,
            data.title,
            data.short_text,
            params.data.blogContent,
            params.data?.translations
          );
          return { data, ...rest };
        });
      } catch (error) {
        console.log("error: ", error);
        throw new HttpError(error);
      }
    } else {
      params.data.image = params.data.image ? params.data.image.src : undefined;
      return handleSingleRequest(type, resource, {
        ...params
      }).then(async ({ data = [], included = [], relationships, ...rest }) => {
        await createUpdateTranslations(
          type,
          data.id,
          params.data.language_id,
          data.title,
          data.short_text,
          params.data.blogContent,
          params.data?.translations
        );

        return { data, ...rest };
      });
    }
  }
  // const content = JSON.parse(params.data.content);

  // if (content && content.layout && Object.keys(content.layout).length!=0 && content.regions && Object.keys(content.regions).length!=0 )
  //   params.data.text = generateHTML(content);

  params.data.date = moment(params.data.date).format();
};

// name is equal to blog's title prop
// descirption is equal to blog's short_description prop
// blogContent is equal to blog's text prop
export const transformBlogArticles = async (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params,
    include: ["headTags"]
  }).then(async ({ data = [], included = [], relationships, ...rest }) => {
    if (typeof data.map === "function") {
      data.map(async record => {
        record.image = {
          src: record.image
        };

        record.head_tags_meta = included.find(
          item => item.type === "headTags" && record.head_tag_id === item.id
        );

        if (record.head_tags_meta && record.head_tags_meta.meta_data.keywords) {
          record.head_tags_meta.meta_data.keywords = record.head_tags_meta.meta_data.keywords.map(
            word => {
              const refOb = {};
              refOb.keyword = word;
              return refOb;
            }
          );
        }

        return record;
      });
    } else {
      data.image = {
        src: data.image
      };
      data.head_tags_meta = included.find(item => item.type === "headTags");
      if (data.head_tags_meta && data.head_tags_meta.meta_data.keywords) {
        data.head_tags_meta.meta_data.keywords = data.head_tags_meta.meta_data.keywords.map(
          word => {
            const refOb = {};
            refOb.keyword = word;
            return refOb;
          }
        );
      }
    }

    if (type === "GET_ONE") {
      const allLanguages = await handleSingleRequest(GET_LIST, "languages", {
        filter: {
          is_enabled: true
        },
        pagination: { page: "all" },
        sort: { field: "id", order: "DESC" }
      });
      const allLanguagesIds = allLanguages.data.map(item => item.id);
      const blogArticlesTranslations = await handleSingleRequest(GET_LIST, "translations", {
        filter: { table_row_id: params.id, language_id_in: allLanguagesIds },
        pagination: { page: "all" },
        sort: { field: "id" }
      });

      if (blogArticlesTranslations.data.length === 0) {
        const defaultLanguage = allLanguages.data.find(lang => lang.is_default);
        const languageId = defaultLanguage.id || allLanguages.data[0].id;
        const translations = await createUpdateTranslations(
          CREATE,
          data.id,
          languageId,
          data.title,
          data.short_text,
          data.text
        );
        blogArticlesTranslations.data = [translations];
      }

      if (blogArticlesTranslations.data && blogArticlesTranslations.data.length > 0) {
        const constructTranslations = blogArticlesTranslations.data.map(translationItem => {
          let findLanguage = allLanguages.data.find(
            language => translationItem.language_id === language.id
          );
          return {
            id: translationItem.id,
            language: { id: findLanguage.id, name: findLanguage.name },
            name: translationItem.translation.name,
            description: translationItem.translation.description,
            blogContent: translationItem.translation.blogContent
          };
        });
        data.translations = constructTranslations;
      } else {
        // data.translations = [];
      }
      // params.data.translations = [];
    }

    return { data, ...rest };
  });
};
