import React from "react";
import { TextField } from "react-admin";

const EmailAndPhoneField = ({ record, ...rest }) => {
  return (
    <div>
      <TextField record={record} source="email" {...rest} />
      <br />
      <TextField record={record} source="phone" {...rest} />
    </div>
  );
};

export default EmailAndPhoneField;
