export const schedulingType = [
  { id: "one_time_only", name: "One time only" },
  { id: "recurring", name: "Recurring notification" }
];

export const every = [
  { id: 1, name: "1" },
  { id: 2, name: "2" },
  { id: 3, name: "3" },
  { id: 4, name: "4" },
  { id: 5, name: "5" },
  { id: 6, name: "6" },
  { id: 7, name: "7" }
];

export const days = [
  { id: "monday", name: "Monday" },
  { id: "tuesday", name: "Tuesday" },
  { id: "wednesday", name: "Wednesday" },
  { id: "thursday", name: "Thursday" },
  { id: "friday", name: "Friday" },
  { id: "saturday", name: "Saturday" },
  { id: "sunday", name: "Sunday" },
  { id: "day", name: "Day" },
  { id: "days", name: "Days" },
  { id: "week", name: "Week" },
  { id: "weeks", name: "Weeks" },
  { id: "month", name: "Month" },
  { id: "months", name: "Months" }
];

export const appVersion = [
  { id: "2.1.0-alpha", name: "2.1.0-alpha" },
  { id: "2.1.1", name: "2.1.1" },
  { id: "2.1.2", name: "2.1.2" },
  { id: "2.1.3", name: "2.1.3" },
  { id: "2.2.0", name: "2.2.0" },
  { id: "2.3.0", name: "2.3.0" },
  { id: "2.4.0", name: "2.4.0" },
  { id: "2.5.0", name: "2.5.0" },
  { id: "2.6.0", name: "2.6.0" },
  { id: "2.7.0", name: "2.7.0" },
  { id: "2.8.0", name: "2.8.0" },
  { id: "2.9.0", name: "2.9.0" },
  { id: "2.10.0", name: "2.10.0" },
  { id: "2.12.0", name: "2.12.0" },
  { id: "2.13.0", name: "2.13.0" },
  { id: "2.16.0", name: "2.16.0" }
];
