import { Component } from "react";
import { connect } from "react-redux";
import { find, get, isString, isArray, reduce } from "lodash";
import featureConfig from "../config/featureConfig.json";

class FeatureView extends Component {
  check = () => {
    let { selectedCountry, check } = this.props;
    if (isArray(check)) {
      return reduce(
        check,
        (res, curr) => {
          return res && get(featureConfig[selectedCountry], curr);
        },
        true
      );
    } else if (isString(check)) {
      return get(featureConfig[selectedCountry], check);
    } else {
      return false;
    }
  };

  render() {
    let { children } = this.props;

    if (!children) {
      return null;
    }

    if (this.check()) {
      return this.props.children;
    }
    return null;
  }
}

export const Feature = connect(
  state => ({
    selectedCountry: find(
      state.countries,
      country => country.id === state.selectedCountryId
    ).iso_code_3.toLowerCase()
  }),
  {}
)(FeatureView);

export const feature = check => {
  const selectedCountry = JSON.parse(localStorage.getItem("countries"))
    ? JSON.parse(localStorage.getItem("countries")).find(
        country => country.application_id === localStorage.getItem("appId")
      )
      ? JSON.parse(localStorage.getItem("countries")).find(
          country => country.application_id === localStorage.getItem("appId")
        ).international_name
      : undefined
    : undefined;
  if (isArray(check)) {
    return reduce(
      check,
      (res, curr) => {
        return res && get(featureConfig[selectedCountry], curr);
      },
      true
    );
  } else if (isString(check)) {
    return get(featureConfig[selectedCountry], check);
  } else {
    return false;
  }
};
