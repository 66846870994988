import { handleFilesUpload, handleSingleRequest } from "../restClient";
import { CREATE, DELETE, GET_LIST } from "../types";

export const transformProductsMany = (type, resource, params) => {
  if (!params?.id) return null;
  return handleSingleRequest(type, resource, {
    price_rule_id: params?.id,
    pagination: { page: "all" },
    sort: params?.sort
  })
    .then(({ data = [], included = [], ...rest }) => {
      return { data, ...rest };
    })
    .catch(e => {
      console.error(e);
      return { data: [] };
    });
};

export const transformProducts = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params,
    filter: {
      is_visible_in_shop_in: "true,false",
      ...params.filter
    },
    include: ["productTags", "productInventory", "headTags"]
  }).then(({ data = [], included = [], ...rest }) => {
    if (typeof data.map === "function") {
      data.map(record => {
        record.tag_ids = included
          .filter(productTag => productTag.product_id === record.id)
          .map(productTag => productTag.tag_id);
        record.productTag_ids = included
          .filter(productTag => productTag.product_id === record.id)
          .map(productTag => productTag.id);
        record.productImage_ids = [];

        record.product_inventory = included.find(item => item.id === record.id);

        if (record.product_inventory) {
          if (typeof record.product_inventory.stock_available === typeof null) {
            record.product_inventory.stock_available = 0;
          }
          if (typeof record.product_inventory.stock_total === typeof null) {
            record.product_inventory.stock_total = 0;
          }
        }

        return record;
      });

      return { data, ...rest };
    } else {
      data.tag_ids = included
        .filter(productTag => productTag.product_id === data.id)
        .map(productTag => productTag.tag_id);
      data.productTag_ids = included
        .filter(productTag => productTag.product_id === data.id)
        .map(productTag => productTag.id);

      return handleSingleRequest(GET_LIST, "vProductImages", {
        filter: {
          product_id: data.id
        },
        sort: { field: "id", order: "ASC" },
        pagination: {
          page: "all"
        }
      })
        .then(response => {
          if (typeof data.map === "function") {
            // TODO:
          } else {
            data.productImage_ids = response.data.map(image => image.id);
          }
        })
        .then(async () => {
          data.product_inventory = included.find(item => item.id === data.id);

          if (data.product_inventory) {
            if (typeof data.product_inventory.stock_available === typeof null) {
              data.product_inventory.stock_available = 0;
            }
            if (typeof data.product_inventory.stock_total === typeof null) {
              data.product_inventory.stock_total = 0;
            }
          }

          data.head_tags_meta = included.find(item => item.type === "headTags");

          if (data.head_tags_meta && data.head_tags_meta.meta_data.keywords) {
            data.head_tags_meta.meta_data.keywords = data.head_tags_meta.meta_data.keywords.map(
              word => {
                const refOb = {};
                refOb.keyword = word;
                return refOb;
              }
            );
          }

          let idFromUrl = window.location.hash.replace("#/products/", "").split("/")[0];
          if (idFromUrl !== "#") {
            const getProductsTranslations = {
              filter: {
                table_row_id: idFromUrl
              },
              pagination: { page: "all" },
              sort: { field: "id", order: "DESC" }
            };
            const productTranslations = await handleSingleRequest(
              GET_LIST,
              "translations",
              getProductsTranslations
            );
            if (productTranslations.data && productTranslations.data.length > 0) {
              const constructTranslations = productTranslations.data.map(translationItem => {
                return {
                  id: translationItem.id,
                  language: { id: translationItem.language_id },
                  name: translationItem.translation.name,
                  short_description: translationItem.translation.short_description,
                  description: translationItem.translation.description,
                  story: translationItem.translation.story,
                  presentation: translationItem.translation.presentation
                };
              });
              data.find(product_id => product_id.id === idFromUrl).translations =
                constructTranslations;
            } else {
              data.find(product_id => product_id.id === idFromUrl).translations = [];
            }
          }
          // end translations
          return { data, ...rest };
        });
    }
  });
};

export const transformProduct = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params,
    filter: {
      is_visible_in_shop_in: "true,false",
      ...params.filter
    },
    include: ["productTags", "productInventory", "headTags"]
  }).then(({ data = [], included = [], ...rest }) => {
    if (typeof data.map === "function") {
      data.map(record => {
        record.tag_ids = included
          .filter(productTag => productTag.product_id === record.id)
          .map(productTag => productTag.tag_id);
        record.productTag_ids = included
          .filter(productTag => productTag.product_id === record.id)
          .map(productTag => productTag.id);
        record.productImage_ids = [];

        record.product_inventory = included.find(item => item.id === record.id);

        if (record.product_inventory) {
          if (typeof record.product_inventory.stock_available === typeof null) {
            record.product_inventory.stock_available = 0;
          }
          if (typeof record.product_inventory.stock_total === typeof null) {
            record.product_inventory.stock_total = 0;
          }
        }

        return record;
      });

      return { data, ...rest };
    } else {
      data.tag_ids = included
        .filter(productTag => productTag.product_id === data.id)
        .map(productTag => productTag.tag_id);
      data.productTag_ids = included
        .filter(productTag => productTag.product_id === data.id)
        .map(productTag => productTag.id);

      return handleSingleRequest(GET_LIST, "vProductImages", {
        filter: {
          product_id: data.id
        },
        sort: { field: "id", order: "ASC" },
        pagination: {
          page: "all"
        }
      })
        .then(response => {
          if (typeof data.map === "function") {
            // TODO:
          } else {
            data.productImage_ids = response.data.map(image => image.id);
          }
        })
        .then(async () => {
          data.product_inventory = included.find(item => item.id === data.id);

          if (data.product_inventory) {
            if (typeof data.product_inventory.stock_available === typeof null) {
              data.product_inventory.stock_available = 0;
            }
            if (typeof data.product_inventory.stock_total === typeof null) {
              data.product_inventory.stock_total = 0;
            }
          }

          data.head_tags_meta = included.find(item => item.type === "headTags");

          if (data.head_tags_meta && data.head_tags_meta.meta_data.keywords) {
            data.head_tags_meta.meta_data.keywords = data.head_tags_meta.meta_data.keywords.map(
              word => {
                const refOb = {};
                refOb.keyword = word;
                return refOb;
              }
            );
          }

          // manage translations, get translations and put them into category object for show part
          const allLanguages = await handleSingleRequest(GET_LIST, "languages", {
            filter: {
              is_enabled: true
            },
            pagination: { page: "all" },
            sort: { field: "id", order: "DESC" }
          });
          const allLanguagesIds = allLanguages.data.map(item => item.id);
          const categoryTranslations = await handleSingleRequest(GET_LIST, "translations", {
            filter: { table_row_id: data.id, language_id_in: allLanguagesIds },
            pagination: { page: "all" },
            sort: { field: "id" }
          });
          const { data: techDataForProduct } = await handleSingleRequest(
            GET_LIST,
            "techDataLists",
            {
              filter: {
                product_id: data.id
              },
              pagination: { page: "all" },
              sort: { field: "id" }
            }
          );

          const techDataIds = techDataForProduct.map(item => item.id);
          const { data: techDataTranslations } = await handleSingleRequest(
            GET_LIST,
            "translations",
            {
              filter: {
                table_row_id_in: techDataIds,
                language_id_in: allLanguagesIds
              },
              pagination: { page: "all" },
              sort: { field: "language_id", order: "DESC" }
            }
          );
          let constructTechDataTranslations = [];
          if (techDataTranslations.length > 0) {
            constructTechDataTranslations = techDataTranslations.map(transItem => {
              let findLanguage = allLanguages.data.find(
                language => transItem.language_id === language.id
              );
              return {
                id: transItem.id,
                language: { id: findLanguage.id, name: findLanguage.name },
                label: transItem.translation.label,
                tech_data_order: transItem.translation.tech_data_order,
                tech_data_value: transItem.translation.tech_data_value,
                table_row_id: transItem.table_row_id
              };
            });
            // since we catch all of translations we can sort it here
            // first by language, then by tech_data_order
            constructTechDataTranslations.sort(function (a, b) {
              return (
                b.language.id.localeCompare(a.language.id) || a.tech_data_order - b.tech_data_order
              );
            });
            data.techDataTranslations = constructTechDataTranslations;
          } else {
            data.techDataTranslations = [];
          }

          if (categoryTranslations.data && categoryTranslations.data.length > 0) {
            const constructTranslations = categoryTranslations.data.map(translationItem => {
              let findLanguage = allLanguages.data.find(
                language => translationItem.language_id === language.id
              );
              return {
                id: translationItem.id,
                language: { id: findLanguage.id, name: findLanguage.name },
                name: translationItem.translation.name,
                short_description: translationItem.translation.short_description,
                description: translationItem.translation.description,
                story: translationItem.translation.story,
                presentation: translationItem.translation.presentation
              };
            });
            data.translations = constructTranslations;
          } else {
            data.translations = [];
          }
          // end translations
          return { data, ...rest };
        });
    }
  });
};

export const transformProductUpdate = (type, resource, params) => {
  let { stock_available, stock_total, ...data } = params.data;

  params.data = data;

  delete params.data["product_inventory"];

  return handleSingleRequest(CREATE, "productInventory", {
    data: {
      product_id: params.data.id,
      stock_available: Number(stock_available),
      stock_total: Number(stock_total)
    }
  })
    .then(async () => {
      // Make CREATE request for PDF file only if there is newly added file in dropzone
      if (params)
        if (params.data.file_path && params.data.file_path.rawFile) {
          const pdfCreateResponse = await handleFilesUpload(CREATE, "pdfs/" + params.data.id, {
            data: params.data.file_path.rawFile,
            overwrite: true
          });
          if (pdfCreateResponse && pdfCreateResponse.url) {
            params.data.file_path = pdfCreateResponse.url;
          }
        }

      if (!params.data.additional_file_path) {
        params.data.additional_file_path = "";
      }
      if (params.data.additional_file_path && params.data.additional_file_path.rawFile) {
        const pdfCreateResponseAdditional = await handleFilesUpload(
          CREATE,
          "pdfs/" + params.data.id,
          {
            data: params.data.additional_file_path.rawFile,
            overwrite: true
          }
        );

        if (pdfCreateResponseAdditional && pdfCreateResponseAdditional.url) {
          params.data.additional_file_path = pdfCreateResponseAdditional.url;
        }
      }

      return handleSingleRequest(type, resource, {
        ...params,
        filter: {
          is_visible_in_shop_in: "true,false",
          ...params.filter
        }
      });
    })
    .then(async ({ data = [], included = [], ...rest }) => {
      // get all translations for comparison
      const allLanguages = await handleSingleRequest(GET_LIST, "languages", {
        filter: {
          is_enabled: true
        },
        pagination: { page: "all" },
        sort: { field: "id", order: "DESC" }
      });
      const allLanguagesIds = allLanguages.data.map(item => item.id);
      const allTranslations = await handleSingleRequest(GET_LIST, "translations", {
        filter: { table_row_id: params.data.id, language_id_in: allLanguagesIds },
        pagination: { page: "all" },
        sort: { field: "id" }
      });
      // update or create depending on whether there is existing translation or not
      params.data.translations.forEach(translationItem => {
        let translationType = type;
        let translationParams = {
          data: {
            table_row_id: params.data.id,
            language_id: translationItem.language.id,
            table_name: "products",
            translation: {
              name: translationItem.name,
              short_description: translationItem.short_description,
              description: translationItem.description,
              story: translationItem.story,
              presentation: translationItem.presentation
            }
          }
        };
        let itemExistsUpdate = allTranslations.data.find(
          getItem => getItem.id === translationItem.id
        );
        if (itemExistsUpdate) {
          translationParams = {
            ...translationParams,
            id: translationItem.id
          };
        } else {
          translationType = CREATE;
        }
        handleSingleRequest(translationType, "translations", translationParams);
      });
      // delete if old new record don't have a translation from old
      allTranslations.data.forEach(translationItem => {
        let itemDontExistsDelete = params.data.translations.find(
          getItem => getItem.id === translationItem.id
        );
        if (!itemDontExistsDelete) {
          handleSingleRequest(DELETE, "translations", {
            data: {},
            id: translationItem.id
          });
        }
      });
      params.data.translations = [];
      // end translations
      return handleSingleRequest(GET_LIST, "productInventory", {
        filter: {
          product_id: data.id
        },
        sort: { field: "updated_at", order: "DESC" },
        pagination: {
          page: "all"
        }
      }).then(productInventoryResponse => {
        const latestInventory = productInventoryResponse.data[0];
        return {
          data: {
            ...data,
            product_inventory: {
              id: data.id,
              stock_available: latestInventory.stock_available,
              stock_total: latestInventory.stock_total,
              type: "productInventory"
            }
          },
          ...rest
        };
      });
    });
};

export const transformProductCreate = (type, resource, params) => {
  // Setting required yet un-accessible data to default values
  params.data.additional_file_path = "";
  params.data.file_path = "";
  params.data.stock_number = 0;

  return handleSingleRequest(type, resource, {
    ...params,
    include: ["headTags"]
  }).then(async ({ data = [], included = [], ...rest }) => {
    return { data, ...rest };
  });
};
