import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  NumberInput,
  DateInput,
  required
} from "react-admin";
import { SelectInput } from "ra-ui-materialui/lib/input";
import { feedTypes, feedFields } from "./ParameterConstants";
import { dateValidator } from "../../utils/validators";
import { checkForBracketsNotAlowed } from "./validators";

export const FeedCreate = props => (
  <Create {...props}>
    <SimpleForm variant="standard">
      <TextInput source="name" label="Feed Name" validate={required()} />
      <SelectInput
        source="file_type"
        choices={feedTypes}
        defaultValue={feedTypes[0].id}
        validate={required()}
      />
      <FormDataConsumer>
        {({ formData }) => {
          if (formData.file_type === "application/xml") {
            return (
              <div style={{ display: "flex", flexDirection: "column", maxWidth: "256px" }}>
                <TextInput
                  source="template.xml_wrapper"
                  label="XML wrapper without brackets <>"
                  placeholder="SHOP"
                  validate={checkForBracketsNotAlowed}
                />
                <TextInput source="template.xmlns" label="Add xmlns if needed" />
                <TextInput
                  source="template.xml_product_wrapper"
                  label="XML product without brackets <>"
                  validate={checkForBracketsNotAlowed}
                />
              </div>
            );
          } else {
            return <span />;
          }
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => {
          if (
            formData.file_type === "zbozi" ||
            formData.file_type === "facebookCsv" ||
            formData.file_type === "googleMCsv"
          ) {
            return <span />;
          } else {
            return (
              <ArrayInput source="template.attr_map" label="Template fields">
                <SimpleFormIterator
                  disableReordering
                  TransitionProps={{ enter: false, exit: false }}
                >
                  <TextInput
                    label={
                      formData.file_type === "application/xml"
                        ? "Tag name without brackets <>"
                        : "Field Name"
                    }
                    source="attribute_name"
                    validate={checkForBracketsNotAlowed}
                  />
                  <SelectInput source="field_name" choices={feedFields} label="Field Source" />
                  <FormDataConsumer>
                    {({ getSource, scopedFormData }) => {
                      let checkForFieldType =
                        scopedFormData?.field_name === "feed_name" ||
                        scopedFormData?.field_name === "feed_description";
                      let checkForPrice =
                        scopedFormData?.field_name === "rrp_price" ||
                        scopedFormData?.field_name === "member_price";
                      let checkForStockField = scopedFormData?.field_name === "in_stock";
                      let checkForConditionField = scopedFormData?.field_name === "condition";
                      if (checkForFieldType) {
                        return (
                          <div
                            style={{ display: "flex", flexDirection: "column", maxWidth: "256px" }}
                          >
                            <NumberInput
                              label="Character limit"
                              placeholder="#$%..."
                              source={getSource("char_limit")}
                              min="10"
                            />
                          </div>
                        );
                      } else if (checkForPrice) {
                        return (
                          <BooleanInput
                            label="Use price with currency"
                            source={getSource("with_currency")}
                          />
                        );
                      } else if (checkForConditionField) {
                        return (
                          <TextInput label="Default value" source={getSource("default_value")} />
                        );
                      } else if (checkForStockField) {
                        return (
                          <div
                            style={{ display: "flex", flexDirection: "column", maxWidth: "256px" }}
                          >
                            <BooleanInput
                              label="Sync value with stock"
                              source={getSource("sync_with_stock")}
                              record={scopedFormData}
                            />
                            {!scopedFormData?.sync_with_stock && (
                              <TextInput
                                label="Default value"
                                source={getSource("default_value")}
                              />
                            )}
                            {scopedFormData?.sync_with_stock && (
                              <>
                                <TextInput
                                  label="Value if available in stock"
                                  source={getSource("true_value")}
                                />
                                <TextInput
                                  label="Value if not available in stock"
                                  source={getSource("false_value")}
                                />
                              </>
                            )}
                          </div>
                        );
                      }
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            );
          }
        }}
      </FormDataConsumer>
      <p style={{ fontSize: "12px", paddingBottom: "0px", marginBottom: "-10px" }}>
        Feed will be active from a date:
      </p>
      <DateInput
        label=""
        source="cron.start_date"
        validate={[required(), dateValidator("YYYY-MM-DDTHH:mm:ss.sssZ")]}
        variant="standard"
      />
      <p style={{ fontSize: "12px", paddingBottom: "0px", marginBottom: "-10px" }}>
        Date of feed deactivation:
      </p>
      <DateInput
        label=""
        source="cron.end_date"
        validate={[required(), dateValidator("YYYY-MM-DDTHH:mm:ss.sssZ")]}
        variant="standard"
      />
    </SimpleForm>
  </Create>
);
