import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const imgUrl = process.env.REACT_APP_IMG_URL;

const styles = {
  root: { maxWidth: "100%", margin: "10px 0" }
};

const ImageField = withStyles(styles)(({ classes, record }) => {
  if (record) {
    const src = record.rawFile
      ? record.src
      : record.src
      ? record.src.includes("https://gateway")
        ? record.src
        : `${imgUrl}${record.src}`
      : `${imgUrl}/images/${record.file_name}`;

    return <img src={src} className={classes.root} alt="" />;
  } else {
    return null;
  }
});

export default ImageField;
