import React, { useEffect, useState } from "react";
import { Create, SimpleForm, TextInput, required, GET_LIST } from "react-admin";
import { handleSingleRequest } from "../../services/api/restClient";
import { validateNotificationCategoryName } from "./validators";

export const NotificationCategoriesCreate = props => {
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleSingleRequest(GET_LIST, "notificationCategories", {
          pagination: { page: "all" },
          sort: { field: "id", order: "DESC" },
          filter: {}
        });
        setCategoryList(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const validateCategoryName = value => validateNotificationCategoryName(value, categoryList);

  return (
    <Create {...props}>
      <SimpleForm variant="standard">
        <TextInput source="name" validate={[required(), validateCategoryName]} />
        <TextInput source="description" validate={required()} />
      </SimpleForm>
    </Create>
  );
};
