/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import {
  Edit,
  BooleanInput,
  TextInput,
  ReferenceInput,
  TabbedForm,
  FormTab,
  ReferenceArrayField,
  ArrayField,
  Datagrid,
  DateField,
  ReferenceField,
  TextField,
  EditButton,
  ReferenceManyField,
  NumberField,
  BooleanField,
  RichTextField,
  AutocompleteInput,
  required,
  Toolbar,
  SaveButton,
  FileInput,
  FileField,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  Button,
  useRecordContext,
  useRefresh
} from "react-admin";
import { Tabs, Tab } from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";
import ActionEdit from "@material-ui/icons/Edit";

import { Link } from "react-router-dom";

import { productValidationSirv } from "./validators";
// import RichTextInput from "ra-input-rich-text";
import RichTextInput from "../../components/RichTextEditor";
import AddTagButton from "./AddTagButton";
import AddImageButton from "./AddImageButton";
import AddTechDataButton from "./AddTechDataButton";
import AddPriceButton from "./AddPriceButton";
import ImageField from "../../components/CustomImageField";
import HeadTagsQuickCreateButton from "../common/HeadTagsQuickCreateButton";
import HeadTagsQuickEditButton from "../common/HeadTagsQuickEditButton";
import HeadTagsTable from "../common/HeadTagsTable";

import { feature } from "../../components/Feature";
import { ToolbarOptions } from "../../constants";
import { nameFilter } from "../../utils/filters";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { styles } from "../styles";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

import { handleSingleRequest } from "../../services/api/restClient";
import { DELETE, GET_LIST } from "../../services/api/types";

// ? New Feature: Image guidance
import GuidanceBox from "../../components/GuidanceBox/GuidanceBox";
import ShortDescriptionWeb from "../../components/GuidanceBox/images/products/shortDescription-web.png";
import ShortDescriptionMobile from "../../components/GuidanceBox/images/products/shortDescription-mobile.png";
import PresentationWeb from "../../components/GuidanceBox/images/products/presentation-web.png";
import PresentationMobile from "../../components/GuidanceBox/images/products/presentation-mobile.png";
import resources from "../../i18n/en.json";
import HttpError from "../../services/api/HttpError";

// Defining variables
const ProductEdit = props => {
  const [activeTechDataTab, setActiveTechDataTab] = useState(0);
  const [activePDFTab, setActivePdfTab] = useState(0);
  const [allLanguages, setAllLanguages] = useState([]);

  // * Languages support @web
  const thereCanBeOnlyOne = (value, formValues) => {
    if (value) {
      let checkForExistingLanguageSelected = formValues.translations.filter(
        item => item && item.language && item.language.id === value
      );
      if (checkForExistingLanguageSelected.length > 1) {
        return "There can be only one translation per language";
      } else {
        return undefined;
      }
    } else {
      return "Required";
    }
  };

  const EditTitle = ({ record, translate }) => (
    <TranslatableParagraph
      customStyling={{ display: "inline-block", margin: 0 }}
      translationLabel="resources.products.title.edit"
      customLabel={record.name ? record.name : "Product without a name"}
    />
  );

  const ConfirmationToolbar = props => (
    <Toolbar {...props} style={styles.toolbar}>
      <SaveButton />
      <DeleteConfirmation undoable={false} />
    </Toolbar>
  );

  const TranslateButton = props => {
    return (
      <Button
        // variant="standard"
        component={Link}
        to={{
          pathname: "/techDataLists/create",
          search: `?source=${JSON.stringify({
            product_id: props.record.id,
            isNewTranslation: true,
            ...props.record
          })}`
        }}
        label="Translate"
      >
        <AddCircle />
      </Button>
    );
  };

  const DeleteTranslationButton = props => {
    const refresh = useRefresh();
    return (
      <div style={{ width: "max-content" }}>
        <DeleteConfirmation
          style={{
            marginLeft: "10px",
            color: "#f44336"
          }}
          handleDeleteCustom={() => props.handleDeleteTranslation(props, refresh)}
          key="button"
          disabled={false}
        />
      </div>
    );
  };

  const EditTranslationButton = ({ record, productId }) => {
    const { table_row_id } = useRecordContext();
    return (
      <Button
        component={Link}
        to={{
          pathname: `/techDataLists/${table_row_id}`,
          search: `?source=${JSON.stringify({
            isEditTranslation: true,
            translationId: record.id,
            productId: productId
          })}`
        }}
        label="Edit"
      >
        <ActionEdit />
      </Button>
    );
  };

  const CustomFileField = props => {
    let pdfName;
    if (props.record && props.record.src) {
      pdfName = props.record.rawFile.name
        .replaceAll("_", " ")
        .replaceAll("-", " ")
        .replace(".pdf", "")
        .toUpperCase();
      return <FileField title={pdfName} {...props} />;
    } else {
      pdfName = props.record
        .slice(46)
        .replaceAll("/", "")
        .replaceAll("_", " ")
        .replaceAll("-", " ")
        .replace(".pdf", "")
        .toUpperCase();
      return <a href={`${props.record}`}>{pdfName ? pdfName : props.record.name}</a>;
    }
  };

  useEffect(() => {
    handleSingleRequest(GET_LIST, "languages", {
      pagination: {
        page: "all"
      },
      sort: { field: "name", order: "ASC" },
      filter: { is_enabled: true }
    })
      .then(({ data }) => {
        setAllLanguages(data);
      })
      .catch(error => console.log(error));
  }, []);

  const handleChangeTechDataTab = (event, value) => {
    setActiveTechDataTab(value);
  };

  const handleChangePDFTab = (event, value) => {
    setActivePdfTab(value);
  };

  const handleDeleteTranslation = async (props, refresh) => {
    const { record } = props;
    try {
      await handleSingleRequest(DELETE, "translations", {
        data: {},
        id: record.id
      });
      refresh();
    } catch (error) {
      console.log("[Error delete tech data translation]", error);
      throw new HttpError("Error delete tech data translation");
    }
  };

  return (
    <Edit title={<EditTitle />} {...props}>
      <TabbedForm redirect="show" toolbar={<ConfirmationToolbar />}>
        <FormTab label="resources.products.tabs.basic.name">
          <ReferenceInput
            label="resources.products.tabs.basic.category_name"
            source="category_id"
            reference="productCategories"
            allowEmpty
            validate={required()}
            filterToQuery={nameFilter}
            perPage={10}
            variant="standard"
          >
            <AutocompleteInput optionText="name" inputValueMatcher={() => null} />
          </ReferenceInput>
          <ReferenceInput
            variant="standard"
            label="resources.products.tabs.basic.product_type"
            source="product_type_id"
            reference="productTypes"
            validate={required()}
            filterToQuery={nameFilter}
            perPage={10}
          >
            <AutocompleteInput optionText="name" inputValueMatcher={() => null} />
          </ReferenceInput>
          <TextInput
            label="resources.products.tabs.basic.product_name"
            source="name"
            validate={required()}
            variant="standard"
          />
          <TextInput
            label="resources.products.tabs.basic.url_slug"
            source="url_slug"
            validate={required()}
            variant="standard"
          />
          <TextInput
            label="resources.products.tabs.basic.external_id"
            variant="standard"
            source="external_id"
          />
          <TextInput
            label="resources.products.tabs.basic.external_number"
            source="external_number"
            validate={required()}
            variant="standard"
          />
          <TextInput
            label="resources.products.tabs.basic.sirv_url"
            source="sirv_url"
            variant="standard"
            validate={productValidationSirv}
            parse={value => {
              return value || "";
            }}
          />

          <BooleanInput
            label="resources.products.tabs.basic.is_visible_in_shop"
            source="is_visible_in_shop"
          />
          <BooleanInput label="resources.products.tabs.basic.has_content" source="has_content" />
          <BooleanInput
            label="resources.products.tabs.basic.show_discount"
            source="show_discount"
          />
          <RichTextInput label="Short description v2" source="story" style={{ width: "100%" }} />
          <GuidanceBox
            showInfoBox="none"
            showImageBox="flex"
            buttonLabel="Learn where short description v2 field will showcase"
            labelName="Short description v2"
            imageWeb={ShortDescriptionWeb}
            imageMobile={ShortDescriptionMobile}
          />
          <RichTextInput
            label="resources.products.tabs.basic.short_description"
            source="short_description"
            validate={required()}
          />
          <TextInput label="Product brand" variant="standard" source="brand" />
          {/* please don't change textInput for feed_name and feed_description*/}
          <TextInput label="Product Name for feed" variant="standard" source="feed_name" />
          <TextInput
            label="Product Description for feed"
            variant="standard"
            source="feed_description"
          />
        </FormTab>
        <FormTab label="resources.products.tabs.presentation.name">
          <GuidanceBox
            showInfoBox="none"
            showImageBox="flex"
            buttonLabel="Learn where presentation fields will showcase"
            labelName={resources?.products?.tabs?.presentation.name}
            imageWeb={PresentationWeb}
            imageMobile={PresentationMobile}
          />
          <RichTextInput
            label="resources.products.tabs.presentation.name"
            source="presentation"
            toolbar={[ToolbarOptions]}
            validate={required()}
          />
        </FormTab>
        <FormTab label="resources.products.tabs.price.name">
          <ReferenceManyField
            label="resources.products.tabs.price.name"
            addLabel={false}
            reference="productPrices"
            target="product_id"
            sort={{ field: "end_date", order: "ASC" }}
          >
            <Datagrid>
              <DateField label="resources.products.tabs.price.created_at" source="created_at" />
              <DateField label="resources.products.tabs.price.start_date" source="start_date" />
              <DateField label="resources.products.tabs.price.updated_at" source="updated_at" />
              <DateField label="resources.products.tabs.price.end_date" source="end_date" />
              <TextField
                label="resources.products.tabs.price.erp_price_type"
                source="erp_price_type"
              />
              <TextField label="resources.products.tabs.price.net_price" source="net_price" />
              <TextField
                label="resources.products.tabs.price.original_net_price"
                source="original_net_price"
              />
              <TextField
                label="resources.products.tabs.price.erp_net_price"
                source="erp_net_price"
              />
              <TextField
                label="resources.products.tabs.price.deposit_company"
                source="deposit_company_price"
              />
              <TextField
                label="resources.products.tabs.price.deposit_person"
                source="deposit_person_price"
              />
              <BooleanField
                label="resources.products.tabs.price.affiliate_only"
                source="affiliate_only"
              />
              {feature("fullProductManagment") ? <EditButton /> : undefined}
            </Datagrid>
          </ReferenceManyField>
          {feature("fullProductManagment") ? <AddPriceButton /> : undefined}
        </FormTab>
        <FormTab label="resources.products.tabs.tech_data.name">
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            value={activeTechDataTab}
            onChange={handleChangeTechDataTab}
            style={{ width: "100%" }}
          >
            <Tab
              label={
                <TranslatableParagraph
                  customStyling={{ margin: 0 }}
                  translationLabel="resources.products.tabs.tech_data.name"
                />
              }
            />
            <Tab
              label={
                <TranslatableParagraph
                  customStyling={{ margin: 0 }}
                  translationLabel="resources.products.tabs.translations.name"
                />
              }
            />
          </Tabs>

          {activeTechDataTab === 0 && (
            <ReferenceManyField
              perPage={100}
              addLabel={false}
              reference="techDataLists"
              target="product_id"
              sort={{ field: "tech_data_order", order: "ASC" }}
            >
              <Datagrid>
                <NumberField
                  label="resources.products.tabs.tech_data.tech_data_order"
                  source="tech_data_order"
                />
                <TextField label="resources.products.tabs.tech_data.label" source="label" />
                <RichTextField
                  label="resources.products.tabs.tech_data.tech_data_value"
                  source="tech_data_value"
                />
                <EditButton />
                <DeleteConfirmation undoable={false} redirect={false} />
                <TranslateButton />
              </Datagrid>
            </ReferenceManyField>
          )}
          {activeTechDataTab === 0 && <AddTechDataButton />}
          {activeTechDataTab === 1 && (
            <FormDataConsumer>
              {({ formData, scopedFormData, ...rest }) => {
                return (
                  <ArrayField
                    source="techDataTranslations"
                    variant="standard"
                    label="resources.products.tabs.translations.name"
                  >
                    <Datagrid>
                      <TextField
                        source="language.name"
                        label="resources.products.tabs.translations.language_name"
                      />

                      <TextField
                        source="tech_data_order"
                        label="resources.products.tabs.tech_data.tech_data_order"
                      />
                      <TextField source="label" label="resources.products.tabs.tech_data.label" />
                      <RichTextField
                        source="tech_data_value"
                        label="resources.products.tabs.tech_data.tech_data_value"
                      />

                      <EditTranslationButton productId={formData?.id} />
                      <DeleteTranslationButton
                        handleDeleteTranslation={handleDeleteTranslation}
                        {...props}
                      />
                    </Datagrid>
                  </ArrayField>
                );
              }}
            </FormDataConsumer>
          )}
        </FormTab>
        {/* <FormTab label="resources.products.tabs.description.name">
            <RichTextInput
              label="resources.products.tabs.description.name"
              source="description"
              toolbar={[ToolbarOptions]}
              validate={required()}
            />
          </FormTab> */}
        {/* <FormTab label="resources.products.tabs.story.name">
            <RichTextInput
              label="resources.products.tabs.story.name"
              source="story"
              toolbar={[ToolbarOptions]}
              validate={required()}
            />
          </FormTab> */}
        <FormTab label="resources.products.tabs.assigned_tags.name">
          <ReferenceArrayField
            addLabel={false}
            reference="productTags"
            source="productTag_ids"
            allowEmpty
          >
            <Datagrid>
              <DateField
                label="resources.products.tabs.assigned_tags.assigned_on"
                source="created_at"
              />
              <DateField
                label="resources.products.tabs.assigned_tags.updated_on"
                source="updated_at"
              />
              <ReferenceField
                label="resources.products.tabs.assigned_tags.tag_name"
                reference="tags"
                source="tag_id"
                allowEmpty
              >
                <TextField source="name" />
              </ReferenceField>
              <DeleteConfirmation undoable={false} redirect={false} />
            </Datagrid>
          </ReferenceArrayField>
          <AddTagButton />
        </FormTab>
        <FormTab label="resources.products.tabs.images.name">
          <ReferenceArrayField
            addLabel={false}
            source="productImage_ids"
            reference="vProductImages"
          >
            <Datagrid>
              <TextField label="resources.products.tabs.images.image_order" source="image_order" />
              <TextField label="resources.products.tabs.images.name" source="name" />
              <DateField label="resources.products.tabs.images.created_at" source="created_at" />
              <DateField label="resources.products.tabs.images.updated_at" source="updated_at" />
              <ImageField />
              <EditButton />
              <DeleteConfirmation undoable={false} redirect={false} />
            </Datagrid>
          </ReferenceArrayField>
          <AddImageButton />
        </FormTab>
        {feature("seoTags") && (
          <FormTab label="resources.products.tabs.seo_tags.name">
            <ReferenceField
              addLabel={false}
              reference="headTags"
              source="head_tag_id"
              link={false}
              allowEmpty={true}
            >
              <HeadTagsTable />
            </ReferenceField>
            <AddSEOTags />
          </FormTab>
        )}
        <FormTab label="resources.products.tabs.documents.name">
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            value={activePDFTab}
            onChange={handleChangePDFTab}
            style={{ width: "100%" }}
          >
            <Tab
              label={
                <TranslatableParagraph
                  customStyling={{ margin: 0 }}
                  translationLabel="resources.products.tabs.user_manual.name"
                />
              }
            />
            <Tab
              label={
                <TranslatableParagraph
                  customStyling={{ margin: 0 }}
                  translationLabel="resources.products.tabs.additional_document.name"
                />
              }
            />
          </Tabs>
          {activePDFTab === 0 && (
            <FileInput
              label="resources.products.tabs.user_manual.name"
              labelSingle="ra.custom_files.fileInput.name"
              accept="application/pdf"
              source="file_path"
            >
              <CustomFileField source="src" />
            </FileInput>
          )}
          {activePDFTab === 1 && (
            <FileInput
              label="resources.products.tabs.additional_document.name"
              labelSingle="ra.custom_files.fileInput.name"
              accept="application/pdf"
              source="additional_file_path"
            >
              <CustomFileField source="src" />
            </FileInput>
          )}
        </FormTab>
        <FormTab label="resources.products.tabs.translations.name">
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
              return (
                <ArrayInput
                  addLabel={false}
                  variant="standard"
                  source="translations"
                  label="resources.products.tabs.translations.name"
                >
                  <SimpleFormIterator
                    disableReordering
                    TransitionProps={{ enter: false, exit: false }}
                  >
                    <SelectInput
                      source="language.id"
                      choices={allLanguages}
                      label="resources.products.tabs.translations.language_name"
                      validate={thereCanBeOnlyOne}
                    />
                    <TextInput
                      label="resources.products.tabs.basic.product_name"
                      source="name"
                      validate={required()}
                    />
                    <RichTextInput
                      label="resources.products.tabs.basic.short_description"
                      source="short_description"
                      style={{ width: "100%" }}
                      validate={required()}
                    />
                    <RichTextInput
                      label="resources.products.tabs.presentation.name"
                      source="presentation"
                      style={{ width: "100%" }}
                      validate={required()}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              );
            }}
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
export default ProductEdit;

const AddSEOTags = ({ record }) => {
  if (!record.head_tag_id) {
    return <HeadTagsQuickCreateButton record={record} resource="products" />;
  }
  return <HeadTagsQuickEditButton record={record} resource="products" />;
};
