import moment from "moment";
import { handleSingleRequest } from "../restClient";

import HttpError from "../HttpError";

// Data used for duration conversion.
const CONVERSION_VALUES = {
  COOK: "cook",
  PREP: "prep"
};

/*
 * Maps reference object from ArrayInput to array of strings
 * [{key: value}, {key: value}] => ["value", "value"]
 */
const mapRefObjectArrayToStringArray = (arr, key) => {
  if (arr && arr.length) {
    return arr.map(x => x[key]);
  }
  return [];
};

/*
 * Maps array of strings to reference object in order to use
 * it for prefilling ArrayInput
 * ["value", "value"] => [{key: "value"}, {key: "value"}]
 */
const mapStringArrayToReferenceObject = (arr, key) => {
  if (arr && arr.length) {
    return arr.map(x => {
      const refOb = {};
      refOb[key] = x;

      return refOb;
    });
  }
  return [];
};

/*
 * Converts duration data collected from form into single
 * ISO 8601 duration format.
 */
const convertDurationsToISO = (data, fields, durationField = false) => {
  fields.forEach(item => {
    data[durationField ? "duration" : `${item}_time`] = moment
      .duration({
        seconds: data[`seconds_${item}`],
        minutes: data[`minutes_${item}`],
        hours: data[`hours_${item}`]
      })
      .toISOString();
  });
  return data;
};

/*
 * Converts ISO 8601 values to preformatted time values
 * needed to prefill the data.
 */
const convertISODurationToMoment = (data, fields, durationField = false) => {
  fields.forEach(item => {
    const momentDuration = moment.duration(data[durationField ? "duration" : `${item}_time`]);
    data[`${item}_time`] = moment.utc(momentDuration.as("milliseconds")).format("HH:mm:ss");
    data[`seconds_${item}`] = momentDuration.seconds();
    data[`minutes_${item}`] = momentDuration.minutes();
    data[`hours_${item}`] = momentDuration.hours();
  });

  return data;
};

/*
 * Used to sanitize data that is sent to BE.
 * Deletes unnecesary time related data after conversion
 * to ISO 8601 format.
 */
const sanitizeData = (data, fields) => {
  fields.forEach(item => {
    delete data[`seconds_${item}`];
    delete data[`minutes_${item}`];
    delete data[`hours_${item}`];
  });

  return data;
};

const preProcessDataOnGet = data => {
  data = convertISODurationToMoment(data, Object.values(CONVERSION_VALUES));
  data.video = JSON.parse(data.video);
  if (data.video) {
    data.video.forEach(video => {
      video = convertISODurationToMoment(video, ["video"], true);
    });
  }
  data.recipe_ingredients = mapStringArrayToReferenceObject(data.recipe_ingredients, "ingredient");
  data.keywords = mapStringArrayToReferenceObject(data.keywords, "keyword");

  return data;
};

// Transformers
// CREATE, UPDATE
export const transformBlogDetailsCreateUpdate = async (type, resource, params) => {
  try {
    params.data.keywords = mapRefObjectArrayToStringArray(params.data.keywords, "keyword");

    params.data.recipe_ingredients = mapRefObjectArrayToStringArray(
      params.data.recipe_ingredients,
      "ingredient"
    );

    params.data = convertDurationsToISO(params.data, Object.values(CONVERSION_VALUES));

    params.data = sanitizeData(params.data, Object.values(CONVERSION_VALUES));

    if (params.data.video) {
      params.data.video.forEach(video => {
        video = convertDurationsToISO(video, ["video"], true);
        video = sanitizeData(video, ["video"]);
      });
    }

    return handleSingleRequest(type, resource, {
      ...params,
      data: {
        ...params.data,
        video: JSON.stringify(params.data.video)
      }
    }).then(({ data = [], included = [], relationships, ...rest }) => {
      return { data, ...rest };
    });
  } catch (error) {
    console.log("error: ", error);
    throw new HttpError(error);
  }
};

// GET_ONE, GET_MANY, GET_MANY_REFERENCE
export const transformBlogDetails = (type, resource, params) => {
  if (type === "GET_ONE") {
    params.include = ["blogArticle"];
  }
  return handleSingleRequest(type, resource, {
    ...params
  }).then(({ data = [], included = [], relationships, ...rest }) => {
    if (data.length && data.length > 0) {
      data = data.map(item => {
        return preProcessDataOnGet(item);
      });
    } else if (data && !Array.isArray(data)) {
      data = preProcessDataOnGet(data);
      data.is_recipe = included[0].is_recipe;
    }
    return { data, ...rest };
  });
};
