import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { createTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

const breakpoints = createBreakpoints({});

export const Theme = createTheme({
  sidebar: {
    width: 200,
    closedWidth: 50
  },
  palette: {
    primary: {
      // main: "#fff",
      main: "#3498db"
    },
    secondary: {
      // main: "#3498db"
      main: "#fff"
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Proxima Nova Regular",
      "Myriad Pro Light",
      "OpenSans Regular",
      '"Segoe UI"',
      "Arial",
      "sans-serif"
    ].join(","),
    fontSize: 12
  },
  overrides: {
    RaSidebar: {
      root: {
        paddingBottom: 24,
        height: "auto",

        // Remove this selector once configurations are tested on production
        "& a[href^='#/configurations']": {
          display: "none"
        },
        "& a[href^='#/languages']": {
          display: "none"
        },
        "& a[href^='#/audits']": {
          display: "none"
        },
        "& a[href^='#/notifications']": {
          display: "none"
        },
        "& a[href^='#/notificationCategories']": {
          display: "none"
        },
        "& a[href^='#/userLevels']": {
          display: "none"
        }
      }
    },
    RaLayout: {
      root: {
        minHeight: "calc(100vh - 85px)"
      },
      appFrame: {
        flexGrow: 0,
        [breakpoints.up("xs")]: {
          marginTop: 0
        }
      },
      content: {
        paddingTop: 24
      }
    },
    RaList: {
      content: {
        paddingTop: 8,
        borderRadius: "0 0 4px 4px"
      }
    },
    RaMenu: {
      main: {
        maxWidth: "100%",
        minWidth: "100%",
        marginTop: "1.5em"
      }
    },
    RaMenuItemLink: {
      active: {
        "& > div": {
          color: "rgba(0, 0, 0, 0.87)"
        }
      }
    },
    RaSingleFieldList: {
      root: {
        marginTop: 0,
        marginBottom: 0
      }
    },
    RaListToolbar: {
      toolbar: {
        [breakpoints.up("xs")]: {
          padding: "0 1rem",
          paddingLeft: "1rem",
          backgroundColor: "#fff",
          boxShadow:
            "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
          borderRadius: "4px 4px 0 0",
          "& > div": {
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: "transparent",
            boxShadow: "none"
          }
        }
      }
    },
    RaTopToolbar: {
      root: {
        [breakpoints.up("xs")]: {
          paddingLeft: "1rem",
          paddingRight: "1rem",
          backgroundColor: "#fff",
          boxShadow:
            "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
          borderRadius: "4px 4px 0 0"
        }
      }
    },
    RaBulkActionsToolbar: {
      title: {
        alignItems: "center"
      },
      toolbar: {
        transform: "translateY(-8px)"
      },
      topToolbar: {
        paddingTop: 8,
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: "transparent",
        boxShadow: "none"
      }
    },
    RaShow: {
      main: {
        position: "relative"
      },
      card: {
        borderRadius: "0 0 4px 4px"
      }
    },
    RaEdit: {
      main: {
        position: "relative"
      },
      card: {
        borderRadius: "0 0 4px 4px"
      },
      noActions: {
        marginTop: 0
      }
    },
    RaTabbedForm: {
      content: {
        paddingLeft: 24,
        paddingRight: 24
      }
    },
    RaTabbedShowLayout: {
      content: {
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 16
      }
    },
    RaCardContentInner: {
      root: {
        paddingLeft: 24,
        paddingRight: 24
      }
    },
    RaAutocompleteArrayInput: {
      container: {
        "& > div": {
          minWidth: 256
        }
      }
    },
    RaAutocompleteInput: {
      container: {
        "& > div": {
          minWidth: 256,
          maxWidth: 256
        }
      }
    },
    RaImageInput: {
      dropZone: {
        padding: 16,
        color: "#777",
        background: "#efefef"
      }
    },
    RaFileInput: {
      dropZone: {
        padding: 16,
        color: "#777",
        background: "#efefef"
      }
    },
    RaButton: {
      button: {
        minHeight: 34,
        padding: "7px 8px"
      }
    },
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        // color: "#3498db" // Some CSS
        color: "black" // Some CSS
      },
      text: {
        padding: "8px 16px",
        lineHeight: 1.4
      }
    },
    MuiMenuItem: {
      root: {
        height: 20,
        padding: "7px 16px",
        paddingTop: 7,
        paddingBottom: 7,
        boxSizing: "content-box"
      }
    },
    MuiChip: {
      root: {
        height: 26
      }
    },
    MuiTableRow: {
      root: {
        height: 38
      },
      head: {
        height: 50
      }
    },
    MuiTableCell: {
      sizeSmall: {
        padding: "0 12px",
        "&$paddingCheckbox": {
          width: 60,
          padding: 0,
          "& > *": {
            width: 48,
            height: 48
          }
        }
      },
      head: {
        fontSize: 11,
        lineHeight: 1.1
      }
    },
    MuiInput: {
      root: {
        "&$disabled": {
          padding: "10px 0"
        }
      },
      underline: {
        "&$disabled": {
          padding: "10px 0"
        }
      }
    },
    MuiSvgIcon: {
      root: {
        fontSize: 24
      },
      fontSizeSmall: {
        fontSize: 24
      }
    },
    MuiFormHelperText: {
      root: {
        lineHeight: 1.2
      }
    },
    PrivateSwitchBase: {
      root: {
        width: "auto"
      }
    }
  }
});
