import { handleSingleRequest } from "../restClient";

export const transformFrontDynamicTables = async (type, resource, params) => {
  console.log("ovo su nove date", type, resource, params);
  return handleSingleRequest(type, resource, {
    ...params
  }).then(async ({ data = {}, included = [], relationships, ...rest }) => {
    return { data, ...rest };
  });
};
