export const PriceLevels = [
  { id: "D1", name: "D1" },
  { id: "D2", name: "D2" },
  { id: "D3", name: "D3" },
  { id: "D4", name: "D4" },
  { id: "D5", name: "D5" },
  { id: "D6", name: "D6" },
  { id: "D7", name: "D7" },
  { id: "D8", name: "D8" },
  { id: "D9", name: "D9" },
  { id: "D10", name: "D10" },
  { id: "D11", name: "D11" },
  { id: "D12", name: "D12" }
];

export const NumberOfRegistrated = [
  { id: "999999999", name: "Empty" },
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: "3" },
  { id: "4", name: "4" },
  { id: "5", name: "5" },
  { id: "6", name: "6" },
  { id: "7", name: "7" },
  { id: "8", name: "8" },
  { id: "9", name: "9" },
  { id: "10", name: "10" },
  { id: "11", name: "11" },
  { id: "12", name: "12" },
  { id: "15", name: "15" },
  { id: "20", name: "20" },
  { id: "25", name: "25" },
  { id: "30", name: "30" },
  { id: "35", name: "35" },
  { id: "40", name: "40" },
  { id: "45", name: "45" },
  { id: "50", name: "50" }
];
