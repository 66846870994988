// in src/authProvider.js
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from "react-admin";

// const authUrl = process.env.REACT_APP_AUTH_URL;

const authProvider = (type, params) => {
  if (type === AUTH_LOGIN) {
    const { newAppId, token } = params;

    localStorage.setItem("appId", newAppId);
    localStorage.setItem("token", token);
    setTimeout(() => window.location.reload());
    return Promise.resolve();
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("token");
    localStorage.removeItem("appId");
    window.location.replace("/#/login");
    //window.location.reload();
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("appId");
      return Promise.reject();
    }
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    return localStorage.getItem("token") &&
      localStorage.getItem("appId") &&
      localStorage.getItem("countries")
      ? Promise.resolve()
      : Promise.reject();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    return Promise.resolve();
  }

  return Promise.resolve();
};

export default authProvider;
