import React, { useState, useEffect } from "react";
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Datagrid,
  ReferenceField,
  EditButton,
  BooleanInput,
  ReferenceManyField,
  ImageInput,
  ImageField,
  required,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  useNotify,
  useRedirect
} from "react-admin";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { handleSingleRequest } from "../../services/api/restClient";

import RichTextInput from "../../components/RichTextEditor";

import AddDetailsButton from "./AddDetailsButton";
import HeadTagsTable from "../common/HeadTagsTable";
import HeadTagsQuickCreateButton from "../common/HeadTagsQuickCreateButton";
import HeadTagsQuickEditButton from "../common/HeadTagsQuickEditButton";

import { LinkTabs } from "../../components/LinkTabs";
import { feature } from "../../components/Feature";

import { styles } from "../styles";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

// Custom Validation for fields
import { BlogSlugValidationTextInput } from "./validators";
import { GET_LIST } from "../../services/api/types";

const toolbar = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "code-block",
  { script: "sub" },
  { script: "super" },
  { header: [1, 2, 3, 4, 5, 6, false] },
  { indent: "-1" },
  { indent: "+1" },
  { list: "ordered" },
  { list: "bullet" },
  { size: ["small", false, "large", "huge"] },
  { color: [] },
  { background: [] },
  { align: "" },
  { align: "center" },
  { align: "right" },
  { align: "justify" },
  "link",
  "video",
  "image",
  "clean"
];

const ConfirmationToolbar = props => {
  return (
    <Toolbar {...props} style={styles.toolbar}>
      <SaveButton />
      <DeleteConfirmation undoable={false} />
    </Toolbar>
  );
};

const BlogArticleTitle = ({ record }) => (
  <TranslatableParagraph translationLabel="resources.blogs.title.edit" customLabel={record.title} />
);

export const BlogArticleEdit = props => {
  const [languages, setLanguages] = useState([]);
  const [translatedItems, setTranslatedItems] = useState(0);
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("Blog article is being updated, please wait", { undoable: false });
    notify("Blog article has been updated", { undoable: true });
    redirect("/blogArticles");
  };

  useEffect(() => {
    getLanguages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLanguages = async () => {
    try {
      const { data } = await handleSingleRequest(GET_LIST, "languages", {
        pagination: {
          page: "all"
        },
        sort: { field: "id", order: "DESC" },
        filter: {
          is_enabled: true
        }
      });

      setLanguages(data);
    } catch (error) {
      console.log("[Error get languages (blog category edit)]", error);
    }
  };

  const thereCanBeOnlyOne = (value, formValues) => {
    if (value) {
      let checkForExistingLanguageSelected = formValues.translations.filter(
        item => item && item.language && item.language.id === value
      );
      if (checkForExistingLanguageSelected.length > 1) {
        return "There can be only one translation per language";
      } else {
        return undefined;
      }
    } else {
      return "Required";
    }
  };

  const TranslationsAndContentForm = props => {
    return (
      <ArrayInput source="translations" label="">
        <SimpleFormIterator
          disableReordering
          disableRemove={translatedItems < 2}
          TransitionProps={{ enter: false, exit: false }}
        >
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }) => {
              const blogContentSource = getSource("blogContent");
              const descriptionSource = getSource("description");
              const nameSource = getSource("name");
              const languageIdSource = getSource("language.id");
              if (formData.translations) {
                setTranslatedItems(formData.translations.length);
              }

              return (
                <Accordion
                  {...rest}
                  style={{
                    width: "100%",
                    marginBottom: "20px"
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ justifyContent: "center" }}
                  >
                    <div>{scopedFormData?.language?.name || "New"} </div>
                    <TranslatableParagraph
                      translationLabel="resources.blogs.nameSingular"
                      component="div"
                    />
                  </AccordionSummary>
                  <>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        flexDirection: "column",
                        padding: "10px"
                      }}
                    >
                      <SelectInput
                        variant="standard"
                        source={languageIdSource}
                        choices={languages}
                        label="resources.languages.fields.language_name"
                        validate={thereCanBeOnlyOne}
                      />
                      <TextInput
                        variant="standard"
                        label="resources.pages.fields.name"
                        source={nameSource}
                        validate={required()}
                      />
                      <TextInput
                        variant="standard"
                        label="resources.blogs.fields.short_text"
                        multiline
                        source={descriptionSource}
                        validate={required()}
                      />
                    </div>
                    <div
                      style={{ padding: "10px 5px", borderTop: "1px solid #000", width: "100%" }}
                    >
                      <div style={{ padding: "10px" }}>
                        <RichTextInput source={blogContentSource} toolbar={toolbar} />
                      </div>
                    </div>
                  </>
                </Accordion>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    );
  };

  return (
    <Edit onSuccess={onSuccess} {...props} title={<BlogArticleTitle />}>
      <TabbedForm variant="standard" toolbar={<ConfirmationToolbar />}>
        <FormTab label={"resources.blogs.tabs.basic"}>
          <LinkTabs tabNumber="1" text="Click to change blog content" />
          <TextInput label="resources.blogs.fields.author" source="author" validate={required()} />
          <BlogSlugValidationTextInput />
          <DateTimeInput label="resources.blogs.fields.date" source="date" validate={required()} />
          <ImageInput
            source="image"
            label="resources.productCategories.fields.imageRaw"
            maxSize={2000000}
            labelSingle={
              props.imageError ? (
                <span
                  style={{
                    color: "#f44336",
                    fontSize: "rem",
                    textAlign: "left",
                    marginTop: "-5px",
                    minHeight: "1em",
                    lineHeight: "1em",
                    fontFamily: `Ubuntu Regular,-apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif`
                  }}
                >
                  {props.imageError}
                </span>
              ) : (
                "resources.productCategories.fields.imageRawLabel"
              )
            }
            accept=".jpg,.jpeg,.png"
            validate={required()}
          >
            <ImageField source="src" />
          </ImageInput>
          <BooleanInput label="resources.blogs.fields.is_recipe" source="is_recipe" />
          <BooleanInput label="resources.blogs.fields.enabled" source="enabled" />
          <ReferenceInput
            source="blog_category_id"
            label="resources.blogs.fields.blog_category"
            reference="blogCategories"
            validate={required()}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </FormTab>
        <FormTab label="translations and content">
          <TranslatableParagraph translationLabel="resources.blogs.tabs.translation_content.message" />
          <TranslationsAndContentForm />
        </FormTab>
        {feature("seoTags") && (
          <FormTab label="resources.blogs.tabs.seo">
            <ReferenceManyField addLabel={false} reference="blogDetails" target="id">
              <Datagrid>
                <ArrayField label="resources.blogs.blogDetails.keywords" source="keywords">
                  <SingleFieldList>
                    <ChipField label="resources.blogs.blogDetails.keyword" source="keyword" />
                  </SingleFieldList>
                </ArrayField>
                <TextField label="resources.blogs.blogDetails.description" source="description" />
                <EditButton />
              </Datagrid>
            </ReferenceManyField>
            <AddBlogDetails />
          </FormTab>
        )}

        {feature("seoTags") && (
          <FormTab label="resources.blogs.tabs.meta">
            <ReferenceField
              addLabel={false}
              reference="headTags"
              source="head_tag_id"
              link={false}
              allowEmpty={true}
            >
              <HeadTagsTable />
            </ReferenceField>
            <AddSEOTags />
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

const AddBlogDetails = ({ record }) => {
  if (!record || !record.head_tags) {
    return <AddDetailsButton record={record} />;
  }
  return null;
};

const AddSEOTags = ({ record }) => {
  if (!record.head_tag_id) {
    return <HeadTagsQuickCreateButton record={record} resource="blogArticles" />;
  }
  return <HeadTagsQuickEditButton record={record} resource="blogArticles" />;
};
