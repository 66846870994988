import React from "react";
import {
  Create,
  Edit,
  Show,
  List,
  SimpleForm,
  Toolbar,
  TextInput,
  TextField,
  ImageInput,
  ImageField,
  BooleanInput,
  SimpleShowLayout,
  DateField,
  DeleteButton,
  SaveButton,
  required
} from "react-admin";
// import ImageInput from "../components/ImageInput";
import { Datagrid } from "ra-ui-materialui/lib/list";

const redirect = (basePath, id, data) => `/products/${data.product_id}`;
const redirectEdit = (basePath, id, data) => `/products/${data.product_id}/7`;

export const ImageCreate = props => (
  <Create title="ra.custom_files.imageInput.create.title" {...props}>
    <SimpleForm variant="standard" redirect={redirect}>
      <ImageInput
        source="imageRaw"
        label="ra.custom_files.imageInput.name"
        labelSingle="ra.custom_files.imageInput.box_label"
        accept=".jpg,.jpeg,.png"
        maxSize={2000000}
        validate={required()}
      >
        <ImageField source="src" />
      </ImageInput>
      <TextInput label="ra.custom_files.imageInput.image_order" source="image_order" />
      <BooleanInput label="ra.custom_files.imageInput.overwrite" source="overwrite" />
    </SimpleForm>
  </Create>
);

const ImageEditTitle = ({ record }) => `ra.custom_files.imageInput.edit.title ${record.name}`;

const ImageEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

export const ImageEdit = props => (
  <Edit title={<ImageEditTitle />} undoable={false} redirect={redirectEdit} {...props}>
    <SimpleForm toolbar={<ImageEditToolbar />} redirect={redirect}>
      <TextField label="ra.custom_files.imageInput.name" source="name" validate={required()} />
      <TextInput
        label="ra.custom_files.imageInput.image_order"
        source="image_order"
        variant="standard"
      />
    </SimpleForm>
  </Edit>
);

const ImageShowTitle = ({ record }) => `ra.custom_files.imageInput.show.title ${record.name}`;

export const ImageShow = props => (
  <Show title={<ImageShowTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="ra.custom_files.imageInput.name" source="name" />
      <TextField label="ra.custom_files.imageInput.image_order" source="image_order" />
    </SimpleShowLayout>
  </Show>
);

export const ImagesList = props => (
  <List title="Images List" {...props}>
    <Datagrid>
      <TextField source="name" />
      <DateField label="ra.custom_files.imageInput.name" source="created_at" />
      <DateField label="ra.custom_files.imageInput.image_order" source="updated_at" />
      <DeleteButton />
    </Datagrid>
  </List>
);
