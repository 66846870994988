import React, { useState, useEffect } from "react";
import { useDataProvider, TextInput, required } from "react-admin";

export const BlogCategoryNameValidationTextInput = formValues => {
  let saveExistingBlogCategoryName =
    formValues && formValues.record && formValues.record.name ? formValues.record.name : "";

  // Declaring state
  const dataProvider = useDataProvider();
  const [blogCategories, setBlogCategories] = useState([]);
  const [currentKeyName, setCurrentKeyName] = useState(saveExistingBlogCategoryName);

  // Drag needed information
  useEffect(() => {
    dataProvider
      .getList("blogCategories", {
        filter: {},
        sort: { field: "created_at", order: "DESC" },
        pagination: { page: "all" }
      })
      .then(({ data }) => {
        setBlogCategories(data);
      })
      .catch(error => {
        console.error(
          "There was a problem with data in your dataProvider, getList for blogCategories"
        );
      });
  }, [currentKeyName, dataProvider]);

  // Validation for page name input field
  const validateName = (value, formValues) => {
    let lastCharacter = value && value.charAt(value.length - 1);
    let firstCharacter = value && value.charAt(0);

    if (!value) {
      return "ra.validation.required";
    }

    if (lastCharacter === " " || firstCharacter === " ") {
      return "Please remove white-spaces from your name";
    }

    if (value && !value.trim()) {
      return "Blog category name is not valid";
    }

    const blogCategoryNameExists = blogCategories.find(
      category => category?.name?.toLowerCase() === value?.toLowerCase()
    );

    if (
      blogCategoryNameExists &&
      blogCategoryNameExists.name.toUpperCase() !== currentKeyName.toUpperCase()
    ) {
      return "Blog category name already exists. Please change blog category name.";
    }

    return undefined;
  };

  // Return TextInput field to be showcased on the form
  return (
    <TextInput
      variant="standard"
      style={{ minWidth: "256px" }}
      label="resources.pages.fields.name"
      source="name"
      validate={[validateName, required()]}
    />
  );
};

export const BlogCategorySlugValidationTextInput = formValues => {
  let saveExistingBlogCategorySlug =
    formValues && formValues.record && formValues.record.url_slug ? formValues.record.url_slug : "";

  // Declaring state
  const dataProvider = useDataProvider();
  const [blogCategories, setBlogCategories] = useState([]);
  const [currentKeyName, setCurrentKeyName] = useState(saveExistingBlogCategorySlug);

  // Drag needed information
  useEffect(() => {
    dataProvider
      .getList("blogCategories", {
        filter: {},
        sort: { field: "created_at", order: "DESC" },
        pagination: { page: "all" }
      })
      .then(({ data }) => {
        setBlogCategories(data);
      })
      .catch(error => {
        console.error(
          "There was a problem with data in your dataProvider, getList for blogCategories"
        );
      });
  }, [currentKeyName, dataProvider]);

  // Validation for page name input field
  const validateSlug = (value, formValues) => {
    let lastCharacter = value && value.charAt(value.length - 1);
    let firstCharacter = value && value.charAt(0);

    if (!value) {
      return "ra.validation.required";
    }

    if (lastCharacter === " " || firstCharacter === " ") {
      return "Please remove white-spaces from your url slug";
    }

    const blogCategorySlugExists = blogCategories.find(
      category => category?.url_slug?.toLowerCase() === value?.toLowerCase()
    );

    if (
      blogCategorySlugExists &&
      blogCategorySlugExists?.url_slug?.toLowerCase() !== currentKeyName.toLowerCase()
    ) {
      return "Blog category url slug already exists. Please change blog category url slug.";
    }

    return undefined;
  };

  // Return TextInput field to be showcased on the form
  return (
    <TextInput
      variant="standard"
      style={{ minWidth: "256px" }}
      label="resources.pages.fields.url_slug"
      source="url_slug"
      validate={[validateSlug, required()]}
    />
  );
};
