import React, { useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  FormDataConsumer,
  ReferenceField,
  TextInput,
  TextField,
  Toolbar,
  SaveButton,
  SelectInput,
  ReferenceInput,
  required
} from "react-admin";
import { Field, useForm, useField } from "react-final-form";
import { withStyles } from "@material-ui/core";

import Editor from "../../components/ContentEditor/Editor";
import IsCategory from "./IsCategory";
import IsHomepage from "./IsHomepage";
import BannerInput from "../../components/BannerInput";
import ImagesInput from "../../components/ImagesInput";

// import get from "lodash/get";

const PageShowTitle = ({ record }) => `Content ${record.name}`;

const SubReference = ({ translateChoice, children, ...props }) => (
  <ReferenceField {...props}>{children}</ReferenceField>
);

const defaultValue = {
  bannersCarousel: []
};

const toolbarStyles = {
  toolbar: {
    display: "flex",
    justifyContent: "space-between"
  }
};

const ConfirmationToolbar = ({ isSaveDisabled, ...rest }) => {
  return (
    <Toolbar {...rest}>
      <SaveButton label="ra.action.save" disabled={isSaveDisabled} />
    </Toolbar>
  );
};

// When loading page, field bannersCarousel is not pre-populated with data
// This is the way to update it live
const UpdateFormField = props => {
  const field = useField();
  const form = useForm();
  useEffect(() => {
    if (props?.record?.bannersCarousel?.length === 0) {
      if (field.input?.value?.bannersCarousel.length !== 0) {
        form.change("images", props.record.bannersCarousel);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.input?.value?.bannersCarousel?.length]);
  return null;
};

const redirect = (basePath, id, data) => `/pages/${data.page_id}/2`;

export const PageContentsEdit = props => {
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const saveButtonHandler = isEditMode => {
    setIsSaveDisabled(isEditMode);
  };

  return (
    <Edit undoable={false} title={<PageShowTitle />} {...props}>
      <SimpleForm
        variant="standard"
        sanitizeEmptyValues={false}
        toolbar={<ConfirmationToolbar isSaveDisabled={isSaveDisabled} />}
        initialValues={defaultValue}
        redirect={redirect}
        style={{ maxWidth: "100vw" }}
      >
        <ReferenceInput
          filter={{ is_enabled: true }}
          label="Language"
          source="language_id"
          reference="languages"
          // perPage={30}
        >
          <SelectInput optionText="name" validate={required()} />
        </ReferenceInput>
        <ReferenceField
          label="resources.pageContents.fields.name"
          source="page_id"
          reference="pages"
          variant="body1"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextInput source="name" label="resources.pageContents.fields.version_name" />
        {/* CATEGORY */}
        <>
          <br />
          <br />
          <hr style={{ width: "100%" }} />
          <br />
        </>
        <UpdateFormField />
        <ReferenceField addLabel={false} source="page_id" reference="pages" link={false}>
          <SubReference source="page_type_id" reference="pageTypes" link={false}>
            <IsCategory>
              <BannerInput />
            </IsCategory>
          </SubReference>
        </ReferenceField>
        {/* ARtICLE */}
        {/* <ReferenceField
          addLabel={false}
          source="page_id"
          reference="pages"
          link={false}
        >
          <SubReference
            source="page_type_id"
            reference="pageTypes"
            link={false}
          >
            <IsArticle>
              <BannerInput />
            </IsArticle>
          </SubReference>
        </ReferenceField> */}
        {/* HOMEPAGE */}
        <ReferenceField addLabel={false} source="page_id" reference="pages" link={false}>
          <SubReference source="page_type_id" reference="pageTypes" link={false}>
            <IsHomepage>
              <BannerInput />
            </IsHomepage>
          </SubReference>
        </ReferenceField>
        <ReferenceField addLabel={false} source="page_id" reference="pages" link={false}>
          <SubReference source="page_type_id" reference="pageTypes" link={false}>
            <IsHomepage>
              <br />
              <br />
              <hr style={{ width: "100%" }} />
              <br />
              <ImagesInput source="images" />
            </IsHomepage>
          </SubReference>
        </ReferenceField>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              <Field
                name="content"
                component={Editor}
                contentData={formData}
                saveButtonHandler={saveButtonHandler}
              />
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};
