import moment from "moment";
import { validateLettersNumbersSpaces, validateTechDataValues } from "./regex";

export const required = value => (value ? undefined : "Required");
export const minValue = min => value =>
  value && value < min ? `Can not be less than ${min}` : undefined;
export const maxValue = max => value =>
  value && value > max ? `Can not be more than ${max}` : undefined;
export const maxLength = max => value =>
  value && value.length > max ? `Can not be longer than ${max} characters` : undefined;
export const validateWholeNumber = value => {
  if (value % 1 !== 0) {
    return "Please enter a whole number.";
  }
  return undefined;
};

export const dateValidator = format => (value, formValues) => {
  const check = moment(value).format(format);
  const errorMsg = `Please provide date in format ${format}`;
  const startDate = formValues?.rule?.start_date || formValues?.cron?.start_date;
  const endDate = formValues?.rule?.end_date || formValues?.cron?.end_date;
  if (moment(startDate).format() >= moment(endDate).format()) {
    return "Start date can not be after end date";
  }

  if (check === "Invalid date") {
    return errorMsg;
  } else {
    const dateElements = format?.length > 10 ? check?.slice(0, 10).split("-") : value?.split("-");
    if (dateElements[0].length !== 4) {
      return errorMsg;
    }
  }
  return undefined;
};

export const couponValidator = value =>
  value && /^([a-zA-Z0-9]{1,10})$/.test(value) ? undefined : `Promo code is not valid`;

export const rolesValidation = (value, formValues) => {
  if (formValues.isSpecificUserPromotion) {
    return undefined;
  } else {
    if (formValues.role_id && formValues.role_id.length > 0) {
      return undefined;
    } else {
      return "Required";
    }
  }
};

export const userIdsValidation = (value, formValues) => {
  if (formValues.isSpecificUserPromotion) {
    if (formValues.user_id && formValues.user_id.length > 0) {
      return undefined;
    } else {
      return "Required";
    }
  } else {
    return undefined;
  }
};

export const userLevelsValidation = (value, formValues) => {
  if (
    formValues &&
    formValues.role_id &&
    formValues.role_id.includes("40e505de-2451-11e8-b34c-37a6db76516b") &&
    !formValues.role_id.includes("40d3ec7c-2451-11e8-b34b-df8ee6486630") &&
    !formValues.role_id.includes("40b098a8-2451-11e8-b349-6b57a44afd96") &&
    !formValues.role_id.includes("40c210ba-2451-11e8-b34a-ff279e1b43e6")
  ) {
    if (formValues.level_id && formValues.level_id.length > 0) {
      return undefined;
    } else {
      return "Required";
    }
  } else {
    return undefined;
  }
};

export const validateTechDataValue = value => {
  if (value) {
    if (value.startsWith("-") || value.endsWith("-")) {
      return "Please make sure your input is not starting or ending in -";
    }

    if (value.startsWith(" ") || value.endsWith(" ")) {
      return "Please remove white-spaces from start as well as the end";
    }

    if (!validateTechDataValues(value)) {
      return "Only letters, numbers and special characters are allowed, stylized text (bold, underlined, etc.) is not allowed";
    }
    return undefined;
  }
};

export const validateNameInput = value => {
  //   If input has value
  if (value) {
    // Search input value for dashes both at start and end
    if (value.startsWith("-") || value.endsWith("-")) {
      return "Please make sure your input is not starting or ending in -";
    }

    // Search input value for spaces both at the start and end
    if (value.startsWith(" ") || value.endsWith(" ")) {
      return "Please remove white-spaces from start as well as the end";
    }
    // If input constains any special characters
    if (!validateLettersNumbersSpaces(value)) {
      return "Input can only have letters and numbers";
    }
    return undefined;
  }
};

export const noWhiteSpaceValidation = value => {
  if (value.trim() === "") {
    return "Not valid, input can not have only white-space as a value";
  }

  if (value.startsWith(" ")) {
    return "Please remove white-space from start";
  }

  if (value.endsWith(" ")) {
    return "Please remove white-space from end";
  }

  return undefined;
};
