import { handleSingleRequest } from "../restClient";
import { CREATE, UPDATE, GET_LIST, GET_ONE } from "../types";
import HttpError from "../HttpError";

const getSearchStringParams = stringInut => {
  try {
    return JSON.parse(
      stringInut.substring(stringInut.indexOf("{"), stringInut.lastIndexOf("}") + 1)
    );
  } catch (e) {
    return null;
  }
};

export const transformTechData = async (type, resource, params) => {
  const decodedSearchString = decodeURIComponent(window.location.hash);
  const searchStringParams = getSearchStringParams(decodedSearchString);

  // updating translation
  if (
    searchStringParams?.isEditTranslation &&
    searchStringParams?.translationId &&
    searchStringParams?.productId
  ) {
    if (type === GET_ONE) {
      // fill form with current translation data
      const { data: techDataTranslation } = await handleSingleRequest(GET_ONE, "translations", {
        id: searchStringParams?.translationId
      });
      const data = {
        id: params.id,
        label: techDataTranslation.translation.label,
        tech_data_order: techDataTranslation.translation.tech_data_order,
        tech_data_value: techDataTranslation.translation.tech_data_value,
        product_id: searchStringParams.productId,
        language_id: techDataTranslation.language_id
      };
      return { data };
    } else if (type === UPDATE) {
      const { data } = await handleSingleRequest(UPDATE, "translations", {
        id: searchStringParams.translationId,
        data: {
          translation: {
            label: params.data.label,
            tech_data_order: params.data.tech_data_order,
            tech_data_value: params.data.tech_data_value
          }
        }
      });
      return { data };
    }
    // creating new translation
  } else if (params.data?.isNewTranslation && params.data?.language) {
    if (type === CREATE) {
      const { data: techDataTranslations } = await handleSingleRequest(GET_LIST, "translations", {
        filter: {
          table_row_id: params.data.id
        },
        pagination: { page: "all" },
        sort: {}
      });
      techDataTranslations.forEach(translation => {
        // prevent create duplicate language translation
        if (translation.language_id === params.data.language.id) {
          throw new HttpError(
            "TRANSLATION FOR THAT LANGUAGE ALLREADY EXISTS, YOU CAN EDIT IT IN TECH DATA TRANSLATION TAB"
          );
        }
      });

      const { data } = await handleSingleRequest(CREATE, "translations", {
        data: {
          table_row_id: params.data.id,
          language_id: params.data.language.id,
          table_name: "tech_data_lists",
          translation: {
            tech_data_order: params.data.tech_data_order,
            tech_data_value: params.data.tech_data_value,
            label: params.data.label
          }
        }
      });
      return { data };
    }
  } else {
    return handleSingleRequest(type, resource, {
      ...params
    }).then(({ data = [], included = [], ...rest }) => {
      return { data, ...rest };
    });
  }
};
