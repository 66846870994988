import React from "react";
import { Filter, TextInput } from "react-admin";

export const ListFilters = props => (
  <Filter variant="standard" {...props}>
    <TextInput
      style={{ minWidth: "600px" }}
      label="resources.notifications.filters.search"
      source="title_ilike"
      alwaysOn
    />
  </Filter>
);
