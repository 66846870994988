import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import Autosuggest from "react-autosuggest";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import blue from "@material-ui/core/colors/blue";
import compose from "recompose/compose";
import classNames from "classnames";
import uniq from "lodash/uniq";

import Confirm from "../../components/CustomConfirm";

import { addField, translate, FieldTitle } from "ra-core";

import AutocompleteArrayInputChip from "./AutocompleteArrayInputChip";

import { handleSingleRequest } from "../../services/api/restClient";
import { GET_LIST } from "react-admin";

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: "relative"
  },
  root: {},
  suggestionsContainerOpen: {
    position: "absolute",
    // marginBottom: theme.spacing(3),
    zIndex: 2
  },
  suggestion: {
    display: "block",
    fontFamily: theme.typography.fontFamily
  },
  suggestionText: { fontWeight: 300 },
  highlightedSuggestionText: { fontWeight: 500 },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  chip: {
    marginRight: theme.spacing(),
    marginTop: `4px`,
    marginBottom: `4px`
  },
  chipDisabled: {
    pointerEvents: "none"
  },
  chipFocused: {
    backgroundColor: blue[300]
  }
});

export class AutocompleteArrayInput extends React.Component {
  state = {
    dirty: false,
    inputValue: null,
    searchText: "",
    suggestions: [],
    pastedValue: "",
    products: [],
    invalidExternalNumbers: [],
    isConfirmDialogOpen: false,
    hideConfirmDialogButton: true
  };

  inputEl = null;

  UNSAFE_componentWillMount() {
    this.setState({
      inputValue: this.props.input.value,
      suggestions: this.props.choices
    });
  }

  componentDidMount() {
    if (this.props.invoked) {
      handleSingleRequest(GET_LIST, "products", {
        pagination: { page: "all" },
        sort: { field: "id", order: "DESC" },
        filter: { is_enabled: true },
        include: ["product"]
      })
        .then(({ data }) => {
          this.setState({
            products: data
          });
        })
        .catch(err => console.log(err));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { choices, input, inputValueMatcher } = nextProps;
    if (!isEqual(input.value, this.state.inputValue)) {
      this.setState({
        inputValue: input.value,
        dirty: false,
        suggestions: this.props.choices
      });
      // Ensure to reset the filter
      this.updateFilter("");
    } else if (!isEqual(choices, this.props.choices)) {
      this.setState(({ searchText }) => ({
        suggestions: choices.filter(suggestion =>
          inputValueMatcher(searchText, suggestion, this.getSuggestionText)
        )
      }));
    }
  }

  getSuggestionValue = suggestion => get(suggestion, this.props.optionValue);

  getSuggestionText = suggestion => {
    if (!suggestion) return "";

    const { optionText, translate, translateChoice } = this.props;
    const suggestionLabel =
      typeof optionText === "function" ? optionText(suggestion) : get(suggestion, optionText);

    // We explicitly call toString here because AutoSuggest expect a string
    return translateChoice
      ? translate(suggestionLabel, { _: suggestionLabel }).toString()
      : suggestionLabel.toString();
  };

  handleSuggestionSelected = (event, { suggestion, method }) => {
    const { input } = this.props;

    input.onChange([...(this.state.inputValue || []), this.getSuggestionValue(suggestion)]);

    this.setState({
      searchText: "" // clear the search text to show all suggestions again
    });

    if (method === "enter") {
      event.preventDefault();
    }
  };

  handleSuggestionsFetchRequested = () => {
    const { choices, inputValueMatcher } = this.props;

    this.setState(({ searchText }) => ({
      suggestions: choices.filter(suggestion =>
        inputValueMatcher(searchText, suggestion, this.getSuggestionText)
      )
    }));
  };

  handleSuggestionsClearRequested = () => {
    this.updateFilter("");
  };

  handleMatchSuggestionOrFilter = inputValue => {
    this.setState({
      dirty: true,
      searchText: inputValue
    });
    this.updateFilter(inputValue);
    this.setState({
      searchText: ""
    });
  };

  onPaste = pastedValue => {
    const { input } = this.props;

    if (this.state.inputValue) {
      input.onChange(this.state.inputValue.concat(pastedValue));
    } else {
      input.onChange(...this.state.inputValue, pastedValue);
    }
  };

  handleChange = (event, { newValue, method }) => {
    this.props?.customOnChange?.({ newValue, method });
    const rawValues = newValue.split(" ");
    if (rawValues.length >= 2 && this.props.invoked) {
      const inputValues = rawValues.filter(val => val !== "");

      let validProductsIds = [];
      let invalidProductsExternalNumbers = [];

      let validObjects = this.state.products.filter(item =>
        inputValues.some(obj => obj === item.external_number)
      );

      invalidProductsExternalNumbers = inputValues.filter(
        item => !validObjects.some(obj => obj.external_number === item)
      );

      validObjects.forEach((item, index) => validProductsIds.push(item.id));
      this.onPaste(validProductsIds);

      if (invalidProductsExternalNumbers.length > 0) {
        this.setState({
          isConfirmDialogOpen: !this.state.isConfirmDialogOpen,
          invalidExternalNumbers: invalidProductsExternalNumbers
        });
      }
    }

    switch (method) {
      case "type":
      case "escape":
        this.handleMatchSuggestionOrFilter(newValue);
        break;
      default:
        break;
    }
  };

  renderInput = inputProps => {
    const { input, disabled } = this.props;
    const {
      autoFocus,
      className,
      classes,
      isRequired,
      label,
      meta,
      onChange,
      resource,
      source,
      value,
      ref,
      options: { InputProps, ...options },
      ...other
    } = inputProps;
    if (typeof meta === "undefined") {
      throw new Error(
        "The TextInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
      );
    }

    const { touched, error } = meta;

    // We need to store the input reference for our Popper element containg the suggestions
    // but Autosuggest also needs this reference (it provides the ref prop)
    const storeInputRef = input => {
      this.inputEl = input;
      ref(input);
    };

    return (
      <AutocompleteArrayInputChip
        clearInputValueOnChange
        onUpdateInput={onChange}
        onAdd={this.handleAdd}
        onDelete={this.handleDelete}
        value={uniq(input.value)}
        inputRef={storeInputRef}
        error={touched && error}
        disabled={disabled}
        helperText={touched && error}
        chipRenderer={this.renderChip}
        label={
          <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
        }
        {...other}
        {...options}
      />
    );
  };

  renderChip = ({ value, isFocused, isDisabled, handleClick, handleDelete }, key) => {
    const { classes = {}, choices } = this.props;

    const suggestion = choices.find(choice => this.getSuggestionValue(choice) === value);

    return (
      <Chip
        key={key}
        className={classNames(classes.chip, {
          [classes.chipDisabled]: isDisabled,
          [classes.chipFocused]: isFocused
        })}
        onClick={handleClick}
        onDelete={handleDelete}
        label={this.getSuggestionText(suggestion)}
      />
    );
  };

  handleAdd = chip => {
    const { choices, input, limitChoicesToValue, inputValueMatcher } = this.props;

    const filteredChoices = choices.filter(choice =>
      inputValueMatcher(chip, choice, this.getSuggestionText)
    );

    const choice =
      filteredChoices.length === 1
        ? filteredChoices[0]
        : filteredChoices.find(c => this.getSuggestionValue(c) === chip);

    if (choice) {
      return input.onChange([...(this.state.inputValue || []), this.getSuggestionValue(choice)]);
    }

    if (limitChoicesToValue) {
      // Ensure to reset the filter
      this.updateFilter("");
      return;
    }

    input.onChange([...this.state.inputValue, chip]);
  };

  handleDelete = chip => {
    const { input } = this.props;

    input.onChange(this.state.inputValue.filter(value => value !== chip));
  };

  renderSuggestionsContainer = options => {
    const {
      containerProps: { className, ...containerProps },
      children
    } = options;

    return (
      <Popper className={className} open anchorEl={this.inputEl} placement="right">
        <Paper square {...containerProps}>
          {children}
        </Paper>
      </Popper>
    );
  };

  renderSuggestionComponent = ({ suggestion, query, isHighlighted, ...props }) => (
    <div {...props} />
  );

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const label = this.getSuggestionText(suggestion);
    const matches = match(label, query);
    const parts = parse(label, matches);
    const { classes = {}, suggestionComponent } = this.props;

    const values = this.props?.input && this.props?.input?.value ? this.props?.input?.value : [];
    if ((values || [])?.indexOf(suggestion?.id) !== -1) {
      return null;
    }

    return (
      <MenuItem
        selected={isHighlighted}
        component={suggestionComponent || this.renderSuggestionComponent}
        suggestion={suggestion}
        query={query}
        isHighlighted={isHighlighted}
      >
        <div>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={index} className={classes.highlightedSuggestionText}>
                {part.text}
              </span>
            ) : (
              <strong key={index} className={classes.suggestionText}>
                {part.text}
              </strong>
            );
          })}
        </div>
      </MenuItem>
    );
  };

  handleFocus = () => {
    const { input } = this.props;
    input && input.onFocus && input.onFocus();
  };

  updateFilter = value => {
    const { setFilter, choices } = this.props;
    if (this.previousFilterValue !== value) {
      if (setFilter) {
        setFilter(value);
      } else {
        this.setState({
          searchText: value,
          suggestions: choices.filter(choice =>
            this.getSuggestionText(choice).toLowerCase().includes(value.toLowerCase())
          )
        });
      }
    }
    this.previousFilterValue = value;
  };

  shouldRenderSuggestions = val => {
    const { shouldRenderSuggestions } = this.props;
    if (shouldRenderSuggestions !== undefined && typeof shouldRenderSuggestions === "function") {
      return shouldRenderSuggestions(val);
    }

    return true;
  };

  handleResendConfirm = val => {
    this.setState({
      invalidExternalNumbers: [],
      isConfirmDialogOpen: false
    });
  };

  handleDialogClose = val => {
    this.setState({
      invalidExternalNumbers: [],
      isConfirmDialogOpen: false
    });
  };

  render() {
    const {
      alwaysRenderSuggestions,
      classes = {},
      isRequired,
      label,
      meta,
      resource,
      source,
      className,
      options,
      customSuggestions
    } = this.props;
    const { suggestions, searchText } = this.state;

    const handleInvalidExternalNumbers = this.state.invalidExternalNumbers
      .filter(item => item !== "")
      .map((item, index) => <li key={index}>{item}</li>);

    return (
      <div>
        <Confirm
          isOpen={this.state.isConfirmDialogOpen}
          title={"Invalid External Numbers"}
          content={handleInvalidExternalNumbers}
          onConfirm={this.handleResendConfirm}
          onClose={this.handleDialogClose}
          hideConfirmDialogButton={true}
          cancel="Confirm"
        />
        <Autosuggest
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderInputComponent={this.renderInput}
          suggestions={customSuggestions || suggestions}
          alwaysRenderSuggestions={alwaysRenderSuggestions}
          onSuggestionSelected={this.handleSuggestionSelected}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          getSuggestionValue={this.getSuggestionText}
          renderSuggestion={this.renderSuggestion}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          inputProps={{
            blurBehavior: "add",
            className,
            classes,
            isRequired,
            label,
            meta,
            onChange: this.handleChange,
            resource,
            source,
            value: searchText,
            onFocus: this.handleFocus,
            options
          }}
        />
      </div>
    );
  }
}

AutocompleteArrayInput.propTypes = {
  allowEmpty: PropTypes.bool,
  alwaysRenderSuggestions: PropTypes.bool, // used only for unit tests
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  InputProps: PropTypes.object,
  input: PropTypes.object,
  inputValueMatcher: PropTypes.func,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  limitChoicesToValue: PropTypes.bool,
  meta: PropTypes.object,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  optionValue: PropTypes.string.isRequired,
  resource: PropTypes.string,
  setFilter: PropTypes.func,
  shouldRenderSuggestions: PropTypes.func,
  source: PropTypes.string,
  suggestionComponent: PropTypes.func,
  translate: PropTypes.func.isRequired,
  translateChoice: PropTypes.bool.isRequired,
  customOnChange: PropTypes.func,
  customSuggestions: PropTypes.arrayOf(PropTypes.object)
};

AutocompleteArrayInput.defaultProps = {
  choices: [],
  options: {},
  optionText: "name",
  optionValue: "id",
  limitChoicesToValue: false,
  translateChoice: true,
  inputValueMatcher: (input, suggestion, getOptionText) =>
    getOptionText(suggestion).toLowerCase().trim().includes(input.toLowerCase().trim())
};

export default compose(addField, translate, withStyles(styles))(AutocompleteArrayInput);
