import { handleSingleRequest } from "../restClient";
import moment from "moment";
// import { GET_LIST } from "../types";

export const transformProductPriceCreate = async (type, resource, params) => {
  params.data.end_date = moment(params.data.end_date).format();
  params.data.start_date = moment(params.data.start_date).format();

  const countries = JSON.parse(localStorage.getItem("countries")) || [];
  let country = countries.find(country => country.application_id === localStorage.getItem("appId"));

  // const currencies = await handleSingleRequest(GET_LIST, "currencies", {
  //   filter: {},
  //   sort: { field: "id", order: "DESC" },
  //   pagination: {
  //     page: "all"
  //   }
  // });
  // const eur = currencies.data.find(currency => currency.alpha_code === "EUR");

  params.data.country_id = country.id;

  if (!params.data.generate_prices) {
    params.data.disable_price_conversion = true;
  }

  return handleSingleRequest(type, resource, {
    ...params
  }).then(({ data = [], included = [], ...rest }) => {
    return { data, ...rest };
  });
};

export const transformProductPriceUpdate = async (type, resource, params) => {
  params.data.end_date = moment(params.data.end_date).format();
  params.data.start_date = moment(params.data.start_date).format();

  const countries = JSON.parse(localStorage.getItem("countries")) || [];
  let country = countries.find(country => country.application_id === localStorage.getItem("appId"));

  // const currencies = await handleSingleRequest(GET_LIST, "currencies", {
  //   filter: {},
  //   sort: { field: "id", order: "DESC" },
  //   pagination: {
  //     page: "all"
  //   }
  // });
  // const eur = currencies.data.find(currency => currency.alpha_code === "EUR");

  params.data.country_id = country.id;

  params.data.disable_price_conversion = true;

  return handleSingleRequest(type, resource, {
    ...params
  }).then(({ data = [], included = [], ...rest }) => {
    return { data, ...rest };
  });
};
