import React, { useState, useEffect } from "react";
import {
  DateField,
  TextInput,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  Edit,
  FormTab,
  TabbedForm,
  translate,
  FormDataConsumer,
  ReferenceField,
  required,
  Toolbar,
  SaveButton,
  SelectInput,
  ArrayInput,
  SimpleFormIterator
} from "react-admin";

import HeadTagsQuickCreateButton from "../../common/HeadTagsQuickCreateButton";
import HeadTagsQuickEditButton from "../../common/HeadTagsQuickEditButton";
import HeadTagsTable from "../../common/HeadTagsTable";
import PostContent from "./PostContent";
import { styles } from "../../styles";

import { feature } from "../../../components/Feature";
import DeleteConfirmation from "../../../components/DeleteConfirmation";
import { PageNameValidationTextInput, PageSlugValidationTextInput } from "../validators";

import { handleSingleRequest } from "../../../services/api/restClient";
import { GET_LIST } from "../../../services/api/types";

const parentPageValidator = (value, formValues) => {
  if (value && value === formValues.id) {
    return "Parent page can't be the same as page you are currently editing - can't be parent page to itself.";
  } else {
    return undefined;
  }
};

const PageShowTitle = ({ record, translate }) =>
  translate("resources.pages.edit.title", { name: record.name });

const TranslatedTitle = translate(PageShowTitle);

const ConfirmationToolbar = props => (
  <Toolbar {...props} style={styles.toolbar}>
    <SaveButton />
    <DeleteConfirmation undoable={false} caution />
  </Toolbar>
);

let pagesList = [];
const PageReferenceInput = () => {
  handleSingleRequest(GET_LIST, "pages", {
    pagination: {
      page: "all"
    },
    sort: { field: "name", order: "ASC" },
    filter: {}
  })
    .then(({ data }) => {
      pagesList = data;
    })
    .catch(error => console.log(error));

  return (
    <AutocompleteInput
      variant="standard"
      source="parent_id"
      label="resources.pages.fields.parent_id"
      validate={parentPageValidator}
      choices={pagesList}
      emptyValue={null}
      emptyText={"Select to reset page parent"}
      allowEmpty
    />
  );
};

const thereCanBeOnlyOne = (value, formValues) => {
  if (value) {
    let checkForExistingLanguageSelected = formValues.translations.filter(
      item => item && item.language && item.language.id === value
    );
    if (checkForExistingLanguageSelected.length > 1) {
      return "There can be only one translation per language";
    } else {
      return undefined;
    }
  } else {
    return "Required";
  }
};

export const PageEdit = props => {
  const [languages, setLanguages] = useState([]);
  useEffect(() => {
    getLanguages();
  }, []);

  const getLanguages = async () => {
    try {
      const { data } = await handleSingleRequest(GET_LIST, "languages", {
        pagination: {
          page: "all"
        },
        sort: { field: "id", order: "DESC" },
        filter: {
          is_enabled: true
        }
      });
      setLanguages(data);
    } catch (error) {
      console.log("[Error get languages, (PageEdit.js)]", error);
    }
  };

  return (
    <Edit title={<TranslatedTitle {...props} />} undoable={false} {...props}>
      <TabbedForm variant="standard" toolbar={<ConfirmationToolbar />}>
        <FormTab label="resources.pages.edit.detailsTab">
          <PageNameValidationTextInput />
          <TextInput source="template_url" />
          <PageSlugValidationTextInput />
          <NumberInput source="weight" />
          {/* <BooleanInput source="enabled" /> */}
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.enabled ? (
                <BooleanInput source="enabled" label="ra.custom_files.booleans.enabled" />
              ) : (
                <BooleanInput source="enabled" label="ra.custom_files.booleans.disabled" />
              )
            }
          </FormDataConsumer>
          <PageReferenceInput />
          <ReferenceInput
            label="resources.pages.fields.page_type"
            source="page_type_id"
            reference="pageTypes"
            filterToQuery={name => {
              if (name) {
                return { name_ilike: name };
              }
            }}
            perPage={20}
            validate={required()}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <DateField source="created_at" variant="body2" />
          <DateField source="updated_at" variant="body2" />
        </FormTab>
        <FormTab label="resources.pages.translations.name">
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
              return (
                <ArrayInput
                  variant="standard"
                  source="translations"
                  label="resources.pages.translations.name"
                >
                  <SimpleFormIterator
                    disableReordering
                    TransitionProps={{ enter: false, exit: false }}
                  >
                    <SelectInput
                      source="language.id"
                      choices={languages}
                      label="resources.pages.translations.language_name"
                      validate={thereCanBeOnlyOne}
                    />
                    <TextInput
                      label="resources.pages.translations.page_name"
                      source="name"
                      validate={required()}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
              );
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="resources.pages.edit.contentTab">
          <FormDataConsumer>{formDataProps => <PostContent {...formDataProps} />}</FormDataConsumer>
        </FormTab>
        {feature("seoTags") && (
          <FormTab label="resources.products.tabs.seo_tags.name">
            <ReferenceField
              addLabel={false}
              reference="headTags"
              source="head_tag_id"
              link={false}
              allowEmpty={true}
            >
              <HeadTagsTable />
            </ReferenceField>
            <AddSEOTags />
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

const AddSEOTags = ({ record }) => {
  if (
    record.pageType &&
    (record.pageType.name === "category" ||
      record.pageType.name === "homepage" ||
      record.pageType.name === "article")
  ) {
    if (!record.head_tag_id) {
      return <HeadTagsQuickCreateButton record={record} resource="pages" />;
    }
    return <HeadTagsQuickEditButton record={record} resource="pages" />;
  } else {
    return <p>Not aplicable for current page type</p>;
  }
};
