import React from "react";

export const FormSeperator = () => (
  <div
    style={{
      marginTop: 30,
      borderTop: "1px solid #ddd",
      width: "100%"
    }}
  />
);
