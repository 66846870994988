/* eslint-disable eqeqeq */
import debounce from "lodash/debounce";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Quill from "quill";
import { addField } from "ra-core";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import ImageUploader from "quill-image-uploader";
import htmlEditButton from "quill-html-edit-button";
import { handleFilesUpload } from "../../services/api/restClient";
import styles from "./styles";

/*
  This is a "forked" version of React Admin Rich Text Input components.
  Fork was needed in order to add image upload capabilities.
*/

Quill.register({
  "modules/imageUploader": ImageUploader,
  "modules/htmlEditButton": htmlEditButton
});

const imageUploader = {
  upload: file => {
    // return a Promise that resolves in a link to the uploaded image
    return new Promise((resolve, reject) => {
      handleFilesUpload("CREATE", "images", {
        data: file
      }).then(data => resolve(data.fullUrl));
    });
  }
};

const htmlEditButtonConfig = {
  debug: true, // logging, default:false
  msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
  okText: "Ok", // Text to display in the OK button, default: Ok,
  cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
  buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
  buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
  syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
  // prependSelector: "div#test", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
  editorModules: {} // The default mod
};

export class RichTextInput extends Component {
  static propTypes = {
    addLabel: PropTypes.bool.isRequired,
    classes: PropTypes.object,
    input: PropTypes.object,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    source: PropTypes.string,
    toolbar: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    fullWidth: PropTypes.bool
  };

  static defaultProps = {
    addLabel: true,
    options: {},
    record: {},
    toolbar: true,
    fullWidth: true
  };

  componentDidMount() {
    const {
      input: { value },
      toolbar
    } = this.props;

    this.quill = new Quill(this.divRef, {
      modules: {
        toolbar,
        imageUploader,
        clipboard: { matchVisual: false },
        htmlEditButton: htmlEditButtonConfig
      },
      theme: "snow"
    });

    this.quill.setContents(this.quill.clipboard.convert(value));

    this.editor = this.divRef.querySelector(".ql-editor");
    this.quill.on("text-change", debounce(this.onTextChange, 500));
  }

  componentWillUnmount() {
    this.quill.off("text-change", this.onTextChange);
    this.quill = null;
  }

  onTextChange = () => {
    const value = this.editor.innerHTML == "<p><br></p>" ? "" : this.editor.innerHTML;
    this.props.input.onChange(value);
  };

  updateDivRef = ref => {
    this.divRef = ref;
  };

  render() {
    let { ra } = require("../../i18n/en.json");
    const { error, helperText = false, length } = this.props.meta;
    return (
      <FormControl
        error={error !== null && error !== undefined}
        fullWidth={this.props.fullWidth}
        className="ra-rich-text-input"
      >
        <div ref={this.updateDivRef} />
        {error && (
          <FormHelperText>
            {error === "ra.validation.required" ? ra?.validation?.required : error}
          </FormHelperText>
        )}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
}

const RichRextInputWithField = addField(withStyles(styles)(RichTextInput));

RichRextInputWithField.defaultProps = {
  addLabel: true,
  fullWidth: true
};
export default RichRextInputWithField;
