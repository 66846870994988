import React, { Component } from "react";
import PropTypes from "prop-types";
import { shallowEqual } from "recompose";
import Dropzone from "react-dropzone";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { addField, translate } from "ra-core";

import Labeled from "./OrderedFormIterator/Labeled";
import FileInputPreview from "./FileInputPreview";

const styles = {
  root: { width: "100%" },
  dropZone: {
    background: "#f9f9f9",
    cursor: "pointer",
    padding: "1rem",
    textAlign: "center",
    color: "#999"
  },
  preview: {},
  removeButton: {
    display: "inline-block",
    position: "relative",
    float: "left",
    "& button": {
      position: "absolute",
      top: "0.5rem",
      right: "0.5rem",
      minWidth: "2rem",
      opacity: 0
    },
    "&:hover button": {
      opacity: 1
    }
  }
};
export class FileInput extends Component {
  static propTypes = {
    accept: PropTypes.string,
    children: PropTypes.element,
    classes: PropTypes.object,
    className: PropTypes.string,
    disableClick: PropTypes.bool,
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    labelMultiple: PropTypes.string,
    labelSingle: PropTypes.string,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    multiple: PropTypes.bool,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    translate: PropTypes.func.isRequired,
    placeholder: PropTypes.node
  };

  static defaultProps = {
    labelMultiple: "ra.input.file.upload_several",
    labelSingle: "ra.input.file.upload_single",
    multiple: false,
    onUpload: () => {}
  };

  constructor(props) {
    super(props);
    let files = props.input.value || [];
    if (!Array.isArray(files)) {
      files = [files];
    }

    this.state = {
      files: files.map(this.transformFile)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let files = nextProps.input.value || [];
    if (!Array.isArray(files)) {
      files = [files];
    }

    this.setState({ files: files.map(this.transformFile) });
  }

  onDrop = files => {
    const updatedFiles = this.props.multiple
      ? [...this.state.files, ...files.map(this.transformFile)]
      : [...files.map(this.transformFile)];

    this.setState({ files: updatedFiles });

    if (this.props.multiple) {
      this.props.input.onChange(updatedFiles);
    } else {
      this.props.input.onChange(updatedFiles[0]);
    }
  };

  onRemove = file => () => {
    const filteredFiles = this.state.files.filter(stateFile => !shallowEqual(stateFile, file));

    this.setState({ files: filteredFiles });

    if (this.props.multiple) {
      this.props.input.onChange(filteredFiles);
    } else {
      this.props.input.onChange(null);
    }
  };

  // turn a browser dropped file structure into expected structure
  transformFile = file => {
    if (!(file instanceof File)) {
      return file;
    }

    const { source, title } = React.Children.toArray(this.props.children)[0].props;

    const transformedFile = {
      rawFile: file,
      [source]: file.preview
    };

    if (title) {
      transformedFile[title] = file.name;
    }

    return transformedFile;
  };

  label = () => {
    const { translate, placeholder, labelMultiple, labelSingle } = this.props;

    if (placeholder) {
      return placeholder;
    }

    if (this.props.multiple) {
      return <p>{translate(labelMultiple)}</p>;
    }

    return <p>{translate(labelSingle)}</p>;
  };

  render() {
    const {
      accept,
      children,
      classes = {},
      className,
      disableClick,
      isRequired,
      label,
      maxSize,
      minSize,
      multiple,
      resource,
      source,
      options,
      ratio,
      imageSize,
      ...rest
    } = this.props;

    return (
      <Labeled
        label={label}
        className={classnames(classes.root, className)}
        source={source}
        resource={resource}
        isRequired={isRequired}
        {...rest}
      >
        <span>
          <Dropzone
            onDrop={this.onDrop}
            accept={accept}
            disableClick={disableClick}
            maxSize={2000000}
            minSize={minSize}
            multiple={multiple}
            className={classes.dropZone}
            {...options}
          >
            {/* We will need this render function code once we upgrade the react-dropzone
                package to latest version. Versions >= 7.0.0. don't have preview option anymore 
                and it will be upgraded/recreated after migration. Currently, we returned to 
                6.2.4. which is latest version with out of the box preview option. */}
            {/* {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()} className={classes.dropZone}>
                  <input {...getInputProps()} />
                  {this.label()}
                </div>
              </section>
            )} */}
            {this.label}
          </Dropzone>
          {children && (
            <div className="previews">
              {this.state.files.map((file, index) => (
                <FileInputPreview
                  key={index}
                  file={file}
                  ratio={ratio}
                  imageSize={imageSize}
                  onRemove={this.onRemove(file)}
                  className={classes.removeButton}
                >
                  {React.cloneElement(children, {
                    record: file,
                    className: classes.preview
                  })}
                </FileInputPreview>
              ))}
            </div>
          )}
        </span>
      </Labeled>
    );
  }
}

export default compose(addField, translate, withStyles(styles))(FileInput);
