import React, { useState, useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  NumberInput,
  required
} from "react-admin";
// import RichTextInput from "ra-input-rich-text";
import { parse } from "query-string";

import { ToolbarOptionsTechData } from "../../constants";

import { handleSingleRequest } from "../../services/api/restClient";
import { GET_LIST } from "../../services/api/types";
import { validateTechDataValue, minValue, validateWholeNumber } from "../../utils/validators/index";

export const TechDataCreate = props => {
  const [languages, setLanguages] = useState([]);

  const { source: searchSource } = parse(props.location.search);
  const { product_id, isNewTranslation } = JSON.parse(searchSource);
  // ! Removing or adding tabs before Tech Data tab can damage the logic below | Look how to name tabs properly instead of having digits per tab
  const redirect = product_id ? `/products/${product_id}/3` : false;

  useEffect(() => {
    if (isNewTranslation) {
      getLanguages();
    }
  }, [isNewTranslation]);

  const getLanguages = async () => {
    try {
      const { data } = await handleSingleRequest(GET_LIST, "languages", {
        pagination: {
          page: "all"
        },
        sort: { field: "name", order: "ASC" },
        filter: { is_enabled: true }
      });

      const allLanguages = data.map(lang => {
        return {
          id: lang.id,
          name: lang.international_name
        };
      });
      setLanguages(allLanguages);
    } catch (error) {
      console.log("[Error get langauges on Create tech data]", error);
    }
  };

  return (
    <Create title={"resources.products.tabs.tech_data.name"} {...props}>
      <SimpleForm variant="standard" submitOnEnter={true} redirect={redirect}>
        {isNewTranslation ? (
          <SelectInput
            source="language.id"
            choices={languages}
            label="resources.products.tabs.translations.language_name"
            validate={required()}
          />
        ) : null}
        <TextInput
          label="resources.products.tabs.tech_data.label"
          source="label"
          validate={[required(), validateTechDataValue]}
        />
        <NumberInput
          label="resources.products.tabs.tech_data.tech_data_order"
          source="tech_data_order"
          // min={1}
          variant="standard"
          step={1}
          validate={[required(), minValue(1), validateWholeNumber]}
        />
        <ReferenceInput
          source="product_id"
          reference="products"
          disabled={product_id}
          label="resources.products.tabs.tech_data.tech_data_product"
          sort={{ field: "name", order: "ASC" }}
          filterToQuery={name => {
            if (name) {
              return { name_ilike: name };
            }
          }}
        >
          <AutocompleteInput optionText="name" disabled={isNewTranslation} />
        </ReferenceInput>
        <TextInput
          label="resources.products.tabs.tech_data.tech_data_value"
          source="tech_data_value"
          toolbar={[ToolbarOptionsTechData]}
          validate={[required(), validateTechDataValue]}
          multiline
          type="text"
        />
      </SimpleForm>
    </Create>
  );
};
