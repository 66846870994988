import React from "react";
import { Show, TextField, SimpleShowLayout } from "react-admin";

export const TableShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="key" label="Key" />
      <TextField source="value" label="Value" />
      <TextField source="sort_order" label="Order" />
    </SimpleShowLayout>
  </Show>
);
