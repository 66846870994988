import React from "react";
import { translate } from "react-admin";
import { htmlTagsList } from "./helpers";

// ! Translation component / before changing consult with Stefan Miljkovic (Zepter)
const TranslatableParagraph = ({
  translate,
  translationLabel,
  customStyling,
  customLabel,
  component,
  textOnly
}) => {
  const mainLabel = translate(translationLabel);
  const additionalLabel = customLabel ? ` ${customLabel}` : "";
  const translatedText = mainLabel + additionalLabel;

  const htmlTag = component && htmlTagsList.includes(component) ? component : "p";

  const attributes = {};

  if (
    typeof customStyling === "object" &&
    !Array.isArray(customStyling) &&
    customStyling !== null
  ) {
    attributes.style = customStyling;
  }

  if (textOnly) {
    return translatedText;
  } else {
    return React.createElement(htmlTag, attributes, translatedText);
  }
};

export default translate(TranslatableParagraph);
