import React, { useEffect, useState } from "react";
import {
  ListBase,
  useListContext,
  Pagination,
  ListToolbar,
  TopToolbar,
  CreateButton,
  EditButton,
  Title
} from "react-admin";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  Card
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { ListFilters } from "./Filters";

const useStyles = makeStyles({
  elevatedCard: {
    zIndex: 1,
    borderRadius: "0 0 4px 4px",
    padding: "10px 0"
  },
  treeWrapper: {
    "& ul": {
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
      }
    }
  },
  treeListItem: {
    transition: "all 0s ease 0s",
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
});

const ProductCategoriesActions = () => {
  const { basePath } = useListContext();

  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

const ProductCategoriesPagination = () => {
  const { total } = useListContext();

  return <Pagination rowsPerPageOptions={[]} total={total} />;
};

export const PagesList = props => {
  const classes = useStyles();

  return (
    <ListBase perPage={10000} {...props}>
      <ListToolbar filters={<ListFilters />} actions={<ProductCategoriesActions />} />
      <Card className={classes.elevatedCard}>
        <Tree />
        <ProductCategoriesPagination />
      </Card>
    </ListBase>
  );
};

// rethink if this function can be optimized
const getSearchTreeItems = (data, matchedItems, rootItems) => {
  const newItems = [];
  for (let i = 0; i < matchedItems.length; i++) {
    if (matchedItems[i].parent_id === null) {
      rootItems.push(matchedItems[i]);
    }
  }

  if (matchedItems.length !== rootItems.length) {
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < matchedItems.length; j++) {
        if (data[i].id === matchedItems[j].parent_id) {
          newItems.push(data[i]);
        }
      }
    }
  }

  if (newItems.length > 0) {
    getSearchTreeItems(data, newItems, rootItems);
  }

  // for some reason rootItems has few duplicate records
  const uniqueRootItems = [...new Set(rootItems)];
  return uniqueRootItems;
};

const Tree = () => {
  const classes = useStyles();
  const {
    ids,
    data,
    defaultTitle,
    filterValues: { name_ilike: searchString }
  } = useListContext();

  const [openChildren, setOpenChildren] = useState([]);
  const toggleNode = node =>
    setOpenChildren(state => {
      if (state.includes(node.id)) {
        return [
          ...state.splice(0, state.indexOf(node.id)),
          ...state.splice(state.indexOf(node.id) + 1, state.length)
        ];
      } else {
        return [...state, node.id];
      }
    });
  const nodes = ids.map(id => data[id]);
  let roots = nodes.filter(
    node => typeof node.parent_id === "undefined" || node.parent_id === null
  );
  const matchedItems =
    searchString && searchString.at(0) !== " "
      ? nodes.filter(nodeItem => {
          if (nodeItem.name) {
            return nodeItem.name.toLowerCase().includes(searchString.toLowerCase());
          }
        })
      : null;

  const getChildNodes = root => nodes.filter(node => node.parent_id === root.id);

  if (matchedItems && matchedItems.length > 0) {
    roots = getSearchTreeItems(nodes, matchedItems, []);
  }
  if (searchString && (!matchedItems || matchedItems.length === 0)) {
    roots = [];
  }

  useEffect(() => {
    if (searchString) {
      roots.forEach(root => {
        const hasChildNodes = getChildNodes(root).length > 0;
        if (hasChildNodes && !openChildren.includes(root.id)) {
          toggleNode(root);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  return (
    <List component="div" className={classes.treeWrapper}>
      <Title defaultTitle={defaultTitle} />
      {roots.map(root => {
        const isHighlighted = searchString
          ? root.name.toLowerCase().includes(searchString.toLowerCase())
          : false;

        return (
          <SubTree
            setOpenChildren={setOpenChildren}
            isHighlighted={isHighlighted}
            key={root.id}
            root={root}
            getChildNodes={getChildNodes}
            openChildren={openChildren}
            toggleNode={toggleNode}
            searchString={searchString}
            level={1}
          />
        );
      })}
    </List>
  );
};

const SubTree = ({ level, root, getChildNodes, openChildren, toggleNode, isHighlighted }) => {
  const classes = useStyles();
  const childNodes = getChildNodes(root);
  const hasChildren = childNodes.length > 0;
  const open = openChildren.includes(root.id);
  const {
    filterValues: { name_ilike: searchString }
  } = useListContext();

  useEffect(() => {
    if (searchString) {
      childNodes.forEach(childNode => {
        const hasChildNodes = getChildNodes(childNode).length > 0;
        if (hasChildNodes && !openChildren.includes(childNode.id)) {
          toggleNode(childNode);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  return (
    <List disablePadding>
      <ListItem
        button={hasChildren}
        onClick={() => hasChildren && toggleNode(root)}
        style={{
          paddingLeft: level * 45 - 30,
          color: isHighlighted ? "#ff0000" : "inherit"
        }}
        className={classes.treeListItem}
      >
        {hasChildren && open && <ExpandLess style={{ marginRight: 10 }} />}
        {hasChildren && !open && <ExpandMore style={{ marginRight: 10 }} />}
        {!hasChildren && <div style={{ width: 34 }}>&nbsp;</div>}
        <ListItemText primary={root.name} />

        <ListItemSecondaryAction>
          <EditButton record={root} basePath="/pages" />
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {childNodes.map(node => {
            const isHighlighted = searchString
              ? node.name.toLowerCase().includes(searchString.toLowerCase())
              : false;
            return (
              <SubTree
                isHighlighted={isHighlighted}
                key={node.id}
                root={node}
                getChildNodes={getChildNodes}
                openChildren={openChildren}
                toggleNode={toggleNode}
                level={level + 1}
              />
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};
