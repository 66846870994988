import { CSV_URL_RECEIVED, RESET_CSV_URLS, REMOVE_CSV_URL, CHANGE_LOCALE } from "../types";

export const csvUrlReceived = (csvUrl, resource, item) => ({
  type: CSV_URL_RECEIVED,
  payload: { csvUrl, resource }
});

export const removeCSVUrl = csvUrl => ({
  type: REMOVE_CSV_URL,
  payload: { csvUrl }
});

export const resetCsvUrls = () => ({
  type: RESET_CSV_URLS
});

export const changeLocale = () => ({
  type: CHANGE_LOCALE,
  payload: {
    locale: "ru"
  }
});
