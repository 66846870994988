import React from "react";
import { TopToolbar, CreateButton, List, TextField, DateField } from "react-admin";

import CustomizableDatagrid from "../../components/CustomizableDatagrid";

const PaymentRulesActions = ({ basePath }) => (
  <TopToolbar>
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

export const paymentRulesList = props => (
  <List exporter={false} bulkActionButtons={false} {...props} actions={<PaymentRulesActions />}>
    <CustomizableDatagrid rowClick="show">
      <TextField source="id" />
      <TextField label="resources.paymentRules.fields.name" source="name" />
      <TextField label="resources.paymentRules.fields.order_amount_min" source="order_amount_min" />
      <TextField label="resources.paymentRules.fields.order_amount_max" source="order_amount_max" />
      <TextField label="resources.paymentRules.fields.max_installments" source="max_instalments" />
      <DateField label="resources.paymentRules.fields.created_at" source="created_at" />
      <DateField label="resources.paymentRules.fields.updated_at" source="updated_at" />
    </CustomizableDatagrid>
  </List>
);
