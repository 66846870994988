import React from "react";
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  BooleanInput,
  ImageInput,
  ImageField,
  ReferenceInput,
  NumberInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  SimpleFormIterator,
  FormDataConsumer,
  ArrayInput,
  SelectInput,
  required,
  translate,
  Toolbar,
  SaveButton
} from "react-admin";
import { handleSingleRequest } from "../../services/api/restClient";
import { GET_LIST } from "../../services/api/types";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { styles } from "../styles";
import { facebookCategories } from "./FacebookCategories";
import { googleCategories } from "./GoogleCategories";

let productCategoriesList = [];
let allLanguages = [];
let currentCategoryName = "";
let currentKeyName = "";
let haveInitialValues = false;

const categoryNameValidator = (value, formValues) => {
  if (!value) {
    return "ra.validation.required";
  }

  const categoryNameExists = productCategoriesList.find(
    category => category.name.toUpperCase() === value.toUpperCase()
  );

  if (
    categoryNameExists &&
    categoryNameExists.name.toUpperCase() !== currentCategoryName.toUpperCase()
  ) {
    return "Category name already exists. Please change category name.";
  } else if (formValues.parent_category_id) {
    const parentCategory = productCategoriesList.find(
      category => category.id === formValues.parent_category_id
    );

    if (parentCategory && value.toUpperCase() === parentCategory.name.toUpperCase()) {
      return "Category name must not be the same as parent category name.";
    }
  }

  return undefined;
};

const keyNameValidator = (value, formValues) => {
  if (!value) {
    return "ra.validation.required";
  }

  const keyNameExists = productCategoriesList.find(
    category => category.key_name.toUpperCase() === value.toUpperCase()
  );

  if (keyNameExists && keyNameExists.key_name.toUpperCase() !== currentKeyName.toUpperCase()) {
    return "Key name already exists. Please change key name.";
  }

  return undefined;
};

const thereCanBeOnlyOne = (value, formValues) => {
  if (value) {
    let checkForExistingLanguageSelected = formValues.translations.filter(
      item => item && item.language && item.language.id === value
    );
    if (checkForExistingLanguageSelected.length > 1) {
      return "There can be only one translation per language";
    } else {
      return undefined;
    }
  } else {
    return "Required";
  }
};

const parentCategoryValidator = (value, formValues) => {
  if (value && value === formValues.id) {
    return "Parent category can't be the same as category you are currently editing - can't be parent category to itself.";
  } else {
    return undefined;
  }
};

const EditTitle = ({ record, translate }) =>
  translate("resources.productCategories.edit.title", {
    categoryName: record.name
  });

const TranslatedTitle = translate(EditTitle);

const ConfirmationToolbar = props => (
  <Toolbar {...props} style={styles.toolbar}>
    <SaveButton />
    <DeleteConfirmation undoable={false} />
  </Toolbar>
);

export class ProductCategoryEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagesList: []
    };
  }
  componentDidMount() {
    handleSingleRequest(GET_LIST, "productCategories", {
      pagination: {
        page: "all"
      },
      sort: { field: "name", order: "ASC" },
      filter: {}
    })
      .then(({ data }) => {
        productCategoriesList = data;
      })
      .catch(error => console.log(error));

    handleSingleRequest(GET_LIST, "pages", {
      pagination: {
        page: "all"
      },
      sort: { field: "name", order: "ASC" },
      filter: {}
    })
      .then(({ data }) => {
        this.setState({ pagesList: data });
      })
      .catch(error => console.log(error));

    handleSingleRequest(GET_LIST, "languages", {
      pagination: {
        page: "all"
      },
      sort: { field: "name", order: "ASC" },
      filter: {
        is_enabled: true
      }
    })
      .then(({ data }) => {
        allLanguages = data.map(translationItem => {
          return {
            id: translationItem.id,
            name: translationItem.international_name
          };
        });
      })
      .catch(error => console.log(error));
  }

  componentWillUnmount() {
    productCategoriesList = [];
    allLanguages = [];
    this.state.pagesList = [];
    currentCategoryName = "";
    currentKeyName = "";
    haveInitialValues = false;
  }

  render() {
    return (
      <Edit title={<TranslatedTitle />} {...this.props}>
        <TabbedForm redirect="show" toolbar={<ConfirmationToolbar />} variant="standard">
          <FormTab label="basic">
            <ImageInput
              source="imageRaw"
              label="resources.productCategories.fields.imageRaw"
              labelSingle="resources.productCategories.fields.imageRawLabel"
              maxSize={2000000}
              accept=".jpg,.jpeg,.png"
              validate={required()}
            >
              <ImageField source="src" />
            </ImageInput>
            <BooleanInput source="overwrite" />
            <TextInput
              label="resources.productCategories.fields.categoryName"
              validate={categoryNameValidator}
              source="name"
            />
            <TextInput multiline validate={required()} source="description" fullWidth />
            <TextInput
              label="resources.productCategories.fields.keyName"
              validate={keyNameValidator}
              source="key_name"
            />
            <ReferenceInput
              label="resources.productCategories.fields.parent_category_id"
              source="parent_category_id"
              reference="productCategories"
              filterToQuery={name => {
                if (name) {
                  return { name_ilike: name };
                }
              }}
              validate={parentCategoryValidator}
              perPage={20}
              allowEmpty
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <NumberInput source="order_number" min={0} />
            <BooleanInput source="show_category" />
            <AutocompleteInput
              source="page_id"
              label="resources.pages.fields.parent_id"
              choices={this.state.pagesList}
              emptyValue={null}
              emptyText={"Select to reset page parent"}
              allowEmpty
            />
            <ReferenceArrayInput
              label="resources.productCategories.fields.tagGroups"
              source="tagGroupIds"
              reference="tagGroups"
              sort={{ field: "name", order: "ASC" }}
              filterToQuery={name => {
                if (name) {
                  return { name_ilike: name };
                }
              }}
              perPage={20}
              defaultValue={[]}
              allowEmpty
            >
              <AutocompleteArrayInput optionText="name" />
            </ReferenceArrayInput>
            <AutocompleteInput
              source="google_category"
              label="Chose google category"
              choices={googleCategories}
              emptyValue={null}
              allowEmpty
            />
            <AutocompleteInput
              source="facebook_category"
              label="Chose facebook category"
              choices={facebookCategories}
              emptyValue={null}
              allowEmpty
            />
          </FormTab>
          <FormTab label="translations">
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                if (formData && !haveInitialValues) {
                  currentCategoryName = formData.name;
                  currentKeyName = formData.key_name;
                  haveInitialValues = true;
                }
                // if (formData.translations) {
                return (
                  <ArrayInput source="translations" label="">
                    <SimpleFormIterator
                      disableReordering
                      TransitionProps={{ enter: false, exit: false }}
                    >
                      <SelectInput
                        source="language.id"
                        choices={allLanguages}
                        label="language"
                        validate={thereCanBeOnlyOne}
                      />
                      <TextInput label="name" source="name" validate={required()} />
                      <TextInput label="description" source="description" validate={required()} />
                    </SimpleFormIterator>
                  </ArrayInput>
                );
                // }
              }}
            </FormDataConsumer>
          </FormTab>
        </TabbedForm>
      </Edit>
    );
  }
}
