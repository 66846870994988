import React from "react";
import { List, TextField, Datagrid } from "react-admin";

export const FooterList = props => (
  <List {...props} exporter={false}>
    <Datagrid rowClick="show">
      <TextField label="Column title" source="translation.column_title" />
      <TextField label="Column order" source="translation.column_order" />
      <TextField label="Language" source="translation.language_name" />
    </Datagrid>
  </List>
);
