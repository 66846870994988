import React from "react";
import {
  List,
  TextField,
  ReferenceField,
  DateField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField
} from "react-admin";

import CustomizableDatagrid from "../../components/CustomizableDatagrid";
import UserFilters from "./Filters";

export const UsersList = props => {
  const countryAppId = localStorage.getItem("appId");
  const countryName = JSON.parse(localStorage.getItem("countries")).find(
    country => country.application_id === countryAppId
  ).international_name;
  const isCountryCzechiaOrSlovakia = countryName === "Czechia" || countryName === "Slovakia";
  const isCountryJordanOrEgypt = countryName === "Jordan" || countryName === "Egypt"; // || countryName === "Egypt";
  const isCountryKazakhstan = countryName === "Kazakhstan";

  const defaultColumns = [
    "user_type_id",
    "first_name",
    "last_name",
    "company_name",
    "tax_id",
    "phone",
    "email",
    "user_level_id",
    "userRole"
  ];

  if (isCountryCzechiaOrSlovakia) {
    defaultColumns.push("company_registration_number");
  }

  if (isCountryJordanOrEgypt) {
    defaultColumns.push("national_id");
  }

  return (
    <List
      bulkActionButtons={false}
      filters={<UserFilters />}
      sort={{ field: "created_at", order: "DESC" }}
      {...props}
    >
      <CustomizableDatagrid rowClick="show" defaultColumns={defaultColumns}>
        <TextField source="rid" sortable={false} />
        <ReferenceField
          label="resources.users.fields.user_type_id"
          source="user_type_id"
          reference="userTypes"
          link={false}
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField label="resources.users.fields.first_name" source="first_name" sortable={false} />
        <TextField label="resources.users.fields.last_name" source="last_name" sortable={false} />
        <TextField
          label="resources.users.fields.company_name"
          source="company_name"
          sortable={false}
        />
        <TextField
          source="tax_id"
          sortable={false}
          label={
            isCountryCzechiaOrSlovakia
              ? "DIČ"
              : isCountryKazakhstan
              ? "ИИН"
              : "resources.users.fields.tax"
          }
        />
        {isCountryCzechiaOrSlovakia && (
          <TextField source="company_registration_number" sortable={false} label="IČO" />
        )}
        <TextField
          label="resources.users.fields.patronimic_name"
          source="patronimic_name"
          sortable={false}
        />
        {isCountryJordanOrEgypt ? (
          <TextField
            label="resources.users.fields.national_id"
            source="personal_number"
            sortable={false}
          />
        ) : null}
        <DateField label="resources.users.fields.created_at" source="created_at" />
        <DateField label="resources.users.fields.updated_at" source="updated_at" />
        <TextField
          label="resources.users.fields.last_update_by"
          source="last_update_by"
          sortable={false}
        />
        <TextField label="resources.users.fields.phone" source="phone" sortable={false} />
        <TextField label="resources.users.fields.email" source="email" sortable={false} />
        <TextField
          label="resources.users.fields.userAddressLines"
          source="userAddressLines"
          sortable={false}
        />
        <TextField
          source="defaultAddress.zip_code"
          sortable={false}
          label="resources.users.fields.defaultAddress.zip_code"
        />
        <ReferenceField
          source="defaultAddress.city_id"
          label="resources.users.fields.defaultAddress.city_id"
          reference="cities"
          link={false}
          sortable={false}
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField
          label="resources.users.fields.stateOrProvince"
          source="stateOrProvince"
          sortable={false}
        />
        <DateField label="resources.users.fields.birth_date" source="birth_date" sortable={false} />
        <TextField label="resources.users.fields.userRole" source="userRole" sortable={false} />
        <ReferenceField
          label="resources.users.fields.user_level_id"
          source="user_level_id"
          reference="userLevels"
          link={false}
          sortable={false}
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="resources.users.fields.price_level_id"
          source="price_level_id"
          reference="priceLevels"
          link={false}
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceArrayField
          label="resources.users.fields.priceLevels"
          reference="priceLevels"
          source="sellingPriceLevels"
          sortable={false}
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField
          label="resources.users.fields.external_id"
          source="external_id"
          sortable={false}
        />
        <TextField
          label="resources.users.fields.source_application"
          source="source_application"
          sortable={false}
        />
      </CustomizableDatagrid>
    </List>
  );
};
