import React, { Component, Fragment } from "react";
import { find, includes, findIndex, cloneDeep, orderBy } from "lodash";
import Draggable from "react-draggable";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import { useForm } from "react-final-form";

import transformHtmlTemplate from "../utils/transformHtmlTemplate";
import generateNumberArray from "../utils/generate-number-array";
import {
  flatten,
  findNode,
  findNodeParent,
  addNode,
  addNodes,
  deleteNode,
  nodeOrder
} from "../utils/tree";

import { FileInput, FileField } from "react-admin";
import TranslatableParagraph from "../../TranslationComponents/TranslatableParagraph";

const ButtonDeleteRecord = ({
  parentId,
  recordId,
  layoutEditorState,
  layoutEditorProps,
  deleteRecordStateHandle
}) => {
  const form = useForm();

  const deleteRecord = (parentId, nodeId) => {
    const {
      layout,
      layout: { records, tree }
    } = layoutEditorState;

    const { files } = layoutEditorProps;

    let treeParent = findNode(tree, parentId);

    if (!treeParent) {
      throw new Error("Couldn't find parent!");
    }

    let node = find(treeParent.children, ["id", nodeId]);
    let recordIdsToDelete = flatten(node, "children").map(child => child.id);

    recordIdsToDelete = [...recordIdsToDelete, node.id];

    let newRecords = records.filter(record => !includes(recordIdsToDelete, record.id));

    let newTree = deleteNode(tree, parentId, nodeId);

    let filteredFiles = files.filter(file => !includes(recordIdsToDelete, file && file.recordId));

    form.change("files", filteredFiles);

    deleteRecordStateHandle(layout, newTree, newRecords);
  };

  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="secondary"
        style={{ margin: "8px 0px" }}
        onClick={() => deleteRecord(parentId, recordId)}
      >
        <TranslatableParagraph translationLabel="ra.action.delete" />
      </Button>
    </>
  );
};

class LayoutEditor extends Component {
  state = {
    defaultColumn: {
      size: { xs: 12, sm: 0, md: 0, lg: 6, xl: 0 }
    },
    defaultRow: {},
    selectedRecordId: null,
    lastNodeId: 1,
    layout: {
      tree: [{ id: 1, children: [] }],
      records: [{ id: 1, type: "root" }]
    }
  };

  initState = () => {
    const { layout } = this.props;
    let lastNodeId;

    if (layout && Array.isArray(layout.tree) && Array.isArray(layout.records)) {
      lastNodeId = orderBy(layout.records, "id", "desc")[0].id;

      let lastNodeType = find(layout.records, ["id", lastNodeId]).type;

      if (lastNodeType === "collapsable" || lastNodeType === "legal-header") {
        let additionalChildrenNodes = 2;
        lastNodeId += additionalChildrenNodes;
      }

      this.setState({
        layout: cloneDeep(layout),
        selectedRecordId: null,
        lastNodeId
      });
    }
  };

  componentDidMount() {
    this.initState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.layout !== this.props.layout) {
      this.initState();
    }
  }

  fetchTemplate(templateUrl) {
    fetch(templateUrl, {
      headers: new Headers({
        authorization: "Basic emVwdGVydWF0OnhvZXBoYWU0Y283TQ=="
      })
    })
      .then(response => response.text())
      .then(html => {
        let layout = transformHtmlTemplate(html);

        this.setState({
          layout
        });
      })

      .catch(e => {
        console.error(e);
      });
  }

  createRecord = (parentId, type) => {
    const {
      layout,
      layout: { records, tree },
      defaultRow,
      defaultColumn,
      lastNodeId
    } = this.state;

    let newRecord;
    let additionalChildrenNodes = 0;

    // collapsable and legal-header elements have 2 editable regions inside - will write this better
    if (type === "collapsable" || type === "legal-header") {
      additionalChildrenNodes += 2;
    }

    let newId = lastNodeId + 1;

    switch (type) {
      case "row":
        newRecord = { ...defaultRow, id: newId, type };
        break;
      case "column":
        newRecord = { ...defaultColumn, id: newId, type, name: `er-${newId}` };
        break;
      case "legal-header":
        newRecord = {
          ...defaultRow,
          id: newId,
          type,
          name: `er-${newId}`,
          children: [
            {
              id: newId + additionalChildrenNodes - 1,
              children: [],
              name: `er-${newId + additionalChildrenNodes - 1}`,
              type
            },
            {
              id: newId + additionalChildrenNodes,
              children: [],
              name: `er-${newId + additionalChildrenNodes}`,
              type
            }
          ]
        };
        break;
      case "collapsable":
        newRecord = {
          ...defaultRow,
          id: newId,
          type,
          name: `er-${newId}`,
          children: [
            {
              id: newId + additionalChildrenNodes - 1,
              children: [],
              name: `er-${newId + additionalChildrenNodes - 1}`,
              type
            },
            {
              id: newId + additionalChildrenNodes,
              children: [],
              name: `er-${newId + additionalChildrenNodes}`,
              type
            }
          ]
        };
        break;
      case "separator":
        newRecord = { ...defaultRow, id: newId, type, name: `er-${newId}` };
        break;
      case "document":
        newRecord = { ...defaultRow, id: newId, type, name: `er-${newId}` };
        break;
      default:
    }

    const newTree =
      type === "collapsable" || type === "legal-header"
        ? addNodes(tree, parentId, newId, additionalChildrenNodes)
        : addNode(tree, parentId, newId);

    if (type === "collapsable" || type === "legal-header") {
      newId += additionalChildrenNodes;
    }

    this.setState({
      layout: {
        ...layout,
        tree: newTree,
        records: [...records, newRecord]
      },
      lastNodeId: newId
    });
  };

  recordOrder = (parentId, nodeId, newOrderIndex) => {
    const {
      layout,
      layout: { tree }
    } = this.state;
    const newTree = nodeOrder(tree, parentId, nodeId, newOrderIndex);

    this.setState({
      layout: {
        ...layout,
        tree: newTree
      }
    });
  };

  deleteRecordStateHandle = (layout, newTree, newRecords) => {
    this.setState({
      layout: {
        ...layout,
        tree: newTree,
        records: newRecords
      },
      selectedRecordId: null
    });
  };

  updateRecord = newRecord => {
    const {
      layout,
      layout: { records }
    } = this.state;

    this.setState({
      layout: {
        ...layout,
        records: records.map(record => (record.id === newRecord.id ? newRecord : record))
      }
    });
  };

  renderRegion = region => {
    if (!region) return null;

    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(region, "text/html");
    const imageUrl = process.env.REACT_APP_IMG_URL;

    htmlDoc.querySelectorAll("img").forEach(img => {
      const imgUrlOld = new URL(img.src);
      if (imgUrlOld.hostname === window.location.hostname) {
        img.src = imageUrl + imgUrlOld.pathname;
      }
    });

    return htmlDoc.body.innerHTML;
  };

  renderColumn = (record, children, index, isSelected) => {
    const { regions } = this.props;
    const hasChildren = Array.isArray(children) && children.length > 0;

    let bgColor = `rgba(0,0,0,${index % 2 === 0 ? "0.25" : "0.20"})`;
    let classes = Object.keys(record.size)
      .map(key => (record.size[key] ? `col-${key}-${record.size[key]}` : ""))
      .join(" ");
    let region = record.name && regions[record.name];

    if (isSelected) {
      bgColor = "rgba(23, 162, 67, 0.5)";
    }

    return (
      <div
        key={index}
        className={classes}
        style={{
          paddingTop: "15px",
          minHeight: "100px",
          cursor: "pointer",
          position: "relative",
          backgroundColor: bgColor
        }}
        onClick={e => {
          e.stopPropagation();
          this.selectRecord(record.id);
        }}
      >
        <span style={{ position: "absolute", top: 0, left: 5, fontSize: "12px" }}>
          {record.type} {record.id}
        </span>

        {hasChildren ? (
          children.map((child, index) => this.renderRecord(child, index))
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: this.renderRegion(region)
            }}
          />
        )}
      </div>
    );
  };

  renderRow = (record, children, index, isSelected) => {
    let bgColor = `rgba(0,0,0,${index % 2 === 0 ? "0.25" : "0.20"})`;

    if (isSelected) {
      bgColor = "rgba(23, 162, 67, 0.5)";
    }

    return (
      <div
        key={index}
        className="row"
        name={record.name}
        style={{
          paddingTop: "15px",
          minHeight: "100px",
          cursor: "pointer",
          position: "relative",
          backgroundColor: bgColor
        }}
        onClick={e => {
          e.stopPropagation();
          this.selectRecord(record.id);
        }}
      >
        <span style={{ position: "absolute", top: 0, left: 5, fontSize: "12px" }}>
          {record.type} {record.id}
        </span>
        {children.map((child, index) => this.renderRecord(child, index))}
      </div>
    );
  };

  renderLegalHeader = (record, children, index, isSelected) => {
    let bgColor = `rgba(0,0,0,${index % 2 === 0 ? "0.25" : "0.20"})`;

    if (isSelected) {
      bgColor = "rgba(23, 162, 67, 0.5)";
    }

    return (
      <div
        key={index}
        className="row"
        name={record.name}
        style={{
          paddingTop: "15px",
          minHeight: "100px",
          cursor: "pointer",
          position: "relative",
          backgroundColor: bgColor
        }}
        onClick={e => {
          e.stopPropagation();
          this.selectRecord(record.id);
        }}
      >
        <span style={{ position: "absolute", top: 0, left: 5, fontSize: "12px" }}>
          {record.type} {record.id}
        </span>
        {/* {children.map((child, index) => this.renderRecord(child, index))} */}
        <div className="col-md-12 legal-header" />
      </div>
    );
  };

  renderCollapsableElement = (record, children, index, isSelected) => {
    let bgColor = `rgba(0,0,0,${index % 2 === 0 ? "0.25" : "0.20"})`;

    // const { regions } = this.props;

    if (isSelected) {
      bgColor = "rgba(23, 162, 67, 0.5)";
    }

    return (
      <div
        key={index}
        className="row"
        name={record.name}
        style={{
          paddingTop: "15px",
          minHeight: "100px",
          cursor: "pointer",
          position: "relative",
          backgroundColor: bgColor
        }}
        onClick={e => {
          e.stopPropagation();
          this.selectRecord(record.id);
        }}
      >
        <span style={{ position: "absolute", top: 0, left: 5, fontSize: "12px" }}>
          {record.type} {record.id}
        </span>
        <div className="col-md-12 accordion">
          <div className="card">
            <div className="card-header">
              {/* <div
                data-editable
                data-name={`er-${children[0].id}`}
                dangerouslySetInnerHTML={{
                  __html: this.renderRecord(regions[`er-${children[0].id}`])
                }}
              /> */}
            </div>
            {/* <div
              className="card-body"
              data-editable
              data-name={`er-${children[1].id}`}
              dangerouslySetInnerHTML={{
                __html: this.renderRecord(regions[`er-${children[1].id}`])
              }}
            /> */}
          </div>
        </div>
      </div>
    );
  };

  renderSeparatorElement = (record, children, index, isSelected) => {
    let bgColor = `rgba(0,0,0,${index % 2 === 0 ? "0.25" : "0.20"})`;

    const { regions } = this.props;
    // const hasChildren = Array.isArray(children) && children.length > 0;
    let region = record.name && regions[record.name];

    if (isSelected) {
      bgColor = "rgba(23, 162, 67, 0.5)";
    }

    if (isSelected) {
      bgColor = "rgba(23, 162, 67, 0.5)";
    }

    return (
      <div
        key={index}
        className="row"
        name={record.name}
        style={{
          paddingTop: "15px",
          minHeight: "100px",
          cursor: "pointer",
          position: "relative",
          backgroundColor: bgColor
        }}
        onClick={e => {
          e.stopPropagation();
          this.selectRecord(record.id);
        }}
      >
        <span style={{ position: "absolute", top: 0, left: 5, fontSize: "12px" }}>
          {record.type} {record.id}
        </span>
        <div className="col-md-12">
          <div
            className="line"
            dangerouslySetInnerHTML={{
              __html: this.renderRegion(region)
            }}
          />
        </div>
      </div>
    );
  };

  renderDocumentElement = (record, children, index, isSelected) => {
    const { regions } = this.props;
    let bgColor = `rgba(0,0,0,${index % 2 === 0 ? "0.25" : "0.20"})`;

    let region = record.name && regions[record.name];

    if (isSelected) {
      bgColor = "rgba(23, 162, 67, 0.5)";
    }

    return (
      <div
        key={index}
        className="row"
        name={`er-${record.id}`}
        style={{
          paddingTop: "15px",
          minHeight: "100px",
          cursor: "pointer",
          position: "relative",
          backgroundColor: bgColor
        }}
        onClick={e => {
          e.stopPropagation();
          this.selectRecord(record.id);
        }}
      >
        <span style={{ position: "absolute", top: 0, left: 5, fontSize: "12px" }}>
          {record.type} {record.id}
        </span>
        <div className="col-md-12">
          <div
            data-editable
            data-name={`er-${record.id}`}
            dangerouslySetInnerHTML={{
              __html: this.renderRegion(region)
            }}
          />
        </div>
      </div>
    );
  };

  renderRoot = (record, children, isSelected) => {
    let bgColor = "rgba(0,0,0,0.1";

    if (isSelected) {
      bgColor = "rgba(23, 162, 67, 0.5)";
    }

    return (
      <div
        className="container-fluid"
        style={{
          backgroundColor: bgColor,
          paddingTop: "15px",
          cursor: "pointer",
          minHeight: "100px",
          position: "relative"
        }}
        onClick={e => {
          e.stopPropagation();
          this.selectRecord(record.id);
        }}
      >
        <span style={{ position: "absolute", top: 0, left: 5, fontSize: "12px" }}>
          {record.type} {record.id}
        </span>
        {children.map((child, index) => this.renderRecord(child, index))}
      </div>
    );
  };

  renderRecord = (node, index) => {
    const {
      layout: { records },
      selectedRecordId
    } = this.state;
    const record = find(records, ["id", node.id]);
    const recordType = record.type;
    const isSelected = record.id === selectedRecordId;

    switch (recordType) {
      case "root":
        return this.renderRoot(record, node.children, isSelected);
      case "row":
        return this.renderRow(record, node.children, index, isSelected);
      case "column":
        return this.renderColumn(record, node.children, index, isSelected);
      case "legal-header":
        return this.renderLegalHeader(record, node.children, index, isSelected);
      case "collapsable":
        return this.renderCollapsableElement(record, node.children, index, isSelected);
      case "separator":
        return this.renderSeparatorElement(record, node.children, index, isSelected);
      case "document":
        return this.renderDocumentElement(record, node.children, index, isSelected);
      default:
        return null;
    }
  };

  renderLayout = () => {
    const {
      layout: { tree }
    } = this.state;

    return this.renderRecord(tree[0]);
  };

  selectRecord = recordId => {
    this.setState({
      selectedRecordId: recordId
    });
  };

  renderToolbar = selectedRecordId => {
    selectedRecordId = selectedRecordId || 1;

    const {
      layout,
      layout: { tree, records }
    } = this.state;
    const { save, reset, regions } = this.props;

    const nodeParent = findNodeParent(tree, selectedRecordId);
    const node = findNode(nodeParent ? [nodeParent] : tree, selectedRecordId);

    const nodeIndex = nodeParent && findIndex(nodeParent.children, ["id", node.id]);
    const nodeIndexMax = nodeParent && nodeParent.children.length;
    const record = find(records, ["id", node.id]);

    return (
      <Draggable bounds="html">
        <div
          style={{
            position: "fixed",
            top: 100,
            display: "flex",
            flexDirection: "column",
            width: "300px",
            minHeight: "250px",
            padding: "15px 15px 100px 15px",
            cursor: "pointer",
            zIndex: 9999,
            backgroundColor: "white",
            borderRadius: "1rem",
            border: "none",
            boxShadow: "0px 0px 10px #d9d9d9",
            right: "50px"
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <TranslatableParagraph translationLabel="ra.action.edit" /> {record.type} {record.id}
          </div>
          <Divider style={{ margin: "8px 0px" }} />
          {record.type === "root" ? this.renderElementsToolbar(record.id) : null}
          {record.type === "row"
            ? this.renderRowToolbar(record, nodeIndex, nodeIndexMax, nodeParent.id)
            : null}
          {record.type === "collapsable" || record.type === "separator"
            ? this.renderSaveToolbar(record, nodeIndex, nodeIndexMax, nodeParent.id)
            : null}
          {record.type === "document" || record.type === "legal-header"
            ? this.renderDocumentToolbar(record, nodeIndex, nodeIndexMax, nodeParent.id)
            : null}
          {record.type === "column"
            ? this.renderColumnToolbar(
                record,
                nodeIndex,
                nodeIndexMax,
                nodeParent.id,
                record.name && regions[record.name]
              )
            : null}
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Divider style={{ margin: "8px 0px" }} />
            <Button
              size="small"
              variant="contained"
              color="primary"
              style={{ margin: "8px 0px" }}
              className="customButtons"
              onClick={() => {
                save(layout);
              }}
            >
              <TranslatableParagraph translationLabel="ra.action.save" />
            </Button>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              style={{ margin: "8px 0px" }}
              onClick={reset}
            >
              <TranslatableParagraph translationLabel="ra.action.cancel" />
            </Button>
          </div>
        </div>
      </Draggable>
    );
  };

  renderRowToolbar = (record, recordIndex, recordIndexMax, parentId) => {
    const orderValues = generateNumberArray(recordIndexMax);

    return (
      <Fragment>
        <FormControl>
          <InputLabel>
            <TranslatableParagraph translationLabel="ra.editor.order" />
          </InputLabel>
          <Select
            value={recordIndex + 1}
            onChange={e => {
              this.recordOrder(parentId, record.id, parseInt(e.target.value - 1));
            }}
          >
            {orderValues.map((value, index) => (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider style={{ margin: "8px 0px" }} />
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => this.createRecord(record.id, "column")}
        >
          <TranslatableParagraph translationLabel="ra.editor.add_column" />
        </Button>
        <Divider style={{ margin: "8px 0px" }} />
        <ButtonDeleteRecord
          parentId={parentId}
          recordId={record.id}
          layoutEditorState={this.state}
          layoutEditorProps={this.props}
          deleteRecordStateHandle={this.deleteRecordStateHandle}
        />
      </Fragment>
    );
  };

  renderDocumentToolbar = (record, recordIndex, recordIndexMax, parentId) => {
    return (
      <Fragment>
        <FileInput label="File" source={`files.${record.id}`}>
          <FileField source="src" title="title" />
        </FileInput>
        <Divider style={{ margin: "8px 0px" }} />
        <ButtonDeleteRecord
          parentId={parentId}
          recordId={record.id}
          layoutEditorState={this.state}
          layoutEditorProps={this.props}
          deleteRecordStateHandle={this.deleteRecordStateHandle}
        />
      </Fragment>
    );
  };

  renderSaveToolbar = (record, recordIndex, recordIndexMax, parentId) => {
    return (
      <Fragment>
        <ButtonDeleteRecord
          parentId={parentId}
          recordId={record.id}
          layoutEditorState={this.state}
          layoutEditorProps={this.props}
          deleteRecordStateHandle={this.deleteRecordStateHandle}
        />
      </Fragment>
    );
  };

  renderColumnToolbar = (record, recordIndex, recordIndexMax, parentId, hasContent) => {
    const updateSize = (record, bptSize) => {
      this.updateRecord({ ...record, size: { ...record.size, ...bptSize } });
    };
    const maxSize = 12;
    const sizeValues = [0, ...generateNumberArray(maxSize)];
    const orderValues = generateNumberArray(recordIndexMax);

    return (
      <Fragment>
        {!hasContent ? (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => this.createRecord(record.id, "row")}
          >
            <TranslatableParagraph translationLabel="ra.editor.row" />
          </Button>
        ) : null}
        <ButtonDeleteRecord
          parentId={parentId}
          recordId={record.id}
          layoutEditorState={this.state}
          layoutEditorProps={this.props}
          deleteRecordStateHandle={this.deleteRecordStateHandle}
        />

        <Divider style={{ margin: "8px 0px" }} />
        <FormControl>
          <InputLabel>
            <TranslatableParagraph translationLabel="ra.editor.order" />
          </InputLabel>
          <Select
            value={recordIndex + 1}
            onChange={e => {
              this.recordOrder(parentId, record.id, parseInt(e.target.value - 1));
            }}
          >
            {orderValues.map((value, index) => (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {Object.keys(record.size).map((bpt, index, arr) => (
          <FormControl key={index}>
            <InputLabel
              style={{
                display: "flex",
                width: "80%",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <TranslatableParagraph translationLabel="ra.editor.column_size" /> {bpt}
            </InputLabel>
            <Select
              value={record.size[bpt]}
              onChange={e => {
                updateSize(record, { [bpt]: parseInt(e.target.value) });
              }}
            >
              {sizeValues.map((value, index) => (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ))}
      </Fragment>
    );
  };

  renderElementsToolbar = recordId => {
    const elements = [
      { key: "row", name: "Add Row" },
      { key: "legal-header", name: "Add header block" },
      { key: "collapsable", name: "Add Collapsable Section" },
      { key: "separator", name: "Add letter separator" },
      { key: "document", name: "Add document section" }
    ];

    return elements.map(element => (
      <Button
        key={element.key}
        size="small"
        variant="contained"
        color="primary"
        style={{ margin: "8px 0px" }}
        onClick={() => this.createRecord(recordId, element.key)}
      >
        <TranslatableParagraph translationLabel={"ra.editor." + element.key} />
      </Button>
    ));
  };

  render() {
    const { selectedRecordId } = this.state;

    return (
      <div style={{ position: "relative" }}>
        {this.renderToolbar(selectedRecordId)}
        {this.renderLayout()}
        {/* <pre
          style={{ fontSize: "80%" }}
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(this.state, undefined, 2)
          }}
        /> */}
      </div>
    );
  }
}

export default LayoutEditor;
