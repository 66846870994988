export const userTypesAndRolesValidation = (value, formValues) => {
  if (formValues.isSpecificUserPromotion) {
    return undefined;
  } else {
    if (value && value.length > 0) {
      return undefined;
    } else {
      return "Required";
    }
  }
};

export const usersValidation = (value, formValues) => {
  if (formValues.isSpecificUserPromotion) {
    if (value && value.length > 0) {
      return undefined;
    } else {
      return "Required";
    }
  } else {
    return undefined;
  }
};

export const productQuantityOperatorValidation = (value, formValues) => {
  if (
    (formValues.rule.combine_products && !value && !formValues.rule.product_amount) ||
    (formValues.rule.combine_products && !value && !formValues.rule.product_amount.operator)
  ) {
    return "Required";
  } else {
    return undefined;
  }
};

export const productAmountOperatorValidation = (value, formValues) => {
  if (
    (formValues.rule.combine_products && !value && !formValues.rule.product_quantity) ||
    (formValues.rule.combine_products && !value && !formValues.rule.product_quantity.operator)
  ) {
    return "Required";
  } else {
    return undefined;
  }
};

export const productQuantityValueValidation = (value, formValues) => {
  if (formValues.rule.product_quantity && formValues.rule.product_quantity.operator) {
    if (value || value === 0) {
      if (value < 0) {
        return "Can not be less than 0";
      } else {
        return undefined;
      }
    } else {
      return "Required";
    }
  } else {
    return undefined;
  }
};

export const productAmountValueValidation = (value, formValues) => {
  if (formValues.rule.product_amount && formValues.rule.product_amount.operator) {
    if (value || value === 0) {
      if (value < 0) {
        return "Can not be less than 0";
      } else {
        return undefined;
      }
    } else {
      return "Required";
    }
  } else {
    return undefined;
  }
};
