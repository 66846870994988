import React from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  ArrayInput,
  DateTimeInput,
  Labeled,
  SimpleFormIterator
} from "react-admin";

import { FormDataConsumer } from "ra-core";
import { maxValue, minValue } from "../../utils/validators";

export const BlogDetailsEdit = props => (
  <Edit {...props}>
    <SimpleForm variant="standard" redirect={`/blogArticles/${props.id}`}>
      <FormDataConsumer>
        {({ record }) => {
          if (record.is_recipe) {
            return (
              <>
                <ArrayInput label="resources.blogs.blogDetails.keywords" source="keywords">
                  <SimpleFormIterator>
                    <TextInput
                      label="resources.blogs.blogDetails.keyword"
                      source="keyword"
                      style={{ marginLeft: 8 }}
                      variant="standard"
                    />
                  </SimpleFormIterator>
                </ArrayInput>
                <TextInput
                  multiline
                  label="resources.blogs.blogDetails.description"
                  source="description"
                  variant="standard"
                  fullWidth
                />
                <TextInput
                  multiline
                  label="resources.blogs.blogDetails.recipe.recipe_instruction"
                  source="recipe_instructions"
                  variant="standard"
                  fullWidth
                />
                <Labeled label="resources.blogs.blogDetails.recipe.cook_time">
                  <span style={{ display: "flex" }}>
                    <TextInput
                      variant="standard"
                      label="resources.blogs.blogDetails.recipe.hours"
                      source="hours_cook"
                      type="number"
                      validate={minValue(0)}
                    />
                    &nbsp;
                    <TextInput
                      variant="standard"
                      label={`resources.blogs.blogDetails.recipe.minutes`}
                      source="minutes_cook"
                      type="number"
                      validate={[minValue(0), maxValue(60)]}
                    />
                    &nbsp;
                    <TextInput
                      variant="standard"
                      label={`resources.blogs.blogDetails.recipe.seconds`}
                      source="seconds_cook"
                      type="number"
                      validate={[minValue(0), maxValue(60)]}
                    />
                  </span>
                </Labeled>
                <Labeled label="resources.blogs.blogDetails.recipe.preparation_time">
                  <span style={{ display: "flex" }}>
                    <TextInput
                      variant="standard"
                      label="resources.blogs.blogDetails.recipe.hours"
                      source="hours_prep"
                      type="number"
                      validate={minValue(0)}
                    />
                    &nbsp;
                    <TextInput
                      variant="standard"
                      label={`resources.blogs.blogDetails.recipe.minutes`}
                      source="minutes_prep"
                      type="number"
                      validate={[minValue(0), maxValue(60)]}
                    />
                    &nbsp;
                    <TextInput
                      variant="standard"
                      label={`resources.blogs.blogDetails.recipe.seconds`}
                      source="seconds_prep"
                      type="number"
                      validate={[minValue(0), maxValue(60)]}
                    />
                  </span>
                </Labeled>
                <ArrayInput
                  label="resources.blogs.blogDetails.recipe.recipe_ingredients"
                  source="recipe_ingredients"
                >
                  <SimpleFormIterator>
                    <TextInput
                      label="resources.blogs.blogDetails.recipe.ingredients"
                      source="ingredient"
                      style={{ marginLeft: 8 }}
                      variant="standard"
                    />
                  </SimpleFormIterator>
                </ArrayInput>
                <TextInput
                  label="resources.blogs.blogDetails.recipe.recipe_cuisine"
                  source="recipe_cuisine"
                  variant="standard"
                />
                <ArrayInput source="video" style={{ width: "100%" }}>
                  <SimpleFormIterator>
                    <TextInput
                      label="resources.blogs.blogDetails.recipe.name"
                      source="name"
                      variant="standard"
                    />
                    <TextInput
                      multiline
                      label="resources.blogs.blogDetails.recipe.description"
                      source="description"
                      variant="standard"
                    />
                    <ArrayInput
                      label="resources.blogs.blogDetails.recipe.thumbnails"
                      source="thumbnailUrl"
                      style={{ width: "50%" }}
                    >
                      <SimpleFormIterator>
                        <TextInput
                          label="resources.blogs.blogDetails.recipe.url"
                          source="thumb_url"
                          variant="standard"
                        />
                      </SimpleFormIterator>
                    </ArrayInput>
                    <TextInput
                      label="resources.blogs.blogDetails.recipe.content_url"
                      source="contentUrl"
                      variant="standard"
                    />
                    <TextInput
                      label="resources.blogs.blogDetails.recipe.embed_url"
                      source="embedUrl"
                      variant="standard"
                    />
                    <DateTimeInput
                      label="resources.blogs.blogDetails.recipe.upload_date"
                      source="uploadDate"
                      variant="standard"
                    />
                    <TextInput
                      label="resources.blogs.blogDetails.recipe.interaction_count"
                      source="interactionCount"
                      variant="standard"
                    />
                    <TextInput
                      variant="standard"
                      label="resources.blogs.blogDetails.recipe.duration_hours"
                      source="hours_video"
                      type="number"
                      validate={minValue(0)}
                    />
                    <TextInput
                      variant="standard"
                      label="resources.blogs.blogDetails.recipe.duration_minutes"
                      source="minutes_video"
                      type="number"
                      validate={[minValue(0), maxValue(60)]}
                    />
                    <TextInput
                      variant="standard"
                      label="resources.blogs.blogDetails.recipe.duration_seconds"
                      source="seconds_video"
                      type="number"
                      validate={[minValue(0), maxValue(60)]}
                    />
                  </SimpleFormIterator>
                </ArrayInput>
                <TextInput
                  label="resources.blogs.blogDetails.recipe.calories"
                  source="calories"
                  variant="standard"
                />
                <TextInput
                  label="resources.blogs.blogDetails.recipe.serving_size"
                  source="serving_size"
                  variant="standard"
                />
              </>
            );
          } else {
            return (
              <>
                <ArrayInput label="resources.blogs.blogDetails.keywords" source="keywords">
                  <SimpleFormIterator>
                    <TextInput
                      label="resources.blogs.blogDetails.keyword"
                      source="keyword"
                      style={{ marginLeft: 8 }}
                      variant="standard"
                    />
                  </SimpleFormIterator>
                </ArrayInput>
                <TextInput
                  multiline
                  label="resources.blogs.blogDetails.description"
                  source="description"
                  variant="standard"
                  fullWidth
                />
              </>
            );
          }
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);
