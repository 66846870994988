import resources from "../../../resources/resources";

// TODO: Add orders includes map when exporting orders
const includes = {
  USERS_EXPORT: {
    "defaultAddress.city_id": "userAddresses.address.city",
    "defaultAddress.zip_code": "userAddresses.address.city",
    price_level_id: "priceLevels",
    stateOrProvince: "userAddresses.address.stateOrProvince",
    userAddressLines: "userAddresses.address.city",
    userRole: "userRoles.role",
    user_level_id: "user_level",
    user_type_id: "userType"
  }
};

const getIncludes = (selection, exportIncludes) => {
  let result = [];
  Object.keys(selection).forEach(item => {
    if (selection[item] && !!exportIncludes[item]) {
      if (result.indexOf(exportIncludes[item]) === -1) {
        result.push(exportIncludes[item]);
      }
    }
  });
  return result;
};

const getIncludesHandler = (resource, selection) => {
  let result = [];
  switch (resource) {
    case resources.USERS: {
      return getIncludes(selection, includes.USERS_EXPORT);
    }
    default:
      return result;
  }
};

export default getIncludesHandler;
