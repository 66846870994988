import React, { useState, useEffect } from "react";
import {
  Create,
  TextInput,
  TabbedForm,
  FormTab,
  Toolbar,
  SaveButton,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  NumberInput,
  BooleanInput,
  required,
  useNotify,
  useRedirect,
  minValue
} from "react-admin";

import { handleSingleRequest } from "../../services/api/restClient";
import { GET_LIST } from "../../services/api/types";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { styles } from "../styles";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";
import { useFormState } from "react-final-form";
import { validateNameInput } from "../../utils/validators/index";

const ConfirmationToolbar = props => {
  const { values: formData } = useFormState();

  let isDisabledSave = !formData.column_title || !formData.column_order ? true : false;

  return (
    <Toolbar {...props} style={styles.toolbar}>
      <SaveButton disabled={isDisabledSave} />
      <DeleteConfirmation undoable={false} />
    </Toolbar>
  );
};

const thereCanBeOnlyOne = (value, formValues) => {
  if (value) {
    let checkForExistingLanguageSelected = formValues.translations.filter(
      item => item && item.language && item.language === value
    );
    if (checkForExistingLanguageSelected.length > 1) {
      return "There can be only one language per column";
    } else {
      return undefined;
    }
  } else {
    return "Required";
  }
};

const checkURL = value => {
  if (value) {
    return value.charAt(0) !== "/"
      ? "Wrong format, try for example /categories/promotions"
      : undefined;
  } else {
    return "Required";
  }
};

export const FooterCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [allTranslations, setAllTranslations] = useState({});
  const [allLanguages, setAllLanguages] = useState([]);

  const grabLanguages = () => {
    handleSingleRequest(GET_LIST, "languages", {
      pagination: {
        page: "all"
      },
      sort: { field: "name", order: "ASC" },
      filter: {
        is_enabled: true,
        is_default: false
      }
    })
      .then(({ data }) => {
        const formatLanguages = data.map(translationItem => {
          return {
            id: translationItem.id,
            name: translationItem.international_name
          };
        });
        setAllLanguages(formatLanguages);
      })
      .catch(error => console.log(error));
  };

  const grabTranslations = () => {
    handleSingleRequest(GET_LIST, "translations", {
      pagination: {
        page: "all"
      },
      sort: { field: "id", order: "ASC" },
      filter: {
        table_name: "footer_info"
      }
    })
      .then(({ data }) => {
        setAllTranslations(data);
      })
      .catch(error => console.log(error));
  };

  const onSuccess = () => {
    notify("Footer has been created");
    redirect("/footer");
  };

  const onFailure = () => {
    notify("Data has been delayed. Footer has been created; Please review");
    redirect("/footer");
  };

  useEffect(() => {
    grabLanguages();
    grabTranslations();
  }, []);

  const checkColumnOrder = value => {
    let regex = /^\+?(0|[1-9]\d*)$/gm;
    if (value && regex.test(value)) {
      const filtered = allTranslations?.filter(item => item.translation.column_order === value);
      return filtered?.length ? "Column number taken" : undefined;
    } else if (!regex.test(value)) {
      return "Number can not be negative";
    } else {
      return "Required";
    }
  };

  return (
    <Create onSuccess={onSuccess} onFailure={onFailure} {...props}>
      <TabbedForm redirect="show" toolbar={<ConfirmationToolbar />} variant="standard">
        <FormTab label="resources.footer.fields.column_content">
          <TextInput
            label="resources.footer.fields.column_title"
            source="column_title"
            validate={[required(), validateNameInput]}
          />
          <NumberInput
            min="1"
            source="column_order"
            label="resources.footer.fields.column_order"
            validate={[checkColumnOrder, required()]}
          />
          <ArrayInput source="text_row" label="resources.footer.fields.text_row">
            <SimpleFormIterator disableReordering TransitionProps={{ enter: false, exit: false }}>
              <NumberInput
                min="1"
                source="order"
                label="resources.footer.fields.order"
                validate={[required(), minValue(1)]}
              />
              <TextInput label="resources.footer.fields.label" source="label" />
              <TextInput label="resources.footer.fields.text" source="text" validate={required()} />
              <FormDataConsumer>
                {({ scopedFormData, getSource }) => {
                  return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <BooleanInput
                        label="resources.footer.fields.has_url_bool"
                        source={getSource("has_url")}
                        record={scopedFormData}
                      />
                      {scopedFormData?.has_url && (
                        <TextInput
                          label="resources.footer.fields.has_url"
                          source={getSource("text_url")}
                          validate={checkURL}
                        />
                      )}
                    </div>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="resources.footer.translations.name">
          <ArrayInput source="translations">
            <SimpleFormIterator disableReordering TransitionProps={{ enter: false, exit: false }}>
              <FormDataConsumer>
                {({ formData, dispatch, getSource, ...rest }) => {
                  return (
                    <div>
                      <p style={{ color: "#bbb", fontSize: "11px" }}>
                        <TranslatableParagraph
                          textOnly
                          translationLabel="resources.footer.translations.original_title"
                        />
                        {formData?.column_title}
                      </p>
                      <TextInput
                        label="resources.footer.translations.column_title"
                        source={getSource("column_title")}
                        validate={required()}
                        variant="standard"
                      />
                      <br />
                      <SelectInput
                        source={getSource("language")}
                        choices={allLanguages}
                        label="resources.footer.translations.language"
                        validate={thereCanBeOnlyOne}
                        variant="standard"
                      />
                      {formData?.text_row?.length &&
                        formData?.text_row.map((row, index) => {
                          return (
                            <div style={{ display: "flex", flexDirection: "column" }} key={index}>
                              {row?.label && (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                  <p style={{ color: "#bbb", fontSize: "11px" }}>
                                    <TranslatableParagraph
                                      textOnly
                                      translationLabel="resources.footer.translations.original_label"
                                    />
                                    {formData?.text_row[index].label}
                                  </p>
                                  <TextInput
                                    label="resources.footer.translations.label_translation"
                                    source={getSource(`content[${index}].label`)}
                                    validate={required()}
                                    variant="standard"
                                  />
                                </div>
                              )}
                              {row?.text && (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                  <p style={{ color: "#bbb", fontSize: "11px" }}>
                                    <TranslatableParagraph
                                      textOnly
                                      translationLabel="resources.footer.translations.original_text"
                                    />
                                    {formData?.text_row[index].text}
                                  </p>
                                  <TextInput
                                    label="resources.footer.translations.text_translation"
                                    source={getSource(`content[${index}].text`)}
                                    validate={required()}
                                    variant="standard"
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
