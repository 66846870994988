import React from "react";
import { useForm } from "react-final-form";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

//  Accepted Props: field="(redux field name)" | accept="(acceptMessage)" decline="(declineMessage)" / If value is accept popup will just close without changing the active state
const PushNotificationDialog = props => {
  const form = useForm();

  const displayEffect = display => {
    var elem = document.getElementById("overrideConsent");
    elem.style.display = display;
  };

  return (
    <div
      id="overrideConsent"
      style={{
        display: "flex",
        justifyContent: `space-between`,
        alignItems: `center`,
        backgroundColor: `#ebedee`,
        boxShadow: "0px 0px 10px #ebedee",
        margin: `15px 0px`,
        padding: "10px",
        borderRadius: `4px`,
        color: "#f9f9f9!important",
        flexWrap: "wrap",
        maxWidth: `300px`
      }}
    >
      <p
        style={{
          width: "100%",
          fontWeight: "300",
          fontSize: "14px",
          lineHeight: "24px",
          padding: "5px"
        }}
      >
        {props.popupDialog}
      </p>
      <Button
        color="primary"
        style={{
          boxShadow: "0px 0px 10px #d9d9d9",
          fontSize: "10px",
          backgroundColor: "#3CB371",
          flex: "1",
          fontWeight: "bold",
          color: "white",
          padding: "5px",
          margin: "5px"
        }}
        label="I accept"
        onClick={() => {
          props.override_consent ? displayEffect("none") : form.change("override_consent", true);
        }}
      >
        <CheckIcon style={{ marginRight: "10px" }} />
        <TranslatableParagraph translationLabel="resources.notifications.popup_dialog.accept" />
      </Button>
      <Button
        color="primary"
        style={{
          boxShadow: "0px 0px 10px #d9d9d9",
          fontSize: "10px",
          backgroundColor: "#ff6666",
          flex: "1",
          fontWeight: "bold",
          color: "white",
          padding: "5px",
          margin: "5px"
        }}
        label="I do not accept"
        onClick={() => form.change("override_consent", false)}
      >
        <ClearIcon style={{ marginRight: "10px" }} />
        <TranslatableParagraph translationLabel="resources.notifications.popup_dialog.decline" />
      </Button>
    </div>
  );
};

export default PushNotificationDialog;
