import React from "react";
import { Link } from "react-router-dom";
import AddCircle from "@material-ui/icons/AddCircle";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
    padding: "6px 10px"
  }
};

const AddTechDataButton = ({ classes, record }) => {
  return (
    <>
      {!record.name && (
        <p style={{ color: "red", fontSize: "13px" }}>
          In order to add tech data, make sure all required fields in the Basic tab are filled in
        </p>
      )}

      <Button
        className={classes.button}
        variant="contained"
        component={Link}
        disabled={!record.name}
        to={{
          pathname: "/techDataLists/create",
          search: `?source=${JSON.stringify({ product_id: record.id })}`
        }}
        label="resources.products.tabs.tech_data.action_button"
      >
        <AddCircle />
      </Button>
    </>
  );
};

export default withStyles(styles)(AddTechDataButton);
