export const facebookCategories = [
  {
    id: "home > home goods > kitchen & dining > small kitchen appliances > water filters & pitchers",
    name: "home > home goods > kitchen & dining > small kitchen appliances > water filters & pitchers"
  },
  {
    id: "clothing & accessories > costumes > costume accessories > glasses & eyewear",
    name: "clothing & accessories > costumes > costume accessories > glasses & eyewear"
  },
  {
    id: "home improvement > home heating & cooling > air purifiers",
    name: "home improvement > home heating & cooling > air purifiers"
  },
  {
    id: "home > cleaning supplies > vacuums & floor cleaners",
    name: "home > cleaning supplies > vacuums & floor cleaners"
  },
  { id: "home > home goods > kitchen & dining", name: "home > home goods > kitchen & dining" },
  { id: "home > home goods", name: "home > home goods" },
  { id: "health & beauty > health", name: "health & beauty > health" },
  { id: "health & beauty ", name: "health & beauty " }
];
