export const ruleTypes = [
  { id: "searcher", name: "Searcher" },
  { id: "cart_amount", name: "Cart Amount" },
  { id: "promo_code", name: "Promo Code" }
];
export const orderTypes = [
  { id: "buying", name: "Buying" },
  { id: "selling", name: "Selling" }
];
export const paymentOptions = [
  { id: "card", name: "Card" },
  { id: "cash", name: "Cash" },
  { id: "rentall", name: "RentAll" },
  { id: "cash_on_delivery", name: "Cash Upon Delivery" },
  { id: "card_on_delivery", name: "Card Upon Delivery" },
  { id: "advance_payment", name: "Advance Payments" },
  { id: "banking_credit", name: "Banking Credit" },
  { id: "bank_transfer", name: "Bank Transfer" },
  { id: "onetime_payment", name: "One-time payment" }
];

export const priceLevels = [
  { id: "D1", name: "D1" },
  { id: "D2", name: "D2" },
  { id: "D3", name: "D3" },
  { id: "D4", name: "D4" },
  { id: "D5", name: "D5" },
  { id: "D6", name: "D6" },
  { id: "D7", name: "D7" },
  { id: "D8", name: "D8" },
  { id: "D9", name: "D9" },
  { id: "D10", name: "D10" },
  { id: "D11", name: "D11" },
  { id: "D12", name: "D12" },
  { id: "D13", name: "D13" },
  { id: "D14", name: "D14" },
  { id: "D15", name: "D15" },
  { id: "D16", name: "D16" }
];

export const comparatorOperators = [
  { id: ">", name: "More than" },
  { id: "<", name: "Less than" },
  { id: "==", name: "Equals" }
];
