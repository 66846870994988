import { handleSingleRequest } from "../restClient";
import { CREATE, GET_ONE, GET_LIST, DELETE } from "../types";

export const transformPageUpdate = async (type, resource, params) => {
  // get all translations for comparison
  if (!params.data?.translations) {
    params.data.translations = [];
  }
  const allLanguages = await handleSingleRequest(GET_LIST, "languages", {
    filter: {
      is_enabled: true
    },
    pagination: { page: "all" },
    sort: { field: "id", order: "DESC" }
  });
  const allLanguagesIds = allLanguages.data.map(item => item.id);
  const allTranslations = await handleSingleRequest(GET_LIST, "translations", {
    filter: { table_row_id: params.data.id, language_id_in: allLanguagesIds },
    pagination: { page: "all" },
    sort: { field: "id" }
  });
  params.data.translations.forEach(translationItem => {
    let translationType = type;
    let translationParams = {
      data: {
        table_row_id: params.data.id,
        language_id: translationItem.language.id,
        table_name: "pages",
        translation: {
          name: translationItem.name
        }
      }
    };
    let itemExistsUpdate = allTranslations.data.find(getItem => getItem.id === translationItem.id);
    if (itemExistsUpdate) {
      translationParams = {
        ...translationParams,
        id: translationItem.id
      };
    } else {
      translationType = CREATE;
    }
    handleSingleRequest(translationType, "translations", translationParams);
  });
  allTranslations.data.forEach(translationItem => {
    let itemDontExistsDelete = params.data.translations.find(
      getItem => getItem.id === translationItem.id
    );
    if (!itemDontExistsDelete) {
      handleSingleRequest(DELETE, "translations", {
        data: {},
        id: translationItem.id
      });
    }
  });
  params.data.translations = [];
  return handleSingleRequest(type, resource, params).then(({ data, ...rest }) => {
    return { data, ...rest };
  });
};

export const transformPageCreate = (type, resource, params) => {
  return handleSingleRequest(type, resource, params).then(response => {
    return handleSingleRequest(GET_ONE, "pageTypes", {
      id: params.data.page_type_id
    }).then(responsePageType => {
      const images =
        responsePageType.data && responsePageType.data.name === "homepage"
          ? [{}, {}, {}, {}, {}, {}]
          : undefined;
      return handleSingleRequest(CREATE, "pageContents", {
        data: {
          name: "Draft",
          page_id: response.data.id,
          content: JSON.stringify({ regions: {}, images, files: [] }),
          language_id: params.data.language_id
        }
      }).then(() => {
        return handleSingleRequest(CREATE, "translations", {
          data: {
            table_row_id: response.data.id, // here page id from response
            language_id: params.data.language_id,
            table_name: "pages",
            translation: {
              name: params.data.name
            }
          }
        }).then(() => {
          return response;
        });
      });
    });
  });
};

// GET_ONE, GET_MANY, GET_MANY_REFERENCE
export const transformPageGet = async (type, resource, params) => {
  params.filter = {}; // filtering will be on the client side
  return handleSingleRequest(type, resource, {
    ...params,
    include: ["headTags", "pageTypes"]
  })
    .then(({ data = [], included = [], relationships, ...rest }) => {
      if (typeof data.map !== "function") {
        data.head_tags_meta = included.find(item => item.type === "headTags");
        data.pageType = included.find(
          item => (item.type = "pageTypes" && data.page_type_id === item.id)
        );
        if (data.head_tags_meta && data.head_tags_meta.meta_data.keywords) {
          data.head_tags_meta.meta_data.keywords = data.head_tags_meta.meta_data.keywords.map(
            word => {
              const refOb = {};
              refOb.keyword = word;
              return refOb;
            }
          );
        }
      } else {
        data = data
          .map(record => {
            record.head_tags_meta = included.find(
              item => item.type === "headTags" && record.head_tag_id === item.id
            );
            record.pageType = included.find(
              item => (item.type = "pageTypes" && record.page_type_id === item.id)
            );
            if (record.head_tags_meta && record.head_tags_meta.meta_data.keywords) {
              record.head_tags_meta.meta_data.keywords =
                record.head_tags_meta.meta_data.keywords.map(word => {
                  const refOb = {};
                  refOb.keyword = word;
                  return refOb;
                });
            }
            return record;
          })
          .sort((a, b) => {
            return a.weight - b.weight;
          });
      }
      return { data, ...rest };
    })
    .then(async ({ data, ...rest }) => {
      let idFromUrl = window.location.hash.replace("#/pages/", "").split("/")[0];
      const allLanguages = await handleSingleRequest(GET_LIST, "languages", {
        filter: {
          is_enabled: true
        },
        pagination: { page: "all" },
        sort: { field: "id", order: "DESC" }
      });

      if (idFromUrl !== "#") {
        const allLanguagesIds = allLanguages.data.map(item => item.id);
        const pageTranslations = await handleSingleRequest(GET_LIST, "translations", {
          filter: { table_row_id: idFromUrl, language_id_in: allLanguagesIds },
          pagination: { page: "all" },
          sort: { field: "id" }
        });
        if (pageTranslations.data && pageTranslations.data.length > 0) {
          const constructTranslations = pageTranslations.data.map(translationItem => {
            let findLanguage = allLanguages.data.find(
              language => translationItem.language_id === language.id
            );
            return {
              id: translationItem.id,
              language: { id: findLanguage.id, name: findLanguage.name },
              name: translationItem.translation.name,
              description: translationItem.translation.description
            };
          });
          data.translations = constructTranslations;
        } else {
          data.translations = [];
        }
      }
      return { data, ...rest };
    });
};
