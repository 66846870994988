import { useNotify, useRedirect } from "react-admin";
import Button from "@material-ui/core/Button";

export const PopupNotification = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  const displayNotification = () => {
    if (props.message && props.type) {
      notify(`${props.message}`, {
        type: `${props.type}`
        //   anchorOrigin: { vertical: props.vertical, horizontal: props.horizontal },
        //   autoHideDuration: props.hide
      });
    }
    if (props.redirectView && props.redirectPath) {
      redirect(`${props.redirectView}`, `/${props.redirectPath}`, props.redirectScreen);
    }
  };

  return props.message && props.type ? (
    <Button
      variant="contained"
      color="primary"
      style={{
        width: "fit-content",
        fontSize: "12px",
        backgroundColor: "rgb(49, 49, 49)",
        color: "#f9f9f9",
        borderRadius: "8px",
        padding: "10px",
        margin: "10px 0px",
        cursor: "pointer"
      }}
      onClick={displayNotification}
    >
      {props?.buttonLabel || "Notification popup"}
    </Button>
  ) : null;
};
