import React, { Component } from "react";

import { PieChart, Pie, Cell, Legend } from "recharts";

export default class PieGraph extends Component {
  state = {
    sum: 0
  };

  componentDidMount() {
    const { data } = this.props;

    const sum = data.reduce(
      (a, b) => ({
        beforeValue: a.beforeValue + b.beforeValue
      }),
      {
        beforeValue: 0
      }
    ).beforeValue;
    this.setState({ sum });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      const { data } = this.props;

      const sum = data.reduce(
        (a, b) => ({
          beforeValue: a.beforeValue + b.beforeValue
        }),
        {
          beforeValue: 0
        }
      ).beforeValue;
      this.setState({ sum });
    }
  }

  renderActiveShape = props => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, fill, payload, percent } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + outerRadius * sin;
    const mx = cx + (outerRadius + 15) * cos;
    const my = cy + (outerRadius + 10) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;

    const ey = my;
    const textAnchor = "start";

    const width = 110;
    const height = 60;

    const { oldKey, newKey } = this.props;
    const { sum } = this.state;

    const prevPercent = payload.beforeValue ? payload.beforeValue / sum : 0;

    // TODO: draw arrows
    const increase = percent > prevPercent;

    return (
      <g>
        <rect
          x={cos >= 0 ? ex : ex - width - 2}
          y={my - 36}
          width={width}
          height={height}
          fill="#fff"
          stroke="#d8d8d8"
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <text
          x={cos >= 0 ? ex + 2 : ex - width}
          y={ey}
          dy={-18}
          textAnchor={textAnchor}
          fill="#000"
          fontWeight="bold"
          fontSize="8"
        >
          {payload.name.toUpperCase() +
            (payload.name === "Returning" || payload.name === "First Time" ? " CUSTOMERS" : "")}
        </text>
        <text
          x={cos >= 0 ? ex + 2 : ex - width}
          y={ey}
          textAnchor={textAnchor}
          fill="#a5a5a5"
          fontSize="9"
        >
          {newKey + " - " + oldKey}
        </text>
        <text
          x={cos >= 0 ? ex + 2 : ex - width}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill={fill}
          fontWeight="bold"
          fontSize="14"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
        <text
          x={cos >= 0 ? ex + 2 + width - 40 : ex - 40}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fontSize="14"
        >
          {`${(prevPercent * 100).toFixed(0)}%`}
        </text>
        <line
          x1={cos >= 0 ? ex + 2 + width - 46 : ex - 46}
          y1={ey + 8}
          x2={cos >= 0 ? ex + 2 + width - 46 : ex - 46}
          y2={ey + 18}
          stroke={increase ? "#5ae21b" : "#f00"}
          strokeWidth="1"
        />
        {increase ? (
          <path
            d={`
          M${cos >= 0 ? ex + 2 + width - 46 : ex - 46},
          ${ey + 8}
          L${cos >= 0 ? ex + 2 + width - 44 : ex - 44},
          ${ey + 12}
          L${cos >= 0 ? ex + 2 + width - 48 : ex - 48},
          ${ey + 12} Z`}
            stroke="#5ae21b"
            fill="#5ae21b"
          />
        ) : (
          <path
            d={`
          M${cos >= 0 ? ex + 2 + width - 46 : ex - 46},
          ${ey + 18}
          L${cos >= 0 ? ex + 2 + width - 44 : ex - 44},
          ${ey + 14}
          L${cos >= 0 ? ex + 2 + width - 48 : ex - 48},
          ${ey + 14} Z`}
            stroke="#f00"
            fill="#f00"
          />
        )}
      </g>
    );
  };

  render() {
    const { colors, data } = this.props;

    return (
      <PieChart width={435} height={260}>
        <Pie
          label={this.renderActiveShape}
          labelLine={false}
          data={data}
          dataKey="value"
          cx={220}
          cy={100}
          innerRadius={40}
          outerRadius={60}
          fill="#8884d8"
          paddingAngle={5}
        >
          {data.map((entry, index) => (
            <Cell key={index} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Legend align="right" iconType="circle" />
      </PieChart>
    );
  }
}
