/* eslint-disable eqeqeq */
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
  UPDATE_MANY
} from "./types";

import { fetchJson, queryParameters } from "./fetch";
import { get, isEmpty } from "lodash";

import {
  transformOrderList,
  transformOrderItem,
  transformPageCreate,
  transformPageUpdate,
  transformUserAddressesList,
  transformProducts,
  transformTags,
  transformTagsCreate,
  transformTagsDelete,
  transformTagGroups,
  transformTagGroupsCreate,
  transformTagGroupsUpdate,
  transformUserList,
  transformUserItem,
  transformProductCategories,
  transformProductCategory,
  transformProductCategoryCreate,
  transformProductCategoryUpdate,
  transformProductImageCreate,
  transformProductImageDelete,
  transformProductImageUpdate,
  transformProduct,
  transformProductUpdate,
  transformProductCreate,
  transformTechData,
  transformProductPriceCreate,
  transformPageContent,
  transformPageContentUpdate,
  transformPageContentMany,
  transformPageContentDelete,
  transformProductPriceUpdate,
  transformShops,
  transformShopsCreate,
  transformBlogCategories,
  transformBlogCategoriesCreate,
  transformBlogCategoriesUpdate,
  transformBlogArticlesCreateUpdate,
  transformBlogArticles,
  transformBlogDetailsCreateUpdate,
  transformBlogDetails,
  transformNotificationCreateUpdate,
  // transformNotificationAudienceCreateUpdate,
  transformBestSellingProductCreateUpdate,
  transformHeadTags,
  transformPageGet,
  transformPriceRules,
  transformPriceRule,
  transformPriceRulesCreateUpdate,
  transformPaymentRulesCreateUpdate,
  transformFooterCreateUpdate,
  transformFooterDelete,
  transformFooters,
  transformFooter,
  transformUserUpdate,
  transformUserLevels,
  transformProductFeedsCreateUpdate,
  transformProductFeeds,
  transformProductFeed,
  transformBenefits,
  transformBenefit,
  transformFrontDynamicTables,
  transformAccordionItems,
  transformDiscountForNumberOfRegistered,
  transformProductsMany
} from "./transformers";
import { transformLanguages } from "./transformers/languages";

const apiUrl = process.env.REACT_APP_API_URL;
const gwUrl = process.env.REACT_APP_GW_URL;
const contentFilesUploadUrl = process.env.REACT_APP_IMG_URL;
const clientId = process.env.REACT_APP_CLIENT_ID || "zepter-admin";
/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */

const convertRESTRequestToHTTP = (type, resource, params) => {
  let url = "";
  const options = {};
  switch (type) {
    case GET_MANY_REFERENCE:
    case GET_LIST:
      const priceRuleId = params.price_rule_id;
      const adminSearchValue = params.admin_search;
      const { page, perPage } = params.pagination || { page: null, petPage: null };
      const { field, order } = params.sort;
      var query = {};
      if (adminSearchValue) {
        query["admin_search"] = adminSearchValue;
      }

      if (page) {
        // eslint-disable-next-line
        query["page[number]"] = perPage && perPage > 100 ? "all" : page;
      }
      if (perPage && perPage <= 100) {
        // eslint-disable-next-line
        query["page[size]"] = perPage;
      }

      if (!isEmpty(params?.filter)) {
        Object.keys(params.filter).forEach(key => {
          var filterField = "filter[" + key + "]";
          query[filterField] = params.filter[key];
        });
      }
      // If OR param is used it needs to match exact filter in order. Example:
      // If in future you have two filters and only one OR param, that one OR needs
      // to match the filter it is meant for. In this case the filter that
      // should have OR as secondary condition should be last.
      // Order is important otherwise unwanted behaviour can occur.
      if (params.or) {
        Object.keys(params.or).forEach(key => {
          var filterField = "or[" + key + "]";
          query[filterField] = params.or[key];
        });
      }
      if (params.include && params.include.length > 0) {
        query["include"] = params.include.toString(); // eslint-disable-line no-use-before-define
      }
      if (type === "GET_MANY_REFERENCE" && params.target) {
        const targetFilter = "filter[" + params.target + "]";
        query[targetFilter] = params.id; // eslint-disable-line no-use-before-define
      }
      if (order === "ASC") {
        query.sort = field; // eslint-disable-line no-use-before-define
      } else {
        query.sort = "-" + field; // eslint-disable-line no-use-before-define
      }

      if (priceRuleId) {
        query.price_rule_id = params.price_rule_id;
      }

      url = `${resource === "configurations" ? gwUrl : apiUrl}/${resource}?${queryParameters(
        query
      )}`; // eslint-disable-line no-use-before-define
      break;
    case GET_ONE:
      var query = {}; // eslint-disable-line no-redeclare

      if (params.filter) {
        Object.keys(params.filter).forEach(key => {
          var filterField = "filter[" + key + "]";
          query[filterField] = params.filter[key];
        });
      }

      if (params.include && params.include.length > 0) {
        query["include"] = params.include.toString(); // eslint-disable-line no-use-before-define
      }

      url = `${resource === "configurations" ? gwUrl : apiUrl}/${resource}/${
        params.id
      }?${queryParameters(query)}`; // eslint-disable-line no-use-before-define
      break;
    case GET_MANY:
      var query = { "filter[id_in]": params.ids.toString() }; // eslint-disable-line

      if (params.ids) {
        // eslint-disable-next-line
        query["page[number]"] = "all";
      }

      if (params.filter) {
        Object.keys(params.filter).forEach(key => {
          var filterField = "filter[" + key + "]";
          query[filterField] = params.filter[key];
        });
      }

      url =
        params.ids.length < 1
          ? `${apiUrl}/${resource}`
          : `${apiUrl}/${resource}?${queryParameters(query)}`;

      break;
    case UPDATE:
      var query = {}; // eslint-disable-line
      if (params.filter) {
        Object.keys(params.filter).forEach(key => {
          var filterField = "filter[" + key + "]";
          query[filterField] = params.filter[key];
        });
      }
      if (params.include && params.include.length > 0) {
        query["include"] = params.include.toString(); // eslint-disable-line no-use-before-define
      }
      url = `${resource === "configurations" ? gwUrl : apiUrl}/${resource}/${
        params.id
      }?${queryParameters(query)}`;
      if (params.forceSave && params.forceSave === true) {
        url = `${apiUrl}/${resource}/${params.id}?${queryParameters(query)}force_save=true`;
      }
      options.method = "PATCH";
      var attrs = {};
      Object.keys(params.data).forEach(key => (attrs[key] = params.data[key]));

      const updateParams = {
        data: {
          type: resource,
          id: params.id,
          attributes: attrs,
          relationships: params.relationships
        }
      };
      options.body = JSON.stringify(updateParams);

      break;
    case CREATE:
      url = `${apiUrl}/${resource}`;
      options.method = "POST";
      const createParams = {
        data: { type: resource, attributes: params.data }
      };

      if (resource === "blogDetails") createParams.data.id = params.data.id;

      options.body = JSON.stringify(createParams);
      break;
    case DELETE:
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = "DELETE";
      break;
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
  return { url, options };
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the  appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} REST response
 */
const convertHTTPResponseToREST = (response, type, resource, params) => {
  const { headers, json } = response; // eslint-disable-line no-unused-vars
  switch (type) {
    case GET_MANY_REFERENCE:
    case GET_LIST:
      var jsonData = json.data?.map(function (dic) {
        var interDic = Object.assign({ id: dic.id }, dic.attributes, dic.meta);

        return interDic;
      });

      var relationships = json.data?.map(function (dic) {
        var interDic = Object.assign({ id: dic.id }, dic.relationships);

        return interDic;
      });

      var included = [];

      if (json.included) {
        included = json.included.map(record => ({
          ...record.attributes,
          id: record.id,
          type: record.type
        }));
      }

      return {
        data: jsonData || [],
        included,
        total: json.meta?.totalCount || null,
        relationships: relationships || []
      };
    case GET_MANY:
      jsonData = json.data.map(function (obj) {
        return Object.assign({ id: obj.id }, obj.attributes);
      });
      return { data: jsonData };
    case UPDATE:
    case CREATE:
    case GET_ONE:
      included = [];
      if (json.included) {
        included = json.included.map(record => ({
          ...record.attributes,
          id: record.id,
          type: record.type
        }));
      }

      return {
        data: Object.assign({ id: json?.data?.id }, json?.data?.attributes),
        included
      };
    case DELETE:
      return { data: { id: params?.id } };
    default:
      return { data: json?.data };
  }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a REST response
 */

export const addHeaders = options => {
  const token = localStorage.getItem("token");
  const noHeaders = !get(options, "headers");
  const notImageFile = !(get(options, "body") && options.body instanceof FormData);
  if (noHeaders) {
    options.headers = new Headers();
  }

  if (notImageFile && !options.acceptCsv) {
    options.headers.set("Accept", "application/vnd.api+json");
    options.headers.set("Content-Type", "application/vnd.api+json");
  } else if (options.acceptCsv) {
    options.headers.set("Accept", "text/csv");
  }

  if (token) {
    options.headers.set("session-id", token);
  }

  options.headers.set("Client-Id", clientId);
  options.headers.set("Application-Id", localStorage.getItem("appId"));
};

const modifyUrlForResource = url => {
  if (/policies|countries|applications|currencies/.test(url)) {
    url = url.replace("/api/", "/gw/");
  }

  if (/currency|exchange_rate/.test(url)) {
    console.log(url);
    url = url.replace("/v1/gw", gwUrl);
  }

  if (/^\/api/.test(url)) {
    url = url.replace("/api", apiUrl);
  }

  return url;
};

export const handleSingleRequest = (type, resource, params) => {
  const { url, options } = convertRESTRequestToHTTP(type, resource, params);

  addHeaders(options);

  return fetchJson(modifyUrlForResource(url), options)
    .then(response => convertHTTPResponseToREST(response, type, resource, params))
    .then(responseData => {
      if (clearCache(type, resource)) {
        return clearCacheRequest(responseData);
      }

      return responseData;
    });
};

const clearCacheRequest = responseData => {
  const request = new Request(apiUrl + "/resetCaches", {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      "Application-Id": localStorage.getItem("appId"),
      "Client-Id": clientId,
      "session-id": localStorage.getItem("token")
    })
  });
  return fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then(response => {
      return responseData;
    });
};

const handleDeleteManyRequest = (type, resource, params) => {
  return Promise.all(
    params.ids.map(id => {
      const singleParams = { id };

      return handleSingleRequest(DELETE, resource, singleParams);
    })
  ).then(response => {
    return { data: response };
  });
};

const handleCreateManyRequestAudiences = (type, resource, params, nId) => {
  const {
    data: {
      level_id,
      role_id,
      user_id,
      isSpecificUserPromotion
      // active,
      // body,
      // category_id,
      // mobile_app_version,
      // override_consent,
      // published,
      // schedule_at,
      // schedule_cron,
      // title
    }
  } = params;

  const audienceparams = {
    level_id,
    role_id,
    user_id,
    notification_id: nId,
    isSpecificUserPromotion
  };

  // const notificationDetails = {
  //   active,
  //   body,
  //   title,
  //   schedule_cron,
  //   schedule_at,
  //   category_id,
  //   mobile_app_version,
  //   override_consent,
  //   published
  // };

  if (audienceparams.user_id && audienceparams != "") {
    return Promise.all(
      audienceparams &&
        audienceparams.user_id.map(id => {
          params.data = {
            notification_id: nId,
            user_id: id,
            role_id: null,
            level_id: null
          };
          return handleSingleRequest(type, "notificationAudiences", params);
        })
    );
  } else if (audienceparams.role_id && !audienceparams.level_id && audienceparams != "") {
    return Promise.all(
      audienceparams &&
        audienceparams.role_id.map(id => {
          params.data = {
            notification_id: nId,
            role_id: id,
            user_id: null,
            level_id: null
          };
          return handleSingleRequest(type, "notificationAudiences", params);
        })
    );
  }
  // Figure out to map role + levels and merge into 1
  else if (
    audienceparams.role_id &&
    audienceparams.role_id.includes("40e505de-2451-11e8-b34c-37a6db76516b")
  ) {
    return Promise.all(
      audienceparams &&
        audienceparams.level_id.map(id => {
          params.data = {
            notification_id: nId,
            role_id: role_id[0],
            level_id: id,
            user_id: null
          };
          return handleSingleRequest(type, "notificationAudiences", params);
        })
    );
  }
};

const handleUpdateManyRequest = (type, resource, params, ids) => {
  return Promise.all(
    params.ids.map(id => {
      const singleParams = {
        id,
        data: resource === "priceRules" ? params.data.find(elem => elem.id == id) : params.data
      };
      if (
        resource === "priceRules" &&
        params.updateOrigin &&
        params.updateOrigin === "reorderWeights"
      ) {
        singleParams.updateOrigin = "reorderWeights";
      }
      if (
        resource === "priceRules" &&
        params.forceSave &&
        params.forceSave === true &&
        params.updateOrigin === "reorderWeights"
      ) {
        singleParams.forceSave = true;
      }
      return getHandlerFor(UPDATE, resource, singleParams);
    })
  ).then(response => {
    return { data: response };
  });
};

let activeNotificationAudience = [];

const checkAudienceRecordsToDelete = (updateData, audienceType) => {
  return activeNotificationAudience.filter(record => {
    return !updateData[audienceType].includes(record[audienceType]);
  });
};

const checkAudienceRecordsToAdd = (updateData, audienceType) => {
  return updateData[audienceType].filter(record => {
    return !activeNotificationAudience.some(activeRecord => activeRecord[audienceType] === record);
  });
};

const handleDeleteManyRequestAudiences = audienceRecordsToDelete => {
  Promise.all(
    audienceRecordsToDelete.map(activeAudienceRecord => {
      return handleSingleRequest(DELETE, "notificationAudiences", {
        id: activeAudienceRecord.id
      });
    })
  );
};

const handleCreateManyOnUpdateAudiences = (
  notificationId,
  audienceRecordsToAdd,
  audienceType,
  affiliateRoleId
) => {
  Promise.all(
    audienceRecordsToAdd.map(id => {
      const paramsAudiences = {
        data: {
          notification_id: notificationId,
          user_id: null,
          role_id: null,
          level_id: null
        }
      };
      paramsAudiences.data.role_id = affiliateRoleId || null;
      paramsAudiences.data[audienceType] = id;

      return handleSingleRequest("CREATE", "notificationAudiences", paramsAudiences);
    })
  );
};

const handleNotificationAndAudienceCreation = (type, resource, params) => {
  return transformNotificationCreateUpdate(type, resource, params)
    .then(res => {
      const notificationId = res.data.id;
      if (type === "CREATE") {
        handleCreateManyRequestAudiences("CREATE", "notificationAudiences", params, notificationId);
      }
      if (type === "UPDATE") {
        // If type is UPDATE then params.data will have next state data
        // Then will check which part of notification audience will update
        if (params.data.user_id) {
          if (activeNotificationAudience.length === 0) {
            handleCreateManyOnUpdateAudiences(notificationId, params.data.user_id, "user_id");
          } else if (activeNotificationAudience[0].user_id) {
            const audienceRecordsToDelete = checkAudienceRecordsToDelete(params.data, "user_id");
            const audienceRecordsToAdd = checkAudienceRecordsToAdd(params.data, "user_id");

            if (audienceRecordsToDelete.length !== 0) {
              handleDeleteManyRequestAudiences(audienceRecordsToDelete);
            }
            if (audienceRecordsToAdd.length !== 0) {
              handleCreateManyOnUpdateAudiences(notificationId, audienceRecordsToAdd, "user_id");
            }
          } else {
            handleDeleteManyRequestAudiences(activeNotificationAudience);
            handleCreateManyOnUpdateAudiences(notificationId, params.data.user_id, "user_id");
          }
        } else if (params.data.level_id) {
          if (activeNotificationAudience.length === 0) {
            handleCreateManyOnUpdateAudiences(
              notificationId,
              params.data.level_id,
              "level_id",
              params.data.role_id[0]
            );
          } else if (activeNotificationAudience[0].level_id) {
            const audienceRecordsToDelete = checkAudienceRecordsToDelete(params.data, "level_id");
            const audienceRecordsToAdd = checkAudienceRecordsToAdd(params.data, "level_id");

            if (audienceRecordsToDelete.length !== 0) {
              handleDeleteManyRequestAudiences(audienceRecordsToDelete);
            }
            if (audienceRecordsToAdd.length !== 0) {
              handleCreateManyOnUpdateAudiences(
                notificationId,
                audienceRecordsToAdd,
                "level_id",
                params.data.role_id[0]
              );
            }
          } else {
            handleDeleteManyRequestAudiences(activeNotificationAudience);
            handleCreateManyOnUpdateAudiences(
              notificationId,
              params.data.level_id,
              "level_id",
              params.data.role_id[0]
            );
          }
        } else if (params.data.role_id && params.data.level_id === null) {
          if (activeNotificationAudience.length === 0) {
            handleCreateManyOnUpdateAudiences(notificationId, params.data.role_id, "role_id");
          } else if (
            activeNotificationAudience[0].role_id &&
            activeNotificationAudience[0].level_id === null
          ) {
            const audienceRecordsToDelete = checkAudienceRecordsToDelete(params.data, "role_id");
            const audienceRecordsToAdd = checkAudienceRecordsToAdd(params.data, "role_id");

            if (audienceRecordsToDelete.length !== 0) {
              handleDeleteManyRequestAudiences(audienceRecordsToDelete);
            }
            if (audienceRecordsToAdd.length !== 0) {
              handleCreateManyOnUpdateAudiences(notificationId, audienceRecordsToAdd, "role_id");
            }
          } else {
            handleDeleteManyRequestAudiences(activeNotificationAudience);
            handleCreateManyOnUpdateAudiences(notificationId, params.data.role_id, "role_id");
          }
        }
      }

      return res;
    })
    .catch(err => {
      console.log(err);
    });
};

export const handleFilesUpload = (type, resource, params) => {
  var formData = new FormData();

  formData.append("file", params.data);
  formData.append("overwrite", params.overwrite);

  var options = {
    body: formData,
    method: "POST"
  };

  addHeaders(options);

  if (resource === "contentImages") {
    resource = "images";
  }

  return fetchJson(`${contentFilesUploadUrl}/${resource}`, options).then(({ json }) => {
    return {
      ...json,
      fullUrl: contentFilesUploadUrl + json.url
    };
  });
};

const clearCache = (type, resource) => {
  const clearCondition = {
    priceLevels: true,
    productPrices: true,
    currencies: true,
    unitTypes: true,
    productCategories: true,
    highlightedProducts: true,
    products: true,
    techDataLists: true,
    bestSellingProducts: true,
    priceRules: true
  };

  const types = {
    UPDATE: true,
    DELETE: true,
    CREATE: true
  };

  return types[type] && clearCondition[resource];
};

const getHandlerFor = (type, resource, params) => {
  var handler = handleSingleRequest;

  const customHandlers = {
    orders: [
      { types: [GET_LIST, GET_MANY], handler: transformOrderList },
      { types: [GET_ONE], handler: transformOrderItem }
    ],
    pages: [
      {
        types: [CREATE],
        handler: transformPageCreate
      },
      {
        types: [UPDATE],
        handler: transformPageUpdate
      },
      {
        types: [GET_MANY, GET_LIST, GET_ONE],
        handler: transformPageGet
      }
    ],
    userAddresses: [
      {
        types: [GET_LIST, GET_MANY_REFERENCE],
        handler: transformUserAddressesList
      }
    ],
    users: [
      { types: [GET_LIST], handler: transformUserList },
      { types: [GET_ONE], handler: transformUserItem },
      { types: [UPDATE], handler: transformUserUpdate }
    ],
    contentImages: [{ types: [CREATE], handler: handleFilesUpload }],
    productCategories: [
      { types: [CREATE], handler: transformProductCategoryCreate },
      { types: [UPDATE], handler: transformProductCategoryUpdate },
      { types: [GET_ONE], handler: transformProductCategory },
      { types: [GET_LIST], handler: transformProductCategories },
      { types: [DELETE_MANY], handler: handleDeleteManyRequest }
    ],
    products: [
      { types: [GET_ONE], handler: transformProduct },
      { types: [GET_LIST, GET_MANY], handler: transformProducts },
      { types: [GET_MANY_REFERENCE], handler: transformProductsMany },
      { types: [UPDATE], handler: transformProductUpdate },
      { types: [CREATE], handler: transformProductCreate }
    ],
    productPrices: [
      { types: [CREATE], handler: transformProductPriceCreate },
      { types: [UPDATE], handler: transformProductPriceUpdate }
    ],
    benefits: [
      { types: [GET_LIST], handler: transformBenefits },
      { types: [GET_ONE], handler: transformBenefit }
    ],
    frontDynamicTables: [
      {
        types: [GET_LIST, GET_ONE, CREATE, UPDATE, DELETE],
        handler: transformFrontDynamicTables
      },
      { types: [DELETE_MANY], handler: handleDeleteManyRequest }
    ],
    accordionItems: [
      {
        types: [GET_LIST, GET_ONE, CREATE, UPDATE, DELETE],
        handler: transformAccordionItems
      },
      { types: [DELETE_MANY], handler: handleDeleteManyRequest }
    ],
    discountForNumberOfRegistered: [
      {
        types: [GET_LIST, GET_ONE, CREATE, UPDATE, DELETE],
        handler: transformDiscountForNumberOfRegistered
      },
      { types: [DELETE_MANY], handler: handleDeleteManyRequest }
    ],
    bestSellingProducts: [
      {
        types: [CREATE],
        handler: transformBestSellingProductCreateUpdate
      }
    ],
    tags: [
      { types: [GET_LIST, GET_ONE], handler: transformTags },
      { types: [CREATE, UPDATE], handler: transformTagsCreate },
      { types: [DELETE], handler: transformTagsDelete }
    ],
    tagGroups: [
      { types: [GET_LIST, GET_ONE], handler: transformTagGroups },
      { types: [CREATE], handler: transformTagGroupsCreate },
      { types: [UPDATE], handler: transformTagGroupsUpdate }
    ],
    vProductImages: [
      { types: [CREATE], handler: transformProductImageCreate },
      { types: [DELETE], handler: transformProductImageDelete },
      { types: [UPDATE], handler: transformProductImageUpdate }
    ],
    techDataLists: [{ types: [GET_ONE, GET_LIST, CREATE, UPDATE], handler: transformTechData }],
    pageContents: [
      { types: [UPDATE], handler: transformPageContentUpdate },
      {
        types: [GET_ONE],
        handler: transformPageContent
      },
      {
        types: [GET_MANY_REFERENCE, GET_LIST, GET_MANY],
        handler: transformPageContentMany
      },
      {
        types: [DELETE],
        handler: transformPageContentDelete
      }
    ],
    shops: [
      { types: [GET_LIST, GET_ONE], handler: transformShops },
      { types: [CREATE, UPDATE], handler: transformShopsCreate }
    ],
    blogCategories: [
      { types: [GET_LIST, GET_ONE], handler: transformBlogCategories },
      { types: [CREATE], handler: transformBlogCategoriesCreate },
      { types: [UPDATE], handler: transformBlogCategoriesUpdate }
    ],
    blogArticles: [
      { types: [GET_LIST, GET_ONE], handler: transformBlogArticles },
      { types: [CREATE, UPDATE], handler: transformBlogArticlesCreateUpdate }
    ],
    blogDetails: [
      {
        types: [GET_MANY, GET_LIST, GET_MANY_REFERENCE, GET_ONE],
        handler: transformBlogDetails
      },
      { types: [CREATE, UPDATE], handler: transformBlogDetailsCreateUpdate }
    ],
    languages: [
      {
        types: [CREATE, UPDATE],
        handler: transformLanguages
      }
    ],
    notifications: [
      {
        types: [CREATE, UPDATE],
        handler: handleNotificationAndAudienceCreation
      },
      {
        types: [GET_ONE],
        handler: (type, resource, params) => {
          return handleSingleRequest(type, resource, {
            ...params,
            include: ["notificationAudiences"]
          }).then(({ data = [], included = [], ...rest }) => {
            let isPromotion = included.length !== 0 && !(included[0].role_id != null);
            // Needed to capture current records of notification audiences
            activeNotificationAudience = [...included];
            return {
              data: {
                ...data,
                isSpecificUserPromotion: isPromotion,
                role_id: !isPromotion
                  ? included.map(e => {
                      return e.role_id;
                    })
                  : null,
                level_id: !isPromotion
                  ? included.map(e => {
                      return e.level_id;
                    })
                  : null,
                user_id: isPromotion
                  ? included.map(e => {
                      return e.user_id;
                    })
                  : null
              }
            };
          });
        }
      }
    ],
    headTags: [
      {
        types: [GET_MANY, GET_LIST, GET_MANY_REFERENCE, GET_ONE],
        handler: transformHeadTags
      }
    ],
    priceRules: [
      { types: [GET_LIST], handler: transformPriceRules },
      { types: [GET_ONE], handler: transformPriceRule },
      {
        types: [CREATE, UPDATE],
        handler: transformPriceRulesCreateUpdate
      }
    ],
    paymentRules: [
      {
        types: [CREATE, UPDATE],
        handler: transformPaymentRulesCreateUpdate
      }
    ],
    footer: [
      { types: [CREATE, UPDATE], handler: transformFooterCreateUpdate },
      { types: [GET_LIST], handler: transformFooters },
      { types: [GET_ONE], handler: transformFooter },
      { types: [DELETE, DELETE_MANY], handler: transformFooterDelete }
    ],
    userLevels: [
      {
        types: [UPDATE, GET_ONE, GET_LIST, GET_MANY, GET_MANY_REFERENCE],
        handler: transformUserLevels
      }
    ],
    productFeeds: [
      { types: [CREATE, UPDATE], handler: transformProductFeedsCreateUpdate },
      { types: [GET_LIST, DELETE, DELETE_MANY], handler: transformProductFeeds },
      { types: [GET_ONE], handler: transformProductFeed }
    ]
  };

  if (customHandlers[resource]) {
    customHandlers[resource].forEach(handlerType => {
      if (handlerType.types.includes(type)) {
        handler = handlerType.handler;
      }
    });
  }

  return handler(type, resource, params);
};

const restClient = (type, resource, params) => {
  if (type === UPDATE_MANY) {
    return handleUpdateManyRequest(type, resource, params);
  } else {
    return getHandlerFor(type, resource, params);
  }
};

export default restClient;
