import React, { Component, Fragment } from "react";
import { handleSingleRequest } from "../../services/api/restClient";
import { GET_LIST } from "../../services/api/types";
import { feature } from "../../components/Feature";

// Custom components
import { PdfSectionName } from "./PrintAdvancedItem";

const loadCountryRelevantDetails = () => {
  // Get current country name
  const countryAppId = localStorage.getItem("appId");

  const countryInternationalName = JSON.parse(localStorage.getItem("countries")).find(
    country => country.application_id === countryAppId
  ).international_name;

  return {
    countryAppId: countryAppId,
    countryInternationalName: countryInternationalName
  };
};

export default class PrintSimpleItem extends Component {
  state = {
    orderItems: [],
    products: [],
    orders: [],
    cities: [],
    shipments: [],
    shipmentsIncluded: [],
    isLoaded: false
  };

  componentDidMount() {
    handleSingleRequest(GET_LIST, "orderItems", {
      pagination: { page: "all" },
      sort: { field: "id", order: "DESC" },
      filter: { order_id: this.props.order.id },
      include: ["product"]
    }).then(({ data, included }) => {
      this.setState({ orderItems: data, products: included });
    });

    handleSingleRequest(GET_LIST, "orders", {
      pagination: { page: "all" },
      sort: { field: "id", order: "DESC" },
      filter: { id: this.props.order.id },
      include: ["city"]
    }).then(({ data, included }) => {
      this.setState({ orders: data, cities: included, isLoaded: true });
    });

    handleSingleRequest(GET_LIST, "shipments", {
      pagination: { page: "all" },
      sort: { field: "id", order: "DESC" },
      filter: { order_id: this.props.order.id },
      include: ["city"]
    }).then(({ data, included }) => {
      this.setState({ shipments: data, shipmentsIncluded: included });
    });
  }

  render() {
    const { countryInternationalName } = loadCountryRelevantDetails();
    const { order } = this.props;
    const [city] = this.state.cities;
    const pickupOffice = this.state.shipments[0];
    const [shipmentCity] = this.state.shipmentsIncluded;

    return (
      <Fragment>
        <h3
          style={{
            textTransform: "uppercase",
            fontWeight: "bold",
            border: "1px solid black",
            borderTop: "3px solid black",
            textAlign: "center",
            padding: "5px",
            marginTop: "1rem"
          }}
        >
          Information for: {order && order.order_number ? order.order_number : " - "}
        </h3>
        <PdfSectionName sectionName="Buyer Information" />
        <table style={{ width: "100%", minWidth: "450px", textAlign: "left" }}>
          <thead>
            <tr>
              <th colSpan={1}>Order number</th>
              <th colSpan={1}>Customer name</th>
              <th colSpan={1}>Phone</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={1}>{order && order.order_number ? order.order_number : " - "}</td>
              <td colSpan={1}>
                {order && order.first_name && order.last_name
                  ? order.first_name + " " + order.last_name
                  : order && order.first_name
                  ? order.first_name
                  : order && order.last_name
                  ? order.last_name
                  : null}
              </td>
              <td colSpan={1}>{order && order.phone ? order.phone : " - "}</td>
            </tr>
          </tbody>
        </table>
        {/* Shipping Information PDF  */}
        <PdfSectionName
          sectionName={
            pickupOffice && pickupOffice.warehouse_id === null
              ? "Shipping Information"
              : "Pickup Office Information"
          }
        />{" "}
        <table style={{ width: "100%", minWidth: "450px", textAlign: "left" }}>
          <thead>
            <tr>
              <th colSpan={1}>
                {pickupOffice && pickupOffice.warehouse_id === null
                  ? "Shipping Address"
                  : "Pickup Office Address"}
              </th>
              <th colSpan={1}>
                {pickupOffice && pickupOffice.warehouse_id === null
                  ? "Shipping City"
                  : "Pickup Office City"}
              </th>
              <th colSpan={1}>
                {pickupOffice && pickupOffice.warehouse_id === null
                  ? "Shipping Country"
                  : "Pickup Office Country"}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={1}>
                {pickupOffice && pickupOffice.warehouse_id
                  ? pickupOffice.address_line1
                  : order && order.address_line1
                  ? order.address_line1
                  : " - "}
              </td>
              <td colSpan={1}>
                {pickupOffice && pickupOffice.warehouse_id !== undefined && shipmentCity
                  ? shipmentCity.name
                  : city && city.name
                  ? city.name
                  : " - "}
              </td>
              <td colSpan={1}>{countryInternationalName ? countryInternationalName : " - "}</td>
            </tr>
          </tbody>
        </table>
        <PdfSectionName sectionName="Products in the order" />
        <table border="1" style={{ width: "100%", minWidth: "450px", textAlign: "left" }}>
          <tbody>
            <tr>
              <th colSpan={1}>External number</th>
              <th colSpan={1}>Product name</th>
              <th colSpan={1}>Order quantity</th>
              {feature("priceRulesDiscountCodeParameter") ? (
                <th colSpan={1}>Discount code</th>
              ) : null}
            </tr>

            {this.state.orderItems.map(orderItem => {
              const product = this.state.products.find(
                product => product.id === orderItem.product_id
              );

              return (
                <tr key={orderItem.id}>
                  <td colSpan={1}>{product.external_number}</td>
                  <td colSpan={1}>{product.name}</td>
                  <td colSpan={1}>{orderItem.quantity}</td>
                  {feature("priceRulesDiscountCodeParameter") ? (
                    <td colSpan={1}>{orderItem.coupon_code}</td>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Fragment>
    );
  }
}
