import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  TextField,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  NumberInput,
  DateInput,
  Toolbar,
  SaveButton,
  required
} from "react-admin";
import { SelectInput } from "ra-ui-materialui/lib/input";
import { feedFields } from "./ParameterConstants";
import { dateValidator } from "../../utils/validators";
import { checkForBracketsNotAlowed } from "./validators";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { styles } from "../styles";

const ConfirmationToolbar = props => (
  <Toolbar {...props} style={styles.toolbar}>
    <SaveButton />
    <DeleteConfirmation undoable={false} caution />
  </Toolbar>
);

export const FeedEdit = props => (
  <Edit {...props}>
    <SimpleForm variant="standard" toolbar={<ConfirmationToolbar />}>
      <TextInput source="name" label="Feed Name" validate={required()} />
      <TextField source="file_type" label="File type" />
      <FormDataConsumer>
        {({ formData }) => {
          if (formData.file_type === "application/xml") {
            return (
              <div style={{ display: "flex", flexDirection: "column", maxWidth: "256px" }}>
                <TextInput
                  source="template.xml_wrapper"
                  label="XML wrapper without brackets <>"
                  placeholder="SHOP"
                  validate={checkForBracketsNotAlowed}
                />
                <TextInput source="template.xmlns" label="Add xmlns if needed" />
                <TextInput
                  source="template.xml_product_wrapper"
                  label="XML product without brackets <>"
                  validate={checkForBracketsNotAlowed}
                />
              </div>
            );
          } else {
            return <span />;
          }
        }}
      </FormDataConsumer>
      <ArrayInput source="template.attr_map" label="Template fields">
        <SimpleFormIterator disableReordering TransitionProps={{ enter: false, exit: false }}>
          <FormDataConsumer>
            {({ getSource, formData }) => {
              return (
                <TextInput
                  label={
                    formData.file_type === "application/xml"
                      ? "Tag name without brackets <>"
                      : "Field Name"
                  }
                  source={getSource("attribute_name")}
                  validate={checkForBracketsNotAlowed}
                />
              );
            }}
          </FormDataConsumer>
          <SelectInput source="field_name" choices={feedFields} label="Field Source" />
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              let checkForFieldType =
                scopedFormData?.field_name === "feed_name" ||
                scopedFormData?.field_name === "feed_description";
              let checkForPrice =
                scopedFormData?.field_name === "rrp_price" ||
                scopedFormData?.field_name === "member_price";
              let checkForStockField = scopedFormData?.field_name === "in_stock";
              let checkForConditionField = scopedFormData?.field_name === "condition";
              if (checkForFieldType) {
                return (
                  <div style={{ display: "flex", flexDirection: "column", maxWidth: "256px" }}>
                    <NumberInput
                      label="Character limit"
                      placeholder="#$%..."
                      source={getSource("char_limit")}
                      min="10"
                    />
                  </div>
                );
              } else if (checkForPrice) {
                return (
                  <BooleanInput
                    label="Use price with currency"
                    source={getSource("with_currency")}
                  />
                );
              } else if (checkForConditionField) {
                return <TextInput label="Default value" source={getSource("default_value")} />;
              } else if (checkForStockField) {
                return (
                  <div style={{ display: "flex", flexDirection: "column", maxWidth: "256px" }}>
                    <BooleanInput
                      label="Sync value with stock"
                      source={getSource("sync_with_stock")}
                      record={scopedFormData}
                    />
                    {!scopedFormData?.sync_with_stock && (
                      <TextInput label="Default value" source={getSource("default_value")} />
                    )}
                    {scopedFormData?.sync_with_stock && (
                      <>
                        <TextInput
                          label="Value if available in stock"
                          source={getSource("true_value")}
                        />
                        <TextInput
                          label="Value if not available in stock"
                          source={getSource("false_value")}
                        />
                      </>
                    )}
                  </div>
                );
              }
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <p style={{ fontSize: "12px", paddingBottom: "0px", marginBottom: "-10px" }}>
        Feed will be active from a date:
      </p>
      <DateInput
        label=""
        source="cron.start_date"
        validate={[required(), dateValidator("YYYY-MM-DDTHH:mm:ss.sssZ")]}
        variant="standard"
      />
      <p style={{ fontSize: "12px", paddingBottom: "0px", marginBottom: "-10px" }}>
        Date of feed deactivation:
      </p>
      <DateInput
        label=""
        source="cron.end_date"
        validate={[required(), dateValidator("YYYY-MM-DDTHH:mm:ss.sssZ")]}
        variant="standard"
      />
    </SimpleForm>
  </Edit>
);
