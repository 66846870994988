import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { GET_LIST } from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Tabs, Tab, Grid } from "@material-ui/core";
// import { translate } from "react-admin";

import dataProvider from "../../services/api/restClient";

// import EptiLogo from "../../assets/images/EptiAdminLogo.png";
import LatestOrders from "./LatestOrders";
import LatestCustomers from "./LatestCustomers";
import Analytics from "./Analytics";

import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";

import PieGraph from "./Graphs/PieGraph";
import LineGraph from "./Graphs/LineGraph";

import { DateRangePicker } from "react-dates";

import "react-dates/initialize";

// import "react-dates/lib/css/_datepicker.css";

import moment from "moment";
import { formatPriceAmount } from "../../utils/formatters/formatPriceAmount";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

const returningCustomerRateColors = ["#3498db", "#FF4100"];

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  headerLabel: {
    fontWeight: "bold",
    borderBottom: "4px solid #3498db",
    width: "fit-content",
    margin: "15px 30px",
    fontSize: "11px"
  },
  headerData: {
    fontWeight: "bold",
    width: "fit-content",
    margin: "15px 30px",
    fontSize: "17px"
  },
  input: {
    zIndex: 3333
  },
  cardStyle: {
    display: "flex",
    justifyContent: "center"
  },
  contentWrapper: {
    padding: "0 !important"
  }
});

// Table Pagination translation extension for languages found in the base:
export function defaultLabelDisplayedRows({ from, to, count }) {
  let total;
  if (localStorage.getItem("locale") === "sr") {
    total = "od";
  } else if (localStorage.getItem("locale") === "hr") {
    total = "od";
  } else if (localStorage.getItem("locale") === "ru") {
    total = "из";
  } else if (localStorage.getItem("locale") === "ro") {
    total = "din";
  } else if (
    localStorage.getItem("locale") === "de" ||
    localStorage.getItem("locale") === "au" ||
    localStorage.getItem("locale") === "ch"
  ) {
    total = "von";
  } else if (localStorage.getItem("locale") === "fr") {
    total = "sur";
  } else if (localStorage.getItem("locale") === "pl") {
    total = "od";
  } else if (localStorage.getItem("locale") === "cs") {
    total = "z";
  } else {
    total = "of";
  }
  return `${from}–${to} ${total} ${count !== -1 ? count : to}`;
}

class Dashboard extends Component {
  state = {
    latestOrdersData: null,
    latestOrdersDataTotal: 0,
    latestCustomers: null,
    latestCustomersTotal: 0,
    averageOrderValue: 0,
    totalSales: 0,
    customers: 0,
    value: 0,
    startDate: moment().subtract(6, "days"),
    endDate: moment(),
    returningCustomerRateData: [],
    totalSalesData: [],
    averageOrderPerDayValues: [],
    totalOrdersData: [],
    totalOrders: 0,
    membersVsRegistered: []
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  componentDidMount() {
    this.fetchDashboardCustomers();
    this.fetchDashboardOrders();
    // this.startAutoRefetchingNewestData(2);
  }

  fetchDashboardCustomers = () => {
    let { startDate, endDate } = this.state;

    if (!endDate) {
      endDate = moment();
    }

    // const duration = moment.duration(endDate.diff(startDate));
    // const days = duration.asDays() + 1;

    const fetchStart = moment(startDate).format("YYYY-MM-DD");
    const fetchEnd = moment(endDate).format("YYYY-MM-DD");

    let allDates = [];
    var date = moment(startDate);
    while (date.isSameOrBefore(moment(endDate))) {
      allDates = [...allDates, date.format("MMM DD YYYY")];
      date.add(1, "days");
    }

    const filter = {
      date_gte: fetchStart,
      date_lte: fetchEnd,
      order_status_in: ["New", "Paid", "Placed", "Shipped", "Released For Shipment", "Delivered"]
    };

    dataProvider(GET_LIST, "dashboardCustomers", {
      filter,
      sort: { field: "created_at", order: "DESC" },
      pagination: { page: "all" }
    })
      .then(response => {
        const formatedCustomers = response.data.map(item => {
          return {
            ...item,
            date: moment(item.date)
          };
        });

        const ordersOnDate = allDates.map(date => {
          const customers = formatedCustomers
            .map(record => ({
              ...record,
              date: record.date.format("MMM DD YYYY")
            }))
            .filter(record => record.date === date);

          return {
            date,
            customers
          };
        });

        let after = ordersOnDate.slice(Math.ceil(ordersOnDate.length / 2));
        let before = ordersOnDate.slice(0, Math.ceil(ordersOnDate.length / 2));

        let firstTime;
        let returning;
        let membersLabel;
        let registeredLabel;

        if (localStorage.getItem("locale") === "sr") {
          firstTime = "Prvi put";
          returning = "Povratni Kupci";
          membersLabel = "Članovi";
          registeredLabel = "Registrovani";
        } else if (localStorage.getItem("locale") === "hr") {
          firstTime = "Prvi put kupci";
          returning = "Povratni Kupci";
          membersLabel = "Članovi";
          registeredLabel = "Registrovani";
        } else if (localStorage.getItem("locale") === "ru") {
          firstTime = "Первые клиенты";
          returning = "Постоянных клиентов";
          membersLabel = "Члены";
          registeredLabel = "Зарегистрировано";
        } else if (localStorage.getItem("locale") === "ro") {
          firstTime = "Clienți pentru prima dată";
          returning = "Clienti care revin";
          membersLabel = "Membri";
          registeredLabel = "Înregistrați";
        } else if (
          localStorage.getItem("locale") === "de" ||
          localStorage.getItem("locale") === "au" ||
          localStorage.getItem("locale") === "ch"
        ) {
          firstTime = "Erstkunden";
          returning = "Wiederkehrende Kunden";
          membersLabel = "Mitglieder";
          registeredLabel = "Eingetragen";
        } else if (localStorage.getItem("locale") === "fr") {
          firstTime = "Clients pour la première fois";
          returning = "Clients de retour";
          membersLabel = "Membres";
          registeredLabel = "Inscrit";
        } else if (localStorage.getItem("locale") === "cs") {
          firstTime = "První zákazníci";
          returning = "Vracející se zákazníci";
          membersLabel = "Členové";
          registeredLabel = "Registrovaný";
        } else {
          firstTime = "First Time Buyers";
          returning = "Returning Customers";
          membersLabel = "Members";
          registeredLabel = "Registered";
        }

        const returningCustomerRateData = [
          {
            name: firstTime,
            value: [].concat
              .apply(
                [],
                after.map(record => record.customers)
              )
              .filter(customer => customer.first_purchase_total > 0).length,
            beforeValue: [].concat
              .apply(
                [],
                before.map(record => record.customers)
              )
              .filter(customer => customer.first_purchase_total > 0).length
          },
          {
            name: returning,
            value: [].concat
              .apply(
                [],
                after.map(record => record.customers)
              )
              .filter(customer => !customer.first_purchase_total > 0).length,
            beforeValue: [].concat
              .apply(
                [],
                before.map(record => record.customers)
              )
              .filter(customer => !customer.first_purchase_total > 0).length
          }
        ];

        const membersVsRegistered = [
          {
            name: membersLabel,
            value: [].concat
              .apply(
                [],
                after.map(record => record.customers)
              )
              .filter(
                customer => customer.role_name === "Affiliate" || customer.role_name === "Member"
              ).length,
            beforeValue: [].concat
              .apply(
                [],
                before.map(record => record.customers)
              )
              .filter(
                customer => customer.role_name === "Affiliate" || customer.role_name === "Member"
              ).length
          },
          {
            name: registeredLabel,
            value: [].concat
              .apply(
                [],
                after.map(record => record.customers)
              )
              .filter(customer => customer.role_name === "Registered").length,
            beforeValue: [].concat
              .apply(
                [],
                before.map(record => record.customers)
              )
              .filter(customer => customer.role_name === "Registered").length
          }
        ];

        this.setState({
          returningCustomerRateData,
          customers: [].concat.apply(
            [],
            after.map(record => record.customers)
          ).length,
          membersVsRegistered,
          latestCustomers: [].concat
            .apply(
              [],
              after.map(record => record.customers)
            )
            .sort((a, b) => moment(a.date).format("X") - moment(b.date).format("X")),
          latestCustomersTotal: [].concat.apply(
            [],
            after.map(record => record.customers)
          ).length
        });
      })
      .catch(error => console.log(error));
  };

  fetchDashboardOrders = () => {
    let { startDate, endDate } = this.state;

    if (!endDate) {
      endDate = moment();
    }

    // const duration = moment.duration(endDate.diff(startDate));
    // const days = duration.asDays() + 1;

    const fetchStart = moment(startDate).format("YYYY-MM-DD");
    const fetchEnd = moment(endDate).format("YYYY-MM-DD");

    let allDates = [];
    var date = moment(startDate);
    while (date.isSameOrBefore(moment(endDate))) {
      allDates = [...allDates, date.format("MMM DD YYYY")];
      date.add(1, "days");
    }

    const filter = {
      date_gte: fetchStart,
      date_lte: fetchEnd,
      order_status_in: ["New", "Paid", "Placed", "Shipped", "Released For Shipment", "Delivered"]
    };

    dataProvider(GET_LIST, "dashboardOrders", {
      filter: filter,
      sort: { field: "date", order: "ASC" },
      pagination: { page: "all" }
    })
      .then(response => {
        const formatedOrders = response.data.map(item => {
          return {
            ...item,
            date: moment(item.date)
          };
        });

        if (formatedOrders.some(e => e.currency_code === "RSD")) {
          formatedOrders.map(item => {
            if (item.currency_code === "RSD") {
              item.currency_code = "EUR";
              item.order_total = Number(item.order_total) / 120;
              item.order_total = Math.round(item.order_total * 100) / 100;
            }
            console.log(item);

            return item;
          });
        }

        let ordersOnDate = allDates.map(date => {
          const ordersOnDay = formatedOrders
            .map(order => ({
              ...order,
              realDate: order.date,
              date: order.date.format("MMM DD YYYY")
            }))
            .filter(order => order.date === date);

          return {
            date: date,
            orders: ordersOnDay
          };
        });

        if (ordersOnDate.length === 2) {
          var allTimes = [];
          for (let i = 1; i <= 12; i++) {
            allTimes.push(i + " am");
          }
          for (let i = 1; i <= 12; i++) {
            allTimes.push(i + " pm");
          }

          let formmatedOrdersOnDate = ordersOnDate.map(day => ({
            date: day.date,
            orders: day.orders.map(order => ({
              ...order,
              time: order.realDate.format("h a")
            }))
          }));
          let after = formmatedOrdersOnDate.slice(Math.ceil(formmatedOrdersOnDate.length / 2));

          let before = formmatedOrdersOnDate.slice(0, Math.ceil(formmatedOrdersOnDate.length / 2));
          let ordersOnDay = allTimes.map(time => {
            const ordersOnTime = after[0].orders.filter(order => order.time === time);

            return ordersOnTime;
          });
          let ordersOnDayBefore = allTimes.map(time => {
            const ordersOnTime = before[0].orders.filter(order => order.time === time);

            return ordersOnTime;
          });

          let totalOrdersData = Array.apply(null, { length: allTimes.length })
            .map(Number.call, Number)
            .map(key => {
              // console.log(ordersOnDay);

              return {
                name: allTimes[key],
                current: ordersOnDay[key].length,
                previous: ordersOnDayBefore[key].length
              };
            });
          let totalOrders = totalOrdersData
            .map(day => Number(day.current))
            .reduce((a, b) => a + b, 0);

          let totalSalesData = Array.apply(null, { length: allTimes.length })
            .map(Number.call, Number)
            .map(key => ({
              name: allTimes[key],
              current: this.calculateTotalSales(ordersOnDay[key]),
              previous: this.calculateTotalSales(ordersOnDayBefore[key])
            }));

          let totalSales =
            totalSalesData.map(day => Number(day.current)).reduce((a, b) => a + b, 0) +
            " " +
            (formatedOrders[0] ? formatedOrders[0].currency_code : "$");

          let averageOrderPerDayValues = Array.apply(null, {
            length: allTimes.length
          })
            .map(Number.call, Number)
            .map(key => ({
              name: allTimes[key],
              current: this.calculateaverageFromOrders(ordersOnDay[key]),
              previous: this.calculateaverageFromOrders(ordersOnDayBefore[key])
            }));

          const averageOrderValue =
            (
              averageOrderPerDayValues.map(day => Number(day.current)).reduce((a, b) => a + b, 0) /
              averageOrderPerDayValues.length
            ).toFixed(0) +
            " " +
            (formatedOrders[0] ? formatedOrders[0].currency_code : "$");

          let latestOrdersData = [].concat
            .apply(
              [],
              Array.apply(null, { length: allTimes.length })
                .map(Number.call, Number)
                .map(key => ordersOnDay[key])
            )
            .reverse();

          this.setState({
            totalOrdersData,
            totalOrders,
            totalSales,
            totalSalesData,
            averageOrderPerDayValues,
            averageOrderValue,
            latestOrdersData: latestOrdersData,
            latestOrdersDataTotal: latestOrdersData.length,
            oldKey: moment(fetchStart).format("MMM DD YYYY"),
            newKey: this.state.startDate.format("MMM DD YYYY")
          });
        } else {
          let after = ordersOnDate.slice(Math.ceil(ordersOnDate.length / 2));
          let before = ordersOnDate.slice(0, Math.ceil(ordersOnDate.length / 2));

          let totalOrdersData = Array.apply(null, { length: after.length })
            .map(Number.call, Number)
            .map(key => ({
              name: `${moment(after[key].date).format("MMM D")} - ${moment(before[key].date).format(
                "MMM D"
              )}`,
              current: after[key].orders.length,
              previous: before[key].orders.length
            }));

          let totalOrders = totalOrdersData
            .map(day => Number(day.current))
            .reduce((a, b) => a + b, 0);

          let totalSalesData = Array.apply(null, { length: after.length })
            .map(Number.call, Number)
            .map(key => ({
              name: `${moment(after[key].date).format("MMM D")} - ${moment(before[key].date).format(
                "MMM D"
              )}`,
              current: this.calculateTotalSales(after[key].orders),
              previous: this.calculateTotalSales(before[key].orders)
            }));

          let totalSales =
            formatPriceAmount(
              totalSalesData.map(day => Number(day.current)).reduce((a, b) => a + b, 0)
            ) +
            " " +
            (formatedOrders[0] ? formatedOrders[0].currency_code : "$");

          let averageOrderPerDayValues = Array.apply(null, {
            length: after.length
          })
            .map(Number.call, Number)
            .map(key => ({
              name: `${moment(after[key].date).format("MMM D")} - ${moment(before[key].date).format(
                "MMM D"
              )}`,
              current: this.calculateaverageFromOrders(after[key].orders),
              previous: this.calculateaverageFromOrders(before[key].orders)
            }));

          const averageOrderValue =
            formatPriceAmount(
              averageOrderPerDayValues.map(day => Number(day.current)).reduce((a, b) => a + b, 0) /
                averageOrderPerDayValues.length
            ) +
            " " +
            (formatedOrders[0] ? formatedOrders[0].currency_code : "$");

          let latestOrdersData = [].concat
            .apply(
              [],
              Array.apply(null, { length: after.length })
                .map(Number.call, Number)
                .map(key => after[key].orders)
            )
            .reverse();

          this.setState({
            totalOrdersData,
            totalOrders,
            totalSales,
            totalSalesData,
            averageOrderPerDayValues,
            averageOrderValue,
            latestOrdersData: latestOrdersData,
            latestOrdersDataTotal: latestOrdersData.length,
            oldKey: moment(fetchStart).format("MMM DD YYYY"),
            newKey: this.state.startDate.format("MMM DD YYYY")
          });
        }
      })
      .catch(error => console.log(error));
  };

  calculateaverageFromOrders = orders =>
    !orders.length
      ? 0
      : Number(
          (
            orders
              .filter(order => order.order_total)
              .map(order => order.order_total)
              .reduce((a, b) => a + b, 0) / orders.length
          ).toFixed(0)
        );

  calculateTotalSales = orders =>
    !orders.length
      ? 0
      : Number(
          orders
            .filter(order => order.order_total)
            .map(order => order.order_total)
            .reduce((a, b) => a + b, 0)
            .toFixed(0)
        );

  render() {
    const {
      latestOrdersData,
      latestOrdersDataTotal,
      latestCustomers,
      latestCustomersTotal,
      totalSales,
      totalSalesData,
      totalOrders,
      returningCustomerRateData,
      value,
      startDate,
      endDate,
      focusedInput,
      oldKey,
      newKey,
      totalOrdersData,
      averageOrderPerDayValues,
      averageOrderValue,
      customers,
      membersVsRegistered
    } = this.state;
    const { classes } = this.props;

    return (
      <Card className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              label={
                <TranslatableParagraph
                  customStyling={{ margin: 0 }}
                  translationLabel="resources.dashboard.tabs.analytics.name"
                />
              }
            />
            <Tab
              label={
                <TranslatableParagraph
                  customStyling={{ margin: 0 }}
                  translationLabel="resources.dashboard.tabs.mutual.customers"
                />
              }
            />
            <Tab
              label={
                <TranslatableParagraph
                  customStyling={{ margin: 0 }}
                  translationLabel="resources.dashboard.tabs.mutual.orders"
                />
              }
            />
            {/* <Tab label="Products" /> */}
          </Tabs>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginRight: "48px",
              marginTop: "-34px"
            }}
          >
            <DateRangePicker
              startDate={startDate} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={endDate} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) =>
                this.setState({ startDate, endDate }, () => {
                  this.fetchDashboardCustomers();
                  this.fetchDashboardOrders();
                })
              } // PropTypes.func.isRequired,
              minimumNights={0}
              small
              anchorDirection="right"
              numberOfMonths={1}
              isOutsideRange={day => day.isSameOrAfter(moment().add(1, "day"), "day")}
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
            />
          </div>
        </AppBar>
        <CardContent className={classes.contentWrapper}>
          {value === 0 && (
            <TabContainer>
              <Analytics
                oldKey={oldKey}
                newKey={newKey}
                totalSales={totalSales}
                totalSalesData={totalSalesData}
                totalOrders={totalOrders}
                totalOrdersData={totalOrdersData}
                averageOrderPerDayValues={averageOrderPerDayValues}
                averageOrderValue={averageOrderValue}
                returningCustomerRateData={returningCustomerRateData}
                customers={customers}
              />
            </TabContainer>
          )}
          {value === 1 && (
            <TabContainer>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Card
                    className={classes.cardStyle}
                    style={{
                      height: 420,
                      marginTop: "2%"
                    }}
                  >
                    <div>
                      <TranslatableParagraph
                        customStyling={{
                          width: "fit-content",
                          fontWeight: "bold",
                          fontSize: "11px",
                          borderBottom: "4px solid #3498db"
                        }}
                        translationLabel="resources.dashboard.tabs.mutual.returningCustomerRate"
                      />
                      {returningCustomerRateData &&
                      returningCustomerRateData[0] &&
                      returningCustomerRateData[0].value &&
                      returningCustomerRateData[1] &&
                      returningCustomerRateData[1].value ? (
                        <PieGraph
                          data={returningCustomerRateData}
                          colors={returningCustomerRateColors}
                          oldKey={oldKey}
                          newKey={newKey}
                        />
                      ) : (
                        <TranslatableParagraph
                          customStyling={{
                            fontWeight: "bold",
                            fontSize: "18px"
                          }}
                          translationLabel="resources.dashboard.tabs.mutual.returningCustomerRate"
                        />
                      )}
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card
                    className={classes.cardStyle}
                    style={{
                      height: 420,
                      marginTop: "2%"
                    }}
                  >
                    <div>
                      <h4 className={classes.headerLabel}>
                        <TranslatableParagraph translationLabel="resources.dashboard.tabs.analytics.membersVsRegistered" />
                      </h4>
                      {membersVsRegistered &&
                      membersVsRegistered[0] &&
                      membersVsRegistered[0].value &&
                      membersVsRegistered[1] &&
                      membersVsRegistered[1].value ? (
                        <PieGraph
                          data={membersVsRegistered}
                          colors={returningCustomerRateColors}
                          oldKey={oldKey}
                          newKey={newKey}
                        />
                      ) : (
                        <TranslatableParagraph
                          customStyling={{
                            fontWeight: "bold",
                            fontSize: "18px"
                          }}
                          translationLabel="resources.dashboard.tabs.mutual.returningCustomerRate"
                        />
                      )}
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} md={12}>
                  {latestCustomers && latestCustomers.length ? (
                    <LatestCustomers
                      latestCustomers={latestCustomers}
                      latestCustomersTotal={latestCustomersTotal}
                    />
                  ) : (
                    <TranslatableParagraph
                      customStyling={{ fontWeight: "bold", fontSize: "18px" }}
                      translationLabel="resources.dashboard.tabs.mutual.noData"
                    />
                  )}
                </Grid>
              </Grid>
            </TabContainer>
          )}
          {value === 2 && (
            <TabContainer>
              {latestOrdersData && (
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Card
                      className={classes.cardStyle}
                      style={{
                        height: 420,
                        marginTop: "2%"
                      }}
                    >
                      <div>
                        <h4 className={classes.headerLabel}>
                          <TranslatableParagraph translationLabel="resources.dashboard.tabs.mutual.totalOrders" />
                        </h4>
                        <h2 className={classes.headerData}>{totalOrders}</h2>
                        <LineGraph data={totalOrdersData} oldKey={oldKey} newKey={newKey} />
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card
                      className={classes.cardStyle}
                      style={{
                        height: 420,
                        marginTop: "2%"
                      }}
                    >
                      <div>
                        <TranslatableParagraph
                          customStyling={{
                            margin: "15px 30px",
                            fontWeight: "bold",
                            width: "fit-content",
                            borderBottom: "4px solid #3489db"
                          }}
                          translationLabel={`resources.dashboard.tabs.mutual.averageOrderValue`}
                        />
                        <h2 className={classes.headerData}>{averageOrderValue}</h2>
                        <LineGraph
                          data={averageOrderPerDayValues}
                          oldKey={oldKey}
                          newKey={newKey}
                        />
                      </div>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {latestOrdersData && latestOrdersData.length ? (
                      <LatestOrders
                        latestOrdersData={latestOrdersData}
                        latestOrdersDataTotal={latestOrdersDataTotal}
                      />
                    ) : (
                      <TranslatableParagraph
                        customStyling={{ fontWeight: "bold", fontSize: "18px" }}
                        translationLabel="resources.dashboard.tabs.mutual.noData"
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </TabContainer>
          )}
          {/* {value === 3 && <TabContainer>Item Four</TabContainer>} */}
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(Dashboard);
