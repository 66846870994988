import React from "react";
import { Edit, SimpleForm, TextInput, Toolbar, SaveButton } from "react-admin";
import { useForm, useFormState } from "react-final-form";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import { styles } from "../styles";

const JSONEditorField = () => {
  const form = useForm();
  const { values: formData } = useFormState();

  const parsedConfig = JSON.parse(formData.values);

  const handleChange = editorValues => {
    const editorValuesJson = JSON.stringify(editorValues);
    form.change("values", editorValuesJson);
  };

  return <Editor value={parsedConfig} onChange={handleChange} />;
};

const ConfirmationToolbar = props => (
  <Toolbar {...props} style={styles.toolbar}>
    <SaveButton />
    <DeleteConfirmation undoable={false} />
  </Toolbar>
);

const ConfigurationsEdit = props => {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm toolbar={<ConfirmationToolbar />}>
        <TextInput source="type" disabled />
        <TextInput source="domain" disabled />
        <TextInput source="iso_code" disabled />
        <TextInput source="platform" disabled />
        <JSONEditorField />
      </SimpleForm>
    </Edit>
  );
};

export default ConfigurationsEdit;
