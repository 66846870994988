import React, { useState, useEffect } from "react";
import { useDataProvider, TextInput, required } from "react-admin";

export const BlogNameValidationTextInput = formValues => {
  // Declaring state
  const dataProvider = useDataProvider();
  const [blogsList, setBlogsList] = useState([]);
  // Drag needed information
  useEffect(() => {
    dataProvider
      .getList("blogArticles", {
        filter: {},
        sort: { field: "created_at", order: "DESC" },
        pagination: { page: "all" }
      })
      .then(({ data }) => {
        setBlogsList(data);
      })
      .catch(error => {
        console.error(
          "There was a problem with data in your dataProvider, getList for blogArticles"
        );
      });
    return () => {
      setBlogsList([]);
    };
  }, [dataProvider]);

  // Validation for page name input field
  const validateName = value => {
    let lastCharacter = value && value.charAt(value.length - 1);
    let firstCharacter = value && value.charAt(0);

    if (!value) {
      return "ra.validation.required";
    }

    if (value && !value.trim()) {
      return "Blog title is not valid";
    }

    if (lastCharacter === " " || firstCharacter === " ") {
      return "Please remove white-spaces from article's title";
    }

    const blogNameExists = blogsList.find(
      page => page?.title?.toLowerCase() === value?.toLowerCase()
    );

    if (blogNameExists) {
      return "Blog title already exists. Please choose another title.";
    }

    return undefined;
  };

  // Return TextInput field to be showcased on the form
  return (
    <TextInput
      variant="standard"
      style={{ minWidth: "256px" }}
      label="resources.pages.fields.name"
      source="title"
      validate={[validateName, required()]}
    />
  );
};

export const BlogSlugValidationTextInput = formValues => {
  let saveExistingBlogName =
    formValues && formValues.record && formValues.record.url_slug ? formValues.record.url_slug : "";

  // Declaring state
  const dataProvider = useDataProvider();
  const [blogsList, setBlogsList] = useState([]);
  const [currentKeyName, setCurrentKeyName] = useState(saveExistingBlogName);

  // Drag needed information
  useEffect(() => {
    dataProvider
      .getList("blogArticles", {
        filter: {},
        sort: { field: "created_at", order: "DESC" },
        pagination: { page: "all" }
      })
      .then(({ data }) => {
        setBlogsList(data);
      })
      .catch(error => {
        console.error(
          "There was a problem with data in your dataProvider, getList for blogArticles"
        );
      });
    return () => {
      setBlogsList([]);
    };
  }, [currentKeyName, dataProvider]);

  // Validation for blog slug input field
  const validateSlug = (value, formValues) => {
    if (!value) {
      return "ra.validation.required";
    }

    const blogSlugExists = blogsList.find(page => page.url_slug === value && value.toLowerCase());

    if (blogSlugExists && blogSlugExists.url_slug.toUpperCase() !== currentKeyName.toUpperCase()) {
      return "Blog url slug already exists. Please choose another blog slug.";
    }

    return undefined;
  };

  // Return TextInput field to be showcased on the form
  return (
    <TextInput
      variant="standard"
      style={{ minWidth: "256px" }}
      label="resources.pages.fields.url_slug"
      source="url_slug"
      validate={[validateSlug, required()]}
    />
  );
};
