import { find } from "lodash";
import { handleSingleRequest } from "../restClient";

export const transformUserAddressesList = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params,
    include: ["address"]
  }).then(({ data = [], included = [], ...rest }) => {
    data.map(record => {
      record["address"] = find(included, ["id", record.address_id]);

      return record;
    });

    return { data, ...rest };
  });
};
