import { handleSingleRequest } from "../restClient";
import { CREATE, GET_LIST, DELETE } from "../types";

import find from "lodash/find";

export const transformBlogCategories = (type, resource, params) => {
  return handleSingleRequest(type, resource, {
    ...params,
    include: ["blogArticles"]
  }).then(async ({ data = [], included = [], relationships, ...rest }) => {
    if (typeof data.map === "function") {
      data.map(async record => {
        record.blogArticlesIds = find(relationships, {
          id: record.id
        }).blogArticles.data.map(item => item.id);

        return record;
      });
    } else {
      data.blogArticlesIds = included.map(item => item.id);
    }

    const allLanguages = await handleSingleRequest(GET_LIST, "languages", {
      filter: {
        is_enabled: true
      },
      pagination: { page: "all" },
      sort: { field: "id", order: "DESC" }
    });
    const allLanguagesIds = allLanguages.data.map(item => item.id);
    const blogCategoryTranslations = await handleSingleRequest(GET_LIST, "translations", {
      filter: { table_row_id: params.id, language_id_in: allLanguagesIds },
      pagination: { page: "all" },
      sort: { field: "id" }
    });

    if (blogCategoryTranslations.data && blogCategoryTranslations.data.length > 0) {
      const constructTranslations = blogCategoryTranslations.data.map(translationItem => {
        let findLanguage = allLanguages.data.find(
          language => translationItem.language_id === language.id
        );
        return {
          id: translationItem.id,
          language: { id: findLanguage.id, name: findLanguage.name },
          name: translationItem.translation.name,
          description: translationItem.translation.description
        };
      });
      data.translations = constructTranslations;
    } else {
      data.translations = [];
    }

    return { data, ...rest };
  });
};

export const transformBlogCategoriesCreate = (type, resource, params) => {
  return handleSingleRequest(type, resource, params)
    .then(response => {
      return handleSingleRequest(CREATE, "translations", {
        data: {
          table_row_id: response.data.id,
          language_id: params.data.language_id,
          table_name: "blogCategories",
          translation: {
            name: params.data.name
          }
        }
      });
    })
    .catch(err => {
      if (err.message[0].title === "ba BlogCategoryBaseResource - Create - Failed") {
        throw new Error(
          "Blog category with said url_slug has been soft deleted please contact IT support to make this creation for you."
        );
      } else {
        throw new Error(
          "There has been an issue with your form, please contact IT Support to investigate this further."
        );
      }
    });
};

export const transformBlogCategoriesUpdate = async (type, resource, params) => {
  if (!params.data?.translations) {
    params.data.translations = [];
  }
  const allLanguages = await handleSingleRequest(GET_LIST, "languages", {
    filter: {
      is_enabled: true
    },
    pagination: { page: "all" },
    sort: { field: "id", order: "DESC" }
  });
  const allLanguagesIds = allLanguages.data.map(item => item.id);
  const allTranslations = await handleSingleRequest(GET_LIST, "translations", {
    filter: { table_row_id: params.data.id, language_id_in: allLanguagesIds },
    pagination: { page: "all" },
    sort: { field: "id" }
  });
  params.data.translations.forEach(translationItem => {
    let translationType = type;
    let translationParams = {
      data: {
        table_row_id: params.data.id,
        language_id: translationItem.language.id,
        table_name: "pages",
        translation: {
          name: translationItem.name
        }
      }
    };
    let itemExistsUpdate = allTranslations.data.find(getItem => getItem.id === translationItem.id);
    if (itemExistsUpdate) {
      translationParams = {
        ...translationParams,
        id: translationItem.id
      };
    } else {
      translationType = CREATE;
    }
    handleSingleRequest(translationType, "translations", translationParams);
  });
  allTranslations.data.forEach(translationItem => {
    let itemDontExistsDelete = params.data.translations.find(
      getItem => getItem.id === translationItem.id
    );
    if (!itemDontExistsDelete) {
      handleSingleRequest(DELETE, "translations", {
        data: {},
        id: translationItem.id
      });
    }
  });
  params.data.translations = [];
  return handleSingleRequest(type, resource, params)
    .then(({ data, ...rest }) => {
      return { data, ...rest };
    })
    .catch(err => {
      if (err.message[0].title === "ba BlogCategoryBaseResource - Update - Failed") {
        throw new Error(
          "Blog category with said url_slug has been soft deleted please contact IT support to make this update for you."
        );
      } else {
        throw new Error(
          "There has been an issue with your form, please contact IT Support to investigate this further."
        );
      }
    });
};
