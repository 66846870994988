import React from "react";
import {
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  BooleanInput,
  ImageField,
  ImageInput,
  required,
  AutocompleteInput,
  Toolbar,
  SaveButton
} from "react-admin";
import { useFormState } from "react-final-form";

// Custom Validation for fields
import { BlogNameValidationTextInput, BlogSlugValidationTextInput } from "./validators";

export const infotab = {
  informationTab: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "50%",
    margin: "1rem 0px",
    backgroundColor: "#3498db",
    padding: "5px",
    borderRadius: "4px",
    color: "#f8f8f8"
  },
  svg: {
    verticalAlign: "middle",
    fontSize: "20px",
    margin: "0px .25rem"
  },
  text: {
    fontSize: "14px"
  }
};

const CustomToolbar = props => {
  const { values: formData } = useFormState();

  let isDisabledSave =
    !formData.language_id ||
    !formData.author ||
    !formData.url_slug ||
    !formData.date ||
    !formData.blog_category_id ||
    !formData.image
      ? true
      : false;

  return (
    <Toolbar {...props}>
      <SaveButton disabled={isDisabledSave} />
    </Toolbar>
  );
};

export const BlogArticleCreate = props => {
  console.log("ovo", props);
  return (
    <Create {...props} title="resources.blogs.title.create">
      <TabbedForm variant="standard" toolbar={<CustomToolbar />}>
        <FormTab label={"resources.blogs.tabs.basic"}>
          <ReferenceInput
            filter={{ is_enabled: true }}
            label="Language"
            source="language_id"
            reference="languages"
            // perPage={30}
          >
            <SelectInput optionText="name" validate={required()} />
          </ReferenceInput>
          <TextInput label="resources.blogs.fields.author" source="author" validate={required()} />
          <BlogNameValidationTextInput />
          <BlogSlugValidationTextInput />
          <TextInput multiline label="resources.blogs.fields.short_text" source="short_text" />
          <DateTimeInput label="resources.blogs.fields.date" source="date" validate={required()} />
          <BooleanInput label="resources.blogs.fields.is_recipe" source="is_recipe" />
          <BooleanInput label="resources.blogs.fields.enabled" source="enabled" />
          <ReferenceInput
            source="blog_category_id"
            label="resources.blogs.fields.blog_category"
            reference="blogCategories"
            validate={required()}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <ImageInput
            source="image"
            labelSingle="resources.productCategories.fields.imageRawLabel"
            maxSize={2000000}
            label={
              props.imageError ? (
                <span
                  style={{
                    color: "#f44336",
                    fontSize: "rem",
                    textAlign: "left",
                    marginTop: "-5px",
                    minHeight: "1em",
                    lineHeight: "1em",
                    fontFamily: `Ubuntu Regular,-apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif`
                  }}
                >
                  {props.imageError}
                </span>
              ) : (
                "Image"
              )
            }
            accept=".jpg,.jpeg,.png"
            validate={required()}
          >
            <ImageField source="src" />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};
