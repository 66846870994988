import React from "react";
import {
  List,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  useRecordContext,
  Datagrid
} from "react-admin";
import Chip from "@material-ui/core/Chip";

export const priceLevelNameTransform = value => {
  let label = value;

  switch (value) {
    case "D2":
      label = "DL2";
      break;
    case "D3":
      label = "DL3";
      break;
    case "D4":
      label = "DL4";
      break;
    case "D5":
      label = "DL5";
      break;
    case "D6":
      label = "DL6";
      break;
    case "D7":
      label = "DL7";
      break;
    case "D8":
      label = "DL8";
      break;
    default:
      break;
  }

  return label;
};

const PriceLevelField = props => {
  const { source } = props;
  const record = useRecordContext(props);

  return record ? (
    <Chip label={priceLevelNameTransform(record[source])} style={{ margin: 4 }} />
  ) : null;
};

export const UserLevelsList = props => {
  return (
    <List
      exporter={false}
      bulkActionButtons={false}
      sort={{ field: "name", order: "ASC" }}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField source="key_name" />
        <TextField source="description" />
        <ReferenceArrayField
          addLabel={false}
          reference="priceLevels"
          label="Price levels"
          source="price_level_ids"
          allowEmpty
        >
          <SingleFieldList linkType={false}>
            <PriceLevelField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      </Datagrid>
    </List>
  );
};
