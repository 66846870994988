import { handleSingleRequest } from "../restClient";
import { UPDATE, GET_LIST, GET_ONE } from "../types";

export const transformUserLevels = (type, resource, params) => {
  if (type === UPDATE) {
    if (params.data.price_levels.length > 0) {
      params.relationships = {
        ...params.relationships,
        priceLevels: {
          data: params.data.price_levels.map(id => ({
            type: "priceLevels",
            id
          }))
        }
      };
    }
  }

  return handleSingleRequest(type, resource, {
    ...params,
    include: "priceLevels"
  }).then(({ data = [], included = [], relationships = [], ...rest }) => {
    if (type === GET_LIST) {
      data.map(record => {
        const rel = relationships.find(item => item.id === record.id);
        record.price_level_ids = rel.priceLevels.data.map(item => item.id);
      });
    }

    if (type === GET_ONE) {
      data.price_levels = included.map(item => item.id);
    }

    return { data, ...rest };
  });
};
