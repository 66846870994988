import React from "react";
import { List, TextField, DateField, BooleanField, ReferenceField } from "react-admin";

import CustomizableDatagrid from "../../components/CustomizableDatagrid";

import ProductFilters from "./Filters";

//title, author, date is recipe, category

export const BlogArticleList = props => (
  <List
    {...props}
    title="resources.blogs.title.list"
    exporter={false}
    bulkActionButtons={false}
    filters={<ProductFilters />}
  >
    <CustomizableDatagrid
      rowClick="edit"
      defaultColumns={["title", "author", "date", "is_recipe", "enabled", "blog_category_id"]}
    >
      <TextField label="resources.blogs.fields.author" source="author" />
      <TextField label="resources.blogs.fields.title" source="title" />
      <TextField label="resources.blogs.fields.url_slug" source="url_slug" />
      <TextField label="resources.blogs.fields.short_text" source="short_text" />
      <DateField label="resources.blogs.fields.date" source="date" />
      <BooleanField label="resources.blogs.fields.is_recipe" source="is_recipe" />
      <BooleanField label="resources.blogs.fields.enabled" source="enabled" />
      <DateField label="resources.blogs.fields.created_at" source="created_at" />
      <DateField label="resources.blogs.fields.updated_at" source="updated_at" />
      <ReferenceField
        basePath="blogCategories"
        source="blog_category_id"
        label="resources.blogs.fields.blog_category"
        reference="blogCategories"
        allowEmpty
      >
        <TextField source="name" />
      </ReferenceField>
    </CustomizableDatagrid>
  </List>
);
