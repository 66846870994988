export const prodDomains = {
  "www.zepter.rs": "srb",
  "www.zepter.ru": "rus",
  "www.zepter.ro": "rou",
  "www.zepter.com": "zzz",
  "www.zepter.hr": "hrv",
  "www.zepter.hu": "hun",
  "www.zepter.cz": "cze",
  "www.zepter.sk": "svk",
  "www.zepter.at": "aut",
  "www.shopzepterusa.com": "usa",
  "www.zepter.fr": "fra",
  "zepter-gesundheit.de": "deu",
  "www.zepter.ch": "che",
  "www.zepter.uz": "uzb",
  "www.zepter.bg": "bgr",
  // preProd domains
  "web-it.zepter.app": "ita",
  "web-ba.zepter.app": "bih",
  "web-es.zepter.app": "esp",
  "web-lt.zepter.app": "ltu",
  "web-lv.zepter.app": "lva",
  "web-mk.zepter.app": "mkd",
  "web-sl.zepter.app": "svn",
  "web-hu.zepter.app": "hun",
  "web-pl.zepter.app": "pol",
  "web-kz.zepter.app": "kaz",
  "web-bg.zepter.app": "bgr",
  "web-by.zepter.app": "blr",
  "web-ua.zepter.app": "ukr",
  "web-eg.zepter.app": "egy",
  "web-ca.zepter.app": "can",
  "web-au.zepter.app": "aus",
  "web-jo.zepter.app": "jor",
  "web-md.zepter.app": "mda",
  "web-kd.zepter.app": "bihKd"
};

export const platforms = ["web", "mobile"];

export const mobileDomains = ["debug", "dev", "release"];
