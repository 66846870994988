import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import download from "downloadjs";

import { addHeaders } from "../services/api/restClient";

import CircularProgress from "@material-ui/core/CircularProgress";

import { resetCsvUrls, removeCSVUrl } from "../redux/actions";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 24
  },
  message: {
    fontSize: 12,
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.54)"
  }
};
class CSVDownloadInfo extends Component {
  state = {
    interval: null
  };

  componentDidMount() {
    if (this.props.csvUrls.length > 0) {
      this.setState({
        interval: setInterval(() => {
          this.fetchCSVs();
        }, 5000)
      });
    }
  }

  componentDidUpdate() {
    const { csvUrls } = this.props;
    const { interval } = this.state;

    if (csvUrls.length > 0 && !interval) {
      this.setState({
        interval: setInterval(() => {
          this.fetchCSVs();
        }, 5000)
      });
    } else if (!csvUrls.length && !!interval) {
      clearInterval(interval);
      this.setState({ interval: null });
    }
  }

  buildMessage = (resource, csvUrls) => {
    return (
      <div style={{ ...styles.container, ...styles.message }}>
        <span>{`${resource} export`}</span>
        <span>{`${csvUrls.length} file(s) are being prepared`}</span>
        <span>Download will start automatically</span>
        <span>once the files are ready</span>
        <span>Please wait.</span>
      </div>
    );
  };

  fetchCSVs = async () => {
    const { csvUrls, resetCSVUrls, removeCSVUrl, resource } = this.props;
    const { interval } = this.state;

    const options = {};
    addHeaders(options);
    if (csvUrls.length > 0) {
      Promise.all(
        csvUrls.map(async url => {
          const response = await fetch(
            `${process.env.REACT_APP_IMG_URL}/v1${url.csv_url}`,
            options
          );

          if (response.ok) {
            removeCSVUrl(url.csv_url);
            const res = await response.blob();
            download(res, `${resource}_export_${uuidv4()}.csv`, "text/csv");
          }
        })
      );
    } else {
      clearInterval(interval);
      resetCSVUrls();
    }
  };

  render() {
    const { csvUrls, resource } = this.props;
    const shouldRender = csvUrls.length > 0;

    return shouldRender ? (
      <div style={styles.container}>
        <CircularProgress />
        <div>{this.buildMessage(resource, csvUrls)}</div>
      </div>
    ) : null;
  }
}

CSVDownloadInfo.propTypes = {
  resource: PropTypes.string.isRequired,
  csvUrls: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  csvUrls: state.exportReducer.csvUrls,
  resource: state.exportReducer.resource
});

const mapDispatchToProps = dispatch => ({
  resetCSVUrls: () => {
    dispatch(resetCsvUrls());
  },
  removeCSVUrl: url => {
    dispatch(removeCSVUrl(url));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CSVDownloadInfo);
