import React, { Component, Fragment } from "react";
import { crudUpdateMany, translate } from "react-admin";
import { connect } from "react-redux";
import compose from "recompose/compose";

import Button from "@material-ui/core/Button";

import Confirm from "../../../components/CustomConfirm";

class ResendEmailButton extends Component {
  state = {
    isConfirmDialogOpen: false,
    hideConfirmDialogButton: true,
    dialogText: {
      confirmResend: this.props.translate(
        "resources.orders.bulkActions.resendEmailButton.dialogText.confirmResend"
      ),
      cannotResend: this.props.translate(
        "resources.orders.bulkActions.resendEmailButton.dialogText.cannotResend"
      )
    }
  };

  shouldDisableResending = () => {
    const { orderStatuses, orders, selectedIds } = this.props;

    const shouldBeDisabled = selectedIds.some(id => {
      const statusId = orders[id].order_status_id;
      const orderStatus = orderStatuses[statusId].name;
      const emailStatus = orders[id].invoice_status;

      const allowedOrderStatuses = ["New", "Placed", "Paid", "Released For Shipment"];
      const allowedEmailStatuses = ["E", "N", "Y", "C", "O"];

      return !(
        allowedOrderStatuses.includes(orderStatus) && allowedEmailStatuses.includes(emailStatus)
      );
    });

    return shouldBeDisabled;
  };

  componentDidMount() {
    this.setState({ hideConfirmDialogButton: this.shouldDisableResending() });
  }

  componentDidUpdate(nextProps) {
    if (this.props.selectedIds.length !== nextProps.selectedIds.length) {
      this.setState({ hideConfirmDialogButton: this.shouldDisableResending() });
    }
  }

  handleDialogOpen = () => {
    this.setState({ isConfirmDialogOpen: true });
  };

  handleDialogClose = () => {
    this.setState({ isConfirmDialogOpen: false });
  };

  handleResendConfirm = () => {
    const { basePath, crudUpdateMany, resource, selectedIds } = this.props;
    crudUpdateMany(resource, selectedIds, { invoice_status: "N" }, basePath);
  };

  render() {
    const { isConfirmDialogOpen, hideConfirmDialogButton, dialogText } = this.state;

    const { translate } = this.props;

    const dialogMessage = this.state.hideConfirmDialogButton
      ? dialogText.cannotResend
      : dialogText.confirmResend;

    return (
      <Fragment>
        <Button
          onClick={this.handleDialogOpen}
          style={{ backgroundColor: "#3498db", color: "white" }}
        >
          {translate("resources.orders.bulkActions.resendEmailButton.buttonLabel")}
        </Button>
        <Confirm
          isOpen={isConfirmDialogOpen}
          title={translate("resources.orders.bulkActions.resendEmailButton.dialogTitle")}
          content={dialogMessage}
          onConfirm={this.handleResendConfirm}
          onClose={this.handleDialogClose}
          hideConfirmDialogButton={hideConfirmDialogButton}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  orderStatuses: state.admin.resources["orderStatuses"].data,
  orders: state.admin.resources["orders"].data
});

const enhance = compose(connect(mapStateToProps, { crudUpdateMany }), translate);

const EnhancedResendEmailButton = enhance(ResendEmailButton);

export default EnhancedResendEmailButton;
