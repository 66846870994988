import React, { Fragment } from "react";
import {
  TextField,
  Datagrid,
  ReferenceField,
  ReferenceManyField,
  DateField,
  Show,
  translate,
  TabbedShowLayout,
  Tab,
  Pagination,
  ShowController,
  BooleanField
} from "react-admin";
// import { makeStyles } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";

import { isEmpty, forIn } from "lodash";
import { EmailStatus } from "./List";

import { feature } from "../../components/Feature";
import OrderStatusReferenceField from "./OrderStatusReferenceField";

import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";
import { PaymentAmountWithHandling } from "./Edit";
import EmailAndPhoneField from "./EmailAndPhoneField";

// import { handleSingleRequest } from "../../services/api/restClient";
// import { GET_LIST } from "../../services/api/types";

const paymentOptionStyles = {
  fieldStyle: {
    marginTop: "1.5em"
  },
  labelStyle: {
    color: "#0000008a",
    fontSize: "0.75rem",
    fontWeight: 500
  }
};

// const useStyles = makeStyles({
//   table: {
//     minWidth: 650
//   },
//   rowOverflow: {
//     display: "flex",
//     justifyContent: "flex-start",
//     alignItems: "center",
//     flexWrap: "wrap",
//     gap: "10px",
//     height: "fit-content",
//     maxHeight: "200px",
//     overflowY: "auto"
//   }
// });

const OrderShowTitle = ({ record, translate }) =>
  translate("resources.orders.show.title", {
    orderNumber: record.order_number
  });

const TranslatedTitle = translate(OrderShowTitle);

const PaymentOptionFieldBase = ({ record, translate }) => {
  if (!record || isEmpty(record.paymentOption)) {
    return null;
  }

  const { paymentOption } = record;
  var paymentOptionText = (function (type) {
    switch (type) {
      case "cashDetails":
        return translate("resources.orders.paymentOptions.cashDetails");
      case "bankCreditPayments":
        return translate("resources.orders.paymentOptions.bankCreditPayments");
      case "advancePayments":
        return translate("resources.orders.paymentOptions.advancePayments");
      case "cashUponDelivery":
        return translate("resources.orders.paymentOptions.cashUponDelivery");
      case "Cash":
        return translate("resources.orders.paymentOptions.cash");
      case "CreditCard":
        return translate("resources.orders.paymentOptions.creditCard");
      default:
        return null;
    }
  })(paymentOption.type);

  const KeyFieldPairs = {
    id: <TextField key="id" record={record} source="paymentOption.id" />,
    rid: <TextField key="rid" record={record} source="paymentOption.rid" />,
    payment_type_id: (
      <TextField key="payment_type_id" record={record} source="paymentOption.payment_type_id" />
    ),
    created_at: <DateField key="created_at" record={record} source="paymentOption.created_at" />,
    updated_at: <DateField key="updated_at" record={record} source="paymentOption.updated_at" />,
    delivery_date: (
      <DateField key="delivery_date" record={record} source="paymentOption.delivery_date" />
    ),
    currency_code: (
      <TextField key="currency_code" record={record} source="paymentOption.currency_code" />
    ),
    month_duration: (
      <TextField key="month_duration" record={record} source="paymentOption.month_duration" />
    ),
    payment_date: (
      <DateField key="payment_date" record={record} source="paymentOption.payment_date" />
    ),
    first_instalment: (
      <TextField key="first_instalment" record={record} source="paymentOption.first_instalment" />
    ),
    first_instalment_at: (
      <DateField
        key="first_instalment_at"
        record={record}
        source="paymentOption.first_instalment_at"
      />
    ),
    remaining_instalment: (
      <TextField
        key="remaining_instalment"
        record={record}
        source="paymentOption.remaining_instalment"
      />
    ),
    last_instalment_at: (
      <DateField
        key="last_instalment_at"
        record={record}
        source="paymentOption.last_instalment_at"
      />
    ),
    month_numbers: (
      <TextField key="month_numbers" record={record} source="paymentOption.month_numbers" />
    ),
    payment_day_in_month_of_installments: (
      <TextField
        key="payment_day_in_month_of_installments"
        record={record}
        source="paymentOption.payment_day_in_month_of_installments"
      />
    )
  };

  const renderFields = () => {
    let fields = [];
    let needsToBeAdded = "needsToBeAdded";
    forIn(record.paymentOption, (value, key) => {
      if (KeyFieldPairs[key]) {
        fields.push(
          <div key={key} style={paymentOptionStyles.fieldStyle}>
            <span style={paymentOptionStyles.labelStyle}>
              {
                <TranslatableParagraph
                  translationLabel={`resources.orders.fields.${key ? key : needsToBeAdded}`}
                />
              }{" "}
              {KeyFieldPairs[key]}
            </span>
          </div>
        );
      }
    });

    return fields;
  };

  return (
    <Fragment>
      <span>{paymentOptionText}</span>
      {renderFields()}
    </Fragment>
  );
};

const PaymentOptionField = translate(PaymentOptionFieldBase);

// ! Price Rule Calculations Table & Algorythm
// const PriceRuleCalculationAlgorythm = formValues => {
//   // ? Change Admin ENV later used with priceRules linking
//   let currentAdminENV = window?.location?.href;
//   let priceRulesUrl;
//   if (currentAdminENV.includes("admin.zepter.app")) {
//     currentAdminENV = "https://admin.zepter.app/";
//   } else if (currentAdminENV.includes("admin-dev.zepter.app")) {
//     currentAdminENV = "https://admin-dev.zepter.app/";
//   } else if (currentAdminENV.includes("localhost:3001")) {
//     currentAdminENV = "http://localhost:3001/";
//   } else {
//     currentAdminENV = "http://localhost:3000/";
//   }

//   let orderId = formValues?.record?.id ? formValues.record.id : "";
//   const [productPriceCalculations, setProductPriceCalculations] = useState([]);
//   const [orderItems, setOrderItems] = useState([]);
//   const [priceRules, setPriceRules] = useState([]);
//   const [products, setProducts] = useState([]);
//   const [discountLevels, setDiscountLevels] = useState([]);

//   useEffect(() => {
//     getProductPriceCalculations();
//     getOrderItems();
//     getPriceLevels();
//     getPriceRules();
//   }, []);

//   const getProductPriceCalculations = async () => {
//     try {
//       const { data: productPriceCalculations } = await handleSingleRequest(
//         GET_LIST,
//         `productPriceCalculations`,
//         {
//           pagination: {
//             page: "all"
//           },
//           sort: {},
//           filter: { order_id: orderId }
//         }
//       );
//       setProductPriceCalculations(productPriceCalculations);
//     } catch (error) {
//       console.error("There was a problem with your productPriceCalculations", error);
//     }
//   };

//   const getOrderItems = async () => {
//     try {
//       const { data: orderItems, included } = await handleSingleRequest(GET_LIST, "orderItems", {
//         pagination: {
//           page: "all"
//         },
//         sort: {},
//         filter: { order_id: orderId },
//         include: ["product"]
//       });
//       setOrderItems(orderItems);
//       setProducts(included);
//     } catch (error) {
//       console.error("There was a problem with your orderItems", error);
//     }
//   };

//   const getPriceLevels = async () => {
//     try {
//       const { data: discountLevels } = await handleSingleRequest(GET_LIST, "priceLevels", {
//         pagination: {
//           page: "all"
//         },
//         sort: {},
//         filter: {}
//       });
//       setDiscountLevels(discountLevels);
//     } catch (error) {
//       console.error("There was a problem with your discount levels", error);
//     }
//   };

//   const getPriceRules = async () => {
//     try {
//       const { data: priceRules } = await handleSingleRequest(GET_LIST, "priceRules", {
//         pagination: {
//           page: "all"
//         },
//         sort: {},
//         filter: {}
//       });
//       setPriceRules(priceRules);
//     } catch (error) {
//       console.error("There was a problem with your price rules", error);
//     }
//   };

//   const loadCountryRelevantDetails = () => {
//     // Get current country name
//     const countryAppId = localStorage.getItem("appId");
//     const country = JSON.parse(localStorage.getItem("countries")).find(
//       countryDetails => countryDetails.application_id === countryAppId
//     );

//     return {
//       countryAppId: countryAppId,
//       countryIsoCode: country.iso_code_2,
//       countryInternationalName: country.international_name
//     };
//   };

//   const filterOrderedItems = orderItems.filter(
//     (orderItem, index) => orderItems.indexOf(orderItem) === index
//   );

//   const classes = useStyles();

//   const tableBody = filterOrderedItems.map(orderItem => {
//     const { countryIsoCode } = loadCountryRelevantDetails();
//     let upperCasedIso = countryIsoCode?.toUpperCase();
//     let textColor = "#3498db";

//     let currencyTransformer = new Intl.NumberFormat(`${countryIsoCode}-${upperCasedIso}`, {
//       style: "currency",
//       currency: orderItem?.currency_code
//     });

//     // * Product External number
//     const productExternalNumber = products.map(product => {
//       if (product?.id === orderItem?.product_id) {
//         let productUrl = `http://localhost:3000/#/products/${product.id}/show`;
//         return (
//           <a style={{ color: textColor }} href={productUrl}>
//             {product.external_number}
//           </a>
//         );
//       }
//     });

//     // * Product Name
//     const productName = products.map(product => {
//       if (product?.id === orderItem?.product_id) {
//         // ! Change pushing to DEV & LIVE
//         let productUrl = `${currentAdminENV}/#/products/${product.id}/show`;
//         return (
//           <a style={{ color: textColor }} href={productUrl}>
//             {product?.name}
//           </a>
//         );
//       }
//     });

//     // * Discount Level
//     const productDiscountLevel = discountLevels.map(level => {
//       if (level?.id === orderItem?.price_level_id) {
//         return level?.name;
//       }
//     });

//     // * Successful rule names
//     let successfulRuleId = productPriceCalculations.map(rule => {
//       if (rule?.product_id === orderItem?.product_id && rule?.is_successful) {
//         const findRuleName = priceRules.find(ruleName => ruleName?.id === rule?.rule_id);

//         if (findRuleName?.id) {
//           priceRulesUrl = `${currentAdminENV}#/priceRules/${findRuleName?.id}/show`;
//         }

//         return (
//           <a
//             style={{
//               color: "white",
//               padding: "5px",
//               borderRadius: "10px",
//               backgroundColor: "rgba(52, 152, 219, 0.12)",
//               color: "rgb(52,152,219)"
//             }}
//             href={priceRulesUrl}
//           >
//             {findRuleName?.rule?.name}
//           </a>
//         );
//       }
//     });
//     const successfulRuleExists = successfulRuleId?.some(element => element !== undefined);

//     // * Failed rule names
//     let failedRuleIds = productPriceCalculations.map(rule => {
//       if (rule?.product_id === orderItem?.product_id && !rule?.is_successful) {
//         const findRuleName = priceRules?.find(ruleName => ruleName?.id === rule?.rule_id);

//         // ! Change pushing to DEV & LIVE
//         priceRulesUrl = `${currentAdminENV}/#/priceRules/${findRuleName?.id}/show`;
//         return (
//           <a
//             style={{
//               color: textColor,
//               backgroundColor: "rgba(244, 67, 54, 0.12)",
//               color: "#f44336",
//               padding: "5px",
//               borderRadius: "5px",
//               width: "fit-content"
//             }}
//             href={priceRulesUrl}
//           >
//             {findRuleName?.rule?.name}
//           </a>
//         );
//       }
//     });
//     const failedRuleExists = failedRuleIds?.some(element => element !== undefined);

//     // * Failed rule(s) description
//     let failedRuleDescription = productPriceCalculations.map(rule => {
//       if (rule?.product_id === orderItem?.product_id && !rule?.is_successful) {
//         const findRuleName = priceRules?.find(ruleName => ruleName?.id === rule?.rule_id);
//         // ? Could it be done better?
//         let reWrittenDescription = rule?.description
//           .replaceAll(`<div class="rule_description">`, "")
//           .replaceAll("</div>", "")
//           .replaceAll("<code>", "")
//           .replaceAll("</code>", "")
//           .replaceAll("user_role_id", "User role")
//           .replaceAll("today", "Rule date")
//           .replaceAll("payment_option", "Payment option")
//           .replaceAll("Condition", " Condition Case: ")
//           .replaceAll("category_id", "Category name")
//           .replaceAll("=", " equals ")
//           .replaceAll("failed on values", "doesn't match")
//           .replaceAll("product_id in", "Product name in group")
//           .replaceAll("product_id", "Product name");

//         return (
//           <span
//             style={{
//               backgroundColor: "rgba(244, 67, 54, 0.12)",
//               color: "#f44336",
//               padding: "5px",
//               borderRadius: "5px",
//               width: "fit-content"
//             }}
//           >
//             <b>{findRuleName?.rule?.name} failed due to next condition(s):</b>
//             {reWrittenDescription}
//           </span>
//         );
//       }
//     });
//     const failedRuleDescriptionExists = failedRuleDescription?.some(
//       element => element !== undefined
//     );

//     return (
//       <TableRow key={orderItem?.id}>
//         <TableCell component="th" scope="row">
//           {productExternalNumber ? productExternalNumber : "Product's external number not found."}
//         </TableCell>
//         <TableCell component="th" scope="row">
//           {productName ? productName : "Product name not found."}
//         </TableCell>
//         <TableCell component="th" scope="row">
//           {orderItem?.quantity || "Quantity data not found."}
//         </TableCell>
//         <TableCell component="th" scope="row">
//           {orderItem?.price
//             ? currencyTransformer.format(orderItem?.price)
//             : "Product's price not found."}
//         </TableCell>
//         <TableCell component="th" scope="row">
//           {orderItem?.currency_code || "Product's currency not found."}
//         </TableCell>
//         <TableCell component="th" scope="row">
//           {orderItem?.coupon_code || "Product's coupon code not found."}
//         </TableCell>
//         <TableCell component="th" scope="row">
//           {productDiscountLevel ? productDiscountLevel : "Product's discount level not found."}
//         </TableCell>
//         <TableCell component="th" scope="row">
//           <div className={classes.rowOverflow}>
//             {successfulRuleExists ? successfulRuleId : "Product's successful rule(s) not found."}
//           </div>
//         </TableCell>
//         <TableCell component="th" scope="row" style={{ maxWidth: "200px" }}>
//           <div className={classes.rowOverflow}>
//             {failedRuleExists ? failedRuleIds : "Product's failed rule(s) not found."}
//           </div>
//         </TableCell>
//         <TableCell component="th" scope="row" style={{ maxWidth: "200px" }}>
//           <div className={classes.rowOverflow}>
//             {failedRuleDescriptionExists
//               ? failedRuleDescription
//               : "Product's failed rule(s) descriptions not found."}
//           </div>
//         </TableCell>
//       </TableRow>
//     );
//   });

//   return (
//     <TableContainer component={Paper}>
//       <Table className={classes.table}>
//         <TableHead>
//           <TableRow>
//             <TableCell>Product external number</TableCell>
//             <TableCell>Product name</TableCell>
//             <TableCell>Quantity</TableCell>
//             <TableCell>Product price</TableCell>
//             <TableCell>Currency</TableCell>
//             <TableCell>Discount code</TableCell>
//             <TableCell>Discount level</TableCell>
//             <TableCell>Successful price rule(s)</TableCell>
//             <TableCell>Failed price rule(s)</TableCell>
//             <TableCell>Failed rules description</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>{tableBody}</TableBody>
//       </Table>
//     </TableContainer>
//   );
// };

const OrderShowNoTranslation = props => {
  const { translate } = props;
  // Grab country iso code
  const countryAppId = localStorage.getItem("appId");
  const countryIsoCode = JSON.parse(localStorage.getItem("countries")).find(
    country => country.application_id === countryAppId
  )?.iso_code_2;

  return (
    <ShowController {...props}>
      {controllerProps => {
        const hasPaymentOption = controllerProps.record && controllerProps.record.paymentOption;
        return (
          <Show title={<TranslatedTitle />} {...props}>
            <TabbedShowLayout>
              <Tab label="resources.orders.tabs.order_details">
                <TextField source="id" />
                <TextField source="rid" />
                <TextField label="resources.orders.fields.order_number" source="order_number" />
                <TextField
                  label="resources.orders.fields.external_number"
                  source="external_number"
                />
                <TextField
                  label="resources.orders.fields.referral_number"
                  source="referral_number"
                />
                <DateField label="resources.orders.fields.created_at" source="created_at" />
                <DateField label="resources.orders.fields.updated_at" source="updated_at" />
                <TextField label="resources.orders.fields.last_update_by" source="last_update_by" />
                <ReferenceField
                  source="customer_id"
                  reference="users"
                  link="show"
                  label="resources.orders.fields.customer_id"
                >
                  <EmailAndPhoneField />
                </ReferenceField>
                <ReferenceField
                  source="seller_id"
                  reference="users"
                  link="show"
                  sortable={false}
                  allowEmpty
                  label="resources.orders.fields.seller_id"
                >
                  <EmailAndPhoneField />
                </ReferenceField>
                <OrderStatusReferenceField
                  source="order_status_id"
                  reference="orderStatuses"
                  addLabel={true}
                  link={false}
                  label="resources.orders.fields.order_status_id"
                />
                {countryIsoCode === "de" ? (
                  <TextField
                    label="resources.orders.fields.shipping_handling"
                    source="shipping_handling"
                  />
                ) : null}
                <TextField label="resources.orders.fields.shipping_price" source="shipping_price" />
                <EmailStatus
                  label="resources.orders.fields.invoice_status"
                  source="invoice_status"
                  addLabel={true}
                />
                <TextField label="resources.orders.fields.sales_tax" source="sales_tax" />
                {countryIsoCode === "de" ? <PaymentAmountWithHandling /> : null}
                <TextField label="resources.orders.fields.payment_amount" source="payment.amount" />
                <TextField
                  label="resources.orders.fields.paymentMethod.name"
                  source="paymentMethod.name"
                />
                {/* Display Credit Card payment provider if any is exists */}
                {controllerProps &&
                controllerProps.record &&
                controllerProps.record.payment &&
                controllerProps.record.payment.provider_name ? (
                  <TextField
                    label="Credit Card Provider"
                    style={{ textTransform: "uppercase" }}
                    source="provider_name"
                  />
                ) : null}

                <ReferenceManyField
                  label={translate("resources.orders.fields.paymentInfo")}
                  reference="payments"
                  target="order_id"
                  pagination={<Pagination />}
                >
                  <Datagrid>
                    <TextField source="rid" sortable={false} />
                    <ReferenceField
                      source="payment_method_id"
                      reference="paymentMethods"
                      link={false}
                      sortable={false}
                      label="resources.orders.fields.paymentMethod.name"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    <TextField
                      style={{ textTransform: "uppercase" }}
                      source="provider_name"
                      label="Credit Card Provider"
                    />
                    <ReferenceField
                      source="status_id"
                      reference="paymentStatuses"
                      link={false}
                      sortable={false}
                      label="resources.orders.fields.payment_status"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                  </Datagrid>
                </ReferenceManyField>
                <TextField label="resources.orders.fields.address_line1" source="address_line1" />
                <TextField label="resources.orders.fields.zip_code" source="zip_code" />
                <ReferenceField
                  label="resources.orders.fields.city_id"
                  source="city_id"
                  reference="cities"
                  link={false}
                >
                  <TextField source="name" />
                </ReferenceField>
                <TextField
                  label="resources.orders.fields.extendedData"
                  source="customerAddress.address_extended_data"
                />
                <BooleanField source="dispatch_to_erp" label="Dispatch to erp" />
                <BooleanField source="is_dispatched_to_erp" label="Is dispatched to erp" />
              </Tab>
              <Tab label="resources.orders.tabs.payment_details">
                {hasPaymentOption ? (
                  <PaymentOptionField
                    label="resources.orders.fields.paymentOption"
                    addLabel={true}
                  />
                ) : null}

                {!hasPaymentOption ? (
                  <TextField
                    label="resources.orders.fields.payment_amount"
                    source="payment.amount"
                  />
                ) : null}

                {!hasPaymentOption ? (
                  <TextField
                    label="resources.orders.fields.shipping_price"
                    source="shipping_price"
                  />
                ) : null}

                {!hasPaymentOption ? (
                  <TextField label="resources.orders.fields.sales_tax" source="sales_tax" />
                ) : null}

                {!hasPaymentOption ? (
                  <TextField
                    label="resources.orders.fields.paymentMethod.name"
                    source="paymentMethod.name"
                  />
                ) : null}

                {!hasPaymentOption ? (
                  <TextField
                    label="Credit Card Provider"
                    style={{ textTransform: "uppercase" }}
                    source="payment.provider_name"
                  />
                ) : null}

                {/* Address field */}
                <ReferenceManyField
                  label="resources.orders.fields.shippingAddress"
                  reference="shipments"
                  target="order_id"
                >
                  <Datagrid>
                    <TextField
                      label="resources.orders.fields.address_line1"
                      source="address_line1"
                      sortable={false}
                    />
                    <TextField
                      label="resources.orders.fields.zip_code"
                      source="zip_code"
                      sortable={false}
                    />
                    <ReferenceField
                      reference="cities"
                      source="city_id"
                      link={false}
                      sortable={false}
                      label="resources.orders.fields.city_id"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                  </Datagrid>
                </ReferenceManyField>
              </Tab>
              <Tab label="resources.orders.tabs.item_details">
                {/* Commented till future db optimisations to handle priceRules info */}
                {/* <PriceRuleCalculationAlgorythm /> */}
                <ReferenceManyField
                  addLabel={false}
                  reference="orderItems"
                  target="order_id"
                  pagination={<Pagination />}
                >
                  <Datagrid>
                    <ReferenceField
                      source="product_id"
                      reference="products"
                      link="show"
                      sortable={false}
                      label="resources.orders.fields.external_number"
                    >
                      <TextField source="external_number" />
                    </ReferenceField>
                    <ReferenceField
                      source="product_id"
                      reference="products"
                      link="show"
                      sortable={false}
                      label="resources.orders.fields.product_name"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    <TextField
                      label="resources.orders.fields.quantity"
                      source="quantity"
                      sortable={false}
                    />
                    <TextField
                      label="resources.orders.fields.price"
                      source="price"
                      sortable={false}
                    />
                    <TextField
                      label="resources.orders.fields.currency"
                      source="currency_code"
                      sortable={false}
                    />
                    {feature("priceRulesDiscountCodeParameter") ? (
                      <TextField
                        source="coupon_code"
                        sortable={false}
                        label="resources.orders.fields.discount_code"
                      />
                    ) : null}
                    <ReferenceField
                      source="price_level_id"
                      reference="priceLevels"
                      link={false}
                      sortable={false}
                      label="resources.orders.fields.price_levels"
                    >
                      <TextField source="name" />
                    </ReferenceField>
                  </Datagrid>
                </ReferenceManyField>
              </Tab>
            </TabbedShowLayout>
          </Show>
        );
      }}
    </ShowController>
  );
};

export const OrderShow = translate(OrderShowNoTranslation);
