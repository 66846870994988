// Toolbar Options for RichTextEditor
export const ToolbarOptionsTechData = [];

export const ToolbarOptions = [
  "bold",
  "italic",
  "underline",
  { script: "sub" },
  { script: "super" },
  { header: [1, 2, false] },
  { list: "ordered" },
  { list: "bullet" },
  "video"
];

export const BannerToolbarOptions = [
  "bold",
  "italic",
  "underline",
  { script: "sub" },
  { script: "super" },
  { header: [1, 2, false] },
  { list: "ordered" },
  { list: "bullet" }
  // { color: [] },
  // { background: [] }
];
