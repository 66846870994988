import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

const HeadTagsTable = ({ record, basePath }) => {
  if (record) {
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <TranslatableParagraph translationLabel="resources.products.tabs.seo_tags.title" />
            </TableCell>
            <TableCell>
              <TranslatableParagraph translationLabel="resources.products.tabs.seo_tags.description" />
            </TableCell>
            <TableCell>
              <TranslatableParagraph translationLabel="resources.products.tabs.seo_tags.keywords" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              {record.metadata_title}
            </TableCell>
            <TableCell>{record.metadata_description}</TableCell>
            <TableCell>
              {record.metadata_keywords ? record.metadata_keywords.join(", ") : ""}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
  return null;
};

export default HeadTagsTable;
