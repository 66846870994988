import generateId from "./generate-id";
import { addNode } from "../utils/tree";
import layoutDefaults from "../utils/layout-defaults";

const findChildren = (node, type, parentId, layout) => {
  const { defaultColumn, defaultRow } = layoutDefaults;

  const query = type === "row" ? ":scope > div" : ":scope > .row";
  const childType = type === "row" ? "column" : "row";
  const newId = generateId(layout.records);
  let newRecord;

  if (type === "root") {
    newRecord = { id: 1 };
  } else if (type === "row") {
    newRecord = { ...defaultRow, type, id: newId };
  } else if (type === "column") {
    let name = node.dataset.name;
    let classes = node.classList;
    let regionName;
    let size = {};

    if (/es-\d/.test(name)) {
      regionName = `es-${parseInt(name.split("-")[1])}`;
    } else {
      regionName = `er-${newId}`;
    }

    classes.forEach(value => {
      if (/col-.*-\d/.test(value)) {
        let arr = value.split("-");
        size[arr[1]] = parseInt(arr[2]);
      }
    });

    newRecord = {
      ...defaultColumn,
      id: newId,
      type,
      name: regionName,
      size: { ...defaultColumn.size, ...size }
    };
  }

  if (type !== "root") {
    const newTree = addNode(layout.tree, parentId, newId);

    layout.tree = newTree;
    layout.records = [...layout.records, newRecord];
  }

  node
    .querySelectorAll(query)
    .forEach(child => findChildren(child, childType, newRecord.id, layout));
};

const transformHtmlTemplate = html => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(html, "text/html");

  const htmlRoot = htmlDoc.body.querySelector(".container-fluid");

  var layout = {
    tree: [{ id: 1, children: [] }],
    records: [{ id: 1, type: "root" }]
  };

  findChildren(htmlRoot, "root", null, layout);

  return layout;
};

export default transformHtmlTemplate;
