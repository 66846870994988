import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Create,
  SimpleForm,
  useNotify,
  ReferenceInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  SaveButton
} from "react-admin";
import { useForm, useFormState } from "react-final-form";
import Button from "@material-ui/core/Button";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

import { bestSellingProductsFilter } from "../../utils/filters";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";
import { handleSingleRequest } from "../../services/api/restClient";
import { GET_LIST } from "../../services/api/types";

const styledInfoBox = {
  backgroundColor: "rgb(235, 237, 238)",
  boxShadow: "rgb(235 237 238) 0px 0px 10px",
  color: "#202020",
  textAlign: "left",
  padding: "10px",
  borderRadius: "5px",
  width: "256px",
  minWidth: "50%"
};

const bestSellingProductValidator = (value, formValues) => {
  const { bestsellers } = formValues;

  if (value) {
    let numberOfRepetitions = 0;

    bestsellers.forEach(product => {
      if (product?.product_id === value) {
        numberOfRepetitions++;
      }
    });

    if (numberOfRepetitions > 1) {
      return (
        <TranslatableParagraph
          textOnly
          translationLabel="resources.bestSellers.errors.sameProduct"
        />
      );
    } else {
      return undefined;
    }
  } else {
    return <TranslatableParagraph textOnly translationLabel="ra.validation.required" />;
  }
};

const CustomToolbar = props => {
  const { values: formData } = useFormState();

  const maxNumberOfBestsellers = 10;
  const isDisabledSave = formData?.bestsellers?.length > maxNumberOfBestsellers ? true : false;

  return (
    <Toolbar {...props}>
      <SaveButton disabled={isDisabledSave} redirect={"/bestSellingProducts"} />
      {/* Additional link back to the List | Ease of access */}
      <Link style={{ textDecoration: "none", marginLeft: "1rem" }} to="/bestSellingProducts">
        <Button size="medium" variant="outlined" color="primary">
          <TranslatableParagraph
            textOnly
            translationLabel="resources.bestSellers.buttons.backToList"
          />
        </Button>
      </Link>
    </Toolbar>
  );
};

const BestSellerFieldsList = props => {
  const form = useForm();
  const { values: formData } = useFormState();

  const maxNumberOfBestsellers = 10;

  useEffect(() => {
    handleSingleRequest(GET_LIST, "bestSellingProducts", {
      pagination: {
        page: "all"
      },
      sort: {},
      filter: {}
    })
      .then(({ data }) => {
        const bestSellersProductIds = data.map(product => ({ product_id: product.product_id }));

        form.change("bestsellers", bestSellersProductIds);
      })
      .catch(error => console.log(error));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return formData?.bestsellers ? (
    <div>
      <p style={styledInfoBox}>
        <TranslatableParagraph
          translationLabel="resources.bestSellers.customFields.maxNumber"
          customLabel={":"}
          textOnly
        />
        <b> {maxNumberOfBestsellers}</b>
      </p>
      <ArrayInput {...props} label="resources.bestSellers.name" source="bestsellers">
        <SimpleFormIterator
          TransitionProps={{ enter: false, exit: false }}
          disableAdd={formData.bestsellers.length >= maxNumberOfBestsellers}
        >
          <ReferenceInput
            label="resources.bestSellers.fields.product_id"
            source="product_id"
            reference="products"
            sort={{ field: "has_content", order: "ASC" }}
            filterToQuery={bestSellingProductsFilter}
            validate={bestSellingProductValidator}
          >
            <AutocompleteInput optionText="external_number" />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      {formData.bestsellers.length > maxNumberOfBestsellers ? (
        <p style={styledInfoBox}>
          <TranslatableParagraph translationLabel="resources.bestSellers.errors.overMax" textOnly />
        </p>
      ) : null}
    </div>
  ) : null;
};

const BestSellingCreate = props => {
  const [errorMessage, setErrorMessage] = useState("");
  const notify = useNotify();

  const onFailure = error => {
    notify(error.message);
    setErrorMessage(error.message);
  };

  return (
    <Create onFailure={onFailure} {...props} title="resources.bestSellers.title.create">
      <SimpleForm variant="standard" toolbar={<CustomToolbar />}>
        <BestSellerFieldsList />
        {errorMessage && typeof errorMessage === "string" ? (
          <p style={styledInfoBox}>
            <SupervisorAccountIcon style={{ verticalAlign: `middle`, color: "red" }} />{" "}
            {errorMessage}
          </p>
        ) : null}
      </SimpleForm>
    </Create>
  );
};

export default BestSellingCreate;
