import React, { Component } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default class CountrySelector extends Component {
  render() {
    const { selectedAppId, countries, handleCountryChange } = this.props;
    return (
      <Select
        value={selectedAppId}
        onChange={handleCountryChange}
        inputProps={{
          name: "country",
          id: "country-selector"
        }}
        autoWidth
        style={{
          background: "white",
          padding: "5px 5px 0px 5px",
          minWidth: "10rem"
        }}
      >
        {countries
          .sort((a, b) => (a.international_name > b.international_name ? 1 : -1))
          .map((country, index) => (
            <MenuItem key={index} value={country.application_id}>
              {country.international_name}
            </MenuItem>
          ))}
      </Select>
    );
  }
}
