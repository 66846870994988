import React, { useState } from "react";
import { List, TextField, DateField } from "react-admin";
import { Tabs, Tab } from "@material-ui/core";
import { connect } from "react-redux";

// import ListFilters from "./Filters";
import CustomizableDatagrid from "../../components/CustomizableDatagrid";
import { prodDomains, platforms, mobileDomains } from "./Constants";

const ConfigurationsListView = props => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeWebTab, setActiveWebTab] = useState(0);
  const [activeMobileTab, setActiveMobileTab] = useState(0);
  const uniqueDomain = new Set();
  uniqueDomain.add("All");
  Object.entries(props.configurations[1]).forEach(([key, value]) => {
    !mobileDomains.includes(value.domain) && uniqueDomain.add(value.domain);
  });

  const handleChangeTab = (event, value) => {
    setActiveTab(value);
  };

  const handleChangeWebTab = (event, value) => {
    setActiveWebTab(value);
  };

  const handleChangeMobileTab = (event, value) => {
    setActiveMobileTab(value);
  };

  const generatePermanentFilters = props => {
    let countryDomain = "";

    const platform = platforms[activeTab];

    const countryAppId = localStorage.getItem("appId");
    const countryIsoCode = JSON.parse(localStorage.getItem("countries"))
      .find(country => country.application_id === countryAppId)
      .iso_code_3.toLowerCase();

    if (window.location.host === "admin.zepter.app") {
      for (const domain in prodDomains) {
        if (prodDomains[domain] === countryIsoCode) {
          countryDomain = domain;
          break;
        }
      }

      if (!countryDomain) {
        countryDomain = "admin.zepter.app";
      }
    } else {
      countryDomain = "web-dev.zepter.app";
    }
    if (platform === "web") {
      countryDomain = Array.from(uniqueDomain)[activeWebTab];
      if (activeWebTab === 0) {
        return {
          domain: countryDomain,
          platform: platform,
          admin: countryIsoCode,
          iso_code: countryIsoCode
        };
      } else {
        return {
          domain: countryDomain,
          platform: platform,
          // admin: countryIsoCode,
          iso_code: countryIsoCode
        };
      }
    }
    if (platform === "mobile") {
      countryDomain = mobileDomains[activeMobileTab];
      return {
        domain: countryDomain,
        platform: platform,
        // admin: countryIsoCode,
        iso_code: countryIsoCode
      };
    }
  };

  return (
    <List
      {...props}
      // filters={<ListFilters />}
      filter={generatePermanentFilters()}
      exporter={false}
      bulkActionButtons={false}
      perPage={20}
      sort={{ field: "platform", order: "DESC" }}
    >
      <>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {platforms.map(platform => {
            return <Tab label={platform.toUpperCase()} />;
          })}
        </Tabs>
        <div
          style={{
            width: "100%",
            height: "1px",
            background: "rgb(218 218 218)",
            marginBottom: "10px"
          }}
        ></div>
        {platforms[activeTab] === "web" ? (
          <Tabs
            value={activeWebTab}
            onChange={handleChangeWebTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {Array.from(uniqueDomain).map(domain => {
              return <Tab label={domain.toUpperCase()} />;
            })}
          </Tabs>
        ) : null}
        {platforms[activeTab] === "mobile" ? (
          <Tabs
            value={activeMobileTab}
            onChange={handleChangeMobileTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {mobileDomains.map(domain => {
              return <Tab label={domain.toUpperCase()} />;
            })}
          </Tabs>
        ) : null}
        <CustomizableDatagrid
          defaultColumns={["id", "type", "created_at", "updated_at"]}
          rowClick="edit"
        >
          <TextField source="id" />
          <TextField source="type" />
          <TextField source="domain" />
          <TextField source="iso_code" />
          <TextField source="platform" />
          <DateField source="created_at" />
          <DateField source="updated_at" />
        </CustomizableDatagrid>
      </>
    </List>
  );
};

export const ConfigurationsList = connect(state =>
  state.admin.resources
    ? {
        configurations: Object.values(state.admin.resources.configurations) || null
      }
    : {}
)(ConfigurationsListView);
