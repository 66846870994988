import React from "react";
import { connect } from "react-redux";
import {
  Edit,
  BooleanInput,
  NumberInput,
  TabbedForm,
  FormTab,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  TextInput,
  DateInput,
  FormDataConsumer,
  required
} from "react-admin";

const postDefaultValue = {
  original_gross_price: 0,
  original_net_price: 0,
  gross_price: 0,
  net_price: 0
};

const redirect = (basePath, id, data) => `/products/${data.product_id}/4`;

const ProductPricesEditView = props => {
  return (
    <Edit title={"Edit Price"} {...props}>
      <TabbedForm variant="standard" initialValues={postDefaultValue} redirect={redirect}>
        <FormTab label="Basic details">
          <ReferenceInput
            source="product_id"
            reference="products"
            sort={{ field: "name", order: "ASC" }}
            filterToQuery={name => {
              if (name) {
                return { name_ilike: name };
              }
            }}
            validate={required()}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>

          <ReferenceInput
            source="price_level_id"
            reference="priceLevels"
            sort={{ field: "name", order: "ASC" }}
            defaultValue={props.priceLevel ? props.priceLevel.id : undefined}
            filterToQuery={name => {
              if (name) {
                return { name_ilike: name };
              }
            }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>

          <FormDataConsumer>
            {({ formData, scopedFormData, ...rest }) =>
              formData.price_level_id ? (
                <TextInput
                  source="erp_price_type"
                  defaultValue={props.priceLevel ? props.priceLevel.name : undefined}
                />
              ) : null
            }
          </FormDataConsumer>

          <DateInput source="start_date" validate={required()} />
          <DateInput source="end_date" validate={required()} />

          <BooleanInput source="affiliate_only" />
        </FormTab>
        <FormTab label="Price Details">
          <ReferenceInput
            source="currency_id"
            reference="currencies"
            sort={{ field: "alpha_code", order: "ASC" }}
            filterToQuery={alpha_code => {
              if (alpha_code) {
                return { alpha_code_ilike: alpha_code };
              }
            }}
            defaultValue={props.sek ? props.sek.id : undefined}
            validate={required()}
          >
            <AutocompleteInput optionText="alpha_code" />
          </ReferenceInput>

          <NumberInput source="gross_price" validate={required()} />
          <NumberInput source="net_price" validate={required()} />
        </FormTab>
        <FormTab label="Original Price Details">
          <ReferenceInput
            source="original_currency_id"
            reference="currencies"
            sort={{ field: "alpha_code", order: "ASC" }}
            defaultValue={props.sek ? props.sek.id : undefined}
            validate={required()}
            filterToQuery={alpha_code => {
              if (alpha_code) {
                return { alpha_code_ilike: alpha_code };
              }
            }}
          >
            <AutocompleteInput optionText="alpha_code" label="Original currency" />
          </ReferenceInput>

          <NumberInput source="original_gross_price" validate={required()} />
          <NumberInput source="original_net_price" validate={required()} />
        </FormTab>
        <FormTab label="ERP Price Details">
          <ReferenceInput
            source="erp_currency_id"
            reference="currencies"
            sort={{ field: "alpha_code", order: "ASC" }}
            defaultValue={props.eur ? props.eur.id : undefined}
            validate={required()}
            filterToQuery={alpha_code => {
              if (alpha_code) {
                return { alpha_code_ilike: alpha_code };
              }
            }}
          >
            <SelectInput optionText="alpha_code" label="ERP currency" validate={required()} />
          </ReferenceInput>

          <NumberInput source="erp_gross_price" validate={required()} />
          <NumberInput source="erp_net_price" validate={required()} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const ProductPricesEdit = connect(state =>
  state.admin.resources
    ? {
        priceLevel: Object.values(state.admin.resources.priceLevels.data).find(
          priceLevel => priceLevel.name === "RRP"
        ),
        eur: Object.values(state.admin.resources.currencies.data).find(
          currency => currency.alpha_code === "EUR"
        ),
        sek: Object.values(state.admin.resources.currencies.data).find(
          currency => currency.alpha_code === "SEK"
        )
      }
    : {}
)(ProductPricesEditView);
