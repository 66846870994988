import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { refreshView } from "ra-core";
import {
  fetchEnd,
  fetchStart,
  showNotification,
  crudGetMatching,
  Button,
  SaveButton,
  FormWithRedirect,
  TextInput,
  ReferenceInput,
  SelectInput,
  UPDATE,
  ArrayInput,
  SimpleFormIterator,
  required
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import IconContentEdit from "@material-ui/icons/Edit";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import restClient from "../../services/api/restClient";
import { maxLength } from "../../utils/validators";
import TranslatableParagraph from "../../components/TranslationComponents/TranslatableParagraph";

const styles = {
  button: {
    marginTop: "1em",
    padding: "6px 10px"
  }
};

class HeadTagsQuickEditButton extends Component {
  state = {
    showDialog: false
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleSubmit = values => {
    const {
      fetchStart,
      fetchEnd,
      showNotification,
      record: { head_tags_meta },
      refreshView
    } = this.props;

    let requestPreparedKeywords = [];

    if (values.metadata_keywords && values.metadata_keywords.length) {
      requestPreparedKeywords = values.metadata_keywords.map(k => k.keyword);
    }

    let requestData = {
      type_id: values.type_id,
      meta_data: {
        title: values.metadata_title,
        description: values.metadata_description,
        keywords: requestPreparedKeywords
      }
    };

    fetchStart();

    restClient(UPDATE, "headTags", {
      id: head_tags_meta.id,
      data: requestData
    })
      .then(() => {
        this.setState({ showDialog: false });
        refreshView();
      })
      .catch(error => {
        showNotification(error.message, "error");
      })
      .finally(() => {
        fetchEnd();
      });
  };

  render() {
    const { showDialog } = this.state;
    const { classes, resource } = this.props;
    let {
      record: { head_tags_meta }
    } = this.props;

    if (!head_tags_meta) {
      head_tags_meta = {
        type_id: "",
        meta_data: {
          title: "",
          description: "",
          keywords: []
        }
      };
    }

    return (
      <Fragment>
        <Button
          onClick={this.handleClick}
          variant="contained"
          label="resources.products.tabs.seo_tags.action_button"
          className={classes.button}
        >
          <IconContentEdit />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Edit head tags"
        >
          <DialogTitle>
            <TranslatableParagraph translationLabel="resources.products.tabs.seo_tags.metadata_main_edit" />{" "}
          </DialogTitle>
          <FormWithRedirect
            variant="standard"
            resource="posts"
            save={this.handleSubmit}
            toolbar={null}
            render={({ handleSubmitWithRedirect, pristine, saving }) => (
              <>
                <DialogContent>
                  <h4>Metadata</h4>
                  <ReferenceInput
                    source="head_tags_meta.type_id"
                    label="resources.products.tabs.seo_tags.headtags"
                    reference="headTagTypes"
                    filter={{ name: resource }}
                    validate={required()}
                  >
                    <SelectInput
                      optionText="name"
                      variant="standard"
                      style={{ width: "256px", display: "flex" }}
                    />
                  </ReferenceInput>
                  <TextInput
                    label="resources.products.tabs.seo_tags.title"
                    source="metadata_title"
                    defaultValue={head_tags_meta.meta_data.title}
                    validate={[maxLength(80)]}
                    style={{ width: "256px", display: "flex" }}
                    variant="standard"
                  />
                  <TextInput
                    multiline
                    label="resources.products.tabs.seo_tags.description"
                    source="metadata_description"
                    defaultValue={head_tags_meta.meta_data.description}
                    validate={[maxLength(250)]}
                    variant="standard"
                    fullWidth
                  />
                  <ArrayInput
                    label="resources.products.tabs.seo_tags.keywords"
                    source="metadata_keywords"
                    defaultValue={head_tags_meta.meta_data.keywords}
                  >
                    <SimpleFormIterator>
                      <TextInput
                        label="resources.products.tabs.seo_tags.keywords"
                        source="keyword"
                        style={{ marginLeft: 8 }}
                        variant="standard"
                      />
                    </SimpleFormIterator>
                  </ArrayInput>
                </DialogContent>
                <DialogActions>
                  <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    pristine={pristine}
                    saving={saving}
                  />
                  <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                    <IconCancel />
                  </Button>
                </DialogActions>
              </>
            )}
          />
        </Dialog>
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  crudGetMatching,
  fetchEnd,
  fetchStart,
  showNotification,
  push,
  refreshView
};

export default withStyles(styles)(connect(null, mapDispatchToProps)(HeadTagsQuickEditButton));
