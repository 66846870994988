import React from "react";
import { Filter, TextInput, translate } from "react-admin";

const ListFilters = ({ translate, ...props }) => (
  <Filter variant="standard" {...props}>
    <TextInput label="Name" source="name_ilike" alwaysOn style={{ minWidth: "350px" }} />
  </Filter>
);

export default translate(ListFilters);
