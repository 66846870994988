import React from "react";
import ChipInput from "material-ui-chip-input";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  label: {
    padding: "10px 0px",
    lineHeight: "20px"
  },
  labelShrink: {
    top: 8
  },
  chipContainer: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    padding: `${theme.spacing()}px 0`,
    minWidth: "256px"
  },
  ChipInput: {
    marginTop: `${theme.spacing()}px`
  }
});

const AutocompleteArrayInputChip = props => <ChipInput {...props} />;

export default withStyles(styles)(AutocompleteArrayInputChip);
