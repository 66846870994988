import React from "react";
import { Show, SimpleShowLayout, TextField } from "react-admin";

const ProductTypeShowTitle = ({ record }) => `Product type ${record.name}`;

export const ProductTypeShow = props => (
  <Show title={<ProductTypeShowTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="description" />
    </SimpleShowLayout>
  </Show>
);
