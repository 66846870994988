export const LinkTabs = props => {
  return (
    <div
      style={{
        margin: "10px 0px",
        maxWidth: "256px",
        borderBottom: "1px solid #3498db",
        paddingBottom: "5px",

        display: "flex",
        flexDirection: "column"
      }}
    >
      <span style={{ color: " gray", fontSize: 10, marginBottom: "5px" }}>External</span>
      <a
        href={window.location.href + "/" + props.tabNumber}
        style={{
          fontSize: 12,
          textDecoration: "none",
          color: "#3498db",
          fontWeight: "bold"
        }}
      >
        {props.text}
      </a>
    </div>
  );
};
